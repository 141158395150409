import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManagementService } from '../services/user-management.service';
import { DataService } from '../services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { MessagePopupComponent } from '../utils/popup/message-popup.component';
import { HttpResponse } from '@angular/common/http';
import { LoadingPopupComponent } from '../utils/loading-popup/loading-popup.component';
import { MessageService } from '../services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageLoadService } from '../services/image-load.service';
import { UtilService } from 'src/app/utils/util.service';
import { NavigationService } from '../services/navigation.service';
import { TranslateService } from '../services/translation.service';
import { environment } from 'src/environments/environment';
import { GateWayErrorResponse } from './auth';
import { SSOLoginResponse } from './auth';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  passcode: string;
  message: string;
  userName;
  password;
  isToken: boolean;



  constructor(private router: Router,
    private route: ActivatedRoute,
    private um: UserManagementService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    public loaderService: ImageLoadService,
    private utilService: UtilService,
    private ms: MessageService,
    private navigationService: NavigationService,
    public translateService: TranslateService) { }

  ngOnInit() {
    const token = localStorage.getItem("73S-Auth");

    if (token) {
      this.singleSignIn(token);
      return;
    }

    this.firstLogin();
  }

  firstLogin() {
    this.um.logout();
    this.isToken = false;
    let id_token = this.route.snapshot.paramMap.get('id_token');
    if (id_token) {
      this.isToken = true;
      this.singleSignIn(id_token);
    } else {
      this.redirectToLogin();
    }
  }


  login() {
    this.dialog.open(LoadingPopupComponent, { disableClose: true });
    this.um.login(this.userName.toLowerCase().trim(), this.password).subscribe((session: HttpResponse<any>) => {
      this.um.setIsValidSession(false);
      localStorage.setItem("73S-VCP-userName", this.userName);

      if (session.body["response"].failedAttempt && session.body["response"].failedAttempt > 0 && session.body["response"].failedAttempt < 3) {
        this.showMessage(this.translateService.getLabel("err_login_attempt") + " " + session.body["response"].failedAttempt);
        return;
      }

      // if(session.body["response"].newRegistration || session.body["response"].newUser) {
      //   this.router.navigateByUrl("/resetPassword");
      //   return;
      // }

      if (session.body["response"].failedAttempt && session.body["response"].failedAttempt >= 3) {
        this.showMessage(this.translateService.getLabel("err_login_failed_reset"));
        return;
      }

      if (session.headers.get('X-AUTH-TOKEN')) {
        localStorage.setItem("73S-Auth", session.headers.get('X-AUTH-TOKEN'));
        localStorage.setItem("73S-LogsOutIn", (new Date().getTime() + 7200000) + "");

        this.um.getUserDetailsByID(this.userName).subscribe((userDetails) => {
          if (userDetails.body["success"]) {

            this.um.setUserDetails(userDetails.body["response"]);
            this.um.manageSession(7200000);

            const details = this.um.getUserDetails();

            this.um.addUserAction("User Login", details.id, details.userName, "User Management");
            // if(this.um.isPortalUser()) {
            //   this.router.navigateByUrl('/application-forms/'+details.id);

            // } else if(this.um.is73sAdmin()) {
            //   this.router.navigateByUrl('/users');
            // }

            if (this.um.isSelectedUserCorp()) {
              // this.router.navigateByUrl('/fundList');
              this.navigationService.navigateToSpecificApp(details);
            } else {
              this.router.navigateByUrl('/users');
            }

            console.log('publishing from login', details);
            this.ms.publish("user-logged-in", details.userName);
          } else {
            // console.log("No user details found. Routing back to Login.");
            // this.router.navigateByUrl('/auth');
          }

          this.dialog.closeAll();
        })

      } else {
        this.showMessage(this.translateService.getLabel("err_login_failed"));
      }
    }, error => {
      console.log("Login error", error);
      this.showMessage(this.translateService.getLabel("err_login_failed"));
    })
  }

  singleSignIn(id_token) {
    this.um.singleSignIn(id_token).subscribe(async (session: HttpResponse<SSOLoginResponse>) => {
      let userName = session.body["response"].userName;
      let userId = session.body["response"].id;
      this.um.setIsValidSession(false);
      localStorage.setItem("73S-VCP-userName", userName);

      if (session.body["success"]) {
        localStorage.setItem("73S-Auth", id_token);
        await this.um.generateSelectedUserToken(userId);
        localStorage.setItem("73S-LogsOutIn", (new Date().getTime() + 7200000) + "");

        let t_and_c = session.body["response"].t_and_c;
        let org_consent_required = session.body["response"].org.consentRequired;
        if (org_consent_required && !t_and_c) {
          this.router.navigateByUrl('/t&c');
          return;
        }

        this.um.getUserDetailsByID(userName).subscribe((userDetails) => {
          if (userDetails.body["success"]) {
            console.log("oooooooooooooooooooooooooooooooooo");
            this.um.setUserDetails(userDetails.body["response"]);
            this.um.manageSession(7200000);

            const details = this.um.getUserDetails();

            this.um.addUserAction("User Login", details.id, details.userName, "User Management");

            if (this.um.isSelectedUserCorp()) {
              this.navigationService.navigateToSpecificApp(details);
              //this.router.navigateByUrl('/fundList');
            } else {
              this.router.navigateByUrl('/users');
            }

            console.log('publishing from singleSignIn', details)
            this.ms.publish("user-logged-in", details.userName);
          }

          this.dialog.closeAll();
        })

      } else {
        this.redirectToLogin()
      }
    }, error => {
      console.log("Single Sign In API error", error);
      if (error.status === 407) {
        const errorResponse = error.error as GateWayErrorResponse;
        if (errorResponse.code === 'INVALID_PORTCO_USER') {
          this.router.navigateByUrl('/failure');
          return;
        }
      }
      this.redirectToLogin();
    })
  }

  redirectToLogin() {
    this.isToken = false;

    this.um.getSingleSignOnURL().subscribe((res: HttpResponse<any>) => {
      let redirectUrl = res.body["response"];
      window.location.replace(redirectUrl);
    }, error => {
      this.showMessage("Single Sign On Failed");
    })
  }

  showMessage(msg) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(MessagePopupComponent, {
      data: {
        okButtonMsg: this.translateService.getLabel("ok"),
        dialogMsg: msg
      }
    });
  }


  forgotPasswordForm(content) {
    this.modalService.open(content, {
      centered: true,
      windowClass: 'copy-submitted-modal',
      backdrop: 'static',
      keyboard: false
    });
  }

  forgotPassword() {
    this.modalService.dismissAll();
    this.dialog.open(LoadingPopupComponent, { disableClose: true });

    this.um.forgotPassword(this.userName).subscribe((status) => {
      this.showMessage(this.translateService.getLabel("info_email_pwd") + " " + this.userName);

    }, error => {
      console.log("Error while reseting forgot password.");
      this.showMessage(this.translateService.getLabel("err_reset_pwd"));
    })
  }
}
