import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-portco",
  templateUrl: "./portco.component.html",
  styleUrls: ["./portco.component.scss"],
})
export class PortcoComponent {

  uatB2CSignInUrl = 'https://73stringsuat.b2clogin.com/73stringsuat.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN_PASSWORDLESS_ONLY&&client_id=30ce0ed9-f04b-4c8f-b5d3-8a40e235a208&nonce=defaultNonce&redirect_uri=https%3A%2F%2Faiplatform-uat.73strings.com&scope=openid&response_type=id_token&prompt=login'
  constructor() {}

  ngAfterViewInit() {
    this.openPortcoSignIn();
  }

  openPortcoSignIn() {
    window.open(this.uatB2CSignInUrl, "_self",);
  }
}
