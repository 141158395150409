import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { UserManagementService } from '../services/user-management.service';


@Injectable({
  providedIn: 'root'
})
export class DataManagementService {

  constructor(private http: HttpClient, private ums: UserManagementService) { }


  getListMasterDataMapping(fundId) {
    const url = environment.apiVersion + "/data-management/list-master-data-mappings?fundId=" + fundId;
    return this.appHttpRequest('GET', '', url, null);
  }

  saveMasterDataMappings(masterDataParams) {
    const url = environment.apiVersion + "/data-management/save-master-data-mappings";
    return this.appHttpRequest('POST', '', url, masterDataParams);
  }


  getStagingFiles(orgId) {
    const url = environment.apiVersion + "/data-management/list-staging-file?orgId=" + orgId;
    return this.appHttpRequest('GET', '', url, null);
  }

  processStagingFile(fileId, fundId, updateType, date) {
    const url = environment.apiVersion + "/data-management/process-staging-file?stagingFileId=" + fileId + "&fundId=" + fundId + "&dateUploaded=" + date +"&updateType="+updateType;
    return this.appHttpRequest('POST', '', url, null);
  }

  deleteMasterDataMappings(fundId, idArray) {
    const url = environment.apiVersion + "/data-management/delete-master-data-mappings?fundId=" + fundId;
    return this.appHttpRequest('POST', '', url, idArray);
  }

  listMasterDataOptions(fundId) {
    const url = environment.apiVersion + "data-management/list-master-data-options?fundId=" + fundId;
    return this.appHttpRequest('GET', '', url, null);
  }

  uploadFile(file, fundId, updateType, date) {

    var form = new FormData();
    form.append("file", file, file.name);

    const url = environment.apiVersion + "/data-management/upload-file?dateUploaded="+date+"&fundId="+fundId+"&updateType="+updateType;
    return this.appHttpRequest('FILE', '', url, form);
  }

  bulkProcess(body: any){
    const url = environment.apiVersion + "/data-management/process-rows";
    return this.appHttpRequest('POST', '', url, body);
  }

  listFileUploads(fundId) {
    const url = environment.apiVersion + "/data-management/list-file-uploads?fundId=" + fundId;
    return this.appHttpRequest('GET', '', url, null);
  }

  progress(fileUploadId) {
    const url = environment.apiVersion + "/data-management/progress?fileUploadId=" + fileUploadId;
    return this.appHttpRequest('GET', '', url, null);
  }

  appHttpRequest(type, api, url, body) {
    const h = this.getHeaderToken();
    let apiGatewayUrl = url;
    apiGatewayUrl = environment.apiGateway + '/debtmodel/api' + url;

    apiGatewayUrl = this.addruid(apiGatewayUrl);
  
    switch (type) {
      case "GET": return this.http.get(apiGatewayUrl, { headers: h, observe: 'response' });
      case "POST": return this.http.post(apiGatewayUrl, body, { headers: h, observe: 'response' });
      case "PUT": return this.http.put(apiGatewayUrl, body, { headers: h, observe: 'response' });
      case "DELETE": return this.http.delete(apiGatewayUrl, { headers: h, observe: 'response' });
      case "FILE" :
        h.append('Content-Type', 'multipart/form-data');
        return this.http.post(apiGatewayUrl, body, { headers: h, observe: 'response' });
    }
  }

  addruid(apiGatewayUrl) {
    apiGatewayUrl = apiGatewayUrl.trim();
    let ruid = this.getUID();
    ruid += '-L-' + this.ums.getUserDetails()?.userId;
    ruid += '-S-' + this.ums.getSelectedUserDetails()?.userId;
    if (apiGatewayUrl.includes('?')) {
      apiGatewayUrl = apiGatewayUrl + '&ruid=' + ruid;
    } else {
      apiGatewayUrl = apiGatewayUrl + '?ruid=' + ruid;
    }
    return apiGatewayUrl;
  }
  public getUID() {
    let now = Date.now().toString();
    now = now + Math.floor(Math.random() * 1000);
    if (now.length < 16) {
      for (let index = 0; index < 16 - now.length; index++) {
        now = '0' + now;
      }
    }
    return [now.slice(0, 4), now.slice(4, 10), now.slice(10, 16)].join('_');
  }
   

  getHeaderToken() {
    const authCode = this.ums.getHeaderToken();
    const authSelectedCode = this.ums.getSelectedUserAuthToken();

    // Always set X-AUTH-TOKEN
    let headers = new HttpHeaders({ "X-AUTH-TOKEN": authCode });

    // Conditionally set SELECTED-X-AUTH-TOKEN if it's available
    if (authSelectedCode) {
      headers = headers.set("SELECTED-X-AUTH-TOKEN", authSelectedCode);
    }

    return headers;
  }
}
