<div class="row">
    <div class="col">
        <span class="header">{{ translateService.getLabel("qualitative_analysis") }}</span>
    </div>
    <div class="col" style="margin-right: 10px;" *ngIf ="!ums.currentUserCompanyPermissionReadOnly">
        <span style="float: right;"><button mat-button class="primary-bg"
                (click)="openQualitativeModal(addQualitativeAnalysis)">{{ translateService.getLabel("add") }}</button></span>
    </div>
</div>
<br/>

<div class="row" style="margin: 20px;">
    <div class="col-6">
        <ng-template ngFor [ngForOf]="activeQualitativeAnalysis" let-data let-i="index">
            <div class="card" *ngIf="data.order == 'left'">
                <div class="card-header">
                    <div class="row">
                        <div class="col-8">
                            <span>{{data.title}}</span>
                        </div>
                        <div class="col">
                            <span style="float: right;" *ngIf ="!ums.currentUserCompanyPermissionReadOnly">
                                <ng-template [ngIf]="data.lastModifiedByUser && data?.lastUpdatedOn">
                                    <span class="commentsDetails">
                                        <i class="fas fa-info-circle action-icon icon-active" 
                                        matTooltip="{{data?.lastModifiedByUser}}
                                        {{data?.lastUpdatedOn | date:'medium'}}" 
                                        style="padding-left: 10px;" 
                                        matTooltipClass="mat-tooltip-sts">
                                        </i>
                                    </span>
                                </ng-template>
                                <i class="far fa-edit action-icon row-icon" (click)="editQualitativeModal(editQualitative, data, i)"></i>
                                <i class="fas fa-trash action-icon row-icon" (click)="deleteQualitative(i, data)"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="white-space: pre-wrap; overflow-wrap: break-word;">               
                    <div class="row">
                        <div class="col">
                            <span>{{data.comment}}</span>
                        </div>
                    </div>
                </div>          
            </div>
        </ng-template>
    </div>
    <div class="col-6">
        <ng-template ngFor [ngForOf]="activeQualitativeAnalysis" let-data let-i="index">
            <div class="card" *ngIf="data.order == 'right'">
                <div class="card-header">
                    <div class="row">
                        <div class="col-8">
                            <span>{{data.title}}</span>
                        </div>
                        <div class="col">
                            <span style="float: right;" *ngIf ="!ums.currentUserCompanyPermissionReadOnly">
                                <ng-template [ngIf]="data.lastModifiedByUser && data?.lastUpdatedOn">
                                    <span class="commentsDetails">                                    
                                        <i class="fas fa-info-circle action-icon icon-active" 
                                        matTooltip="{{data?.lastModifiedByUser}}
                                        {{data?.lastUpdatedOn | date:'medium'}}" 
                                        style="padding-left: 10px;" 
                                        matTooltipClass="mat-tooltip-sts">
                                        </i>
                                    </span>

                                </ng-template>
                                <i class="far fa-edit action-icon row-icon" (click)="editQualitativeModal(editQualitative, data, i)"></i>
                                <i class="fas fa-trash action-icon row-icon" (click)="deleteQualitative(i,data)"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-body" style="white-space: pre-wrap; overflow-wrap: break-word;" >               
                    <div class="row">
                        <div class="col">
                            <span>{{data.comment}}</span>
                        </div>
                    </div>
                </div>          
            </div>
        </ng-template>
    </div>   
</div>

   <div *ngIf="deletedQualitativeAnalysis?.length > 0" style="margin: 25px">
    <mat-accordion >
        <mat-expansion-panel>
            <mat-expansion-panel-header >
                <mat-panel-title>
                    <div class="row">
                        <div class="col">
                            <span class="aq-deleted-header">
                                {{ translateService.getLabel("qualitative_analysis_deleted") }}
                            </span>
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

                <div class="deleted-qualitative-analysis">
                    <ng-template ngFor [ngForOf]="deletedQualitativeAnalysis" let-data let-i="index">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-8">
                                        <span>{{data.title}}</span>
                                    </div>
                                    <div style="font-size: 12px; margin-left:auto" *ngIf="!ums.currentUserCompanyPermissionReadOnly">
                                        <span style="float: right;" >
                                                <span class="commentsDetails" *ngIf="data.lastModifiedByUser && data?.deletedOn">
                                                    <i class="fas fa-info-circle action-icon icon-active" 
                                                    matTooltip="{{data?.lastModifiedByUser}}
                                                    {{data?.deletedOn | date:'medium'}}" 
                                                    style="padding-left: 10px;" 
                                                    matTooltipClass="mat-tooltip-sts">
                                                    </i>
                                                </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" >               
                                <div class="row">
                                    <div class="col">
                                        <span>{{data.comment}}</span>
                                    </div>
                                </div>
                            </div>          
                        </div>
                    </ng-template>
                </div>

        </mat-expansion-panel>
        </mat-accordion>
    </div>


<ng-template #addQualitativeAnalysis let-qualitativeAnalysis>
    <div class="modal-header">
        <div class="modal-title">
            {{ translateService.getLabel("add_qualitative_analysis") }}
        </div>
        <button #closeBtn type="button" class="close" aria-label="Close"
            (click)="qualitativeAnalysis.dismiss('Cross click');restQualitativeAnalysisForm()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="model-content">
            <form #qualitativeValue="ngForm"
                (ngSubmit)="AddAndSaveQualitativeAnalysis(qualitativeValue.value); qualitativeAnalysis.dismiss('Cross click');restQualitativeAnalysisForm()">
    
                <div class="row">
                    <div class="col spacing"
                        style="background-color: #f5f4f4; border-radius: 10px; height: 30px; margin: 10px; padding-top: 5px;">
                        <input matInput class="margin" placeholder="{{ translateService.getLabel('add_a_title')}}" type="text" [(ngModel)]="title"
                            [matAutocomplete]="auto" (ngModelChange)="inputChange($event)" name="title">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions" (click)="onOptionSelected(option.label)"
                                style="white-space: normal; line-height: inherit; height: auto; margin: 5px 0;">
                                {{option.label}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

                <span *ngIf="titleExists" style="color: red;">The title already exists.</span>

                <!-- <ejs-richtexteditor id='iframeRTE' #rteObj [toolbarSettings]='tools' [iframeSettings]='iframe' [(value)]="comment" [height]='height' [width]='width'>
                            <ng-template #valueTemplate>
                                <p><b>Add a comment</b></p>-->
                <div class="row">
                    <div class="col spacing comment-field"
                        style="background-color: #f5f4f4; border-radius: 10px; margin: 10px; padding-top: 5px;">
                        <textarea #myInput matInput rows="9" class="margin" placeholder="{{ translateService.getLabel('add_a_comment') }}" [(ngModel)]="comment"
                            name="comment">
                                                    </textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label style="float: right;" *ngIf="comment.length">
                            No. of characters: {{myInput.value.length}}</label>
                    </div>
                </div>
                        <!-- </ng-template>                     
                </ejs-richtexteditor>-->
    
                <div class="row" style="margin-top: 10px;">
                    <div class="col">
                        <span style="float: right;">
                            <button type="button" mat-raised-button class="secondary-bg p-button"
                                (click)="qualitativeAnalysis.dismiss('Cross click');restQualitativeAnalysisForm()">
                                {{ translateService.getLabel("cancel") }}
                            </button>
                            <button [disabled]="!title || !comment || titleExists" [ngClass]="{'borderLess': !qualitativeData.title || !qualitativeData.comment || titleExists}"
                             style="margin-left: 10px;" mat-raised-button class="primary-bg p-button">
                                {{ translateService.getLabel("save") }}
                            </button>
                        </span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #editQualitative let-editQualitative>
    <div class="modal-header">
        <div class="modal-title">
            {{ translateService.getLabel("edit") }} {{ translateService.getLabel("qualitative_analysis") }}
        </div>
        <button type="button" class="close" aria-label="Close"
            (click)="editQualitative.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="model-content">
            <div class="row">
                <div class="col spacing" style="background-color: #f5f4f4; border-radius: 10px; height: 30px; margin: 10px; padding-top: 5px;">
                    <input matInput class="margin" type="text" [(ngModel)]="qualitativeData.title" name="title"
                        style="margin-left: 5px;"
                        [matAutocomplete]="autoEdit" (ngModelChange)="inputChange($event)">

                    <mat-autocomplete autoActiveFirstOption #autoEdit="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions" (click)="qualitativeData.title = option.label;onOptionSelected(option.label)"
                            style="white-space: normal; line-height: inherit; height: auto; margin: 5px 0;">
                            {{option.label}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <span *ngIf="titleExists" style="color: red;">The title already exists.</span>

            <div class="row">
                <div class="col spacing comment-field" style="background-color: #f5f4f4; border-radius: 10px; margin: 10px; padding-top: 5px;">
                    <textarea  #myInput matInput rows="9" class="margin" [(ngModel)]="qualitativeData.comment" name="comment">
                    </textarea>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label style="float: right;" *ngIf="qualitativeData.comment.length">
                        No. of characters: {{myInput.value.length}}</label>
                </div>
            </div>

            <div class="row" style="margin-top: 10px;">
                <div class="col">
                    <span style="float: right;">
                        <button type="button" mat-raised-button class="secondary-bg p-button"
                            (click)="editQualitative.dismiss('Cross click');restQualitativeAnalysisForm()">
                            {{ translateService.getLabel("cancel") }}
                        </button>
                        <button [disabled]="!qualitativeData.title || !qualitativeData.comment" 
                            style="margin-left: 10px;" mat-raised-button class="primary-bg p-button"
                            [ngClass]="{'borderLess': !qualitativeData.title || !qualitativeData.comment}"
                            (click)="editQualitativeData(); editQualitative.dismiss('Cross click');restQualitativeAnalysisForm()">
                            {{ translateService.getLabel("update") }}
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

