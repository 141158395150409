<div class="app-container">
  <mat-toolbar class="row topbar-toolbar"
    *ngIf="!(router.url.indexOf('/auth') >= 0 || router.url=='/scheduled-upgrade' || router.url == '/status' || router.url == '/login-fail' || router.url == '/logout' || router.url.includes('/organization') || router.url == '/product-integration')">

    <div class="col-3">

      <div class="row">
        <div>
          <button mat-icon-button class="primary-bg-theme-header" (click)="snav.toggle()" *ngIf="!navigationService.isExternalUser">
            <i class="fas fa-bars"></i>
          </button>
        </div>
        <div style="align-self: center;">
          <h1 class="topbar-app-name">
            {{translateService.getLabel("qubit_x_logo")}}
            <ng-template [ngIf]="router.url.indexOf('/monitoring') >= 0">
              <span>- Monitoring</span>
            </ng-template>
            <ng-template [ngIf]="orgLogoUrl?.logo">
              <span style="max-width:50px; max-height:50px; margin-left: 10px;">
                <img style="max-width:50px; max-height: auto;" [src]="orgLogoUrl.logo" alt="">
              </span>
            </ng-template>
          </h1>
        </div>
      </div>
      <!-- <span> -->
      <!-- <img [src]="imageLoadService.getImage('companyIcon.png')" class="company-icon" alt=""> -->
      <!-- </span> -->
    </div>
    <div class="col" style="text-align: right;">
      <app-company-search [isLoggedIn]="isLoggedIn"></app-company-search>
    </div>

    <div class="col right-content ml-auto" *ngIf="isLoggedIn" style="max-width: 285px;">
      <!-- <span class="top-icon">
        <i class="fas fa-tools" [routerLink]="'/tools-home'"></i>
      </span> -->

      <!-- <mat-icon id="doc360_help_btn" class="btn hide" (click)="openHelpCentrePopup()">import_contacts</mat-icon> -->

      <span class="top-icon notify-pd" *ngIf="isLoggedIn && !us.isExternalUser() && (router.url.includes('company-valuation') || router.url.includes('/portfolio'))">
        <app-check-list-pop-up></app-check-list-pop-up>
      </span>

      <span class="top-icon notify-pd" style="margin-right: 5px;">
        <button mat-button [matMenuTriggerFor]="menu" style="font-size: medium; padding: 0%; min-width: none;">
          {{translateService.selectedLang}}
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
          
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="translateService.changeLanguage('EN')"> <b style="font-size: medium;">EN</b> &nbsp;&nbsp;&nbsp; <span style="font-size: small;">English (International)</span> </button>
          <button mat-menu-item (click)="translateService.changeLanguage('FR')"> <b style="font-size: medium;">FR</b> &nbsp;&nbsp;&nbsp; <span style="font-size: small;">Français (French)</span> </button>
        </mat-menu>
      </span>

      <span class="top-icon notify-pd" *ngIf="isLoggedIn && router.url !== '/users' && router.url !== '/user-management' && !us.isExternalUser()">
        <i class="far fa-bell" (hidden)="markAllAsRead()" [autoClose]="'outside'" [ngbPopover]="notificationContent"></i>
        <span id="red-badge"></span>
      </span>

      <span class="userName" style="cursor: pointer;" (click)="openChangePasswordForm()">{{navigationService.shortUserName}}</span>
      <span class="logout"><em (click)="logout(true); snav.close()" class=" fa fa-power-off"></em></span>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="topbar-sidenav-container">
    <mat-sidenav #snav fixedTopGap="56" class="sidenav-style" (backdropClick)="snav.close()">
      <!-- <mat-nav-list class="list-style">
        <a mat-list-item *ngFor="let navItem of navigationService.allowedNavItems"
          (click)="snav.toggle(); navigationService.openSidenavLinks(navItem.link)" class="sidenav-links">
          <span class="label">{{navItem.label}}</span>
        </a>
      </mat-nav-list> -->

      <!-------------------New SideMenu UI Start------------------->

      <app-side-menu (navToggle)="snav.close()"></app-side-menu>
      
      <!----------------------------END---------------------------->
    </mat-sidenav>

    <mat-sidenav-content style="overflow-x: hidden;">
      <div *ngIf="notification" style="text-align: right;">
        {{notification.message}} - <b>{{notification.label}}</b>
      </div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #notificationContent>
  <app-notification></app-notification>
</ng-template>

<ng-template #instaValContent let-instaValModal>
  <div class="modal-header">
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="instaValModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body company-search">
    <div style="height: 60vh; margin: 5px;">
      Company Name
      <div class="row">
        <div class="col-9">
          <mat-form-field>
            <input matInput [(ngModel)]="instaValCompanyName" />
          </mat-form-field>
        </div>

        <div class="col-3">
          <span class="search" (click)="searchInstaCompany()" *ngIf="!searchingCompany">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </div>
      <div class="iv-search-list">
        <div *ngIf="searchingCompany" style="margin: 10px 0;">{{searchingMessage}}</div>

        <div *ngIf="ivSearchList.length === 0 && !searchingCompany" style="margin: 10px 0;">
          {{searchingMessage}}
        </div>

        <div *ngIf="searchingMessage === 'No matching companies found.'">
          <button class="btn landscape-btn" (click)="openRequestForm(newCompanyContent)" mat-raised-button>
            Request Company Information
          </button>
        </div>

        <div *ngFor="let company of ivSearchList" style="margin: 10px 0; cursor: pointer;"
          (click)="openInstaValForm(company)">
          <div class="iv-company">
            <b>{{company.name}}</b>
            <span class="form-tag" *ngIf="company.tag">{{company.tag}} </span>
            - <i>{{company.region}}</i>
          </div>
          <!-- <div style="margin-left: 5px;">
            <ng-template [ngIf]="company.source === '111'">data quality score - high</ng-template>
            <ng-template [ngIf]="company.source === '222'">data quality score - low</ng-template>
          </div> -->
          <hr>
        </div>
      </div>
    </div>
  </div>
</ng-template>
