import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FundListService } from '../qubit-x/portfolio/fund-list-ui/fund-list.service';
import { PortFolioSummaryServiceV2 } from '../qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.service';
import { DataService } from '../services/data.service';
import { MessageService } from '../services/message.service';
import { TranslateService } from '../services/translation.service';
import { UserManagementService } from '../services/user-management.service';
import { UtilService } from '../utils/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'nav-menu-header',
  templateUrl: './nav-menu-header.component.html',
  styleUrls: ['./nav-menu-header.component.scss']
})
export class NavMenuHeaderComponent implements OnInit, OnChanges {
  @Input() headerLabel;
  @Input() lockIcon;
  @Input() headerToBeAppended;
  @Input() currency;
  @Input() isDebtInvestmentSummary;

  @Output() navClick = new EventEmitter();
  
  isLoggedIn = false;
  isInstaVal = false;
  isT2 = false;

  url:any
  isCreditURL:boolean = false
  isEquityURL:boolean = false
  formData;
  fundId: string;
  fundName;
  isCredit:boolean = false
  isCreditFundId;

  constructor(public router: Router, 
    private ds: DataService, private ms: MessageService, 
    private popUpService:UtilService,
    private ums: UserManagementService,
    public fundService : FundListService,
    private portfolioService: PortFolioSummaryServiceV2,
    private route : ActivatedRoute,
    public translateService: TranslateService) { 

    this.router.events.subscribe((event) => {
      // Only receive NavigationStart events
      if(event instanceof NavigationEnd && ( event.url.toUpperCase() !== "/AUTH" && event.url !== "/")) {

        this.isLoggedIn = true;
      }
      else if(event instanceof NavigationEnd && (event.url.toUpperCase() === "/AUTH" || event.url === "/")) {

        this.isLoggedIn = false;
      }
    });
  }
 
  ngOnInit() {
    const url = this.router.url;
    this.isCreditURL = url.includes('leverage-ratio');
    this.isEquityURL  = url.includes('configuration-v2')
    const forParam = this.route.snapshot.queryParamMap.get('for');
    this.isCredit = forParam === 'credit';
    this.ms.subscribe("formData", (data)=>{
      if(data){
        this.formData = data;    
      }
    });
    this.route.paramMap.subscribe(async params => {
      this.fundId = params.get("fundId");

      if (!this.fundId) {
        this.fundId = this.route.snapshot.queryParamMap.get('fundId');
        this.isCreditFundId = this.route.snapshot.queryParamMap.get('fundId');

        if (!this.fundId) {
          this.fundId = this.route.snapshot.queryParamMap.get("parentId");
        }
        
        if (!this.fundId) {
          const hash = window.location.hash.split("#/")[1]; 
          if (hash) {
            const hashSegments = hash.split("/"); 
            this.fundId = hashSegments[1] || null; 
          }
        }
      }

      this.fundName = await this.portfolioService.getSelectedFundName();
    });
 
  }

  ngOnChanges() {
    if(this.lockIcon) {
      this.isInstaVal = this.ds.isInstaValForm();
      this.isT2 = this.ds.isT2Form();
    }
  }

  navigate(){
    this.popUpService.navigateHome();
  }

  getConfigurationLabel() {
    if (this.isCreditURL) {
      return this.translateService.getLabel("73_Value_Credit_Configuration");
    } else if (this.isEquityURL) {
      return this.translateService.getLabel("73_Value_Equity_Configuration");
    }
  }

  goToEquity() {
    const url = this.fundId 
      ? `${environment.portalUrl}/#/configuration-v2/${this.fundId}` 
      : `${environment.portalUrl}/#/configuration-v2/default`;
    window.open(url, '_blank');
  }
  
  goToCredit() {
    const url = `${environment.portalUrl}/#/leverage-ratio?fundId=${this.fundId || ''}&for=credit`;
    window.open(url, '_blank');
  }
  

  navigateToFundList(){
    if(this.headerLabel == this.translateService.getLabel('funds')){
      const forParam = this.route.snapshot.queryParamMap.get('for');
      if (forParam === 'credit') {
        window.open(environment.portalUrl + "/v2-home/credit-portfolio", "_self");
      } else {
        this.router.navigateByUrl('/fundList');
      }
    }
  }


  navClickEvent(headerLabel) {
      this.navClick.next(headerLabel);
  }

  navigateToPortfolio() {

    let parentId = this.portfolioService.selectedFundId;
    
    if(!parentId){
      parentId = this.route.snapshot.queryParamMap.get('parentId');
    }

    // Back Navigation from Value Bridge Fund Level
    if(!parentId){
      parentId = this.route.snapshot.queryParamMap.get('fundId');
    }

    if(!parentId){
      parentId = this.route.snapshot.params.fundId;
    }

    // const fundExists = this.fundService.getFundById(parentId);
    
    try {
      let reportId = this.route.snapshot.queryParamMap.get('reportId')
      if(reportId){ 
        this.router.navigateByUrl('/customSummary/' + reportId);
      }
      else {
        const forParam = this.route.snapshot.queryParamMap.get('for');
        if (forParam === 'credit') {
          window.open(environment.portalUrl +"/v2-home/fund/"+ parentId,"_self");
        } else {
          this.router.navigateByUrl('/portfolio/' + parentId);
        }
      }
    } catch(err) {
      this.router.navigateByUrl('/users');
    }
  }

  onSpanClick(event: MouseEvent) {
    event.stopPropagation();
  }

  navigateToConsolidatedSummary() {
    const fundId = this.route.snapshot.queryParamMap.get('fundId');
    const companyId = this.route.snapshot.queryParamMap.get('companyId');
    let issuerCompanyId;

    this.ds.getIssuerCompanyId(companyId).subscribe((response:any)=>{
      issuerCompanyId = response?.body['response']?.issuerCompanyId;
      this.router.navigateByUrl(`/debt-company-summary?issuerCompanyId=${issuerCompanyId}&companyId=${companyId}&fundId=${fundId}`);
    })
  }
}
