<nav-menu-header headerLabel="Funds" headerToBeAppended="Configuration"></nav-menu-header>

<div class="leverage-header-margin">
  <span class="main-header">Leverage Ratio Grid</span>
</div>

<mat-spinner style="margin: 0 auto;" [diameter]="50" *ngIf="!isLoading">
</mat-spinner>
<div class="table-custom-border" *ngIf="isLeverageData">
  <table class="table">
    <tr class="table-custom-header">
      <th class="col-sm-2 align-right">Low</th>
      <th class="col-sm-2 align-right">High</th>
      <th class="col-sm-5 align-right">Concluded Adjustment factor</th>
      <th></th>
      <th></th>
    </tr>
    <tr class="table-custom-body" *ngFor="let row of gridArray; let i = index">
      <td *ngIf="!row.isEdit" class="align-right">
        {{ row.low| dynamicDecimalPipe }}x
      </td>
      <td *ngIf="!row.isEdit" class="align-right">
        {{ row.high| dynamicDecimalPipe }}x
      </td>
      <td *ngIf="!row.isEdit" class="align-right">
        {{ row.concludedAdjustmentFactor | dynamicDecimalPipe }}%
      </td>

      <td *ngIf="row.isEdit">
        <ejs-numerictextbox class="input-field yellow-input" style="width: 92%" max="100" [(ngModel)]="row.low"
          format="#,###.0'x'" [showSpinButton]="false" (ngModelChange)="validateInputs(row, i)">
        </ejs-numerictextbox>
      </td>
      <td *ngIf="row.isEdit">
        <ejs-numerictextbox class="input-field yellow-input" style="width: 92%" max="100" [(ngModel)]="row.high"
          format="#,###.0'x'" [showSpinButton]="false" (ngModelChange)="validateInputs(row, i)">
        </ejs-numerictextbox>
      </td>
      <td *ngIf="row.isEdit">
        <ejs-numerictextbox class="input-field yellow-input" style="padding-right: 2px;" max="100"
          [(ngModel)]="row.concludedAdjustmentFactor" format="#,###.0'%'" [showSpinButton]="false">
        </ejs-numerictextbox>
      </td>
      <!-- <td>
        <i class="far fa-save cursor-pointer" *ngIf="row.edit==true" (click)="updateMetric(row)"></i>
      </td> -->
      <td class="align-right">
        <i *ngIf="!row.isEdit" class="far fa-edit cursor-pointer" (click)="row.isEdit=true"></i>
      </td>
      <td class="align-center">
        <i class="far fa-trash-alt cursor-pointer" (click)="deleteMetric(i)"></i>
      </td>
    </tr>
    <!-- <tr>
      <td colspan="2" class="column-2">
      </td>
    </tr> -->
  </table>
  <div *ngIf="errorMessage" class="text-danger">
    {{ errorMessage }}
  </div>
</div>

<div class="button-margin" *ngIf="isLoading">
  <button mat-raised-button type="button" class="btn-custom-primary add-btn-margin" (click)="addNewMetric()">Add</button>
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="updateMetric()" [disabled]="!isInputValid || isSaving">Save</button>
</div>

<div>
  <mat-spinner style="margin: 0 auto;" [diameter]="40" *ngIf="isSaving">
  </mat-spinner>
</div>


<!-- Performace Classification Section -->

<div class="performance-header-margin">
  <span class="main-header">Performance Classification</span>
</div>

<div class="table-custom-border" *ngIf="isClassificationData">
  <table class="table">
    <tr class="table-custom-header">
      <th class="table-column-seperator" colspan="4">Based on Enterprise Value Coverage</th>
    </tr>
    <tr class="table-custom-header">
      <th class="col-sm-2 align-right">Upto</th>
      <th class="col-sm-3 align-right">Classification</th>
      <th></th>
      <th></th>
    </tr>
    <tr class="table-custom-body" *ngFor="let row of performanceArray; let i = index">
      <td *ngIf="!row.isEdit" class="align-right">
        {{ row.upperBound | dynamicDecimalPipe }}%
      </td>
      <td *ngIf="!row.isEdit" class="align-right">
        {{ row.classification }}
      </td>
      <td *ngIf="row.isEdit">
        <ejs-numerictextbox class="input-field yellow-input" style="width: 92%" [(ngModel)]="row.upperBound" max="100"
          format="#,###.0'%'" [showSpinButton]="false">
        </ejs-numerictextbox>
      </td>
      <td *ngIf="row.isEdit">
        <input class="textBox-input" style="width: 92%" [(ngModel)]="row.classification" [showSpinButton]="false">
      </td>
      <td class="align-right">
        <i *ngIf="!row.isEdit" class="far fa-edit cursor-pointer" (click)="row.isEdit=true"></i>
      </td>
      <td class="align-center">
        <i class="far fa-trash-alt cursor-pointer" (click)="deleteClassification(i)"></i>
      </td>
    </tr>
  </table>
  <!-- Comments box -->
  <textarea class="commentsBox-input" placeholder="Add Comment" [(ngModel)]="classificationComments"></textarea>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary add-btn-margin"
    (click)="addNewClassification()">Add</button>
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="updateClassification()"
    [disabled]="isClassificationSaving">Save</button>
</div>


<!-- Fair Value Conclusion -->
<div class="leverage-header-margin">
  <span class="main-header">Fair Value Conclusion</span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">Do you want to conclude on the Dirty Price or the Clean Price?</span>      
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectedfairValue">
        <mat-option [value]="'dirtyPrice'">Dirty Price</mat-option>
        <mat-option [value]="'cleanPrice'">Clean Price</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(fairValueContent)"
    [disabled]="fairValueSaved">Save</button>
</div>


<!-- Value Bridge Conclusion -->
<div class="leverage-header-margin">
  <span class="main-header">Enable Value Bridge</span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">Do you want to enable Value Bridge</span>      
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectedValueBridge">
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(valueBridgeContent)"
    [disabled]="isValueBridgeSaving">Save</button>
</div>

<!-- Percentage par computation -->
<div class="leverage-header-margin">
  <span class="main-header">% of Par Computation</span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      In computing the % of Par, do you want to take into account the accrued interest
      between the valuation date and the last payment date before?
    </span>      
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectedPerecentPar">
        <mat-option [value]="false">No</mat-option>
        <mat-option [value]="true">Yes</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(percentParContent)"
    [disabled]="percentParValueSaved">Save</button>
</div>

<!-- Number of Decimals -->
<div class="leverage-header-margin">
  <span class="main-header">Number of Decimals</span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">How many decimals do you want to display across the platform?</span>      
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectdNumberOfDecimals">
        <mat-option [value]="'1'">1</mat-option>
        <mat-option [value]="'2'">2</mat-option>
        <mat-option [value]="'3'">3</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(numberofDecimals)"
    [disabled]="isNumberOfDecimalsSaved">Save</button>
</div>

<!-- Price Rounding -->
<div class="leverage-header-margin">
  <span class="main-header">Price Rounding</span><br>
  <div class="margin-credit-quality">
    <span class="credit-quality-label">Do you want to round the concluded price of the investments?
    </span>
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectedPriceRounding">
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="margin-credit-quality" *ngIf="selectedPriceRounding">
    <span class="credit-quality-label">Round to Nearest</span>
    <mat-form-field class="custom-input-select">
      <input matInput  class="right" type="number" [(ngModel)]="selectedPriceRoundingValue">
    </mat-form-field>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(priceRoundingContent)"
    [disabled]="priceRoundingValueSaved">Save</button>
</div>

<!-- Reset Credit Quality -->
<div class="leverage-header-margin">
  <span class="main-header">Reset Credit Quality</span><br>
  <div class="margin-credit-quality">
    <span class="credit-quality-label">On rollover, to set all calculations to default in credit quality adjustment factor and custom adjustment - select 'Yes'.</span>
  
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectedDefaultCreditQuality">
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field><br>
  
    <label style="font-size: 12px;">Note - all manual overwrites will be lost.</label>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(creditQualityContent)"
    [disabled]="isCreditQualitySaving">Save</button>
</div>

<!-- Call Premium - % of Par -->
<div class="leverage-header-margin">
  <span class="main-header">Cap % of Par</span><br>
  <div class="margin-credit-quality">
    <span class="credit-quality-label">Cap % of Par in case of call premiums
      <mat-icon class="mat-info-icon info-icon" matTooltip="This setting allows capping of % of Par, clean or dirty, in case the 
      concluded value is greater" matTooltipPosition="below">info_outline</mat-icon>
    </span>
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectedCallPremium">
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="margin-credit-quality" *ngIf="selectedCallPremium">
    <span class="credit-quality-label">Cap clean or dirty price?</span>
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectedCallPremiumValue">
        <mat-option [value]="'clean'">Clean</mat-option>
        <mat-option [value]="'dirty'">Dirty</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(callPremium)"
    [disabled]="isCallPremiumCapSaving">Save</button>
</div>

<!-- Forward Rate -->
<div class="leverage-header-margin">
  <span class="main-header">Selection of Forward rate to be applied</span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">Do you want to apply forward rate lag?
      <mat-icon class="mat-info-icon info-icon" matTooltip="By applying a lag, you are choosing to select the forward base rates prevalent 
      on the previous cashflow date" matTooltipPosition="below">info_outline</mat-icon>
    </span>      
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectedForwardRate">
        <mat-option [value]="'1'">Yes</mat-option>
        <mat-option [value]="'0'">No</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(forwardRate)"
    [disabled]="isForwardRateSaving">Save</button>
</div>

<!-- Discount Factor Computation -->
<div class="leverage-header-margin">
  <span class="main-header">Discount Factor Computation</span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to adjust the discount factor with a payment frequency adjustment?
    </span>      
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectedDiscountFactor">
        <mat-option [value]="'0'">No</mat-option>
        <mat-option [value]="'1'">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>

    <label style="font-size: 12px; padding-top: 10px;">Note - the discount rate and discount period will be divided and multiplied, respectively, by the payment frequency.</label>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(discountFactorContent)"
    [disabled]="isDiscountFactorSaving">Save</button>
</div>

<!-- Different Discounting -->
<div class="leverage-header-margin">
  <span class="main-header">Different Discounting Interest Payments - Principal Repayments </span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to add a custom adjustment in the discount rate when discounting principal repayments only? 
    </span>      
    <mat-form-field class="custom-input-select">
      <mat-select [(ngModel)]="selectedDifferentDiscounting">
        <mat-option [value]="'0'">No</mat-option>
        <mat-option [value]="'1'">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(differentDiscountingContent)"
    [disabled]="isDifferentDiscountingSaving">Save</button>
</div>

<!-- Warrant Valuation Discount -->
<div class="leverage-header-margin">
  <span class="main-header">Warrant Valuation Discount Rate</span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Discount Rate Methodology 
    </span>      
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedDiscountRateMethodology">
        <mat-option [value]="'0'">Equity Module Suggested Dis. Rate</mat-option>
        <mat-option [value]="'1'">Custom Input - Damodaran CoD Multiplier</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(warrantValuationDiscountContent)"
    [disabled]="isDiscountRateMethodologySaving">Save</button>
</div>

<!-- Original Issue Discount Impact -->
<div class="leverage-header-margin">
  <span class="main-header">Original Issue Discount Impact</span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to take into account the impact of OID on the Implied Yield as of Amendment Date? 
    </span>      
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedOIDImpact">
        <mat-option [value]="'0'">No</mat-option>
        <mat-option [value]="'1'">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>


    <label style="font-size: 12px; padding-top: 10px;">Note that the investment will not be at par at origination date if this setting is set to ‘Yes’ and there is an OID.</label> 
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(oidImpactContent)"
    [disabled]="isOIDImpactSaving">Save</button>
</div>

<!-- CSA (Credit Spread Adjustment) -->
<div class="leverage-header-margin">
  <span class="main-header">Credit Spread Adjustment</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to be able to add a Credit Spread Adjustment (CSA) on the interest rates? 
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedCSAImpact">
        <mat-option [value]="'0'">No</mat-option>
        <mat-option [value]="'1'">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
    <label style="font-size: 12px; padding-top: 10px;">Note that the use of this adjustment is advised to mitigate the risk of value transfer when transitioning a reference rate (e.g., LIBOR) to another risk-free rate (e.g., SOFR)</label> 
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(csaImpactContent)"
    [disabled]="isCSAImpactSaving">Save</button>
</div>

<!-- Middle Market Adjustment -->
<div class="leverage-header-margin" *ngIf="isClientSpecificSettings">
  <span class="main-header">Middle Market Adjustment</span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to be able to add a Middle Market Adjustment on the discount rate?
    </span>      
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedMiddleMarketAdj">
        <mat-option [value]="'0'">No</mat-option>
        <mat-option [value]="'1'">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin" *ngIf="isClientSpecificSettings">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(middleMarketAdj)"
    [disabled]="isMiddleMarketAdjSaving">Save</button>
</div>

<!-- EUR LLI Adjustment -->
<div class="leverage-header-margin" *ngIf="isClientSpecificSettings">
  <span class="main-header">EUR LLI Adjustment</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to be able to add a EUR LLI Adjustment on the discount rate?
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedEurLliAdj">
        <mat-option [value]="false">No</mat-option>
        <mat-option [value]="true">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin" *ngIf="isClientSpecificSettings">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(eurLliAdj)"
    [disabled]="isEurLliAdj">Save</button>
</div>

<!-- Spread Matrix Adjustments -->
<div class="leverage-header-margin" *ngIf="isClientSpecificSettings">
  <span class="main-header">Spread Matrix Adjustments</span>

  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to add the adjustments from the spread matrix to the discount rate?
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedSpreadMatrixAdjustments">
        <mat-option [value]="'0'">No</mat-option>
        <mat-option [value]="'1'">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin" *ngIf="isClientSpecificSettings">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(spreadMatrixAdjustments)"
    [disabled]="isSpreadMatrixAdjustmentsSaving">Save</button>
</div>
<!-- Amortization Schedule Logic -->
<div class="leverage-header-margin">
  <span class="main-header">Amortization Schedule Logic</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to adjust the principal repayments based on the frequency?
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedAmortization">
        <mat-option [value]="false">No</mat-option>
        <mat-option [value]="true">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>
<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(amortizationSchedule)"
    [disabled]="isAmortization">Save</button>
</div>

<!-- Tags -->
<div class="leverage-header-margin">
  <span class="main-header">Tags</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to add tags to the investments?
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedTags">
        <mat-option [value]="'0'">No</mat-option>
        <mat-option [value]="'1'">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
    <form [formGroup]="tagsForm" *ngIf="selectedTags === '1'" class="row">
      <div formArrayName="allTagFormData" *ngFor="let item of tagsForm.get('allTagFormData')['controls']; let i = index"
        class="col-md-4">
        <div class="p-3 border mt-3">
          <div class="float-right">
            <button type="button" class="btn" (click)="deleteTag(i)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div [formGroupName]="i" class="pt-4">
            <div class="row my-4 ml-0">
              <div class="col-2">
                <span class="fair-value-label">
                  Name
                </span>
              </div>
              <div class="col-1">
                <mat-form-field class="custom-input-select">
                  <input matInput class="left" formControlName="name" type="text">
                </mat-form-field>
              </div>
            </div>
            <div class="row my-4 ml-0">
              <div class="col-2">
                <span class="fair-value-label">
                  Level
                </span>
              </div>
              <div class="col-1">
                <mat-form-field class="custom-input-select">
                  <mat-select formControlName="level">
                    <mat-option [value]="'company'">Company</mat-option>
                    <mat-option [value]="'security'">Security</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row my-4 ml-0">
              <div class="col-2">
                <span class="fair-value-label">
                  Options
                </span>
              </div>
              <div class="col-10">
                <mat-form-field class="custom-input-select">
                  <input matInput class="left" type="text" [formControl]="option">
                </mat-form-field>
                <button type="button" class="btn-custom-primary add-btn-margin option-button" *ngIf="selectedTags"
                  (click)="addOption(i)">Add</button>
              </div>
              <div class="col-2">
                
              </div>
                <mat-chip-list class="mt-3 ml-3">
                  <mat-chip *ngFor="let option of tagsForm.value.allTagFormData[i].options" class="chip">
                    {{ option }}
                    <mat-icon matChipRemove (click)="removeOption(i, option)">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="button-margin" style="padding-bottom: 20px;">
  <p *ngIf="tagsErrorState.duplicateTagsFound" style="color: red"><em>Duplicates Found!</em></p>
  <button mat-raised-button type="button" class="btn-custom-secondry add-btn-margin" *ngIf="selectedTags === '1'"
    (click)="addTag()">Add</button>
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(bxTagsContent)"
    [disabled]="tagsErrorState.duplicateTagsFound || isTagsSaving">Save</button>
</div>

<!-- Additional Input  -->
<div class="leverage-header-margin">
  <span class="main-header">Additional Inputs</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to add additional inputs to the investments?
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedAdditionalInput">
        <mat-option [value]="'0'">No</mat-option>
        <mat-option [value]="'1'">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
    <form [formGroup]="additionalInputForm" *ngIf="selectedAdditionalInput === '1'" class="row">
      <div formArrayName="allAdditionalInput" *ngFor="let item of additionalInputForm.get('allAdditionalInput')['controls']; let i = index" class="col-md-3">
        <div class="p-3 border mt-3">
          <div class="float-right">
            <button type="button" class="btn" (click)="deleteInput(i)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div [formGroupName]="i" class="pt-4">
            <div class="row my-4 ml-0">
              <div class="col-2">
                <span class="fair-value-label">
                  Name
                </span>
              </div>
              <div class="col-1">
                <mat-form-field class="custom-input-select">
                  <input matInput class="left" formControlName="name" type="text">
                </mat-form-field>
              </div>
            </div>
            <div class="row my-4 ml-0">
              <div class="col-2">
                <span class="fair-value-label">
                  Level
                </span>
              </div>
              <div class="col-1">
                <mat-form-field class="custom-input-select">
                  <mat-select formControlName="level">
                    <mat-option [value]="'company'">Company</mat-option>
                    <mat-option [value]="'security'">Security</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="button-margin" style="padding-bottom: 20px;">
  <p *ngIf="additionalInputErrorState.duplicateAdditionalInputFound" style="color: red"><em>Duplicates Found!</em></p>
  <button mat-raised-button type="button" class="btn-custom-secondry add-btn-margin" *ngIf="selectedAdditionalInput === '1'" (click)="addInputs()">Add</button>
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(additionalInputData)"
    [disabled]="isInputSaving || additionalInputErrorState.duplicateAdditionalInputFound">Save</button>
</div>

<!-- Shadow Rating  -->
<div class="leverage-header-margin" *ngIf="isClientSpecificSettings">
  <span class="main-header">Shadow Rating</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to add the shadow rating methodology to compute the credit rating?
    </span>      
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedShadowRating">
        <mat-option [value]="false">No</mat-option>
        <mat-option [value]="true">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>
<div class="classification-button-margin" *ngIf="isClientSpecificSettings">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(shadowRating)"
    [disabled]="isShadowRating">Save</button>
</div>

<!-- Spread build up -->
<div class="leverage-header-margin" *ngIf="isClientSpecificSettings">
  <span class="main-header">Spread Build-up</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to add the spread build-up methodology to value investments?
    </span>      
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedSpreadBuildUp">
        <mat-option [value]="'0'">No</mat-option>
        <mat-option [value]="'1'">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>
<div class="classification-button-margin" *ngIf="isClientSpecificSettings">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(spreadBuildUp)"
    [disabled]="isSpreadBuildUp">Save</button>
</div>

<!-- Qualitative Analysis  -->
<div class="leverage-header-margin">
  <span class="main-header">Qualitative Analysis</span>

  <div class="row">
    <div class="margin-credit-quality col-md-4 mb-4">
      <span class="fair-value-label">
        <mat-form-field class="custom-input-select col-md-12 pl-0 pr-0">
          <input matInput placeholder="Enter a title" class="text-capitalize" [formControl]="qualitativeAnalysisNewTitle" />
        </mat-form-field>
      </span>
    </div>
    <div class="col-md-2 text-left pl-0 mt-2 pt-1">
      <button mat-raised-button type="button" class="mt-1 ml-0 btn-custom-primary option-button"
        [disabled]="!qualitativeAnalysisNewTitle.valid" (click)="addNewTitle()">Add</button>
    </div>
    <div class="col-md-12">
      <div *ngFor="let title of qualitativeAnalysisTitleList; let a=index" class="mb-1 setHeightList">
        <!-- Update title  -->
        <div class="fair-value-label d-flex">

          <span class="mr-2"><b>{{a+1}}.</b></span>
          <ng-template [ngIf]="title.editStatus">
            <div class="position-relative topSetQa w-auto d-block">
              <mat-form-field class="custom-input-select col-md-12 pl-0 pr-0 w-auto mr-2">
                <input matInput placeholder="Enter a title" class="w-auto text-capitalize" [value]="title.value" #titleUpdate />
              </mat-form-field>
            </div>
            <button type="button" class="btn-sm w-auto btn-custom-secondry p-0 pl-2 pr-2 topSetQa position-relative" 
            (click)="updateTitle(a,titleUpdate.value)" [disabled]="!titleUpdate?.value">Update</button>
          </ng-template>

          <!-- list of title  -->
          <ng-template [ngIf]="!title.editStatus">
            <span class="text-capitalize">{{title.value}}</span>
            <i (click)="title.editStatus=!title.editStatus" class="far fa-edit cursor-pointer ml-4 mt-1 mr-2"></i>
            <i class="far fa-trash-alt cursor-pointer ml-1 mt-1" (click)="deleteTitle(a)"></i>
          </ng-template>

        </div>

      </div>
    </div>

  </div>
</div>
<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(qualitativeAnalysis)"
    [disabled]="isQualitativeAnalysis">Save</button>
</div>


<!-- Change in NWC  -->
<div class="leverage-header-margin">
  <span class="main-header">Change In NWC</span>
  
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to add change in NWC in the financials? 
    </span>      
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedFinancialNWC">
        <mat-option [value]="false">No</mat-option>
        <mat-option [value]="true">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>
<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(financialNWC)"
    [disabled]="isFinancialNWC">Save</button>
</div>

<!-- Change in NET LEVERAGE  -->
<div class="leverage-header-margin">
  <span class="main-header">Net Latest Leverage (Pricing Grid)</span>
  
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to add Net Latest Leverage (Pricing Grid) in financials? 
    </span>      
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedNetLatestLeverage">
        <mat-option [value]="false">No</mat-option>
        <mat-option [value]="true">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>
<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(netLatestLeverage)"
  [disabled]="isNetLatestLeverage">Save</button>
</div>

<!-- Change in Discount Rate Type  -->
<div class="leverage-header-margin">
  <span class="main-header">Default Discount Rate Type</span>
  
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want the default Discount Rate Type to be Constant or Time Varying?
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedDiscountRateType">
        <mat-option [value]="false">Constant</mat-option>
        <mat-option [value]="true">Time Varying</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>
<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(discountRateType)" 
  [disabled]="isDiscountRateType">Save</button>
</div>

<!-- Implied Yield Calibration -->
<div class="leverage-header-margin">
  <span class="main-header">Implied Yield Calibration</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      The implied yield computed while calibrating to par can goal seek to either the dirty or clean price.
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedImpliedYieldCalibration" (ngModelChange)="isImpliedYieldCalibration=false">
        <mat-option [value]="true">Dirty Price</mat-option>
        <mat-option [value]="false">Clean Price</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(impliedYieldCalibration);isImpliedYieldCalibration = false" [disabled]="isImpliedYieldCalibration">Save</button>
</div>

<!-- Security Linkage -->
<div class="leverage-header-margin">
  <span class="main-header">Security Linkage</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to enable linking of securities together?
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="selectedSecurityLinkage">
        <mat-option [value]="false">No</mat-option>
        <mat-option [value]="true">Yes</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(securityLinkage)" [disabled]="isSecurityLinkage">Save</button>
</div>

<!-- Compounding Interest Payments -->
<div class="leverage-header-margin">
  <span class="main-header">Compounding Interest Payments</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to add a compounding formula option for accrued interest payments on the platform?
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="isCompoundingInterestPayments">
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(compoundingInterestPayments)" [disabled]="isCompoundingInterestPaymentsSaving">Save</button>
</div>

<!-- Valuation Signoff -->
<div class="leverage-header-margin">
  <span class="main-header">Fund Level Valuation Sign Off</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to enable audit trail for fund level valuation Sign Off?
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="isValuationSignOff">
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(valuationSignOff)" [disabled]="isValuationSignOffSaving">Save</button>
</div>

<!-- Single Regime Cashflow -->
<div class="leverage-header-margin">
  <span class="main-header">Single Regime Cash/PIK Allocation</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want to enable/disable the ability to split a payment between Cash and PIK in the cashflow?
    </span>
    <mat-form-field class="custom-input-select" style="width: 19rem;">
      <mat-select [(ngModel)]="isSingleRegimeAllocation">
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(singleRegimeSignOff)" [disabled]="isSingleRegimeAllocationSaving">Save</button>
</div>


<!--SRT Assets -->
<div class="leverage-header-margin">
  <span class="main-header">SRT Assets Card</span>
  <div class="fair-value-bottom-margin margin-credit-quality">
    <span class="fair-value-label">
      Do you want SRT Assets Card to be added to the valuation cards?
    </span>
    <mat-form-field class="custom-input-select custom-width-srtcard">
      <mat-select [(ngModel)]="isSRTCardsSelected">
        <mat-option [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field><br>
  </div>
</div>

<div class="classification-button-margin">
  <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(SRTCardModal)" [disabled]="isSRTCardSaving">Save</button>
</div>


<!-- Confirmation Dialog - Discount Rate Type -->
<ng-template #SRTCardModal let-SRTCardModal>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="SRTCardModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you want to save the changes for SRT Assets Card ?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="SRTCardModal.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveConfigStatus('SRT_ASSETS_CARD', isSRTCardsSelected); isSRTCardSaving = true">
      Yes
    </button>
  </div>
</ng-template>


<!-- Confirmation Net Latest Leverage -->
<ng-template #netLatestLeverage let-netLatestLeverage>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="netLatestLeverage.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you want to save the changes for Net Latest Leverage (Pricing Grid) in financials ?</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-custom-secondry" (click)="netLatestLeverage.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveConfigStatus('NET_LATEST_LEVERAGE',selectedNetLatestLeverage);isNetLatestLeverage=!isNetLatestLeverage">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Financial NWC -->
<ng-template #financialNWC let-financialNWC>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="qualitativeAnalysis.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you want to save the changes for financial NWC ?</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-custom-secondry" (click)="financialNWC.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveConfigStatus('NWC',selectedFinancialNWC);isFinancialNWC=!isFinancialNWC">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Qualitative Analysis -->
<ng-template #qualitativeAnalysis let-qualitativeAnalysis>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="qualitativeAnalysis.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you want to save the changes for qualitative analysis ?</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-custom-secondry" (click)="qualitativeAnalysis.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="qualitativeAnalysisChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Shadow Rating -->
<ng-template #shadowRating let-shadowRating>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="shadowRating.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to add the shadow rating methodology to compute the credit rating?</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-custom-secondry" (click)="shadowRating.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveConfigStatus('BX_SHADOW_RATING',selectedShadowRating);isShadowRating=!isShadowRating">
      Yes
    </button>
  </div>
</ng-template>



<!-- Confirmation Dialog - Fair Value Content -->
<ng-template #fairValueContent let-fairValueContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="fairValueContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you wish to conclude on the {{fairValueSelected[selectedfairValue]}}?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="fairValueContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="fairValueChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Value Bridge Content -->
<ng-template #valueBridgeContent let-valueBridgeContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="valueBridgeContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you wish to enable value bridge ?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="valueBridgeContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveConfigStatus('VALUE_BRIDGE_ENABLED', selectedValueBridge); isValueBridgeSaving = true">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Percent Par Computation Content -->
<ng-template #percentParContent let-percentParContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="percentParContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you wish to change % of Par computation?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="percentParContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="percentParChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Number of Decimals -->
<ng-template #numberofDecimals let-numberofDecimals>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="numberofDecimals.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you wish to change Number of Decimals ?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="numberofDecimals.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="numberofDecimalsChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Price Rounding -->
<ng-template #priceRoundingContent let-priceRoundingContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="priceRoundingContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you wish to change Price Rounding computation?</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-custom-secondry" (click)="priceRoundingContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="savePriceRounding()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Reset to Default -->
<ng-template #creditQualityContent let-creditQualityContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Reset to Default</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="creditQualityContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you wish to reset custom and credit quality settings to default?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="creditQualityContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveCreditQualityChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Call Premium -->
<ng-template #callPremium let-callPremium>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="callPremium.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you wish to cap % of par for call premium?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="callPremium.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveCallPremiumCap()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Forward Rate -->
<ng-template #forwardRate let-forwardRate>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="forwardRate.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you want to change forward rate lag?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="forwardRate.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveForwardRate()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Discount Factor Computation Content -->
<ng-template #discountFactorContent let-discountFactorContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="discountFactorContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you wish to adjust the discount factor with a payment frequency adjustment?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="discountFactorContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="discountFactorChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Different Discounting Content -->
<ng-template #differentDiscountingContent let-differentDiscountingContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="differentDiscountingContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to add a custom adjustment in the discount rate when discounting principal repayments only?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="differentDiscountingContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="differentDiscountingChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Warrant Valuation Discount -->
<ng-template #warrantValuationDiscountContent let-warrantValuationDiscountContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="warrantValuationDiscountContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to change the discount rate methodology for warrant valuation?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="warrantValuationDiscountContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="warrantValuationDiscountChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - OID Impact -->
<ng-template #oidImpactContent let-oidImpactContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="oidImpactContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to take into account the impact of OID on the Implied Yield as of Amendment Date?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="oidImpactContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="oidImpactChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - CSA Impact -->
<ng-template #csaImpactContent let-csaImpactContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="csaImpactContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 10px;">Do you want to be able to add a Credit Spread Adjustment (CSA) on the interest rates?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="csaImpactContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="csaImpactChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Middle Market Adjustment -->
<ng-template #middleMarketAdj let-middleMarketAdj>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="middleMarketAdj.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to be able to add a Middle Market Adjustment on the discount rate?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="middleMarketAdj.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="middleMarketChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Eur Lli Adjustment -->
<ng-template #eurLliAdj let-eurLliAdj>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="eurLliAdj.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to be able to add a EUR LLI Adjustment on the discount rate? </span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-custom-secondry" (click)="eurLliAdj.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveConfigStatus('EUR_LLI_ADJUSTMENT',selectedEurLliAdj); isEurLliAdj=!isEurLliAdj">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Spread Matrix Adjustments -->
<ng-template #spreadMatrixAdjustments let-spreadMatrixAdjustments>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="spreadMatrixAdjustments.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to add the adjustments from the spread matrix to the discount rate?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="spreadMatrixAdjustments.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="spreadMatrixAdjustmentChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Amortization Schedule -->
<ng-template #amortizationSchedule let-amortizationSchedule>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="amortizationSchedule.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to adjust the principal repayments based on the frequency?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="amortizationSchedule.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveConfigStatus('AMORTIZATION_SCHEDULE', selectedAmortization); isAmortization = !isAmortization;">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - BX Tags Content -->
<ng-template #bxTagsContent let-bxTagsContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="bxTagsContent.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm if you wish to add tags to the investment?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="bxTagsContent.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary"  (click)="tagsChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- confirmation dialog - BX Additional Input Data -->
<ng-template #additionalInputData let-additionalInputData>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="additionalInputData.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to add additional inputs to the investments?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="additionalInputData.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary"  (click)="additionalInputChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Spread Build Up -->
<ng-template #spreadBuildUp let-spreadBuildUp>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="spreadBuildUp.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to add the spread build-up methodology to value investments?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="spreadBuildUp.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="spreadBuildUpChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Discount Rate Type -->
<ng-template #discountRateType let-discountRateType>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="discountRateType.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want the default Discount Rate Type to be Constant or Time Varying?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="discountRateType.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveConfigStatus('DISCOUNT_RATE_TYPE', selectedDiscountRateType); isDiscountRateType=!isDiscountRateType">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Implied Yield Calibration -->
<ng-template #impliedYieldCalibration let-impliedYieldCalibration>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="impliedYieldCalibration.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Please confirm that you wish to calibrate Implied Yield based on {{selectedImpliedYieldCalibration?'Dirty price':'Clean price'}}?</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-custom-primary" (click)="saveConfigStatus('RECALIBRATION',selectedImpliedYieldCalibration);isImpliedYieldCalibration=true">
      Confirm
    </button>
    <button type="button" class="btn-custom-secondry" (click)="impliedYieldCalibration.dismiss('Cross click');isImpliedYieldCalibration=false">
      Cancel
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Compouding Interest Payments -->
<ng-template #compoundingInterestPayments let-compoundingInterestPayments>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="compoundingInterestPayments.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to add a compounding formula option for accrued interest payments on the platform?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="compoundingInterestPayments.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="compoundingInterestPaymentsChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Valuation Sign Off -->
<ng-template #valuationSignOff let-valuationSignOff>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="valuationSignOff.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to enable/disable audit trail for fund level valuation Sign Off?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="valuationSignOff.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="valuationSignOffChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Dialog - Single regime Allocation -->
<ng-template #singleRegimeSignOff let-singleRegimeSignOff>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="singleRegimeSignOff.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">Do you want to Enable/Disable the ability to split a payment between Cash and PIK in the cashflow?</span>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn-custom-secondry" (click)="singleRegimeSignOff.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="singleRegimeAllocationChanged()">
      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Security Linkage -->
<ng-template #securityLinkage let-securityLinkage>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Confirm</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="securityLinkage.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span style="font-size: 15px;">{{ selectedSecurityLinkage ? 'Do you want to enable linking of securities together?' : 'This change will be trickled down to all valuations for the client. All valuation and discount rate inputs will be retained but Link to Referrer Security will change to ‘No’.' }}</span>
    </div>
    
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-custom-secondry" (click)="securityLinkage.dismiss('Cross click')">
      No
    </button>
    <button type="button" class="btn-custom-primary" (click)="saveConfigStatus('SECURITY_LINKAGE',selectedSecurityLinkage);isSecurityLinkage=!isSecurityLinkage">
      Yes
    </button>
  </div>
</ng-template>

<audit-trail-global></audit-trail-global>