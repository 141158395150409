
export class ConfigurationModal {
    // Threshold
    valuationMarkThreshHold: boolean = false;

    applyChangeInValDateNav: boolean = false;
    changeInValDateNavPercentage: number = 10;

    applyChangeInTotalNav: boolean = false;
    changeInTotalNavPercentage: number = 0.5;
    // Threshold

    // Implied Decline
    enableImpliedDecline: boolean = false;

    // Risk/Return Framework
    valuationAndRiskReturn: boolean = false;

    //rounding off
    roundingOff = "No"

    // LFR
    multiplesComputation: boolean = true;
    filingVersionToBeApplied: any = 'Latest Fully Reported';
    dateComparisonToBeApplied : any = 'Filing date'

    //custom Attributes
    customAttributes:any = [];

    //Qualitative Comments
    qualitativeComments:any = [];

    //poriThreshold
    poriThreshold: number = 6;

    //IA : SizePremium
    sizePremium: boolean = false;

    //Calculated NTM
    calculatedNTM: boolean = false;

}

export class ConfigurationDatabaseModal {
    "configuration" : string;
    "value": string;
    "target": string;
    "module": string;
    "capturedFor": string;
    "capturedForId": string;
    "orgId": string;
    "updating": boolean;
    "createdBy": string;
    "lastModifiedBy": string;

    setValues(configuration, value, target, module,
        capturedFor, capturedForId,
        orgId, createdBy, lastModifiedBy) {
            this.configuration = configuration;
            this.value = value;
            this.target = target;
            this.module = module;
            this.capturedFor = capturedFor;
            this.capturedForId = capturedForId;
            this.orgId = orgId;
            this.updating = false;
            this.createdBy = createdBy;
            this.lastModifiedBy = lastModifiedBy;
    }
}
