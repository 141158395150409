<div class="card" style="padding: 20px 0.55vw;">
  
  <div class="row" style=" margin: auto 1.11vw; margin-bottom: 2%;">
    <ng-template [ngIf] = "!portfolioService.loading">
      <div class="col-2" style="max-width: 14%;">
        <span class="main-heading">{{translateService.getLabel("equity_portfolio")}}</span>
      </div>
      <div class="col-2">
        <mat-form-field class="custom-input-select" 
        [matTooltip]="translateService.getLabel('portfolio_summary_ason_date_dropdown')"
        matTooltipPosition = "above">
            <mat-select [placeholder]="this.translateService.getLabel('latest_ValuationDate_Label')" [(ngModel)]="selectedValDate"
                (selectionChange)="onAsOnDateChange($event)">
                <mat-option *ngFor="let data of allValDates" [value]="data">
                    <span *ngIf="data != 'null'">
                    {{ translateService.getLabel(data) }}
                    </span>
                </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
    </ng-template>

    <div class="col" style="text-align: right;">
      <ng-template [ngIf]="portfolioService.summary[portfolioService.selectedFundId] && !portfolioService.loading">
                
        <mat-form-field  class="currencySelect" style="max-width: 200px;">
          <mat-select [(ngModel)]="portfolioViewMode">
            <mat-option value="both">
             {{translateService.getLabel("reporting_and_local_currency") }}
            </mat-option>
  
            <mat-option value="reporting_currency">
              {{translateService.getLabel("reporting_currency")}}
            </mat-option>
  
            <mat-option value="local_currency" >
              {{translateService.getLabel("local_currency")}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        &nbsp;&nbsp;
          
        <mat-form-field class="currencySelect" style="max-width: 200px;">

          <mat-select [(ngModel)]="portfolioService.selectedCurrency" 
          [disabled] = "ums.currentUserFundPermissionReadOnly">

            <mat-option *ngFor="let currency of currencyService.currencyList" value="{{ currency.id }}" 
            (click)="changeInFundCurrencyByUser(currency.id)">
              {{ currency.country }} - {{ currency.id }}
            </mat-option>
    
          </mat-select>
        </mat-form-field>
      
      </ng-template>
    </div>
  </div>

  <div *ngIf="portfolioService.summary[portfolioService.selectedFundId] && !portfolioService.loading">

    <div class="row" style=" margin: auto 1.11vw; margin-bottom: 2%;">
      <div class="col-5 border-shadow-class">

        <div class="valuation-heading">{{translateService.getLabel("valuation_by_sectors")}}</div>
        <div [chart]="columnChart" style="padding-top: 1.11vw;"></div>

      </div>

      <div class="col-2" style="margin-right: 0.33vw;">
        <div class="row bubble">
          <p class="col-12 portfolio-bubble-heading">
            <ng-template [ngIf]="!portfolioService.loading && totalAggregations.totalIRR != 'loading'">

              <ng-template [ngIf]="!totalAggregations.totalIRR || totalAggregations.totalIRR >= 0">
                <!-- {{ totalAggregations.totalIRR | number: '1.1-1'}}% -->
                <app-data-drill-down-menu [value]="totalAggregations.totalIRR" [decimalPoint]="1" [suffix]="'%'"
                  [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'"
                  [onlyPositiveAllowed]="true" [label]="translateService.getLabel('gross_irr')"
                  [formula]="drillDownService.getFormulaForDrillDownItems('total_gross_irr')"
                  [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')"
                  [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]=""
                  [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
                </app-data-drill-down-menu>
              </ng-template>

              <ng-template [ngIf]="totalAggregations.totalIRR < 0">
                <span style="color: red;">
                  <!-- {{ totalAggregations.totalIRR | number: '1.1-1'}}%  -->
                  <app-data-drill-down-menu [value]="totalAggregations.totalIRR" [decimalPoint]="1" [suffix]="'%'"
                    [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'"
                    [onlyPositiveAllowed]="false" [label]="translateService.getLabel('gross_irr')"
                    [formula]="drillDownService.getFormulaForDrillDownItems('total_gross_irr')"
                    [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')"
                    [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]=""
                    [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
                  </app-data-drill-down-menu>
                </span> 
              </ng-template>

            </ng-template>

            <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"
              *ngIf="portfolioService.loading || totalAggregations.totalIRR == 'loading'">
            </mat-spinner>
          </p>
          <p class="col-12 portfolio-total-header">
            {{translateService.getLabel("gross_irr")}}
          </p>
        </div>
        <div class="row bubble">
          <p class="col-12 portfolio-bubble-heading">
            <ng-template [ngIf]="!portfolioService.loading && totalAggregations.totalMOIC != 'loading'">
              <!-- {{ totalAggregations.totalMOIC | number: '1.1-1'}}x -->
              <app-data-drill-down-menu [value]="totalAggregations.totalMOIC" [decimalPoint]="1" [suffix]="'x'"
                  [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'"
                  [onlyPositiveAllowed]="false" [label]="translateService.getLabel('moic')"
                  [formula]="drillDownService.getFormulaForDrillDownItems('totalMoic')"
                  [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')"
                  [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]=""
                  [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
              </app-data-drill-down-menu>
            </ng-template>

            <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"
              *ngIf="portfolioService.loading"></mat-spinner>
          </p>
          <p class="col-12 portfolio-total-header">
            {{translateService.getLabel("moic")}}
          </p>
        </div>
        <div class="row bubble">
          <p class="col-12 portfolio-bubble-heading">
            <ng-template [ngIf]="!portfolioService.loading && totalAggregations.totalInvestment != 'loading'">
              <ng-template [ngIf]="totalAggregations.totalInvestment >= 100" [ngIfElse]="million">
                {{this.portfolioService.selectedCurrency}}
                <!-- {{ totalAggregations.totalInvestment / 1000 | number: "1.2-2"}}  -->
                <app-data-drill-down-menu [value]="totalAggregations.totalInvestment / 1000" [decimalPoint]="2" [suffix]="''"
                  [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'"
                  [onlyPositiveAllowed]="false" [label]="translateService.getLabel('investment_amount')"
                  [formula]="drillDownService.getFormulaForDrillDownItems('totalInvestmentAmount')"
                  [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')"
                  [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]=""
                  [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
                </app-data-drill-down-menu>
                Bn
              </ng-template>
              <ng-template #million>
                {{this.portfolioService.selectedCurrency}} 
                <!-- {{ totalAggregations.totalInvestment | number: "1.1-1"}} -->
                <app-data-drill-down-menu [value]="totalAggregations.totalInvestment" [decimalPoint]="1" [suffix]="''"
                  [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'"
                  [onlyPositiveAllowed]="false" [label]="translateService.getLabel('investment_amount')"
                  [formula]="drillDownService.getFormulaForDrillDownItems('totalInvestmentAmount')"
                  [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')"
                  [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]=""
                  [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
                </app-data-drill-down-menu>
                Mn
              </ng-template>
            </ng-template>

            <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"
              *ngIf="portfolioService.loading"></mat-spinner>
          </p>
          <p class="col-12 portfolio-total-header">
            {{translateService.getLabel("investment_amount")}}
          </p>
        </div>
        <div class="row bubble" style="margin-bottom: 0;">
          <p class="col-12 portfolio-bubble-heading">
            <ng-template [ngIf]="!portfolioService.loading && totalAggregations.totalRealisedProceeds != 'loading'">
              <ng-template [ngIf]="totalAggregations.totalRealisedProceeds >= 100" [ngIfElse]="million">
                {{this.portfolioService.selectedCurrency}}
                 <!-- {{ totalAggregations.totalRealisedProceeds / 1000 | number: "1.2-2"}} -->
                <app-data-drill-down-menu [value]="totalAggregations.totalRealisedProceeds / 1000" [decimalPoint]="1" [suffix]="''"
                  [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'"
                  [onlyPositiveAllowed]="false" [label]="translateService.getLabel('realised_proceeds')"
                  [formula]="drillDownService.getFormulaForDrillDownItems('totalRealisedProceeds')"
                  [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')"
                  [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]=""
                  [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
                </app-data-drill-down-menu>
                  Bn
              </ng-template>
              <ng-template #million>
                {{this.portfolioService.selectedCurrency}} 
                <!-- {{ totalAggregations.totalRealisedProceeds | number: "1.1-1"}} -->
                <app-data-drill-down-menu [value]="totalAggregations.totalRealisedProceeds" [decimalPoint]="1" [suffix]="''"
                  [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'"
                  [onlyPositiveAllowed]="false" [label]="translateService.getLabel('realised_proceeds')"
                  [formula]="drillDownService.getFormulaForDrillDownItems('totalRealisedProceeds')"
                  [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')"
                  [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]=""
                  [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
                </app-data-drill-down-menu>
                  Mn
              </ng-template>
            </ng-template>

            <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"
              *ngIf="portfolioService.loading"></mat-spinner>
          </p>
          <p class="col-12 portfolio-total-header">
            {{translateService.getLabel("realised_proceeds")}}
          </p>
        </div>
      </div>

      <div class="col border-shadow-class">
        <!-- <div [chart] = "portfolioService.columnChart" style="height: 400px; width: 450px;"> -->
        <div class="valuation-heading">{{translateService.getLabel("geography_ps")}}</div>
        <div [chart]="mapChart" id="map-chart" style="width: 27.9vw; margin: auto;">
        </div>
      </div>
    </div>

    <div class="row" style="margin: auto 0 1%;" *ngIf="portfolioViewMode == 'both' || portfolioViewMode == 'reporting_currency'">
      <div class="col-6">
        <span class="main-heading">{{ translateService.getLabel("reporting_currency")}} ({{ portfolioService.selectedCurrency }})</span>
      
      </div>
      
      <div class="col-6 company-search-dropdown" style="text-align: right;">
        <!-- <mat-form-field style="width: 16.74vw; margin-right: 0.84vw;">
            <input matInput type="text" placeholder="Search" [(ngModel)]="searchString"
              (ngModelChange)="searchCompanies()">
            <mat-icon matSuffix>
              <i class="fas fa-search" style="color: #9D9D9D;"></i>
            </mat-icon>
          </mat-form-field> -->

        <button class="secondaryBgButton" style="cursor: pointer;" *ngIf ="!ums.currentUserFundPermissionReadOnly" (click)="navigateToleaverageCost()">
          {{translateService.getLabel("leverage_cost_adjustment")}}
        </button>
          
        <app-qualitative-analysis-v2 [id]="portfolioService.selectedFundId" groupId="PORTFOLIO_SUMMARY" commentId="QA"></app-qualitative-analysis-v2>

        <button class="btn btn-add-company" *ngIf ="!ums.currentUserFundPermissionReadOnly"
          (click)="openPopupModal(applicationContent)">
          {{translateService.getLabel("add_company")}}
        </button>

        <button class="btn-download-icon" (click)="excelExport()">
          <img src="assets/imgs/download.svg">
        </button>

        <button class="btn-download-icon" (click)="downloadCSVForPortfolioSummaryPIF()">
          <i class="fas fa-file-csv" style="font-size: large;" ></i>
        </button>

        <button class="btn-delete-icon" (click)="openPopupModal(deleteCompanyContent)"
          *ngIf="!ums.currentUserFundPermissionReadOnly 
          && currentPortfolioCompanies && currentPortfolioCompanies.length> 0">
          <img src="assets/imgs/delete.svg">
        </button>
      </div>
    </div>

    <ng-template [ngIf]="portfolioViewMode == 'both' || portfolioViewMode == 'reporting_currency'">

    <!-- Portfolio Summary -->
    <div class="row pf-row pf-header-row">
      <div class="col pf-expand-icon"></div>

      <div class="col pf-header">{{translateService.getLabel("portfolio")}}</div>

      <div class="col pf-numbers">{{translateService.getLabel("currency")}}</div>

      <div class="col pf-numbers">{{translateService.getLabel("investment_amount")}}</div>

      <div class="col pf-numbers">{{translateService.getLabel("realised_proceeds")}}</div>

      <div class="col pf-numbers">{{translateService.getLabel("nav")}}</div>

      <div class="col pf-numbers">{{translateService.getLabel("total_value")}}</div>

      <div class="col pf-numbers">{{translateService.getLabel("gross_irr")}}</div>

      <div class="col pf-numbers">{{translateService.getLabel("moic")}}</div>

      <!-- <div class="col pf-numbers">{{translateService.getLabel("percentage_change_in_moic")}}</div> -->

      <div class="col pf-numbers">{{translateService.getLabel("from_last_val_date")}}</div>
    </div>

    <div class="row pf-row pf-normal-row">
      <div class="col pf-expand-icon">
        <!--expance/collapse-->
        <i class="fas fa-chevron-right" (click)="portfolio.current.expandPortfolio = !portfolio.current.expandPortfolio"
          *ngIf="!portfolio.current.expandPortfolio"></i>
        <i class="fas fa-chevron-down" (click)="portfolio.current.expandPortfolio = !portfolio.current.expandPortfolio"
          *ngIf="portfolio.current.expandPortfolio"></i>
      </div>

      <div class="col pf-header">{{portfolio.current.name}}</div>

      <div class="col pf-numbers">
        <app-data-drill-down-menu [value]="portfolioService.selectedCurrency" textField="true" [valuationDate]="'NA'"
          [label]="translateService.getLabel('currency')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
          [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
          [source]="drillDownService.getSourceForDrillDown('form', 'currency')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
        </app-data-drill-down-menu>
      </div>

      <div class="col pf-numbers">
        <ng-template [ngIf]="portfolio.current.totalInvestment > 0">
          <span>
            <app-data-drill-down-menu [value]="portfolio.current.totalInvestment" [decimalPoint]="1" [suffix]="''"
              [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
              [label]="translateService.getLabel('investment_amount')" [formula]="drillDownService.getFormulaForDrillDownItems('currentPortfolio_investedAmount')"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>

        <ng-template [ngIf]="!portfolio.current.totalInvestment || portfolio.current.totalInvestment <= 0">
          <span style="color: red;" [RawDataDirective]="portfolioService.nATooltip">
            {{utilService.getNegativeNumberDisplayFormat(portfolio.current.totalInvestment, 1, "", "NA")}}
          </span>
        </ng-template>
      </div>

      <div class="col pf-numbers">
        <span>
          <app-data-drill-down-menu [value]="portfolio.current.totalRealisedProceeds" [decimalPoint]="1" [suffix]="''"
            [valueInPlaceOfZero]="'0.0'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
            [label]="translateService.getLabel('realised_proceeds')" [formula]="drillDownService.getFormulaForDrillDownItems('totalRealisedProceedsForCurrentInvestments')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
      </div>

      <div class="col pf-numbers">
        <span>
          <app-data-drill-down-menu [value]="portfolio.current.totalStakeValue" [decimalPoint]="1" [suffix]="''"
            [valueInPlaceOfZero]="'0.0'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
            [label]="translateService.getLabel('nav')" [formula]="drillDownService.getFormulaForDrillDownItems('currentPortfolio_stakeValue')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>  
        </span>
      </div>

      <div class="col pf-numbers">
        <span>
          <app-data-drill-down-menu [value]="portfolio.current.totalTotalValue" [decimalPoint]="1" [suffix]="''"
            [valueInPlaceOfZero]="'0.0'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
            [label]="translateService.getLabel('total_value')" [formula]="drillDownService.getFormulaForDrillDownItems('currentPortfolio_totalValue')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('totalValue', portfolio.current)" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
      </div>

      <div class="col pf-numbers">

        <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"
            *ngIf="portfolio.current.totalIRR == 'loading'">
        </mat-spinner>

        <ng-template [ngIf]="portfolio.current.totalIRR !== 'loading'">
          <ng-template [ngIf]="portfolio.current.totalIRR > 0">
            <span>
              <app-data-drill-down-menu [value]="portfolio.current.totalIRR" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
                [label]="translateService.getLabel('gross_irr')" [formula]="drillDownService.getFormulaForDrillDownItems('currentPortfolio_gross_irr')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('')" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
            </span>
          </ng-template>
  
          <ng-template [ngIf]="!portfolio.current.totalIRR || portfolio.current.totalIRR <= 0">
            <span style="color: red;" [RawDataDirective]="portfolioService.nATooltip">
              {{utilService.getNegativeNumberDisplayFormat(portfolio.current.totalIRR, 1, "%", "NA")}}
            </span>
          </ng-template>
        </ng-template>
      </div>

      <div class="col pf-numbers">
        <ng-template [ngIf]="portfolio.current.totalMOIC > 0">
          <span>
            <app-data-drill-down-menu [value]="portfolio.current.totalMOIC" [decimalPoint]="1" [suffix]="'x'"
              [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
              [label]="translateService.getLabel('moic')" [formula]="drillDownService.getFormulaForDrillDownItems('currentPortfolio_moic')"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('moic', portfolio.current)" [typeOfSource]="'Calculated'"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>

        <ng-template [ngIf]="!portfolio.current.totalMOIC || portfolio.current.totalMOIC <= 0">
          <span style="color: red;" [RawDataDirective]="portfolioService.nATooltip">
            {{utilService.getNegativeNumberDisplayFormat(portfolio.current.totalMOIC, 1, "x", "NA")}}
          </span>
        </ng-template>
      </div>

      <!-- <div class="col pf-numbers">
        <ng-template [ngIf]="portfolio.current.totalChangeMOIC >= 0">
          <span>
            <app-data-drill-down-menu [value]="portfolio.current.totalChangeMOIC" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalChangeInMOICForCurrentPortfolio')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('percentageChangeInMOICForCurrentOrRealised', portfolio.current)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
        <ng-template [ngIf]="portfolio.current.totalChangeMOIC < 0 || portfolio.exit.totalChangeMOIC == 'NA'">
          <span style="color: red;">
            <app-data-drill-down-menu [value]="portfolio.current.totalChangeMOIC" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false" [displayNegativeNo]="true"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalChangeInMOICForCurrentPortfolio')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('percentageChangeInMOICForCurrentOrRealised', portfolio.current)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
      </div> -->

      <div class="col pf-numbers">
        <ng-template [ngIf]="portfolio.current.totalChangeMOIC >= 0">
          <span>
            <app-data-drill-down-menu [value]="portfolio.current.totalChangeMOIC" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalChangeInMOICForCurrentPortfolio')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('percentageChangeInMOICForCurrentOrRealised', portfolio.current)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
        <ng-template [ngIf]="portfolio.current.totalChangeMOIC < 0 || portfolio.exit.totalChangeMOIC == 'NA'">
          <span style="color: red;">
            <app-data-drill-down-menu [value]="portfolio.current.totalChangeMOIC" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false" [displayNegativeNo]="true"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalChangeInMOICForCurrentPortfolio')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('percentageChangeInMOICForCurrentOrRealised', portfolio.current)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
      </div>

      <div class="col pf-numbers">
        <ng-template [ngIf]="!portfolio.current.totalChangeInValPercentage || portfolio.current.totalChangeInValPercentage >= 0">
          <span>
            <app-data-drill-down-menu [value]="portfolio.current.totalChangeInValPercentage" [decimalPoint]="1" [suffix]="'%'"
              [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
              [label]="translateService.getLabel('from_last_val_date')"
              [formula]="drillDownService.getFormulaForDrillDownItems('currentPortfolio_from_last_val_date')" [typeOfSource]="'Calculated'"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('from_last_val_date', portfolio.current)"
              [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
        <ng-template [ngIf]="portfolio.current.totalChangeInValPercentage < 0">
          <span style="color: red;">
            <app-data-drill-down-menu [value]="portfolio.current.totalChangeInValPercentage" [decimalPoint]="1" [suffix]="'%'"
              [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="'NA'" [displayNegativeNo]="true"
              [label]="translateService.getLabel('from_last_val_date')"
              [formula]="drillDownService.getFormulaForDrillDownItems('currentPortfolio_from_last_val_date')" [typeOfSource]="'Calculated'"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('from_last_val_date', portfolio.current)"
              [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </div>
    </div>

    <div class="row pf-row pf-normal-row" style="margin: 0 10px 10px 10px" *ngIf="portfolio.current.expandPortfolio">

      <app-equity-portfolio-grid [portfolioData]="currentPortfolioCompanies" currencyKey="valuesInPortfolioCurrency"
        [totalAggregations]="currentPortfolioTotals" [showAggregations]="true" (emitAction) = "emittingActionOnPortfolio($event)"
        currencyToBeDisplayed = "portfolioCurrency" (showHideColumnsUpdated)="updateShowHideColumns('reportingCurrencyCurrentPortfolio', $event)" [showHideColumns]="showHideColumns.reportingCurrencyCurrentPortfolio">
      </app-equity-portfolio-grid>
    </div>

    <div class="row pf-row pf-normal-row">
      <div class="col pf-expand-icon">
        <!--expance/collapse-->
        <i class="fas fa-chevron-right" (click)="portfolio.exit.expandPortfolio = !portfolio.exit.expandPortfolio"
          *ngIf="!portfolio.exit.expandPortfolio"></i>
        <i class="fas fa-chevron-down" (click)="portfolio.exit.expandPortfolio = !portfolio.exit.expandPortfolio"
          *ngIf="portfolio.exit.expandPortfolio"></i>
      </div>

      <div class="col pf-header">{{portfolio.exit.name}}</div>

      <div class="col pf-numbers">
        <app-data-drill-down-menu [value]="portfolioService.selectedCurrency" textField="true" [valuationDate]="'NA'"
          [label]="translateService.getLabel('currency')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
          [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
          [source]="drillDownService.getSourceForDrillDown('form', 'currency')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
        </app-data-drill-down-menu>  
      </div>

      <div class="col pf-numbers">
        <ng-template [ngIf]="portfolio.exit.totalInvestment > 0">
          <span>
            <app-data-drill-down-menu [value]="portfolio.exit.totalInvestment" [decimalPoint]="1" [suffix]="''"
              [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
              [label]="translateService.getLabel('investment_amount')" [formula]="drillDownService.getFormulaForDrillDownItems('realisedPortfolio_investedAmount')"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>

        <ng-template [ngIf]="!portfolio.exit.totalInvestment || portfolio.exit.totalInvestment <= 0">
          <span style="color: red;" [RawDataDirective]="portfolioService.nATooltip">
            {{utilService.getNegativeNumberDisplayFormat(portfolio.exit.totalInvestment, 1, "", "NA")}}
          </span>
        </ng-template>
      </div>

      <div class="col pf-numbers">
        <span>
          <app-data-drill-down-menu [value]="portfolio.exit.totalRealisedProceeds" [decimalPoint]="1" [suffix]="''"
            [valueInPlaceOfZero]="'0.0'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
            [label]="translateService.getLabel('realised_proceeds')" [formula]="drillDownService.getFormulaForDrillDownItems('totalRealisedProceedsForExitedInvestments')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
      </div>

      <div class="col pf-numbers">
        <span>
          <app-data-drill-down-menu [value]="portfolio.exit.totalStakeValue" [decimalPoint]="1" [suffix]="''"
            [valueInPlaceOfZero]="'0.0'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
            [label]="translateService.getLabel('nav')" [formula]="drillDownService.getFormulaForDrillDownItems('realisedPortfolio_stakeValue')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
      </div>

      <div class="col pf-numbers">
        <span>
          <app-data-drill-down-menu [value]="portfolio.exit.totalTotalValue" [decimalPoint]="1" [suffix]="''"
            [valueInPlaceOfZero]="'0.0'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
            [label]="translateService.getLabel('total_value')" [formula]="drillDownService.getFormulaForDrillDownItems('realisedPortfolio_totalValue')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('totalValue', portfolio.exit)" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
      </div>

      <div class="col pf-numbers">        

        <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"
            *ngIf="portfolio.exit.totalIRR == 'loading'">
        </mat-spinner>

        <ng-template [ngIf]="portfolio.exit.totalIRR !== 'loading'">
          <ng-template [ngIf]="portfolio.exit.totalIRR > 0">
            <span>
              <app-data-drill-down-menu [value]="portfolio.exit.totalIRR" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
                [label]="translateService.getLabel('gross_irr')" [formula]="drillDownService.getFormulaForDrillDownItems('realisedPortfolio_gross_irr')"
                [underlyingMetrics]="drillDownService.getIRRForAggregationInPortfolioSummary(portfolio.exit.IRRInDestCurrReqBody)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
            </span>
          </ng-template>
  
          <ng-template [ngIf]="!portfolio.exit.totalIRR || portfolio.exit.totalIRR <= 0">
            <span style="color: red;" [RawDataDirective]="portfolioService.nATooltip">
              {{utilService.getNegativeNumberDisplayFormat(portfolio.exit.totalIRR, 1, "%", "NA")}}
            </span>
          </ng-template>
        </ng-template>
      </div>

      <div class="col pf-numbers">
        <span *ngIf="portfolio.exit.totalMOIC > 0">
          <app-data-drill-down-menu [value]="portfolio.exit.totalMOIC" [decimalPoint]="1" [suffix]="'x'"
            [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
            [label]="translateService.getLabel('moic')" [formula]="drillDownService.getFormulaForDrillDownItems('realisedPortfolio_moic')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('moic', portfolio.exit)" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
        <span style="color: red;" *ngIf="!portfolio.exit.totalMOIC || portfolio.exit.totalMOIC <= 0" [RawDataDirective]="portfolioService.nATooltip">
          {{utilService.getDisplayFormattedNumber(portfolio.exit.totalMOIC, 1, "x", "NA", false)}}
        </span>
      </div>

      <!-- <div class="col pf-numbers">
        <ng-template [ngIf]="portfolio.exit.totalChangeMOIC >= 0">
          <span>
            <app-data-drill-down-menu [value]="portfolio.exit.totalChangeMOIC" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalChangeInMOICForRealisedPortfolio')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('percentageChangeInMOICForCurrentOrRealised', portfolio.exit)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
        <ng-template [ngIf]="portfolio.exit.totalChangeMOIC < 0 || portfolio.exit.totalChangeMOIC == 'NA'">
          <span style="color: red;">
            <app-data-drill-down-menu [value]="portfolio.exit.totalChangeMOIC" [decimalPoint]="1" [suffix]="'%'" [textField]="portfolio.exit.totalChangeMOIC == 'NA' ? true : false"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false" [displayNegativeNo]="true"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalChangeInMOICForRealisedPortfolio')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('percentageChangeInMOICForCurrentOrRealised', portfolio.exit)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
      </div> -->

      <div class="col pf-numbers">
        <ng-template [ngIf]="portfolio.exit.totalChangeMOIC >= 0">
          <span>
            <app-data-drill-down-menu [value]="portfolio.exit.totalChangeMOIC" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalChangeInMOICForRealisedPortfolio')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('percentageChangeInMOICForCurrentOrRealised', portfolio.exit)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
        <ng-template [ngIf]="portfolio.exit.totalChangeMOIC < 0 || portfolio.exit.totalChangeMOIC == 'NA'">
          <span style="color: red;">
            <app-data-drill-down-menu [value]="portfolio.exit.totalChangeMOIC" [decimalPoint]="1" [suffix]="'%'" [textField]="portfolio.exit.totalChangeMOIC == 'NA' ? true : false"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false" [displayNegativeNo]="true"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalChangeInMOICForRealisedPortfolio')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('percentageChangeInMOICForCurrentOrRealised', portfolio.exit)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
      </div>

      <div class="col pf-numbers">

        <ng-template [ngIf]="!portfolio.exit.totalChangeInValPercentage || portfolio.exit.totalChangeInValPercentage >= 0">
          <span>
            <app-data-drill-down-menu [value]="portfolio.exit.totalChangeInValPercentage" [decimalPoint]="1" [suffix]="'%'"
              [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
              [label]="translateService.getLabel('from_last_val_date')"
              [formula]="drillDownService.getFormulaForDrillDownItems('realisedPortfolio_from_last_val_date')" [typeOfSource]="'Calculated'"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('from_last_val_date', portfolio.exit)"
              [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>

        <ng-template [ngIf]="portfolio.exit.totalChangeInValPercentage < 0">
          <span style="color: red;">
            <app-data-drill-down-menu [value]="portfolio.exit.totalChangeInValPercentage" [decimalPoint]="1" [suffix]="'%'"
              [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="'NA'" [displayNegativeNo]="true"
              [label]="translateService.getLabel('from_last_val_date')"
              [formula]="drillDownService.getFormulaForDrillDownItems('realisedPortfolio_from_last_val_date')" [typeOfSource]="'Calculated'"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('from_last_val_date', portfolio.exit)"
              [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>

      </div>
    </div>

    <div class="row pf-row pf-normal-row" style="margin: 0 10px 10px 10px" *ngIf="portfolio.exit.expandPortfolio">

      <app-equity-portfolio-grid [portfolioData]="exitedPortfolioCompanies" currencyKey="valuesInPortfolioCurrency"
        [totalAggregations]="exitedPortfolioTotals" [exitGrid]="true" [showAggregations]="true" (emitAction) = "emittingActionOnPortfolio($event)"
        currencyToBeDisplayed = "portfolioCurrency" 
        (showHideColumnsUpdated)="updateShowHideColumns('reportingCurrencyExitedPortfolio', $event)" [showHideColumns]="showHideColumns.reportingCurrencyExitedPortfolio">
      </app-equity-portfolio-grid>
    </div>


    <div class="row pf-row pf-footer-row">
      <div class="col pf-expand-icon"></div>

      <div class="col pf-header">{{translateService.getLabel("total(current+realised)")}}</div>

      <div class="col pf-numbers">{{portfolioService.selectedCurrency}}</div>

      <div class="col pf-numbers">
        <span>
          <app-data-drill-down-menu [value]="totalAggregations.totalInvestment" [decimalPoint]="1" [suffix]="''"
            [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
            [label]="translateService.getLabel('investment_amount')" [formula]="drillDownService.getFormulaForDrillDownItems('totalInvestmentAmount')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
      </div>

      <div class="col pf-numbers">
        <span>
          <app-data-drill-down-menu [value]="totalAggregations.totalRealisedProceeds" [decimalPoint]="1" [suffix]="''"
            [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
            [label]="translateService.getLabel('realised_proceeds')" [formula]="drillDownService.getFormulaForDrillDownItems('totalRealisedProceeds')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
      </div>

      <div class="col pf-numbers">
        <span>
          <app-data-drill-down-menu [value]="totalAggregations.totalStakeValue" [decimalPoint]="1" [suffix]="''"
            [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
            [label]="translateService.getLabel('nav')" [formula]="drillDownService.getFormulaForDrillDownItems('total_stake')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
      </div>

      <div class="col pf-numbers">
        <span>
         <app-data-drill-down-menu [value]="totalAggregations.totalTotalValue" [decimalPoint]="1" [suffix]="''"
            [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false"
            [label]="translateService.getLabel('total_value')" [formula]="drillDownService.getFormulaForDrillDownItems('total_totalValue')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('totalValue', totalAggregations)" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
      </div>

      <div class="col pf-numbers">
        <ng-template [ngIf]="totalAggregations.totalIRR > 0">
          <span>
            <app-data-drill-down-menu [value]="totalAggregations.totalIRR" [decimalPoint]="1" [suffix]="'%'"
              [valueInPlaceOfZero]="'0.0%'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
              [label]="translateService.getLabel('gross_irr')" [formula]="drillDownService.getFormulaForDrillDownItems('total_gross_irr')"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')" [typeOfSource]="'Calculated'"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>

        <ng-template [ngIf]="!totalAggregations.totalIRR || totalAggregations.totalIRR <= 0">
          <span style="color: red;">
            <app-data-drill-down-menu [value]="totalAggregations.totalIRR" [decimalPoint]="1" [suffix]="'%'"
              [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="'NA'" [displayNegativeNo]="true"
              [label]="translateService.getLabel('gross_irr')" [formula]="drillDownService.getFormulaForDrillDownItems('total_gross_irr')"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')" [typeOfSource]="'Calculated'"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
        <sup class="superscript" [RawDataDirective]="portfolioService.aggregateTooltip">*</sup>
      </div>

      <div class="col pf-numbers">
        <span *ngIf="totalAggregations.totalMOIC > 0">
          <app-data-drill-down-menu [value]="totalAggregations.totalMOIC" [decimalPoint]="1" [suffix]="'x'"
            [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
            [label]="translateService.getLabel('moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalMoic')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('moic', totalAggregations)" [typeOfSource]="'Calculated'"
            [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
          </app-data-drill-down-menu>
        </span>
        <span style="color: red;" *ngIf="!totalAggregations.totalMOIC || totalAggregations.totalMOIC <= 0" [RawDataDirective]="portfolioService.nATooltip">
          {{utilService.getDisplayFormattedNumber(totalAggregations.totalMOIC, 1, "x", "NA", false)}}
        </span>
        <sup class="superscript" [RawDataDirective]="portfolioService.aggregateTooltip">*</sup>
      </div>

      <!-- <div class="col pf-numbers">
        <ng-template [ngIf]="totalAggregations.totalPercentageChangeInMOIC >= 0" [ngIfElse]="elsePart">
          <span>
            <app-data-drill-down-menu [value]="totalAggregations.totalPercentageChangeInMOIC" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false" [displayNegativeNo]="true"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalPercentageChangeInMOIC')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('totalPercentageChangeInMOIC', portfolio)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
        <ng-template #elsePart>
          <span style="color: red;">
            <app-data-drill-down-menu [value]="totalAggregations.totalPercentageChangeInMOIC" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false" [displayNegativeNo]="true"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalPercentageChangeInMOIC')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('totalPercentageChangeInMOIC', portfolio)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
      </div> -->

      <div class="col pf-numbers">
        <ng-template [ngIf]="totalAggregations.totalPercentageChangeInMOIC >= 0" [ngIfElse]="elsePart">
          <span>
            <app-data-drill-down-menu [value]="totalAggregations.totalPercentageChangeInMOIC" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false" [displayNegativeNo]="true"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalPercentageChangeInMOIC')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('totalPercentageChangeInMOIC', portfolio)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
        <ng-template #elsePart>
          <span style="color: red;">
            <app-data-drill-down-menu [value]="totalAggregations.totalPercentageChangeInMOIC" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false" [displayNegativeNo]="true"
                [label]="translateService.getLabel('percentage_change_in_moic')" [formula]="drillDownService.getFormulaForDrillDownItems('totalPercentageChangeInMOIC')"
                [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('totalPercentageChangeInMOIC', portfolio)" [typeOfSource]="'Calculated'"
                [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
          </span>
        </ng-template>
      </div>

      <div class="col pf-numbers">
        <ng-template [ngIf]="totalAggregations.totalChangeInValPercentage >= 0">
          <span>
            <app-data-drill-down-menu [value]="totalAggregations.totalChangeInValPercentage" [decimalPoint]="1" [suffix]="'%'"
              [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="'NA'" [onlyPositiveAllowed]="true"
              [label]="translateService.getLabel('from_last_val_date')"
              [formula]="drillDownService.getFormulaForDrillDownItems('total_from_last_val_date')" [typeOfSource]="'Calculated'"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('from_last_val_date', totalAggregations)"
              [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
        <ng-template [ngIf]="totalAggregations.totalChangeInValPercentage < 0">
          <span style="color: red;" [RawDataDirective]="totalAggregations.totalChangeInValPercentage">
            <app-data-drill-down-menu [value]="totalAggregations.totalChangeInValPercentage" [decimalPoint]="1" [suffix]="'%'"
              [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="'NA'" [displayNegativeNo]="true"
              [label]="translateService.getLabel('from_last_val_date')"
              [formula]="drillDownService.getFormulaForDrillDownItems('total_from_last_val_date')" [typeOfSource]="'Calculated'"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('from_last_val_date', totalAggregations)"
              [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </div>
    </div>

    </ng-template>

    <!-- Summary Table in Local Currency -->
    <ng-template [ngIf]="portfolioViewMode == 'both' || portfolioViewMode == 'local_currency'">

    <div class="row" style="margin: 3% auto auto auto;">
      <div class="col-6">
        <span class="main-heading">{{translateService.getLabel("local_currency")}} - {{translateService.getLabel("current_portfolio")}}</span>
      </div>
      
      <div class="col-6 company-search-dropdown" style="text-align: right;">
        
        <app-qualitative-analysis-v2 *ngIf ="portfolioViewMode == 'local_currency'" [id]="portfolioService.selectedFundId" groupId="PORTFOLIO_SUMMARY" commentId="QA"></app-qualitative-analysis-v2>
        
        <button class="btn btn-add-company" *ngIf ="portfolioViewMode == 'local_currency' && !ums.currentUserFundPermissionReadOnly"
          (click)="openPopupModal(applicationContent)">
          {{translateService.getLabel("add_company")}}
        </button>

        <button class="btn-download-icon" (click)="excelExport(true)" [class.margin-right-zero]="portfolioViewMode == 'both'">
          <img src="assets/imgs/download.svg">
        </button>

        <button class="btn-delete-icon" (click)="openPopupModal(deleteCompanyContent)"
          *ngIf="portfolioViewMode == 'local_currency' && !ums.currentUserFundPermissionReadOnly 
          && currentPortfolioCompanies && currentPortfolioCompanies.length> 0">
          <img src="assets/imgs/delete.svg">
        </button>
      </div>
    </div>

    <app-equity-portfolio-grid [portfolioData]="currentPortfolioCompanies" currencyKey="valuesInLocalCurrency" 
    (emitAction) = "emittingActionOnPortfolio($event)" currencyToBeDisplayed = "localCurrency" (showHideColumnsUpdated)="updateShowHideColumns('localCurrencyCurrentPortfolio', $event)" [showHideColumns]="showHideColumns.localCurrencyCurrentPortfolio">
    </app-equity-portfolio-grid>

    <ng-template [ngIf]="exitedPortfolioCompanies && exitedPortfolioCompanies.length > 0">
      <div style="padding: 0.56vw; margin-top: 2%;">
        <span class="main-heading">{{translateService.getLabel("local_currency")}} - {{translateService.getLabel("realised_portfolio")}} </span>
      </div>
  
      <app-equity-portfolio-grid [portfolioData]="exitedPortfolioCompanies" currencyKey="valuesInLocalCurrency"
        [exitGrid]="true" (emitAction) = "emittingActionOnPortfolio($event)" currencyToBeDisplayed = "localCurrency" (showHideColumnsUpdated)="updateShowHideColumns('localCurrencyExitedPortfolio', $event)" [showHideColumns]="showHideColumns.localCurrencyExitedPortfolio">
      </app-equity-portfolio-grid>
    </ng-template>

    </ng-template>
  </div>


  <div class="loading-container" *ngIf="!portfolioService.summary[portfolioService.selectedFundId] && portfolioService.loading">
    <span class="loading-text">
      <!-- Building Portfolio Summary -->
      <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="50"></mat-spinner>
    </span>
  </div>

  <!-- When no company exists in the fund -->
  <div class="card" style="padding: 10px; height: 100%; overflow: hidden;"
      *ngIf="!portfolioService.summary[portfolioService.selectedFundId] && !portfolioService.loading">
      <div class="add-portfolio-company-bubble" (click)="openPopupModal(applicationContent)">
          <div>
              <img src="assets/imgs/add_icon.svg">
          </div>
          <div class="new-portfolio-header">
              Click to Add a New Portfolio Company
          </div>
      </div>
  </div>
</div>

<a style="display: none;" #downloadExcelPortfolio></a>

<ng-template #applicationContent let-applicationModal>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">{{translateService.getLabel("add_company")}}</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="applicationModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  
    <div class="model-content">
      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("company_name")}}
        </div>
  
        <div class="col modal-textbox">
          <mat-form-field style="width: 100%;">
            <!-- <input matInput [(ngModel)]="newCompany.name" required> -->
  
            <input matInput [(ngModel)]="newCompany.name" [matAutocomplete]="auto" (keyup)="companyNameChange($event)"
              required>
  
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
  
              <mat-option *ngFor="let option of searchedCompanyNames" [value]="option.company_name"
                style="line-height: unset;" (click)="selectedCompanyName(option)">
                {{option.company_name}} <br>
                <span style="font-size: 10px;">{{option.country}}</span>
              </mat-option>
  
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
  
      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("security")}}
        </div>
  
        <div class="col company-search-dropdown">
          <mat-form-field style="width: 100%;">
            <mat-select disableOptionCentering [(ngModel)]="newCompany.security" required>
              <mat-option value="EQUITY">{{translateService.getLabel("equity")}}</mat-option>
              <mat-option value="DEBT">{{translateService.getLabel("credit")}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
  
      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("investment_date")}}
        </div>
  
        <div class="col modal-textbox">
          <mat-form-field (click)="vDatePicker.open()" style="width: 100%;">
            <input matInput [matDatepicker]="vDatePicker" readonly class="date-field"
              [(ngModel)]="newCompany.valuationDate" [max]="todayDate" required>
            <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
  
  
      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("MBU_exists_question_label")}}
        </div>
  
        <div class="col">
          <span style="float: left;">
            <mat-label style="padding-right: 2px;">No&nbsp;</mat-label>
            <mat-slide-toggle (change)="multipleBusinessUnitExists = !multipleBusinessUnitExists"></mat-slide-toggle>
            <mat-label style="padding-left: 5px;">Yes&nbsp;</mat-label>
          </span>
        </div>
        <div class="col modal-textbox" *ngIf="multipleBusinessUnitExists">
          <mat-form-field matTooltip="No. of Business Units" style="width: 100%;">
            <input matInput type="number" [(ngModel)]="newCompany.businessUnitsNumber" required>
          </mat-form-field>
        </div>
      </div>
  
    </div>
  </div>
  <div class="modal-footer">
      <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
      <button type="button" class="btn btn-cancel" (click)="applicationModal.dismiss('Cross click')">
          {{translateService.getLabel("cancel")}}
      </button>
      <button type="button" class="btn btn-save" (click)="startApplication()">
        {{translateService.getLabel("save")}}
      </button>
  </div>
</ng-template>


<ng-template #deleteCompanyContent let-deleteCompanyContent>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 24px;">{{translateService.getLabel("delete_company")}}</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="deleteCompanyContent.dismiss('Cross click') ; resetCompanyList()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="model-content deletePopUpContent">
      <div *ngFor="let company of allCompaniesList">
        <div class="row">
          <div class="col-7">
            <div class= "row p-modal-row">
              <mat-checkbox class="example-margin" [(ngModel)]="company.delete">
                  <span style="font-weight: 500;font-size: 16px;">{{company.name}}</span>
              </mat-checkbox>
            </div>   
          </div>
          
          <div class="col-5 " >
            <div class= "row p-modal-row">
              {{company.prevValuationDate | date:'MMMM d, yyyy'}}
            </div>
           
          </div>
        </div>
          
      </div>
    </div>

   
    
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-cancel" (click)="deleteCompanyContent.dismiss('Cross click'); resetCompanyList()">
      {{translateService.getLabel("cancel")}}
    </button>
    <button type="button" class="btn btn-save" (click)="confirmAndDelete(); deleteCompanyContent.dismiss('Cross click')">
      {{translateService.getLabel("delete")}}
    </button>
  </div>
</ng-template>