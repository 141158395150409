import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges
} from '@angular/core';
import * as moment from 'moment/moment';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'src/app/services/message.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { CommentServiceService } from 'src/app/utils/comment-service.service';
import { UtilService } from 'src/app/utils/util.service';


@Component({
  selector: "comments-section",
  templateUrl: './comments-section.component.html',
  styleUrls: ['./comments-section.component.scss']
})
export class CommentsSectionComponent implements OnInit, OnChanges {
  comments: any = [];
  @Input() keyName;
  @Input() grpId;
  @Input() formId;

  @Output() sendMessage;

  activeCommentIconIndex = 0;
  commentText = '';
  placeHolder = '';
  saveStatus
  showEdit = '';


  constructor(private ums: UserManagementService, 
    private ds: DataService, 
    public commentService: CommentServiceService, 
    public utilService: UtilService,
    public translateService: TranslateService) { }

  ngOnInit(): void {
    this.comments = this.commentService.commentsForValuationDate[this.formId];
    this.comments =  this.comments.filter(item=> item.commentId == this.keyName)
    this.commentService.comments = this.comments;
  }

  ngOnChanges() {
  }


  sendMessage1() {
    if(this.commentText !=''){
      const reqBody = {
        groupId: this.grpId,
        commentId: this.keyName,
        comment: this.commentText.toString(),
        formId: this.formId.toString()
      }
      this.commentService.saveComment(reqBody, this.comments, false);
    }

    this.commentText =''
  }

  async deleteComment(comment) {
    this.commentService.deleteComment(comment.id).subscribe(async (response) => {
      let getIndex = this.comments.findIndex( item => item.id == comment.id)
      this.comments.splice(getIndex, 1)
    })
  }

  editComment(comment){
    this.commentService.comments.forEach((item) =>{
      if(comment.id == item.id){
        item.comments = comment.comment
        return
      }
    })
       let reqBody = {
        id: comment.id,
        groupId: comment.groupId,
        commentId: comment.commentId,
        comment: comment.comment,
        formId: this.formId.toString()
      }
    this.commentService.saveComment(reqBody, this.comments, true)
    this.commentText =''
    this.showEdit = ''; 
  }
}
