import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';

@Component({
  selector: 'app-replicate-funds',
  templateUrl: './replicate-funds.component.html',
  styleUrls: ['./replicate-funds.component.scss']
})
export class ReplicateFundsComponent implements OnInit {

  @ViewChild('usersListGrid') public usersListGrid: GridComponent;

  gridFilterSettings = { type: 'Excel' };

  selectedOrg;
  selectedFund;
  selectedFundName = '';
  selectedCopyToOrg;
  organization = [];
  filteredOrgs = [];

  funds = [];
  filteredFunds = [];

  usersInSelectedOrg = [];
  userCopyTo;

  statusSource = []
  is73SAdmin: boolean=false;


  constructor(private util: UtilService, private ums: UserManagementService, private ds: DataService,private router: Router) { 
    
  }

  ngOnInit(): void {

    this.is73SAdmin = this.ums.is73sAdminAndMasterUser();

    if (!this.is73SAdmin) {
      this.router.navigateByUrl('/fundList');
    }else{
    const details = this.ums.getUserDetails();
    let con = this.ums.getOrgList(this.is73SAdmin ? null : details.id).subscribe(result=>{
      this.organization = result.body['response'];
      this.organization.sort((a,b) => a.orgName.localeCompare(b.orgName))
      this.filteredOrgs = this.organization;

      console.log();
    }, error=>{
      console.log("error while fetching org list", error);
    });
    this.getFundCloneStatus();
  }
}

  filterOrgs(input) {
    this.filteredOrgs = []
    
    this.organization.forEach(x=>{
      let orgName = x.orgName.toLowerCase()
      if(orgName.includes(input.target.value.toLowerCase())){
        this.filteredOrgs.push(x)
      }
    })
  }

  filterFunds(input) {
    this.filteredFunds = []
    
    this.funds.forEach(x=>{
      let fundName = x.name.toLowerCase()
      if(fundName.includes(input.target.value.toLowerCase())){
        this.filteredFunds.push(x)
      }
    })
  }
  
  selectOrganization(org){
    this.util.showLoadingPopup();
    this.selectedOrg = org;
    this.ds.getFundsByOrgId(org.id).subscribe(res=>{
      this.funds = res.body['response'].funds;
      this.filteredFunds = this.funds;

      this.util.closeAllPopups();
    }, error=>{
      this.util.closeAllPopups();
      this.util.showMessage("Failed to fetch funds list", "OK");
      console.log("error while fetching fund list", error);
    });
  }

  selectFund(fund){
    this.selectedFund = fund;
    this.selectedFundName = fund.name;
    this.filteredFunds = this.funds;
  }

  selectCopyToOrg(org){
    this.selectedCopyToOrg = org;
    this.filteredFunds = this.funds;

    this.usersInSelectedOrg = [];

    this.getUsersInSelectedOrg(org);
  }

  getUsersInSelectedOrg(org) {
    this.userCopyTo = null;

    this.ums.getUsers(org.id).subscribe(res => {
      this.usersInSelectedOrg = res.body["response"];
    })
  }

  selectCopyToUser(u) {
    this.userCopyTo = u;
  }

  async copyToOrg(){
    const userDetails = this.ums.getUserDetails();
    this.util.showMessage("Sent for copying", "OK");

    const fromFundUserAPI = await this.ums.getUserDetailsByDB_ID(this.selectedFund.createdBy).toPromise();
    const fromFundUser = fromFundUserAPI.body["response"];

    this.ds.copyFundToOrg(fromFundUser.userId, this.userCopyTo.userId, this.selectedFund.id, this.selectedOrg.id, this.selectedCopyToOrg.id, userDetails.userId, this.selectedFundName).subscribe(res=>{
      this.funds = res.body['response'].funds;
      this.getFundCloneStatus();
    }, error=>{
      this.getFundCloneStatus();
      this.util.showMessage("Failed to copy fund", "OK");
      console.log("error while copying fund", error);
    });

    setTimeout(() => {
      this.getFundCloneStatus();
    }, 5000);
    
  }

  getFundCloneStatus(){
    this.ds.getFundCloneStatus().subscribe(res => {
      this.statusSource = res.body['response'];
      
      this.statusSource = this.statusSource.sort((f1, f2)=>{
        const f1Date = new Date(f1.createdDate);
        const f2Date = new Date(f2.createdDate);
        return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
      })
      
    }, error => {
      this.util.showMessage("Failed to fetch fund status", "OK");
      console.log("error while geting fund status", error);
    })
  }

}
