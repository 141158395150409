import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  TemplateRef,
  SimpleChanges,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ImageLoadService } from "src/app/services/image-load.service";
import { FundListService } from "../fund-list-ui/fund-list.service";
import { DataService } from "src/app/services/data.service";
import moment from "moment";
import { cloneDeep, isArray, isBoolean } from "lodash";
import { Chart } from "angular-highcharts";
import * as Highcharts from "highcharts";
import { DatePipe } from "@angular/common";
import { UtilService } from "src/app/utils/util.service";
import { colors } from "src/app/utils/colors";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserManagementService } from "src/app/services/user-management.service";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { environment } from "src/environments/environment";
import { InvestmentSummaryService } from "./investment-summary.service";
import { PortFolioSummaryServiceV2 } from "../portfolio-summary-v2/portfolio-summary-v2.service";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { TrackRecordService } from "./track-record-preferences/track-record-preferences.service";
import { TranslateService } from "src/app/services/translation.service";
import { ThemeService } from "src/app/utils/theme.service";

declare var ResizeObserver;

@Component({
  selector: "app-investment-page-summary-v2",
  templateUrl: "./investment-page-summary-v2.component.html",
  styleUrls: ["./investment-page-summary-v2.component.scss"],

})
export class InvestmentPageSummaryV2Component implements OnInit, AfterViewInit {
  @Input() formData;
  @Input() selectedValuationDates = [];
  @Input() companyId;
  @Input() buildUpSaved = false;
  @Input() valuationDatesWithSummaryData = {};
  @Input() isReloadInvestmentSummary = false;
  @Input() valDateUpperLimit;
  @Input() settings;

  @Output() consolFormSelected = new EventEmitter();

  @Output() upperLimitChanged = new EventEmitter();

  @ViewChild("downloadLink", { static: true }) downloadLink: ElementRef;
  @ViewChild("barChartRef", { static: true }) barChartRef: ElementRef;

  fundId;

  showAllValDatesinTrackRecord: boolean = false;
  trackRecord = [];
  summaryNumbers = {} as any;

  areaChartLoader = true;
  multipleEvolutionFormWiseData = {};
  marketMultiple = "bevRevenue";
  buMarketMultiple;
  marketEvolutionChartData = {};
  multipleChartShowLabels = false;

  evolutionChartAdditionalDate;
  minForChartAdditionalDate;
  maxForChartAdditionalDate;
  marketDataForAdditionalDate = {} as any;

  marketChartDataVariations = {
    vsLastestValuation: 0,
    vsQ4QuarterPrevToLastestValuation: 0,
    vsInvestmentValuation: 0,
  };

  areaChart;

  multipleValueBridgeData = [];
  valueBridgeName = "";
  selectedValueBridgeIndex = -1;
  isMultiValueBridge = false;
  startDateValuationBridge = "";
  endDateValuationBridge = "";
  intermediateDateValuationBridge = "";

  startDateValuationBridgeClone = "";
  endDateValuationBridgeClone = "";
  intermediateDateValuationBridgeClone = "";

  filterAlgorithmnSelectionReqbody;

  // advanceNavFilter;

  searchInput = "";

  algorithmFiltersForNAVBridge = [];

  uniqueListOfAlgorithmsForValueBridge = [];

  valDateWiseAlgorithmList;

  selectedAdjustments = [];

  showDropdown = false;


  preparedValueBridge = false;
  customAttributesEntries = [];

  variationsName = "KPIs - Implied Multiple Variations";

  valueBridgeModel = [];
  valueBridgeModelClone;

  isVBExcelDownloading;

  isTrackRecordExcelDownloading;
  isMultipleEvolutionChartExcelDownloading;
  waterFallNumbers = [];

  valueBridgeFullData;

  filteredForms = [];

  startDateForm = {};
  endDateForm = {};
  intermediateDateForm = {};

  valuationBarChart;
  defaultValueBridgeLoading;

  // showAllValdatesInChart = false;

  showValueBridgePopUpLoader = false;

  partnerList = {
    name: "",
    isSelected: false,
  };

  savedData = {
    comments: [
      { title: "", comment: "" },
      { title: "", comment: "" },
      { title: "", comment: "" },
      { title: "", comment: "" },
    ],
    significantEventMarket: "",
    significantEventCompany: "",
    performance: {
      revenue: {},
      grossProfit: {},
      gpMargin: {},
      ebitda: {},
      ebitdaMargin: {},
      ebit: {},
      ebitMargin: {},
    },
    partners: [],
    fullyDilutedOwnership: 0,
    securityType: "",
    marketMultiple: "bevRevenue",
    valuationBridgeDates: [],

    trackRecordPreference: {
      period: "LTM",
      selectedMetrics: ["revenue", "ebitda"],
    },
  };

  orgKeyWords = [];

  latestValDateTrackRecord = {} as any;

  latestValuationDate = {} as any;

  loadingTrackRecord = true;

  isEZ = false;

  fundDetails: any;

  orgId;

  masterPartnersList = [];

  //Excel Datastructure
  actualPerformanceDataModel = {
    header1: ["", ""],
    header2: ["Actual", "Budget", "Delta(%)", "Actual", "Budget", "Delta(%)"],
    rows: [
      {
        label: "Revenue",
        values: [],
      },
      {
        label: "Gross Profit",
        values: [],
      },
      {
        label: "GP Margin(%)",
        values: [],
      },
      {
        label: "EBITDA",
        values: [],
      },
      {
        label: "EBITA Margin(%)",
        values: [],
      },
      {
        label: "EBIT",
        values: [],
      },
    ],
  };

  editCompanyDetails;

  actualPerformanceGraph;

  fullyDilutedOwnershipStake;
  securityTypeAv: string = "";

  selectedExitStatus = "CURRENT PORTFOLIO";
  selectedExitDate;

  valDatesForUpperLimitSelection = [];
  valDateUpperLimitUserInput;

  companyDescription = "";
  companySector = "";
  countryName = "";
  countryAlias = "";
  companyName = "";

  valueBridgeCurrencyMenuLabel = "Reporting";
  valueBridgeCurrency;
  valueBridgeLabel;

  localCurrency = false;

  latestValuationYear;

  selectedBusinessUnits = [];

  selectedForm;
  displayCustomAttributes;

  uniqueAttributesList = [];
  attributesData;

  isLatestValDateGreaterThanCurrentDate = false;

  impliedMultipleForDrillDown;

  generalAttributeData;

  popupState = "CLOSE";

  showHideStatusOfGeneralAttributes;

  showHideStatusOfGeneralAttributesClone;

  @ViewChild('actualVsPlannedGrid', { static: false }) public actualVsPlannedGrid: GridComponent;
  @ViewChild("businessUnitPopup", {static: true}) businessUnitPopup: TemplateRef<any>;

  constructor(
    public imageLoadService: ImageLoadService,
    private route: ActivatedRoute,
    public fundListService: FundListService,
    public ds : DataService,
    public datePipe: DatePipe,
    public utilService : UtilService,
    private modalService : NgbModal,
    public ums: UserManagementService, 
    public invSummaryService : InvestmentSummaryService,
    public portfolioService: PortFolioSummaryServiceV2,
    // private fb: FormBuilder,
    private router: Router,
    public trService: TrackRecordService,
    public translateService: TranslateService,
    private themeService: ThemeService
  ) {}

  ngAfterViewInit(): void {
    // Event Triggered on Resizing of an Element
    const barChartEle = document.getElementById("barChartRef");

    // Create observer
    const observer = new ResizeObserver((Entries) => {
      console.log("Entries of Resized Ele", Entries);

      this.reinitializingValuationChart();
    });

    // Add element (observe)
    observer.observe(barChartEle);
  }

  ngOnInit() {
    this.loadingTrackRecord = true;

    this.valueBridgeModel = this.getDefaultValueBridge();

    this.fundId = this.route.snapshot.queryParamMap.get("parentId");

    if (
      !this.fundListService.allFunds ||
      this.fundListService.allFunds.length == 0
    ) {
      const myDetails = this.ums.getSelectedUserDetails();

      this.fundListService.getFunds(myDetails);
    }

    this.isEZ = this.ums.isEZUser();

    this.orgId = this.ums.getSelectedUserDetails().organization.id;

    this.updateFormsWithInvestment();

    this.loadInvestmentSummary();

    this.invSummaryService.valuationDateSummaryReady$.subscribe((summary) => {
      try {
        this.selectedForm = cloneDeep(this.latestValuationDate);
        this.buMarketMultiple = this.selectedForm.businessUnits[0].businessUnitName
      } catch(e) {}
      
      this.fetchMarketEvolutionChartOnBUChange();
    });

    this.selectedExitStatus = this.invSummaryService.investmentDateValuation.exitStatus
      ? this.invSummaryService.investmentDateValuation.exitStatus
      : "CURRENT PORTFOLIO";
    this.selectedExitDate = this.invSummaryService.investmentDateValuation.exitDate;

    // Allow user to select upper limit only till investment date
    this.valDatesForUpperLimitSelection =
      this.invSummaryService.allValuationDates
        .filter((form) => {
          const details = JSON.parse(form.details);
          return details.status == "Submitted";
        })
        .map((comp) => comp.valuationDate);

    this.valDateUpperLimitUserInput = this.latestValuationDate.valuationDate;

    this.getCustomAttributesForLatestValDate();

    this.evolutionChartAdditionalDate = new Date();

    this.minForChartAdditionalDate = new Date(
      this.latestValuationDate.valuationDate
    );
    this.maxForChartAdditionalDate = new Date();

    //Reinitializing Track record on Saving Of Valuation Date Attrs
    this.invSummaryService.updatingTrackRecordOnAttrsUpdate$.subscribe(
      (res) => {
        this.initTrackRecordData();
      }
    );


    // this.prepareAdjustmentSelection(this.algorithmFiltersForNAVBridge);

    console.log("this.invSummaryService.allValuationDates", this.invSummaryService.allValuationDates)

  }

  getCustomAttributesForLatestValDate() {
    this.ds.getCustomAttributeWidgetData(this.latestValuationDate.id).subscribe(
      (res) => {
        if (res.body["response"].length) {
          this.displayCustomAttributes = res.body["response"];
        }
      },
      (error) => {
        console.log(
          "Error while getting Custom Attribute Data in investment summary",
          error
        );
      }
    );
  }

  getStringValue(value) {
    if (typeof value === "string") {
      return true;
    } else {
      return false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fundId = this.route.snapshot.queryParamMap.get("parentId");

    let valuatedForms = this.selectedValuationDates.filter(
      (comp) => !comp.userEntered && comp.status !== "Initiated"
    );
    //sorted in ascending order of valuation dates;
    this.filteredForms = valuatedForms.sort((f1, f2) => {
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date ? 0 : f1Date > f2Date ? 1 : -1;
    });

    if (
      changes.buildUpSaved != undefined &&
      changes.buildUpSaved.currentValue == true &&
      this.settings
    ) {
      try {
        this.initValueBridgeChart();
      } catch (error) {
        console.log("Error in Loading VB", error);
        this.defaultValueBridgeLoading = false;
      }
    }

    if (
      changes.settings &&
      changes.settings.previousValue &&
      JSON.stringify(changes.settings.previousValue) !=
        JSON.stringify(changes.settings.currentValue) &&
      !this.defaultValueBridgeLoading
    ) {
      try {
        this.initValueBridgeChart();
      } catch (error) {
        console.log("Error in Loading VB", error);
        this.defaultValueBridgeLoading = false;
      }
    }

    if (this.settings) {
      let trackRecordPreference = this.getTrackRecordMetric();
      this.initMultiplePreferenceInTrackRecord(
        trackRecordPreference.firstMetric,
        "_primary"
      );
      this.initMultiplePreferenceInTrackRecord(
        trackRecordPreference.secondMetric,
        "_secondary"
      );
      this.initMultiplePreferenceInTrackRecord(
        trackRecordPreference.thirdMetric,
        "_tertiary"
      );
    }

    if (!this.valDateUpperLimit) {
      this.valDateUpperLimitUserInput = this.valDateUpperLimit;
    }

    if (
      changes.isReloadInvestmentSummary &&
      changes.isReloadInvestmentSummary.previousValue == false &&
      changes.isReloadInvestmentSummary.currentValue == true
    ) {
      this.ngOnInit();
    }

    this.reloadMarketEvolutionChartOnUpdateOfSettings(changes);

    this.updateFormsWithInvestment();
  }

  initMultiplePreferenceInTrackRecord(metric, periodType) {
    if (
      metric &&
      !this.trService.preferences[metric + periodType] &&
      this.trService.preferences[metric + periodType] != false
    ) {
      this.trService.preferences[metric + periodType] = true;
      this.trService.preferences[metric + "Multiple" + periodType] = true;
    }
  }

  reloadMarketEvolutionChartOnUpdateOfSettings(changes: SimpleChanges) {
    if (
      changes &&
      changes.settings &&
      changes.settings.currentValue &&
      changes.settings.previousValue
    ) {
      if (
        changes.settings.currentValue["metric"] !=
          changes.settings.previousValue["metric"] ||
        changes.settings.currentValue["period"] !=
          changes.settings.previousValue["period"]
      ) {
        this.fetchMarketEvolutionChartOnBUChange();
      }
    }
  }

  updateFormsWithInvestment() {
    if (
      this.invSummaryService.businessUnitsValSummaryForAllIds &&
      Object.keys(this.invSummaryService.businessUnitsValSummaryForAllIds)
        .length > 0
    ) {
      this.selectedValuationDates = this.selectedValuationDates.map((comp) => {
        if (
          this.invSummaryService.businessUnitsValSummaryForAllIds &&
          this.invSummaryService.businessUnitsValSummaryForAllIds[comp.id]
        ) {
          if (
            this.invSummaryService.businessUnitsValSummaryForAllIds[comp.id][
              "consolSummary"
            ]
          ) {
            comp["investment"] =
              this.invSummaryService.initBU_ConsolSummary(comp);

            comp["consolSummary"] =
              this.invSummaryService.businessUnitsValSummaryForAllIds[comp.id][
                "consolSummary"
              ];
            comp["businessUnits"] =
              this.invSummaryService.addInfoToFormobjectFromDetails(
                this.invSummaryService.businessUnitsValSummaryForAllIds[
                  comp.id
                ]["businessUnits"]
              );
          }
        }
        return comp;
      });

      //   this.fetchMarketEvolutionChartOnBUChange();
    }
    // }

    if (this.settings) {
      this.savedData.trackRecordPreference.period = this.settings.period;
    }
  }

  initValueBridgeChart() {
    this.valueBridgeCurrencyMenuLabel = "Reporting";

    const latestFormData = JSON.parse(this.selectedValuationDates[0].details);
    this.valueBridgeCurrency = latestFormData.currency;

    if (!this.valueBridgeCurrency) {
      this.valueBridgeCurrency = this.latestValDateTrackRecord.currency;
    }

    this.prepareValueBridgeChart();
    this.valueBridgeLabel = this.settings.valueBridge;
  }

  openSettingsOption() {
    this.invSummaryService.showSettings = true;

    this.invSummaryService.getToSettingsOptions();
  }

  changeCurrencyForValueBridge() {
    if (this.valueBridgeCurrency == this.settings.currency) {
      this.valueBridgeCurrencyMenuLabel = "Reporting";
      this.valueBridgeCurrency = this.latestValDateTrackRecord.currency;
      this.prepareValueBridgeChart();
      this.localCurrency = true;
    } else if (
      this.valueBridgeCurrency == this.latestValDateTrackRecord.currency
    ) {
      this.valueBridgeCurrencyMenuLabel = "Local";
      this.valueBridgeCurrency = this.settings.currency;
      this.prepareValueBridgeChart();
      this.localCurrency = false;
    }
  }

  getLabelsForValueBridgeType(type) {
    switch (type) {
      case "ENTERPRISE":
        return this.translateService.getLabel("enterprise");
      case "EQUITY":
        return this.translateService.getLabel("equity");
      case "NAV":
        return this.translateService.getLabel("nav");
    }
  }

  getUnderlyingMetrics(item, trackRecordObj?){
    let data = this.trackRecord[this.trackRecord.length - 1];
    
    switch(item){
      case "equityValue" :
        if(trackRecordObj.netDebt < 0) {
          return [
            { "key": trackRecordObj.enterpriseValue, "type": "value"},
            { "key": "", "type": "operator" },
            { "key": trackRecordObj.netDebt, "type": "value" }
          ]
        }

        else {
          return [
            { "key": trackRecordObj.enterpriseValue, "type": "value"},
            { "key": "+", "type": "operator" },
            { "key": trackRecordObj.netDebt, "type": "value" }
          ]
        }

      case "stakeValue" :
        return [
          { "key": "(", "type": "operator" },
          { "key": trackRecordObj.adjustedConcludedEquityValue, "type": "value"},
          { "key": "*", "type": "operator" },
          { "key": trackRecordObj.stake, "type": "value" },
          { "key": ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": 100, "type": "value" },
        ]

      case "stakeAdjustedEquityValue" :
        if(trackRecordObj.stakeEquityAdjustments < 0) {
          return [
            { "key": trackRecordObj.stakeValue, "type": "value"},
            { "key": "", "type": "operator" },
            { "key": trackRecordObj.stakeEquityAdjustments, "type": "value" }
          ]
        }

        else {
          return [
            { "key": trackRecordObj.stakeValue, "type": "value"},
            { "key": "+", "type": "operator" },
            { "key": trackRecordObj.stakeEquityAdjustments, "type": "value" }
          ]
        }

      case "dpi" :
        return [
          { "key": trackRecordObj.realisedProceeds, "type": "value"},
          { "key": "/", "type": "operator" },
          { "key": trackRecordObj.investmentAmount, "type": "value" }
        ]

      case "moic" :
        return [
          { "key": "(", "type": "operator" },
          { "key": trackRecordObj.stakeAdjustedEquityValue, "type": "value"},
          { "key": "+", "type": "operator" },
          { "key": trackRecordObj.realisedProceeds, "type": "value" },
          { "key": ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": trackRecordObj.investmentAmount, "type": "value"}
        ]

      case "IRR" :
        return this.getIRRforTrackRecord(trackRecordObj);

      case "ytd_irr" :
        return this.getYTDIRRforTrackRecord(trackRecordObj);

      case "adjustedEquityValue" :
        if(trackRecordObj.adjustmentsToEquityValue < 0) {
          return [
            { "key": trackRecordObj.equityValue, "type": "value"},
            { "key": "", "type": "operator" },
            { "key": trackRecordObj.adjustmentsToEquityValue, "type": "value" }
          ]
        }

        else {
          return [
            { "key": trackRecordObj.equityValue, "type": "value"},
            { "key": "+", "type": "operator" },
            { "key": trackRecordObj.adjustmentsToEquityValue, "type": "value" }
          ]
        }

        case "fully_diluted_ownership":
          if(data){
            if(data["selectedTypeOfStakeValue"] == 'fromWaterFallSummary'){
              return [
                { "key": "NA", "type": "operator" }
              ]
            }
            else{
              return [
                { "key": "(", "type": "operator" },
                { "key":  data["stakeValue"], "type": "value" },
                { "key": "/", "type": "operator" },
                { "key":  data["adjustedConcludedEquityValueInValuationSummary"], "type": "value" },
                { "key": ")", "type": "operator" },
                { "key": "*", "type": "operator" },
                { "key": 100, "type": "value" }
              ]
            }
          }
          else{
            return [
              { "key": "NA", "type": "operator" }
            ]
          }
  
        case "subjectCompBevEbitda":
          if(data){
            return [
              { "key":  data["enterpriseValue"], "type": "value" },
              { "key": "/", "type": "operator" },
              { "key":  data["ebitda"], "type": "value" },
            ]
          }
          else{
            return [
              { "key": "NA", "type": "operator" }
            ]
          }
  
        case "subjectCompBevRevenue":
          if(data){
            return [
              { "key":  data["enterpriseValue"], "type": "value" },
              { "key": "/", "type": "operator" },
              { "key":  data["revenue"], "type": "value" },
            ]
          }
          else{
            return [
              { "key": "NA", "type": "operator" }
            ]
          }
  
        case "subjectCompBevEbit":
          if(data){
            return [
              { "key":  data["enterpriseValue"], "type": "value" },
              { "key": "/", "type": "operator" },
              { "key":  data["ebit"], "type": "value" },
            ]
          }
          else{
            return [
              { "key": "NA", "type": "operator" }
            ]
          }
  
        case "subjectCompEVGrossProfit":
          if(data){
            return [
              { "key":  data["enterpriseValue"], "type": "value" },
              { "key": "/", "type": "operator" },
              { "key":  data["grossProfit"], "type": "value" },
            ]
          }
          else{
            return [
              { "key": "NA", "type": "operator" }
            ]
          }
        
      case "vsLastestValuation" :
        if(this.marketChartDataVariations.vsLastestValuation){
          let length = this.impliedMultipleForDrillDown["subjectComp"].length;
          return [
            {"key": "(", "type": "operator"},
            {"key": "(", "type": "operator"},
            {"key": this.impliedMultipleForDrillDown.subjectComp[length-1], "type": "operator"},
            {"key": "/", "type": "operator"},
            {"key": this.impliedMultipleForDrillDown.subjectComp[length-2], "type": "operator"},
            {"key": ")", "type": "operator"},
            {"key": "-1", "type": "operator"},
            {"key": ")", "type": "operator"},
            {"key": "*", "type": "operator"},
            {"key": 100, "type": "operator"}
          ]
        }
        else{
          return [{"key": "NA", "type": "operator"}]
        }

      case "vsQ4QuarterPrevToLastestValuation" :
        if(this.marketChartDataVariations.vsQ4QuarterPrevToLastestValuation){
          return this.getQ4QuatPrevToLatestValuation();
        }
        else{
          return [{"key": "NA", "type": "operator"}]
        }

      case "vs_inception" :
        if(this.marketChartDataVariations.vsInvestmentValuation){
          let length2 = this.impliedMultipleForDrillDown["subjectComp"].length;

          return [
            {"key": "(", "type": "operator"},
            {"key": "(", "type": "operator"},
            {"key": this.impliedMultipleForDrillDown.subjectComp[length2-1], "type": "operator"},
            {"key": "/", "type": "operator"},
            {"key": this.impliedMultipleForDrillDown.subjectComp[0], "type": "operator"},
            {"key": ")", "type": "operator"},
            {"key": "-1", "type": "operator"},
            {"key": ")", "type": "operator"},
            {"key": "*", "type": "operator"},
            {"key": 100, "type": "operator"}
          ]
        }
        else{
          return [{"key": "NA", "type": "operator"}]
        }

      case "ebitdaMargin" :
        return [
          {"key": "(", "type": "operator"},
          {"key": trackRecordObj.ebitda, "type": "operator"},
          {"key": "/", "type": "operator"},
          {"key": trackRecordObj.revenue, "type": "operator"},
          {"key": ")", "type": "operator"},
          {"key": "*", "type": "operator"},
          {"key": 100, "type": "operator"},
        ]

      case "ebitMargin" :
        return [
          {"key": "(", "type": "operator"},
          {"key": trackRecordObj.ebit, "type": "operator"},
          {"key": "/", "type": "operator"},
          {"key": trackRecordObj.revenue, "type": "operator"},
          {"key": ")", "type": "operator"},
          {"key": "*", "type": "operator"},
          {"key": 100, "type": "operator"},
        ]

      default :
        return [ { "key": "NA", "type": "operator" } ];
    }
  }

  getQ4QuatDate(){
    const valDates = this.impliedMultipleForDrillDown.xAxis;
    const latestValDateYear : number = new Date(valDates[valDates.length - 1]).getFullYear();
    const Q4QuarterPrevToLatestValDateYear : number = latestValDateYear - 1;

    // Finding the last quarter for the previous year to latest valuation date year
    const q4IndexFound = valDates.findIndex(date => {
      const d = new Date(date);
      return d.getFullYear() == Q4QuarterPrevToLatestValDateYear &&  d.getMonth() == 11
    });

    let quarterDate = this.impliedMultipleForDrillDown.xAxis[q4IndexFound];

    return quarterDate
  }

  getQ4QuatPrevToLatestValuation(){
    let vsQ4QuarterPrevToLastestValuation = 0;

    const valDates = this.impliedMultipleForDrillDown.xAxis;
    const latestValDateYear : number = new Date(valDates[valDates.length - 1]).getFullYear();
    const Q4QuarterPrevToLatestValDateYear : number = latestValDateYear - 1;

    // Finding the last quarter for the previous year to latest valuation date year
    const q4IndexFound = valDates.findIndex(date => {
      const d = new Date(date);
      return d.getFullYear() == Q4QuarterPrevToLatestValDateYear &&  d.getMonth() == 11
    });

    const impliedMultiples = this.impliedMultipleForDrillDown.subjectComp;

    // if(q4IndexFound >= 0){
    //   vsQ4QuarterPrevToLastestValuation = (impliedMultiples[impliedMultiples.length - 1] / impliedMultiples[q4IndexFound]) - 1;
    // }

    let q4List = [
      {"key": "(", "type": "operator"},
      {"key": "(", "type": "operator"},
      {"key": impliedMultiples[impliedMultiples.length - 1], "type": "operator"},
      {"key": "/", "type": "operator"},
      {"key": impliedMultiples[q4IndexFound], "type": "operator"},
      {"key": ")", "type": "operator"},
      {"key": "-1", "type": "operator"},
      {"key": ")", "type": "operator"},
      {"key": "*", "type": "operator"},
      {"key": 100, "type": "operator"}
    ]

    return q4List;
  }

  getUnderlyingMetricsForMultiple(trackRecordObj, metric, year, type){
    let metricValue = this.getSelectedTRMultiple(trackRecordObj, metric, year, type);

    if(metric == 'pe' || metric == 'pbv' || metric == 'priceEarnings'){
      return [
        { "key": trackRecordObj.equityValue, "type":"operator" },
        { "key": "/", "type": "operator" },
        { "key": metricValue, "type":"operator" }
      ]
    }
    else{
      return [
        { "key": trackRecordObj.enterpriseValue, "type":"operator" },
        { "key": "/", "type": "operator" },
        { "key": metricValue, "type":"operator" }
      ]
    }
  }

  getRoundingUpLabelForSource(trackRecordItem, rowHeader){
    // Net Debt and Other Balance Sheet Adjustments
    if(rowHeader == 'netDebt'){
      if((trackRecordItem.enterpriseValueRoundingOffOption != "NO_ROUNDING_OFF" || trackRecordItem.equityValueRoundingOffOption != "NO_ROUNDING_OFF") 
        && (trackRecordItem.enterpriseValueRoundingOffSelectionStatus || trackRecordItem.equityValueRoundingOffSelectionStatus)){
        return true
      }
    }
    // Adjustments to Equity Value 
    else if(rowHeader == 'adjustmentsToEquityValue'){
      if((trackRecordItem.adjustedConcludedEquityValueRoundingOffOption != "NO_ROUNDING_OFF" || trackRecordItem.equityValueRoundingOffOption != "NO_ROUNDING_OFF") 
        && (trackRecordItem.adjustedConcludedEquityValueRoundingOffSelectionStatus || trackRecordItem.equityValueRoundingOffSelectionStatus)){
        return true
      }
    }
    // Adjustment to Stake Equity Value
    else if(rowHeader == 'stakeEquityAdjustments'){
      if((trackRecordItem.stakeValueRoundingOffOption != "NO_ROUNDING_OFF" || trackRecordItem.stakeAdjustedEquityValueRoundingOffOption != "NO_ROUNDING_OFF") 
        && (trackRecordItem.stakeValueRoundingOffSelectionStatus || trackRecordItem.stakeAdjustedEquityValueRoundingOffSelectionStatus)){
        return true
      }
    }
    else{
      return false
    }
  }

  getSourceForDrillDown(item, subcategory?, subcategory1?, rowWiseValues?, rowHeader?){
    switch(item){
      case "track_record":
        return [
          {"key": item, "type": "value", "link": "track_record"},
          {"key": " / ", "type": "operator",},
          {"key": subcategory, "type": "value", "link": "track_record"},
          {"key": " / ", "type": "operator",},
          {"key": this.latestValuationDate.valuationDate, "type": "value", "link": "track_record"},
        ]

      case "form":
        let formArray = [];

        formArray.push({ "key": item, "type": "value", "link": "form" });

        if(subcategory){
          formArray.push(this.getSubCategory(" / ", "operator"));
          formArray.push(this.getSubCategory(subcategory, "value", "form"));
        }

        if(subcategory1){
          formArray.push(this.getSubCategory(" / ", "operator"));
          formArray.push(this.getSubCategory(subcategory1, "value", "form"));
        }

        return formArray;

      case "transactions":
        return [
          { "key": item, "type": "value", "link": item }
        ]

      case "data":
        let dataArray = [];

        dataArray.push({ "key": item, "type": "value", "link": "data_tab" });
        if(subcategory){
          if( subcategory =='rounding_off_valuation_summary' && this.getRoundingUpLabelForSource(rowWiseValues, rowHeader)){
            dataArray.push(this.getSubCategory(" /", "operator"));
            dataArray.push(this.getSubCategory( this.translateService.getLabel(subcategory), "value", "valuation_summary"));
          }
          else if(subcategory != 'rounding_off_valuation_summary'){
            dataArray.push(this.getSubCategory(" / ", "operator"));
            dataArray.push(this.getSubCategory(this.translateService.getLabel(subcategory), "value", "data_tab"));
          }
        }


        if(subcategory1){
          dataArray.push(this.getSubCategory(" / ", "operator"));
          dataArray.push(this.getSubCategory(subcategory1, "value", "data_tab"));
        }

        return dataArray;

      case "valuation":

        let valuationArray = [];

        valuationArray.push({ "key": item, "type": "value", "link": "valuation" });

        if(subcategory){
          valuationArray.push(this.getSubCategory(" / ", "operator"));
          valuationArray.push(this.getSubCategory(subcategory, "value", "transactions"));
        }

        if(subcategory1){
          valuationArray.push(this.getSubCategory(" / ", "operator"));
          valuationArray.push(this.getSubCategory(subcategory1, "value", "valuation"));
        }

        return valuationArray;

      case "valuation_summary":
        let valuationSummaryArray = [];

        valuationSummaryArray.push({"key": item, "type": "value", "link": "valuation_summary"})

        if(subcategory){
          valuationSummaryArray.push(this.getSubCategory(" / ", "operator"));
          valuationSummaryArray.push(this.getSubCategory(subcategory, "value", "valuation_summary"));
        }

        if(subcategory1){
          valuationSummaryArray.push(this.getSubCategory(" / ", "operator"));
          valuationSummaryArray.push(this.getSubCategory(subcategory1, "value", "valuation_summary"));
        }

        return valuationSummaryArray;

      default :
        let defaultArray = [];

        if(item != ""){
          defaultArray.push({"key": item, "type": "operator"})
        }
        else{
          defaultArray.push({"key": "Null", "type": "operator"})
        }

        return defaultArray;
      
    }
  }

  getSourceForStakeInDrillDown(item){
    switch(item){
      case "fullyDilutedShares":
        return [
          this.getSubCategory("valuation_summary", "value", "valuation_summary"),
          this.getSubCategory(" / ", "operator"),
          this.getSubCategory("fully_diluted_stake", "value", "valuation_summary")
        ]

      case "fromWaterFallSummary":
        return [
          this.getSubCategory("valuation_summary", "value", "valuation_summary"),
          this.getSubCategory(" / ", "operator"),
          this.getSubCategory("implied_stake_from_waterfall", "value", "valuation_summary")
        ]
    }
  }

  getSubCategory(key, type, link?){
    
    let obj = {
      "key": key,
      "type": type
    }

    if(link){
      Object.assign(obj, {"link" : link});
    }

    return obj;

  }

  getFormulaForTrackRecordItems(item){

    switch(item){
      case "equityValue" : 
        return [
          { "key": "enterprise_value", "type": "value", "link": "valuation_summary" },
          { "key": "+", "type": "operator" },
          { "key": "balance_sheet_adjustments", "type": "value", "link": "data_tab" }
        ]

      case "stakeValue" :         
        return [
          { "key": "(", "type": "operator" },
          { "key": "adjusted_equity_value", "type": "value", "link": "valuation_summary" },
          { "key": "*", "type": "operator" },
          { "key": "stake", "type": "value", "link": "valuation_summary" },
          { "key": ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "100", "type": "operator" },
        ]

      case "stakeAdjustedEquityValue" :       
        return [
          { "key": "stake_equity_value", "type": "value", "link": "valuation_summary" },
          { "key": "+", "type": "operator" },
          { "key": "debt_others", "type": "value", "link": "data_tab" }
        ]

      case "moic" :                
        return [
          { "key": "(", "type": "operator" },
          { "key": "nav", "type": "value", "link": "valuation_summary" },
          { "key": "+", "type": "operator" },
          { "key": "realised_proceeds", "type": "value", "link": "transactions" },
          { "key": ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "invested_amount", "type": "value", "link": "transactions" },
        ]

      case "ebitdaMargin" :
        return [
          { "key": "(", "type": "operator" },
          { "key": "Ebitda" + " (" + this.getYearTranslation(this.latestValuationYear) + ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "Revenue" + " (" + this.getYearTranslation(this.latestValuationYear) + ")", "type": "operator" },
          { "key": ")", "type": "operator" },
          { "key": "*", "type": "operator" },
          { "key": 100, "type": "operator" },
        ]

      case "ebitMargin" :
        return [
          { "key": "(", "type": "operator" },
          { "key": "EBIT" + " (" + this.getYearTranslation(this.latestValuationYear) + ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "Revenue" + " (" + this.getYearTranslation(this.latestValuationYear) + ")", "type": "operator" },
          { "key": ")", "type": "operator" },
          { "key": "*", "type": "operator" },
          { "key": 100, "type": "operator" },
        ]

      case "dpi" :               
        return [
          { "key": "realised_proceeds", "type": "value", "link": "transactions" },
          { "key": "/", "type": "operator" },
          { "key": "invested_amount", "type": "value", "link": "transactions" },
        ]

        case "IRR" :
          return [
            { "key": "XIRR(Amounts : Dates)", "type": "operator" }
          ]
  
        case "IRR_YTD" :
          return [
            { "key": "(1 + XIRR(Amounts : Dates))^(YEARFRAC(Start Date, End Date, 1)) -1", "type": "operator" }
          ]

      case "adjustedEquityValue" :
        return [
          {"key": "equity_value", "type": "value", "link": "valuation_summary"},
          {"key": "+", "type": "operator"},
          {"key": "adjustments_to_equity_value", "type": "value", "link": "data_tab"}
        ]

      case "fully_diluted_ownership":
        let data = this.trackRecord[this.trackRecord.length - 1]
        if (data["selectedTypeOfStakeValue"] == 'fromWaterFallSummary') {
          return [
            { "key": "From Waterfall", "type": "value", "link": "valuation_summary" }
          ]
        }
        else {
          return [
            { "key": "(", "type": "operator" },
            { "key": "Stake Equity Value", "type": "value", "link": "valuation_summary" },
            { "key": "/", "type": "operator" },
            { "key": "Adjusted Equity Value (100%)", "type": "value", "link": "valuation_summary" },
            { "key": ")", "type": "operator" },
            { "key": "*", "type": "operator" },
            { "key": "100", "type": "operator" }
          ]
        }

      case "subjectCompBevEbitda":
        return [
          { "key": "Enterprise Value", "type": "value", "link": "valuation_summary" },
          { "key": "/", "type": "operator" },
          { "key": "Adjusted EBITDA", "type": "value", "link": "data_tab" },
          { "key": " (" + this.getYearTranslation(this.latestValuationYear) + ")", "type": "value", "link": "data_tab" }
        ]

      case "subjectCompBevRevenue":
        return [
          { "key": "Enterprise Value", "type": "value", "link": "valuation_summary" },
          { "key": "/", "type": "operator" },
          { "key": "Adjusted Revenue", "type": "value", "link": "data_tab" },
          { "key": " (" + this.getYearTranslation(this.latestValuationYear) + ")", "type": "value", "link": "data_tab" }
        ]

      case "subjectCompBevEbit":
        return [
          { "key": "Enterprise Value", "type": "value", "link": "valuation_summary" },
          { "key": "/", "type": "operator" },
          { "key": "Adjusted EBIT", "type": "value", "link": "data_tab" },
          { "key": " (" + this.getYearTranslation(this.latestValuationYear) + ")", "type": "value", "link": "data_tab" }
        ]

      case "subjectCompEVGrossProfit":
        return [
          { "key": "Enterprise Value", "type": "value", "link": "valuation_summary" },
          { "key": "/", "type": "operator" },
          { "key": "Adjusted Gross Profit", "type": "value", "link": "data_tab" },
          { "key": " (" + this.getYearTranslation(this.latestValuationYear) + ")", "type": "value", "link": "data_tab" }
        ]  

      case "vsLastestValuation" :
        if(this.marketChartDataVariations.vsLastestValuation){
          let length = this.impliedMultipleForDrillDown["xAxis"].length;
  
          return [
            {"key": "(", "type": "operator"},
            {"key": "(", "type": "operator"},
            {"key": this.translateService.getLabel("implied_multiple") + '(' + this.impliedMultipleForDrillDown.xAxis[length-1] + ')', "type": "operator"},
            {"key": "/", "type": "operator"},
            {"key": this.translateService.getLabel("implied_multiple") + '(' + this.impliedMultipleForDrillDown.xAxis[length-2] + ')', "type": "operator"},
            {"key": ")", "type": "operator"},
            {"key": "", "type": "newLine" },
            {"key": "-1", "type": "operator"},
            {"key": ")", "type": "operator"},
            {"key": "*", "type": "operator"},
            {"key": 100, "type": "operator"}
          ]
        }
        else{
          return [
            {"key": "NA", "type": "operator"}
          ]
        }

      case "vsQ4QuarterPrevToLastestValuation" :
        if(this.marketChartDataVariations.vsQ4QuarterPrevToLastestValuation){
          let length2 = this.impliedMultipleForDrillDown["xAxis"].length;
          return [ 
              {"key": "(", "type": "operator"},
              {"key": "(", "type": "operator"},
              {"key": this.translateService.getLabel("implied_multiple") + '(' + this.impliedMultipleForDrillDown.xAxis[length2-1] +')', "type": "operator"},
              {"key": "/", "type": "operator"},
              {"key": this.translateService.getLabel("implied_multiple") + '(' + this.getQ4QuatDate() + ')', "type": "operator"},
              {"key": ")", "type": "operator"},
              {"key": "", "type": "newLine" },
              {"key": "-1", "type": "operator"},
              {"key": ")", "type": "operator"},
              {"key": "*", "type": "operator"},
              {"key": 100, "type": "operator"}
          ]
        }
        else{
          return [
            {"key": "NA", "type": "operator"}
          ]
        }

      case "vs_inception" :
        if(this.marketChartDataVariations.vsInvestmentValuation){
          let length3 = this.impliedMultipleForDrillDown["xAxis"].length;
          return [
            {"key": "(", "type": "operator"},
            {"key": "(", "type": "operator"},
            {"key": this.translateService.getLabel("implied_multiple") + '(' + this.impliedMultipleForDrillDown.xAxis[length3-1] +')', "type": "operator"},
            {"key": "/", "type": "operator"},
            {"key": this.translateService.getLabel("implied_multiple") + '(' + this.impliedMultipleForDrillDown.xAxis[0] + ')', "type": "operator"},
            {"key": ")", "type": "operator"},
            {"key": "", "type": "newLine" },
            {"key": "-1", "type": "operator"},
            {"key": ")", "type": "operator"},
            {"key": "*", "type": "operator"},
            {"key": 100, "type": "operator"}
          ]
        }
        else{
          return [
            {"key": "NA", "type": "operator"}
          ]
        }

      
      default :
        return [ { "key": "NA", "type": "operator" } ]
    }

  }

  getFormulaForMultiple(metric, year){
    let multiple = [];
    
    if(metric == 'pe' || metric == 'pbv' || metric == 'priceEarnings'){
      multiple.push({ "key": "equity_value", "type":"value", "link": "valuation_summary" })
    }
    else{
      multiple.push({ "key": "enterprise_value", "type":"value", "link": "valuation_summary" })
    }

    multiple.push({ "key": "/", "type": "operator" });
    multiple.push({ "key": this.getValueBridgeMetricLabel(metric) + " (" + this.getSelectedPeriodLabel(year) + ")", "type":"value", "link": "data_tab" });

    return multiple;
  }

  getIRRforTrackRecord(valuation){

    if(valuation.IRRReqBody && valuation.IRRReqBody.length > 0){
      let xIRR = [{"key": "XIRR(", "type": "value"}]

      valuation.IRRReqBody.forEach((item, index) => {
        xIRR.push({"key": item.amount, "type": "value"});

        if(index != valuation.IRRReqBody.length - 1){
          xIRR.push({"key": ", ", "type": "operator"});
        }
      })

      xIRR.push({"key": " : ", "type": "operator"});
      xIRR.push({ "key": "", "type": "newLine"});

      valuation.IRRReqBody.forEach((item, index) => {
        xIRR.push({"key": item.date, "type": "value"});

        if(index != valuation.IRRReqBody.length - 1){
          xIRR.push({"key": ", ", "type": "operator"});
        }
      })

      xIRR.push({"key": ")", "type": "operator"});
      
      return xIRR;
    }
    else{
      return [ { "key": "NA", "type": "operator" } ];
    }
  }

  getYTDIRRforTrackRecord(valuation){

    if(valuation.YTDIRRTransForAllDatesYTD.data && valuation.YTDIRRTransForAllDatesYTD.data.length > 0){
      let xIRR = [{"key": "(1+ XIRR(", "type": "value"}]

      valuation.YTDIRRTransForAllDatesYTD.data.forEach((item, index) => {
        xIRR.push({"key": item.amount, "type": "value"});

        if(index != valuation.YTDIRRTransForAllDatesYTD.data.length - 1){
          xIRR.push({"key": ", ", "type": "operator"});
        }
      })
      xIRR.push({"key": " : ", "type": "operator"});
      xIRR.push({ "key": "", "type": "newLine"});

      valuation.YTDIRRTransForAllDatesYTD.data.forEach((item, index) => {
        xIRR.push({"key": item.date, "type": "value"});

        if(index != valuation.YTDIRRTransForAllDatesYTD.data.length - 1){
          xIRR.push({"key": ",", "type": "operator"});
        }
      })
     
      xIRR.push({"key": "))^YEARFRAC(", "type": "operator"});

        xIRR.push({"key": valuation.YTDStartTransDate,  "type": "value"});
        xIRR.push({"key": ", ", "type": "operator"});
        xIRR.push({"key": valuation.YTDEndTransDate,  "type": "value"});
        xIRR.push({"key": ", 1) - 1", "type": "operator"});


      return xIRR;
    }
    else{
      return [ { "key": "NA", "type": "operator" } ];
    }
  }

  getAlgorithmName(algo){

    switch(algo){
      case 'Comparable Company Method':
        return 'trading_comps';

      case 'Comparable Transaction Method':
        return 'transaction_comps';

      case 'Income Approach':
        return 'income_approach_discounted_cash_flow';

      case 'Calibration Approach':
        return 'implied_ev_from_calibration';

      case 'Secondaries':
        return 'implied_ev_from_secondary';

      case 'Price of Recent Investment':
        return 'implied_ev_from_pori';

      case 'Bid Details':
        return 'implied_ev_from_bid';

      case 'Custom':
        return 'implied_ev_from_custom';

      case 'DDM Approach':
        return 'income_approach_dividend_discount_model';

      case 'Listed Company Valuation':
        return 'listed_company';

      case 'MOIC Based Valuation':
        return "Concluded Approach";

      default :
        return algo;
    }
  }

  getLabelForMeticsInDrillDown(metric, period, multiple?){
    let item;
      
    if(multiple){
      item = this.getValueBridgeMetricLabel(metric) + " " + this.translateService.getLabel("multiple") + " (" + this.getSelectedPeriodLabel(period) + ")";
    }
    else{
      item = this.getValueBridgeMetricLabel(metric) + " (" + this.getSelectedPeriodLabel(period) + ")";
    }

    return item;
  }

  // remove(keyword, index){      
  //   this.orgKeyWords.splice(index, 1);
  // }

  // getUnderlyingMetricsForMultiple(trackRecordObj, metric, year, type) {
  //   let metricValue = this.getSelectedTRMultiple(
  //     trackRecordObj,
  //     metric,
  //     year,
  //     type
  //   );

  //   return [
  //     { key: trackRecordObj.enterpriseValue, type: "operator" },
  //     { key: "/", type: "operator" },
  //     { key: metricValue, type: "operator" },
  //   ];
  // }

  // getSourceForDrillDown(item, subcategory?, subcategory1?) {
  //   switch (item) {
  //     case "track_record":
  //       return [
  //         { key: item, type: "value", link: "track_record" },
  //         { key: " / ", type: "operator" },
  //         { key: subcategory, type: "value", link: "track_record" },
  //         { key: " / ", type: "operator" },
  //         {
  //           key: this.latestValuationDate.valuationDate,
  //           type: "value",
  //           link: "track_record",
  //         },
  //       ];

  //     case "form":
  //       let formArray = [];

  //       formArray.push({ key: item, type: "value", link: "form" });

  //       if (subcategory) {
  //         formArray.push(this.getSubCategory(" / ", "operator"));
  //         formArray.push(this.getSubCategory(subcategory, "value", "form"));
  //       }

  //       if (subcategory1) {
  //         formArray.push(this.getSubCategory(" / ", "operator"));
  //         formArray.push(this.getSubCategory(subcategory1, "value", "form"));
  //       }

  //       return formArray;

  //     case "transactions":
  //       return [{ key: item, type: "value", link: item }];

  //     case "data":
  //       let dataArray = [];

  //       dataArray.push({ key: item, type: "value", link: "data_tab" });

  //       if (subcategory) {
  //         dataArray.push(this.getSubCategory(" / ", "operator"));
  //         dataArray.push(this.getSubCategory(subcategory, "value", "data_tab"));
  //       }

  //       if (subcategory1) {
  //         dataArray.push(this.getSubCategory(" / ", "operator"));
  //         dataArray.push(
  //           this.getSubCategory(subcategory1, "value", "data_tab")
  //         );
  //       }

  //       return dataArray;

  //     case "valuation":
  //       let valuationArray = [];

  //       valuationArray.push({ key: item, type: "value", link: "valuation" });

  //       if (subcategory) {
  //         valuationArray.push(this.getSubCategory(" / ", "operator"));
  //         valuationArray.push(
  //           this.getSubCategory(subcategory, "value", "valuation")
  //         );
  //       }

  //       if (subcategory1) {
  //         valuationArray.push(this.getSubCategory(" / ", "operator"));
  //         valuationArray.push(
  //           this.getSubCategory(subcategory1, "value", "valuation")
  //         );
  //       }

  //       return valuationArray;

  //     case "valuation_summary":
  //       let valuationSummaryArray = [];

  //       valuationSummaryArray.push({
  //         key: item,
  //         type: "value",
  //         link: "valuation_summary",
  //       });

  //       if (subcategory) {
  //         valuationSummaryArray.push(this.getSubCategory(" / ", "operator"));
  //         valuationSummaryArray.push(
  //           this.getSubCategory(subcategory, "value", "valuation_summary")
  //         );
  //       }

  //       if (subcategory1) {
  //         valuationSummaryArray.push(this.getSubCategory(" / ", "operator"));
  //         valuationSummaryArray.push(
  //           this.getSubCategory(subcategory1, "value", "valuation_summary")
  //         );
  //       }

  //       return valuationSummaryArray;

  //     default:
  //       let defaultArray = [];

  //       if (item != "") {
  //         defaultArray.push({ key: item, type: "operator" });
  //       } else {
  //         defaultArray.push({ key: "Null", type: "operator" });
  //       }

  //       return defaultArray;
  //   }
  // }

  // getSourceForStakeInDrillDown(item) {
  //   switch (item) {
  //     case "fullyDilutedShares":
  //       return [
  //         this.getSubCategory(
  //           "valuation_summary",
  //           "value",
  //           "valuation_summary"
  //         ),
  //         this.getSubCategory(" / ", "operator"),
  //         this.getSubCategory(
  //           "fully_diluted_stake",
  //           "value",
  //           "valuation_summary"
  //         ),
  //       ];

  //     case "fromWaterFallSummary":
  //       return [
  //         this.getSubCategory(
  //           "valuation_summary",
  //           "value",
  //           "valuation_summary"
  //         ),
  //         this.getSubCategory(" / ", "operator"),
  //         this.getSubCategory(
  //           "implied_stake_from_waterfall",
  //           "value",
  //           "valuation_summary"
  //         ),
  //       ];
  //   }
  // }

  // getSubCategory(key, type, link?) {
  //   let obj = {
  //     key: key,
  //     type: type,
  //   };

  //   if (link) {
  //     Object.assign(obj, { link: link });
  //   }

  //   return obj;
  // }

  // getFormulaForTrackRecordItems(item) {
  //   switch (item) {
  //     case "equityValue":
  //       return [
  //         { key: "enterprise_value", type: "value", link: "valuation_summary" },
  //         { key: "+", type: "operator" },
  //         { key: "balance_sheet_adjustments", type: "value", link: "data_tab" },
  //       ];

  //     case "stakeValue":
  //       return [
  //         { key: "(", type: "operator" },
  //         {
  //           key: "adjusted_equity_value",
  //           type: "value",
  //           link: "valuation_summary",
  //         },
  //         { key: "*", type: "operator" },
  //         { key: "stake", type: "value", link: "valuation_summary" },
  //         { key: ")", type: "operator" },
  //         { key: "/", type: "operator" },
  //         { key: "100", type: "operator" },
  //       ];

  //     case "stakeAdjustedEquityValue":
  //       return [
  //         {
  //           key: "stake_equity_value",
  //           type: "value",
  //           link: "valuation_summary",
  //         },
  //         { key: "+", type: "operator" },
  //         { key: "debt_others", type: "value", link: "data_tab" },
  //       ];

  //     case "moic":
  //       return [
  //         { key: "(", type: "operator" },
  //         { key: "nav", type: "value", link: "valuation_summary" },
  //         { key: "+", type: "operator" },
  //         { key: "realised_proceeds", type: "value", link: "transactions" },
  //         { key: ")", type: "operator" },
  //         { key: "/", type: "operator" },
  //         { key: "invested_amount", type: "value", link: "transactions" },
  //       ];

  //     case "ebitdaMargin":
  //       return [
  //         { key: "(", type: "operator" },
  //         {
  //           key:
  //             "Ebitda" +
  //             " (" +
  //             this.getYearTranslation(this.latestValuationYear) +
  //             ")",
  //           type: "operator",
  //         },
  //         { key: "/", type: "operator" },
  //         {
  //           key:
  //             "Revenue" +
  //             " (" +
  //             this.getYearTranslation(this.latestValuationYear) +
  //             ")",
  //           type: "operator",
  //         },
  //         { key: ")", type: "operator" },
  //         { key: "*", type: "operator" },
  //         { key: 100, type: "operator" },
  //       ];

  //     case "ebitMargin":
  //       return [
  //         { key: "(", type: "operator" },
  //         {
  //           key:
  //             "EBIT" +
  //             " (" +
  //             this.getYearTranslation(this.latestValuationYear) +
  //             ")",
  //           type: "operator",
  //         },
  //         { key: "/", type: "operator" },
  //         {
  //           key:
  //             "Revenue" +
  //             " (" +
  //             this.getYearTranslation(this.latestValuationYear) +
  //             ")",
  //           type: "operator",
  //         },
  //         { key: ")", type: "operator" },
  //         { key: "*", type: "operator" },
  //         { key: 100, type: "operator" },
  //       ];

  //     case "dpi":
  //       return [
  //         { key: "realised_proceeds", type: "value", link: "transactions" },
  //         { key: "/", type: "operator" },
  //         { key: "invested_amount", type: "value", link: "transactions" },
  //       ];

  //     case "IRR":
  //       return [{ key: "XIRR(Dates : Amounts)", type: "operator" }];

  //     case "adjustedEquityValue":
  //       return [
  //         { key: "equity_value", type: "value", link: "valuation_summary" },
  //         { key: "+", type: "operator" },
  //         {
  //           key: "adjustments_to_equity_value",
  //           type: "value",
  //           link: "data_tab",
  //         },
  //       ];

  //     case "fully_diluted_ownership":
  //       let data = this.trackRecord[this.trackRecord.length - 1];
  //       if (data["selectedTypeOfStakeValue"] == "fromWaterFallSummary") {
  //         return [
  //           { key: "From Waterfall", type: "value", link: "valuation_summary" },
  //         ];
  //       } else {
  //         return [
  //           { key: "(", type: "operator" },
  //           {
  //             key: "Stake Equity Value",
  //             type: "value",
  //             link: "valuation_summary",
  //           },
  //           { key: "/", type: "operator" },
  //           {
  //             key: "Adjusted Equity Value (100%)",
  //             type: "value",
  //             link: "valuation_summary",
  //           },
  //           { key: ")", type: "operator" },
  //           { key: "*", type: "operator" },
  //           { key: "100", type: "operator" },
  //         ];
  //       }

  //     case "subjectCompBevEbitda":
  //       return [
  //         { key: "Enterprise Value", type: "value", link: "valuation_summary" },
  //         { key: "/", type: "operator" },
  //         { key: "Adjusted EBITDA", type: "value", link: "data_tab" },
  //         {
  //           key: this.getYearTranslation(this.latestValuationYear),
  //           type: "value",
  //           link: "data_tab",
  //         },
  //       ];

  //     case "subjectCompBevRevenue":
  //       return [
  //         { key: "Enterprise Value", type: "value", link: "valuation_summary" },
  //         { key: "/", type: "operator" },
  //         { key: "Adjusted Revenue", type: "value", link: "data_tab" },
  //         {
  //           key: this.getYearTranslation(this.latestValuationYear),
  //           type: "value",
  //           link: "data_tab",
  //         },
  //       ];

  //     case "subjectCompBevEbit":
  //       return [
  //         { key: "Enterprise Value", type: "value", link: "valuation_summary" },
  //         { key: "/", type: "operator" },
  //         { key: "Adjusted EBIT", type: "value", link: "data_tab" },
  //         {
  //           key: this.getYearTranslation(this.latestValuationYear),
  //           type: "value",
  //           link: "data_tab",
  //         },
  //       ];

  //     case "subjectCompEVGrossProfit":
  //       return [
  //         { key: "Enterprise Value", type: "value", link: "valuation_summary" },
  //         { key: "/", type: "operator" },
  //         { key: "Adjusted Gross Profit", type: "value", link: "data_tab" },
  //         {
  //           key: this.getYearTranslation(this.latestValuationYear),
  //           type: "value",
  //           link: "data_tab",
  //         },
  //       ];

  //     case "vsLastestValuation":
  //       if (this.marketChartDataVariations.vsLastestValuation) {
  //         let length = this.impliedMultipleForDrillDown["xAxis"].length;

  //         return [
  //           { key: "(", type: "operator" },
  //           { key: "(", type: "operator" },
  //           {
  //             key:
  //               this.translateService.getLabel("implied_multiple") +
  //               "(" +
  //               this.impliedMultipleForDrillDown.xAxis[length - 1] +
  //               ")",
  //             type: "operator",
  //           },
  //           { key: "/", type: "operator" },
  //           {
  //             key:
  //               this.translateService.getLabel("implied_multiple") +
  //               "(" +
  //               this.impliedMultipleForDrillDown.xAxis[length - 2] +
  //               ")",
  //             type: "operator",
  //           },
  //           { key: ")", type: "operator" },
  //           { key: "-1", type: "operator" },
  //           { key: ")", type: "operator" },
  //           { key: "*", type: "operator" },
  //           { key: 100, type: "operator" },
  //         ];
  //       } else {
  //         return [{ key: "NA", type: "operator" }];
  //       }

  //     case "vsQ4QuarterPrevToLastestValuation":
  //       if (this.marketChartDataVariations.vsQ4QuarterPrevToLastestValuation) {
  //         let length2 = this.impliedMultipleForDrillDown["xAxis"].length;
  //         return [
  //           { key: "(", type: "operator" },
  //           { key: "(", type: "operator" },
  //           {
  //             key:
  //               this.translateService.getLabel("implied_multiple") +
  //               "(" +
  //               this.impliedMultipleForDrillDown.xAxis[length2 - 1] +
  //               ")",
  //             type: "operator",
  //           },
  //           { key: "/", type: "operator" },
  //           {
  //             key:
  //               this.translateService.getLabel("implied_multiple") +
  //               "(" +
  //               this.getQ4QuatDate() +
  //               ")",
  //             type: "operator",
  //           },
  //           { key: ")", type: "operator" },
  //           { key: "-1", type: "operator" },
  //           { key: ")", type: "operator" },
  //           { key: "*", type: "operator" },
  //           { key: 100, type: "operator" },
  //         ];
  //       } else {
  //         return [{ key: "NA", type: "operator" }];
  //       }

  //     case "vs_inception":
  //       if (this.marketChartDataVariations.vsInvestmentValuation) {
  //         let length3 = this.impliedMultipleForDrillDown["xAxis"].length;
  //         return [
  //           { key: "(", type: "operator" },
  //           { key: "(", type: "operator" },
  //           {
  //             key:
  //               this.translateService.getLabel("implied_multiple") +
  //               "(" +
  //               this.impliedMultipleForDrillDown.xAxis[length3 - 1] +
  //               ")",
  //             type: "operator",
  //           },
  //           { key: "/", type: "operator" },
  //           {
  //             key:
  //               this.translateService.getLabel("implied_multiple") +
  //               "(" +
  //               this.impliedMultipleForDrillDown.xAxis[0] +
  //               ")",
  //             type: "operator",
  //           },
  //           { key: ")", type: "operator" },
  //           { key: "-1", type: "operator" },
  //           { key: ")", type: "operator" },
  //           { key: "*", type: "operator" },
  //           { key: 100, type: "operator" },
  //         ];
  //       } else {
  //         return [{ key: "NA", type: "operator" }];
  //       }

  //     default:
  //       return [{ key: "NA", type: "operator" }];
  //   }
  // }

  // getFormulaForMultiple(metric, year) {
  //   let multiple = [
  //     { key: "enterprise_value", type: "value", link: "valuation_summary" },
  //     { key: "/", type: "operator" },
  //   ];

  //   multiple.push({
  //     key:
  //       this.getValueBridgeMetricLabel(metric) +
  //       " (" +
  //       this.getSelectedPeriodLabel(year) +
  //       ")",
  //     type: "value",
  //     link: "data_tab",
  //   });

  //   return multiple;
  // }

  // getIRRforTrackRecord(valuation) {
  //   if (valuation.IRRReqBody && valuation.IRRReqBody.length > 0) {
  //     let xIRR = [{ key: "XIRR(", type: "value" }];

  //     valuation.IRRReqBody.forEach((item, index) => {
  //       xIRR.push({ key: item.date, type: "value" });

  //       if (index != valuation.IRRReqBody.length - 1) {
  //         xIRR.push({ key: ", ", type: "operator" });
  //       }
  //     });

  //     xIRR.push({ key: " : ", type: "operator" });
  //     xIRR.push({ key: "", type: "newLine" });

  //     valuation.IRRReqBody.forEach((item, index) => {
  //       xIRR.push({ key: item.amount, type: "value" });

  //       if (index != valuation.IRRReqBody.length - 1) {
  //         xIRR.push({ key: ", ", type: "operator" });
  //       }
  //     });

  //     xIRR.push({ key: ")", type: "operator" });

  //     return xIRR;
  //   } else {
  //     return [{ key: "NA", type: "operator" }];
  //   }
  // }

  getFormulaForEnterpriseValue(valuationData) {
    if (valuationData.selectedAlgorithms) {
      let enterpriseArray = [];

      let selectedAlgorithmsWithWeight = valuationData.selectedAlgorithms.filter((ele) => ele.y > 0);

      selectedAlgorithmsWithWeight.forEach((item, index) => {
        if (item.name == "Income Approach") {
          enterpriseArray.push(
            { key: "(", type: "operator" },
            {
              key: item.name,
              type: "value",
              link: "valuation_summary",
              color: "#EF4949CC",
            },
            { key: "x", type: "operator" },
            { key: "Weight", type: "operator", color: "#7971B7" },
            { key: ")", type: "operator" }
          );
        } else if (item.name == "Comparable Company Method") {
          enterpriseArray.push(
            { key: "(", type: "operator" },
            {
              key: item.name,
              type: "value",
              link: "valuation_summary",
              color: "#F9BBBB",
            },
            { key: "x", type: "operator" },
            { key: "Weight", type: "operator", color: "#7971B7" },
            { key: ")", type: "operator" }
          );
        } else if (item.name == "Comparable Transaction Method") {
          enterpriseArray.push(
            { key: "(", type: "operator" },
            {
              key: item.name,
              type: "value",
              link: "valuation_summary",
              color: "#675611",
            },
            { key: "x", type: "operator" },
            { key: "Weight", type: "operator", color: "#7971B7" },
            { key: ")", type: "operator" }
          );
        } else if (item.name == "Custom") {
          enterpriseArray.push(
            { key: "(", type: "operator" },
            {
              key: item.name,
              type: "value",
              link: "valuation_summary",
              color: "#CCA922",
            },
            { key: "x", type: "operator" },
            { key: "Weight", type: "operator", color: "#7971B7" },
            { key: ")", type: "operator" }
          );
        } else if (item.name == "Calibration Approach") {
          enterpriseArray.push(
            { key: "(", type: "operator" },
            {
              key: item.name,
              type: "value",
              link: "valuation_summary",
              color: "#6FC469",
            },
            { key: "x", type: "operator" },
            { key: "Weight", type: "operator", color: "#7971B7" },
            { key: ")", type: "operator" }
          );
        } else if (item.name == "Listed Company Valuation") {
          enterpriseArray.push(
            { key: "(", type: "operator" },
            {
              key: item.name,
              type: "value",
              link: "valuation_summary",
              color: "#8D1010",
            },
            { key: "x", type: "operator" },
            { key: "Weight", type: "operator", color: "#7971B7" },
            { key: ")", type: "operator" }
          );
        } else if (item.name == "Concluded Approach") {
          enterpriseArray.push(
            { key: "(", type: "operator" },
            {
              key: item.name,
              type: "value",
              link: "valuation_summary",
              color: "#313B4A",
            },
            { key: "x", type: "operator" },
            { key: "Weight", type: "operator", color: "#7971B7" },
            { key: ")", type: "operator" }
          );
        } else {
          enterpriseArray.push(
            { key: "(", type: "operator" },
            {
              key: item.name,
              type: "value",
              link: "valuation_summary",
              color: "#443E3E",
            },
            { key: "x", type: "operator" },
            { key: "Weight", type: "operator", color: "#7971B7" },
            { key: ")", type: "operator" }
          );
        }

        if (
          selectedAlgorithmsWithWeight[index + 1] &&
          selectedAlgorithmsWithWeight[index + 1].y > 0
        ) {
          enterpriseArray.push(
            { key: "+", type: "operator" },
            { key: "", type: "newLine" }
          );
        }
      });

      if (enterpriseArray.length > 0) {
        return enterpriseArray;
      } else {
        return [{ key: "NA", type: "operator" }];
      }
    } else {
      return [{ key: "NA", type: "operator" }];
    }
  }

  getUnderlyingMetricsForEnterpriseValue(valuationData) {
    if (valuationData.selectedAlgorithms) {
      let underlyingMetrics = [];

      let selectedAlgorithmsWithWeight = valuationData.selectedAlgorithms.filter((ele) => ele.y > 0);

      selectedAlgorithmsWithWeight.forEach((item, index) => {
        if(item.name == "Income Approach"){
          underlyingMetrics.push(
            { key: "(", type: "operator" },
            { key: item.evValueAfterCurrency, type: "value" , color: "#EF4949CC" },
            { key: "x", type: "operator" },
            { key: item.y + '%', type: "value" },
            { key: ")", type: "operator" }
          )
        }
        else if (item.name == "Comparable Company Method") {
          underlyingMetrics.push(
            { key: "(", type: "operator" },
            { key: item.evValueAfterCurrency, type: "value" , color: "#F9BBBB" },
            { key: "x", type: "operator" },
            { key: item.y + '%', type: "value" },
            { key: ")", type: "operator" }
          );
        } 
        else if (item.name == "Comparable Transaction Method") {
          underlyingMetrics.push(
            { key: "(", type: "operator" },
            { key: item.evValueAfterCurrency, type: "value" , color: "#675611" },
            { key: "x", type: "operator" },
            { key: item.y + '%', type: "value" },
            { key: ")", type: "operator" }
          );
        }
        else if (item.name == "Custom") {
          underlyingMetrics.push(
            { key: "(", type: "operator" },
            { key: item.evValueAfterCurrency, type: "value" , color: "#CCA922" },
            { key: "x", type: "operator" },
            { key: item.y + '%', type: "value" },
            { key: ")", type: "operator" }
          );
        }
        else if (item.name == "Calibration Approach") {
          underlyingMetrics.push(
            { key: "(", type: "operator" },
            { key: item.evValueAfterCurrency, type: "value" , color: "#6FC469" },
            { key: "x", type: "operator" },
            { key: item.y + '%', type: "value" },
            { key: ")", type: "operator" }
          );
        }
        else if (item.name == "Listed Company Valuation") {
          underlyingMetrics.push(
            { key: "(", type: "operator" },
            { key: item.evValueAfterCurrency, type: "value" , color: "#8D1010" },
            { key: "x", type: "operator" },
            { key: item.y + '%', type: "value" },
            { key: ")", type: "operator" }
          );
        } else if (item.name == "Concluded Approach") {
          underlyingMetrics.push(
            { key: "(", type: "operator" },
            { key: item.evValueAfterCurrency, type: "value" , color: "#313B4A" },
            { key: "x", type: "operator" },
            { key: item.y + '%', type: "value" },
            { key: ")", type: "operator" }
          );
        }
         else{
          underlyingMetrics.push(
            { key: "(", type: "operator" },
            { key: item.evValueAfterCurrency, type: "value" },
            { key: "x", type: "operator" },
            { key: item.y + '%', type: "value" },
            { key: ")", type: "operator" }
          );
        }

        if (selectedAlgorithmsWithWeight[index + 1] && selectedAlgorithmsWithWeight[index + 1].y > 0) {
          underlyingMetrics.push(
            { key: "+", type: "operator" },
            { key: "", type: "newLine" }
          );
        }
       
      });

      if (underlyingMetrics.length > 0) {
        return underlyingMetrics;
      } else {
        return [{ key: "NA", type: "operator" }];
      }
    } else {
      return [{ key: "NA", type: "operator" }];
    }
  }

  // getLabelForMeticsInDrillDown(metric, period, multiple?) {
  //   let item;

  //   if (multiple) {
  //     item =
  //       this.getValueBridgeMetricLabel(metric) +
  //       " " +
  //       this.translateService.getLabel("multiple") +
  //       " (" +
  //       this.getSelectedPeriodLabel(period) +
  //       ")";
  //   } else {
  //     item =
  //       this.getValueBridgeMetricLabel(metric) +
  //       " (" +
  //       this.getSelectedPeriodLabel(period) +
  //       ")";
  //   }

  //   return item;
  // }

  remove(keyword, index) {
    this.orgKeyWords.splice(index, 1);
  }

  loadPartnersList() {
    this.ds.getWidgetDataFromDB("PARTNER_MASTER_LIST", this.orgId).subscribe(
      (res) => {
        this.masterPartnersList = res["body"]["response"][0].widgetData;
      },
      (error) => {
        console.log("Failed to load Masters List.", error);
      }
    );
  }

  loadSaveInvestmentSummarySavedData() {
    this.ds.getWidgetDataFromDB("INVESTMENT_SUMMARY", this.companyId).subscribe(
      (res) => {
        try {
          this.savedData = res.body["response"][0].widgetData;

          // Adding ValueBridgeId to the Existing User Saved Value Bridges to identify uniquely instead of depending on Date Combination
          if (
            this.savedData.valuationBridgeDates &&
            this.savedData.valuationBridgeDates.length > 0
          ) {
            this.savedData.valuationBridgeDates.forEach((vb) => {
              if (!vb.valueBridgeId) {
                vb.valueBridgeId = "VB-" + this.utilService.getUID();
              }
            });
          }

          this.initValueBridge();

          //Selected market multiple - Multiple evolution chart
          // this.marketMultiple = this.savedData.marketMultiple;

          if (!this.savedData.trackRecordPreference) {
            this.savedData.trackRecordPreference = {
              period: "LTM",
              selectedMetrics: ["revenue", "ebitda"],
            };
          }

          // Actual Vs Performance Data
          if (this.savedData["actualPerformance"]) {
            this.actualPerformanceDataModel =
              this.savedData["actualPerformance"];
            this.createActualPerformanceGraph();
          }

          this.fullyDilutedOwnershipStake =
            this.savedData.fullyDilutedOwnership;
          this.securityTypeAv = this.savedData.securityType;
          const partnerNames = this.masterPartnersList.map((m) =>
            m.name.toLowerCase()
          );

          this.savedData.partners = this.savedData.partners.filter((s) => {
            return partnerNames.indexOf(s.name.toLowerCase()) >= 0;
          });

          this.masterPartnersList.forEach((m) => {
            m.isSelected = false;
            this.savedData.partners.forEach((s) => {
              if (s.name.toLowerCase() === m.name.toLowerCase()) {
                m.isSelected = s.isSelected;
              }
            });
          });
        } catch (error) {
          this.masterPartnersList.forEach((m) => {
            if (m.isSelected) {
              m.isSelected = !m.isSelected;
            }
          });
        }
      },
      (error) => {
        this.masterPartnersList.forEach((m) => {
          if (m.isSelected) {
            m.isSelected = !m.isSelected;
          }
        });

        this.initValueBridge();
        console.log("Failed to fetch Investment Summary details", error);
      }
    );
  }

  setExitStatus() {
    if(this.selectedExitStatus == 'EXIT PORTFOLIO' && !this.selectedExitDate){
      return;
    }
    let inputObj = {
      companyId: this.companyId,
      exitStatus: this.selectedExitStatus,
      exitDate: this.selectedExitStatus == 'EXIT PORTFOLIO' ? moment(this.selectedExitDate).format("YYYY-MM-DD") : null,
    };

    this.ds.setExitStatus(inputObj).subscribe(
      (res) => {
        this.invSummaryService.investmentDateValuation.exitStatus = this.selectedExitStatus;
        this.invSummaryService.investmentDateValuation.exitDate = this.selectedExitDate;

        // Portfolio Service has to reload after change in exit status
        this.portfolioService.summary[this.fundId] = null;
      },
      (error) => {
        console.log("Failed to save exit status", error);
        this.utilService.showMessage(
          this.translateService.getLabel("err_failed_save_status"),
          this.translateService.getLabel("ok"),
          true
        );
      }
    );
  }

  reinitializingValuationChart() {
    console.log("Resizing Event Using Resizing Observer");
    this.initBarChart(this.trackRecord);
  }

  initBarChart(trackRecord) {
    const __this = this;

    // Plot all valuation dates
    // If status is submitted
    // If it has valuation numbers
    // If it has business units, then atleast one the BU shall be submitted

    const trackRecordClone = cloneDeep(trackRecord).reverse();

    const realisedProceeds = trackRecordClone.map((data) => {
      return {
        y: __this.utilService.getValidNumber(data.realisedProceeds),
      };
    });

    this.valuationBarChart = new Chart({
      chart: {
        type: "bar",
      },
      lang: {
        contextButtonTitle:
          this.translateService.getLabel("chart_context_menu"),
      },
      title: { text: "" },
      xAxis: {
        visible: true,
        categories: trackRecordClone.map((data) => {
          const month = this.datePipe
            .transform(data.valuationDate, "MMMM")
            .slice(0, 3);
          const transMonth = this.translateService.getLabel(month);
          const year = this.datePipe.transform(data.valuationDate, "yyyy");
          return transMonth + " " + year;
        }),
      },
      yAxis: { title: { text: "" }, visible: false },
      legend: { enabled: true },
      exporting: {
        enabled: true,
        menuItemDefinitions: {
          viewFullscreen: {
            text: this.translateService.getLabel("view_in_full_screen"),
          },
        },
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              {
                text: __this.showAllValDatesinTrackRecord
                  ? this.translateService.getLabel("hide_all_valuation_dates")
                  : this.translateService.getLabel("show_all_valuation_dates"),
                onclick: function () {
                  __this.showAllDatesInTrackRecord();
                },
              } as any,
            ],
          },
        },
      },
      credits: { enabled: false },
      tooltip: {
        enabled: true,
        formatter(event) {
          return this.point.series.name;
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.y > 0) {
                const currency = __this.trackRecord[
                  __this.trackRecord.length - 1
                ].currency
                  ? __this.trackRecord[__this.trackRecord.length - 1].currency
                  : "";
                return (
                  currency +
                  " " +
                  __this.utilService.getDisplayFormattedNumber(
                    this.y,
                    1,
                    "",
                    "",
                    false
                  ) +
                  " Mn"
                );
              }
            },
          },
        },
        series: {
          cursor: "pointer",
          stacking: "normal",
          events: {
            click: async (e) => {
              const record = trackRecordClone[e.point["index"]];
              const comp = __this.selectedValuationDates.find(
                (comp) => comp.id == record.formId
              );
              const indexOfConsolForm = __this.selectedValuationDates.findIndex(
                (comp) => comp.id == record.formId
              );
              const details = JSON.parse(comp.details);
              if (details.businessUnitsNumber > 0) {
                __this.selectedBusinessUnits = [];
                __this.modalService.open(__this.businessUnitPopup, {
                  size: "md",
                  centered: true,
                });
                // ToDo : Add BUs
                try {
                  const apiData = await __this.ds
                    .getBusinessUnitsByConsolFormWithInvestmentSummary(comp.id)
                    .toPromise();

                  const data =
                    this.invSummaryService.sortBusinessUnitsByBusinessUnitNameOrderByASC(
                      apiData.body["response"]
                    );

                  const businessUnits =
                    __this.invSummaryService.addInfoToFormobjectFromDetails(
                      data
                    );
                  __this.selectedValuationDates[
                    indexOfConsolForm
                  ].businessUnits = businessUnits;
                  __this.selectedBusinessUnits = [comp].concat(businessUnits);
                } catch (error) {
                  console.log("Faile dto BU Popup", error);
                }
              } else {
                __this.openValuation(comp);
              }
            },
          },
        },
      },
      series: [
        {
          name: this.translateService.getLabel("nav"),
          color: colors.primaryColor,
          data: trackRecordClone.map((data) => {
            let stakeValue = data.stakeAdjustedEquityValue;
            return {
              y: __this.utilService.getValidNumber(stakeValue),
              color:
                data.formId && data.formId.indexOf("_S") < 0
                  ? colors.primaryColor // SAF Forms
                  : colors.primaryColorLight, // User added old valuation date
            };
          }),
        },
        {
          name: this.translateService.getLabel("realised_proceeds"),
          data: realisedProceeds,
          color: colors.secondaryColor,
        },
      ] as any,
    });
  }

  emitAction(event) {
    this.openBUValuation(event.bu);
  }

  openBUValuation(comp) {
    const details = JSON.parse(comp.details);

    //Checking whether the selected form is consol form
    if (details.businessUnitsNumber > 0 && comp.consolForm == null) {
      this.modalService.dismissAll();
      this.consolFormSelected.emit(comp);
    }
    //Its a business Unit
    else {
      this.openValuation(comp);
    }
  }

  openValuation(comp, additionalParams = "") {
    //Ignore if it is user added old valuation date
    if (!comp.id || comp.id.indexOf("_S") > 0) return;

    //Ignore if it is Consol form (which has business units)
    if (comp.businessUnits) return;

    const version = comp.formVersion; //this.ums.getApplicationVersion() + "";

    localStorage.setItem("formId", comp.id);
    localStorage.setItem("fv", version);
    localStorage.setItem("qubit-val-date", comp.valuationDate);
    localStorage.setItem("qubit-investment-date-id", this.companyId);
    localStorage.setItem("FUND_ID", comp.fundCompany);
    let fundName = this.fundListService.getFundName(comp.fundCompany);
    let versionName = comp.versionName;
    let urlParameters =
      "id=" +
      comp.id +
      "&fv=" +
      version +
      "&fundName=" +
      fundName +
      "&versionName=" +
      versionName;
    if (
      comp.consolForm &&
      this.selectedValuationDates &&
      this.selectedValuationDates.length > 0
    ) {
      const consolForm = this.selectedValuationDates.find(
        (f) => f.id == comp.consolForm
      );
      urlParameters += "&consol=" + consolForm.companyNameInForm;
    }
    this.ums.addUserAction(
      "Valuation Date Selection",
      comp.id,
      comp.companyName + " | " + comp.valuationDate,
      "Investment Summary"
    );
    let url =
      environment.portalUrl +
      environment.pvValuation +
      "/#/valuation-summary?" +
      urlParameters +
      "&parentId=" +
      comp.fundCompany;

    if (comp.approvalRequestId) {
      url += "&aid=" + comp.approvalRequestId;
    }

    if (additionalParams) {
      url += additionalParams;
    }

    window.open(url, "_self");
  }

  loadInvestmentSummary() {
    if (this.selectedValuationDates && this.selectedValuationDates.length > 0) {
      let valuatedForms = this.selectedValuationDates.filter(
        (comp) => !comp.userEntered && comp.status !== "Initiated"
      );
      //sorted in ascending order of valuation dates;
      this.filteredForms = valuatedForms.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date ? 0 : f1Date > f2Date ? 1 : -1;
      });

      this.latestValuationDate = cloneDeep(
        this.filteredForms[this.filteredForms.length - 1]
      );
      this.invSummaryService.investmentDateValuation = cloneDeep(
        this.selectedValuationDates.find((f) => !f.groupFormId)
      );

      this.latestValuationYear = new Date(
        this.latestValuationDate.valuationDate
      ).getFullYear();

      this.loadPartnersList();

      this.initTrackRecordData();

      if (this.formData) {
        try {
          this.companyDescription =
            this.formData.GENERAL.GD_General_Details.GD_BD_BIZ_DESCRIPTION;

          if (this.formData.GENERAL.GD_General_Details.GD_Industry) {
            const translatedSector = this.translateService.getLabel("SAF_industryType_" + this.formData.GENERAL.GD_General_Details.GD_Industry);
            this.companySector =
              translatedSector.split(
                ","
              )[0];
            this.invSummaryService.investmentDateValuation["sector"] = this.companySector;
          }

          if (this.formData.GENERAL.GD_General_Details.GD_CT_COUNTRY_NAME) {
            this.countryName =
              this.formData.GENERAL.GD_General_Details.GD_CT_COUNTRY_NAME.name;
              this.countryAlias =  this.formData.GENERAL.GD_General_Details.GD_CT_COUNTRY_NAME.cbAlias;
          }

          if (this.formData.GENERAL.GD_General_Details.GD_CN_COMPANY_NAME) {
            this.companyName =
              this.formData.GENERAL.GD_General_Details.GD_CN_COMPANY_NAME;
          }

          this.getKeywords();
        } catch (error) {
          console.log("Error in formData", error);
        }
      }

      this.loadSaveInvestmentSummarySavedData();

      // this.initValueBridge();
    }

    this.loadShowHideStatusOfAttrs();
  }

  loadShowHideStatusOfAttrs(){
    this.ds.getWidgetDataFromDB("GENERAL_ATTRS_SHOW_HIDE_STATUS", this.companyId).subscribe(res => {
      this.showHideStatusOfGeneralAttributes = res.body["response"][0].widgetData
      this.showHideStatusOfGeneralAttributesClone = cloneDeep(this.showHideStatusOfGeneralAttributes);
    }, error => {
      console.error("Failed to read the Genral Attrs Show Hide Status", error);
      this.initDefaultShowHideStatusOfGeneralAttrs()
    })
  }

  saveShoHideAttrsData(){
    this.ds.saveWidgetDataToDB("GENERAL_ATTRS_SHOW_HIDE_STATUS", this.showHideStatusOfGeneralAttributes, this.companyId).subscribe(res => {
      console.log("Saved ShowHide Attrs Data");
      this.utilService.closeAllPopups();
    }, error => {
      console.error("Failed to Save the Genral Attrs Show Hide Status", error);
      this.utilService.closeAllPopups();
    })
  }

  // ---------------- Track Record --------------------------------

  showAllDatesInTrackRecord() {
    this.showAllValDatesinTrackRecord = !this.showAllValDatesinTrackRecord;
    this.initTrackRecordData();
  }

  getSelectedTRMultiple(valuationDate, selectedMultiple, period, type) {
    // const year = this.savedData.trackRecordPreference.period;
    switch (selectedMultiple) {
      case "revenue":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "bevRevenue",
          period,
          type
        );
      case "bevRevenue":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "bevRevenue",
          period,
          type
        );

      case "ebitda":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "bevEbitda",
          period,
          type
        );
      case "bevEbitda":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "bevEbitda",
          period,
          type
        );

      case "ebit":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "bevEbit",
          period,
          type
        );
      case "bevEbit":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "bevEbit",
          period,
          type
        );

      case "ebitda_capex":
        let ebitdaCapexMultiple = this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "evEbitda_Capex",
          period,
          type
        );
        if (ebitdaCapexMultiple == "NMF") {
          ebitdaCapexMultiple = this.getSelectedTRMultipleValue(
            valuationDate.allAdjustedMetricsYearWise,
            "bevEbitda_Capex",
            period,
            type
          );
        }
        return ebitdaCapexMultiple;

      case "bevEbitda_Capex":
        let ebitda_CapexMultiple = this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "evEbitda_Capex",
          period,
          type
        );
        if (ebitda_CapexMultiple == "NMF") {
          ebitda_CapexMultiple = this.getSelectedTRMultipleValue(
            valuationDate.allAdjustedMetricsYearWise,
            "bevEbitda_Capex",
            period,
            type
          );
        }
        return ebitda_CapexMultiple;

      case "priceEarnings":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "pe",
          period,
          type
        );
      case "pe":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "pe",
          period,
          type
        );

      case "priceBookValue":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "pbv",
          period,
          type
        );
      case "pbv":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "pbv",
          period,
          type
        );

      case "grossProfit":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "evGrossProfit",
          period,
          type
        );
      case "evGrossProfit":
        return this.getSelectedTRMultipleValue(
          valuationDate.allAdjustedMetricsYearWise,
          "evGrossProfit",
          period,
          type
        );
    }
  }

  getSelectedTRMultipleValue(valuationDate, multipleKey, period, type) {
    try {
      let periodKey = period;

      // For Implied Multiple, else For adjusted Metric
      if (type == "impliedMultiple") {
        if (multipleKey == "bevRevenue" && this.isEquityMultipleSelected()) {
          multipleKey = "psales";
        }
      }

      return valuationDate[multipleKey][periodKey] &&
        valuationDate[multipleKey][periodKey][type]
        ? valuationDate[multipleKey][periodKey][type]
        : "NMF";
    } catch (e) {
      return "NMF";
    }
  }

  isEquityMultipleSelected() {
    const allEquityMultiples = ["priceEarnings", "priceBookValue"];

    let isEquityMultiple = false;

    allEquityMultiples.forEach((m) => {
      isEquityMultiple =
        isEquityMultiple ||
        this.savedData.trackRecordPreference.selectedMetrics[0] == m ||
        this.savedData.trackRecordPreference.selectedMetrics[1] == m;
    });
    return isEquityMultiple;
  }

  getSelectedTRMultipleLabel(selectedMetric) {
    switch (selectedMetric) {
      case "revenue":
        return "Revenue";
      case "ebitda":
        return "EBITDA";
      case "ebit":
        return "EBIT";
      case "ebitda_capex":
        return "EBITDA-Capex";
      case "priceEarnings":
        return "Net Income";
      case "priceBookValue":
        return "Shareholder's Equity";
      case "grossProfit":
        return "Gross Profit";
    }
  }

  getSelectedPeriodLabel(label) {
    switch (label) {
      case "fy":
        return this.translateService.getLabel("ltm");
      case "ntm":
        return this.translateService.getLabel("ntm");
      case "ntm+1":
        return this.translateService.getLabel("ntm") + " + 1";
      case "fy-1":
        return "FY - 1";
      case "fy+1":
        return "FY + 1";
      case "fy+2":
        return "FY + 2";
    }
  }

  getTrackRecordMetric() {
    return this.invSummaryService.getTrackRecordMetricAndPeriod(this.settings);
  }

  initTrackRecordData() {
    let allFormIds = this.selectedValuationDates
      .filter((f) => f.id.indexOf("_S") < 0 && f.status !== "Initiated")
      .map((f) => f.id);

    if (!allFormIds) return;

    if (allFormIds.length > 6 && !this.showAllValDatesinTrackRecord) {
      const latestFiveDates = allFormIds.slice(0, 5);
      allFormIds = latestFiveDates.concat([allFormIds[allFormIds.length - 1]]);
    }

    const reqBody = {
      fundId: this.fundId,
      formId: this.companyId,
      valDateIds: allFormIds,
      skipIRR: true,
    };

    const trackRecordClone = cloneDeep(this.trackRecord);
    this.trackRecord = [];

    this.ds.getTrackRecord(reqBody).subscribe(
      (res) => {
        this.trackRecord = res.body["response"].sort((f1, f2) => {
          const f1Date = new Date(f1.valuationDate);
          const f2Date = new Date(f2.valuationDate);
          return f1Date === f2Date ? 0 : f1Date < f2Date ? -1 : 1;
        });

        this.trackRecord = this.trackRecord.filter((rec) => {
          const formExists = this.selectedValuationDates.find(
            (form) => form.id == rec.formId
          );
          return this.invSummaryService.checkWhetherBusinessUnitIsSubmitted(
            formExists
          );
        });

        let IRR_ReqBodyForAllDates = [];
        let IRR_ReqBodyForAllDatesYTD = [];

        //Initializing First Investment Transaction
        let firstInvestmentTransactionDate = null; //DD/MM/YY
        let firstInvestmentTransactionDateYear = null; //YYYY
        
        if(this.trackRecord[0] 
          && this.trackRecord[0].investmentAmountTrans
          && this.trackRecord[0].investmentAmountTrans[0]){
            const firstTrans = this.trackRecord[0].investmentAmountTrans[0];

            firstInvestmentTransactionDate = moment(firstTrans.date).format("DD/MM/YY");
            firstInvestmentTransactionDateYear = firstInvestmentTransactionDate.slice(firstInvestmentTransactionDate.length - 2); //Valuation Date Year
        }

        this.trackRecord.forEach((row, index) => {
          let requestBodyIRR;
          if (row.IRRReqBody) {
            requestBodyIRR = {
              request_id: row.formId,
              data: row.IRRReqBody,
            };
          }

          if(requestBodyIRR){
            IRR_ReqBodyForAllDates.push(requestBodyIRR);
          }

          //Initializing Current Valuation Date and Year
          let currentValuationDate = moment(row.valuationDate).format("DD/MM/YY"); 
          let currentValuationDateYear = currentValuationDate.slice(currentValuationDate.length - 2); //Valuation Date Year

          //If current valuation date year falls in the year same as first investment transaction date year,
          //considering all the Transactions as per the Normal IRR
          if(currentValuationDateYear == firstInvestmentTransactionDateYear){
            IRR_ReqBodyForAllDatesYTD.push(requestBodyIRR);
          }
          // Need to find the prev year Dec 31 valuation date
          else {
            //Since ValDateYear is not falling on Inv Date Trans Year

            //Initializing Starting Date for Filtering Transactions
            const startingYear = +currentValuationDateYear - 1; //
            const startFilterDate = "31/12/"+startingYear;
            const endFilterDate = currentValuationDate;

            const valuationDateWithStartFilterDateExists = this.selectedValuationDates
            .find(tr => moment(tr.valuationDate).format("DD/MM/YY") == startFilterDate && tr.status =="Submitted" && tr.frozen);
            moment(row.valuationDate, "DD/MM/YY")

            // If the Dec 31 Prev Year Valuation exists,
            if(valuationDateWithStartFilterDateExists && row.IRRReqBody){
              const ytdIRRReqBody = row.IRRReqBody.filter(body => {
                const lowDate = moment(startFilterDate, "DD/MM/YY").format("YYYY-MM-DD");
                const highDate = moment(endFilterDate, "DD/MM/YY").format("YYYY-MM-DD");

                const transDate = moment(body.date, "DD/MM/YY").format("YYYY-MM-DD");

                return this.utilService.compareDates(new Date(lowDate), new Date(transDate)) >= 0
                || this.utilService.compareDates(new Date(transDate), new Date(highDate)) <= 0
              });

              const additionalInvTrans = {
                "date": startFilterDate,
                "amount": valuationDateWithStartFilterDateExists.investment.equityValue.finalStakeValue * -1, 
                "type": "investmentAmount"
              } as any

              ytdIRRReqBody.unshift(additionalInvTrans);

              IRR_ReqBodyForAllDatesYTD.push({
                request_id: row.formId,
                data: ytdIRRReqBody
              })
            }
            // If the date doesnot exists
            else {
              IRR_ReqBodyForAllDatesYTD.push({
                request_id: row.formId,
                data: []
              })
            }
          }
          
        });

        this.getAllIRR(IRR_ReqBodyForAllDates, IRR_ReqBodyForAllDatesYTD);

        this.latestValDateTrackRecord =
          this.trackRecord && this.trackRecord.length > 0
            ? this.trackRecord[this.trackRecord.length - 1]
            : {};

        this.getInitialInvestmentAmount();

        if (!this.valueBridgeCurrency) {
          this.valueBridgeCurrency = this.latestValDateTrackRecord.currency;
        }

        this.loadingTrackRecord = false;

        this.initBarChart(this.trackRecord);

        this.assignSubjectCompanyMultiples();
      },
      (error) => {
        console.log(
          "ERROR: Failed to fetch all form data for Dashboard Presentation",
          error
        );
        // this.loadingTrackRecord = false;

        this.trackRecord = trackRecordClone;

        this.initTrackRecordData();
      }
    );

    this.getCustomAttributesData();
  }

  getInitialInvestmentAmount() {
    if (this.trackRecord && this.trackRecord.length > 0) {
      try {
        const firstValDate = this.trackRecord[0];
        const oldestDate = firstValDate.investmentAmountTrans[0].date;

        const investmentAmountTrans = firstValDate.investmentAmountTrans.filter(
          (data) => data.date == oldestDate
        );

        let initialInvestment = 0;

        for (let index = 0; index < investmentAmountTrans.length; index++) {
          initialInvestment =
            initialInvestment + investmentAmountTrans[index].value;
        }

        this.trackRecord[0].initialInvestment = initialInvestment;
      } catch (e) {
        this.trackRecord[0].initialInvestment = 0;
      }
    }
  }

  // Fetch attributes for all valuation dates for track record
  getCustomAttributesData() {
    this.ds.getUniqueAttributesList(this.companyId).subscribe((res) => {
      this.uniqueAttributesList = res.body["response"];
    });

     this.ds.getAttributesListByCompany(this.companyId).subscribe((res) => {
      this.attributesData = res.body["response"];
    });

    this.getCustomAttributesForLatestValDate();
  }

  getAllIRR(IRR_ReqBodyForAllDates , IRR_ReqBodyForAllDatesYTD) {
    // console.log("latestValDateTrackRecord", this.latestValDateTrackRecord)
    // if (IRR_ReqBodyForAllDates.length > 0) {
    //   this.ds.getIRRList(IRR_ReqBodyForAllDates).subscribe((res) => {
    //     const allIRRs = res.body["response"];

    //     this.trackRecord.forEach((row) => {
    //       if (allIRRs[row.formId]) {
    //         row.grossIRR = this.utilService.zeroInForOneDecimalFormat(
    //           allIRRs[row.formId] * 100
    //         );
    //       }
    //     });
    //   });
    // }

    if (IRR_ReqBodyForAllDatesYTD.length > 0) {
      this.ds.getIRRList(IRR_ReqBodyForAllDatesYTD).subscribe((res) => {
        const allIRRs = res.body["response"];

        if(allIRRs){
          this.trackRecord.forEach((row) => {
            if(allIRRs.hasOwnProperty(row.formId)){
              // (1 + IRR)^(((last date - first date)/365)) - 1
              
              let eachIRRIndex = IRR_ReqBodyForAllDatesYTD.find(item => item.request_id == row.formId)
              if(eachIRRIndex){
                let daysDifference = this.utilService.getDifferenceInDays(eachIRRIndex?.data[0].date, eachIRRIndex?.data[eachIRRIndex.data.length-1].date)
                let perfixFormula = 1 + allIRRs[row.formId]
                let exponent = (daysDifference/365)

                row['YTDDaysDifference'] = daysDifference
                row['YTDperfixFormula'] = perfixFormula
                row['YTDexponent' ] = exponent
                row['YTDStartTransDate'] = eachIRRIndex?.data[0].date
                row['YTDEndTransDate'] = eachIRRIndex?.data[eachIRRIndex.data.length-1].date
                row['YTDIRRTransForAllDatesYTD' ] = eachIRRIndex
                
                const YTDIRRCalculation = Math.pow(perfixFormula, exponent)-1;
                row['grossIRR_YTD'] = this.utilService.zeroInForOneDecimalFormat(YTDIRRCalculation * 100);
              }
            }
          });
        }
        console.log("TrackRecord getAllIRR IRR YTD",this.trackRecord)
      });
    }

    if (IRR_ReqBodyForAllDatesYTD.length > 0) {
      this.ds.getIRRList(IRR_ReqBodyForAllDatesYTD).subscribe((res) => {
        const allIRRs = res.body["response"];

        if(allIRRs){
          this.trackRecord.forEach((row) => {
            if(allIRRs.hasOwnProperty(row.formId)){
              // (1 + IRR)^(((last date - first date)/365)) - 1
              
              let eachIRRIndex = IRR_ReqBodyForAllDatesYTD.find(item => item.request_id == row.formId)
              if(eachIRRIndex){
                let daysDifference = this.utilService.getDifferenceInDays(eachIRRIndex?.data[0].date, eachIRRIndex?.data[eachIRRIndex.data.length-1].date)
                let perfixFormula = 1 + allIRRs[row.formId]
                let exponent = (daysDifference/365)

                row['YTDDaysDifference'] = daysDifference
                row['YTDperfixFormula'] = perfixFormula
                row['YTDexponent' ] = exponent
                row['YTDStartTransDate'] = eachIRRIndex?.data[0].date
                row['YTDEndTransDate'] = eachIRRIndex?.data[eachIRRIndex.data.length-1].date
                row['YTDIRRTransForAllDatesYTD' ] = eachIRRIndex
                
                const YTDIRRCalculation = Math.pow(perfixFormula, exponent)-1;
                row['grossIRR_YTD'] = this.utilService.zeroInForOneDecimalFormat(YTDIRRCalculation * 100);
              }
            }
          });
        }
      });
    }
  }

  assignSubjectCompanyMultiples() {
    // this.prepareCompanyData()

    const latestValDateTrackRecord = this.latestValDateTrackRecord;

    const latestForm = this.selectedValuationDates.find((c) => !c.userEntered);

    try {
      const finArray =
        this.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_DOC;
      //picking the last column in Hist Financials
      let lastYearFin = {} as any;

      if (finArray.length == 1) {
        lastYearFin = finArray[0];
      } else {
        lastYearFin = finArray[finArray.length - 1];
      }

      this.latestValuationYear = lastYearFin.year;

      this.summaryNumbers.fullyDilutedOwnership = latestValDateTrackRecord[
        "stake"
      ]
        ? latestValDateTrackRecord["stake"]
        : 0;

      this.summaryNumbers.revennue = latestValDateTrackRecord["revenue"]
        ? latestValDateTrackRecord["revenue"]
        : 0; //this.getMetricValueFromCCM(lastYearFin.totalNetRevenue, "bevRevenue", this.latestValuationYear);

      this.summaryNumbers.ebitda = latestValDateTrackRecord["ebitda"]
        ? latestValDateTrackRecord["ebitda"]
        : 0; //this.getMetricValueFromCCM(lastYearFin.eBITDA, "bevEbitda", this.latestValuationYear);

      this.summaryNumbers.ebitdaMargin =
        this.summaryNumbers.revennue > 0
          ? (this.summaryNumbers.ebitda / this.summaryNumbers.revennue) * 100
          : 0;

      this.summaryNumbers.ebit = latestValDateTrackRecord["ebit"]
        ? latestValDateTrackRecord["ebit"]
        : 0; //this.getMetricValueFromCCM(lastYearFin.earningsBefore, "evEbit", this.latestValuationYear);

      this.summaryNumbers.grossProfit = latestValDateTrackRecord["grossProfit"]
        ? latestValDateTrackRecord["grossProfit"]
        : 0; //this.getMetricValueFromCCM(lastYearFin.grossProfit, "evGrossProfit", this.latestValuationYear);

      this.summaryNumbers.ebitMargin =
        this.summaryNumbers.revennue > 0
          ? (this.summaryNumbers.ebit / this.summaryNumbers.revennue) * 100
          : 0;

      this.summaryNumbers.psales = latestValDateTrackRecord["priceSales"]
        ? latestValDateTrackRecord["priceSales"]
        : 0; //this.getMetricValueFromCCM(lastYearFin.totalNetRevenue, "psales", this.latestValuationYear);
      this.summaryNumbers.pe = latestValDateTrackRecord["priceEarnings"]
        ? latestValDateTrackRecord["priceEarnings"]
        : 0; //this.getMetricValueFromCCM(lastYearFin.netIncome, "pe", this.latestValuationYear);
      this.summaryNumbers.pbv = latestValDateTrackRecord["priceBookValue"]
        ? latestValDateTrackRecord["priceBookValue"]
        : 0; //this.getMetricValueFromCCM(lastYearFin.totalShareholdersEquity, "pbv", this.latestValuationYear);

      // this.summaryNumbers.industryBevEbitda = this.valuationAlgoData.multipleType['bevEbitda'].aggregations.median[0];
      // this.summaryNumbers.industryBevRevennue = this.valuationAlgoData.multipleType["bevRevenue"].aggregations.median[0];
    } catch (e) {
      console.log(
        "Exception while assigning subject company multiples to summary",
        e
      );
    }

    this.summaryNumbers.subjectCompBevRevenue = latestValDateTrackRecord[
      "bevRevenue"
    ]
      ? latestValDateTrackRecord["bevRevenue"]
      : 0;

    this.summaryNumbers.subjectCompBevEbitda = latestValDateTrackRecord[
      "bevEbitda"
    ]
      ? latestValDateTrackRecord["bevEbitda"]
      : 0;

    this.summaryNumbers.subjectCompBevEbit = latestValDateTrackRecord["bevEbit"]
      ? latestValDateTrackRecord["bevEbit"]
      : 0;

    this.summaryNumbers.subjectCompEVGrossProfit = latestValDateTrackRecord[
      "evGrossProfit"
    ]
      ? latestValDateTrackRecord["evGrossProfit"]
      : 0;

    this.summaryNumbers.subjectCompPsales = latestValDateTrackRecord["psales"]
      ? latestValDateTrackRecord["psales"]
      : 0;

    this.summaryNumbers.subjectCompPe = latestValDateTrackRecord["pe"]
      ? latestValDateTrackRecord["pe"]
      : 0;

    this.summaryNumbers.subjectCompPbv = latestValDateTrackRecord["pbv"]
      ? latestValDateTrackRecord["pbv"]
      : 0;

    this.loadShowHideStatusOfAttrs();
  }

  initValueBridge() {
    let selectedCompIds = this.selectedValuationDates
      .map((comp) => comp.id)
      .filter((id) => id);

    let allBusinessUnits = [];
    this.selectedValuationDates.forEach((comp) => {
      if (comp.businessUnits) {
        allBusinessUnits = allBusinessUnits.concat(
          comp.businessUnits.map((bu) => bu.id)
        );
      }
    });

    selectedCompIds = selectedCompIds.concat(allBusinessUnits);

    if (this.settings) {
      this.prepareValueBridgeChart();
    }
  }

  async prepareValueBridgeChart() {
    this.valueBridgeLabel = this.settings.valueBridge;
    this.defaultValueBridgeLoading = true;

    this.multipleValueBridgeData = [];
    if (
      this.savedData.valuationBridgeDates &&
      this.savedData.valuationBridgeDates.length > 0
    ) {
      for (
        let index = 0;
        index < this.savedData.valuationBridgeDates.length;
        index++
      ) {
        const eachVB = this.savedData.valuationBridgeDates[index];

        let response;

        const startDate = eachVB["startDate"];
        const endDate = eachVB["endDate"];
        const centerDate = eachVB["centerDate"] ? eachVB["centerDate"] : "";
        const typeOfValueBridge = eachVB["isMultiValueBridge"] ? 2 : 1;
        let intermediateDateExists: any;
        console.log("Value Bridge ----------", eachVB);

        try {
          const apiData = await this.ds
            .getSavedValuationBridge(
              startDate,
              endDate,
              this.companyId,
              centerDate,
              typeOfValueBridge
            )
            .toPromise();
          response = apiData.body["response"];

          console.log("Saved Value Bridge ----------", response);

          if (response) {
            this.valueBridgeModel = cloneDeep(JSON.parse(response.data));
            this.valueBridgeModelClone = cloneDeep(this.valueBridgeModel);

            this.startDateValuationBridge = this.datePipe.transform(
              Date.parse(this.valueBridgeModel[0].name),
              "yyyy-MM-dd"
            );
            this.startDateValuationBridgeClone = this.startDateValuationBridge;

            this.endDateValuationBridge = this.datePipe.transform(
              Date.parse(
                this.valueBridgeModel[this.valueBridgeModel.length - 1].name
              ),
              "yyyy-MM-dd"
            );
            this.endDateValuationBridgeClone = this.endDateValuationBridge;

            intermediateDateExists = this.valueBridgeModel.find(
              (data) => data.isIntermediateSum == true
            );
            if (intermediateDateExists) {
              this.intermediateDateValuationBridge = this.datePipe.transform(
                Date.parse(intermediateDateExists.name),
                "yyyy-MM-dd"
              );
              this.intermediateDateValuationBridgeClone =
                this.intermediateDateValuationBridge;
            }
          } else {
            this.startDateValuationBridge = this.datePipe.transform(
              Date.parse(startDate),
              "yyyy-MM-dd"
            );
            this.startDateValuationBridgeClone = this.startDateValuationBridge;

            this.endDateValuationBridge = this.datePipe.transform(
              Date.parse(endDate),
              "yyyy-MM-dd"
            );
            this.endDateValuationBridgeClone = this.endDateValuationBridge;

            if (typeOfValueBridge == 2) {
              this.intermediateDateValuationBridge = this.datePipe.transform(
                Date.parse(centerDate),
                "yyyy-MM-dd"
              );
              this.intermediateDateValuationBridgeClone =
                this.intermediateDateValuationBridge;
            }
          }

          // Fetching MASTER Value Bridge On load
          if (index == 0) {
            this.initValueBridgeDatesDefault();

            const reqBody = this.getValueBridgeAPIReqBody();

            await this.fetchAdvanceNavBridgeFilterData(reqBody.forms);

            const res = await this.ds
              .getValuationBridgeDataForAllForms(
                reqBody,
                this.fundId,
                this.companyId
              )
              .toPromise();

            const apiResponse = res.body["response"];

            this.valueBridgeFullData = apiResponse;

            this.initValueBridgeChartAndDraw(apiResponse);

            // this.waterFallNumbers = cloneDeep(this.valueBridgeModel);

            // Updating Start, End & Center Date for Master VB if updated
            const typeOfValueBridgeIsTwo = this.valueBridgeModel.find(
              (v) => v.isIntermediateSum == true
            );

            this.savedData.valuationBridgeDates[index].startDate =
              this.datePipe.transform(
                Date.parse(this.valueBridgeModel[0].name),
                "dd-MMM-yyyy"
              );
            this.savedData.valuationBridgeDates[index].endDate =
              this.datePipe.transform(
                Date.parse(
                  this.valueBridgeModel[this.valueBridgeModel.length - 1].name
                ),
                "dd-MMM-yyyy"
              );
            this.savedData.valuationBridgeDates[index].centerDate =
              typeOfValueBridgeIsTwo
                ? this.datePipe.transform(
                    Date.parse(typeOfValueBridgeIsTwo.name),
                    "dd-MMM-yyyy"
                  )
                : null;
            this.savedData.valuationBridgeDates[index].isMultiValueBridge =
              typeOfValueBridgeIsTwo ? true : false;

            intermediateDateExists = this.valueBridgeModel.find(
              (data) => data.isIntermediateSum == true
            );

            this.defaultValueBridgeLoading = false;
          } else {
            // Additional User Saved Valuation Bridges
            this.initValueBridgeDates();
          }

          const waterFallChartData = this.drawValuationBridge();

          // Adding ValueBridgeId to Multiple Value Bridge from User saved data for Unique Mapping
          const valueBridgeObj: any = {
            valueBridgeId: eachVB.valueBridgeId,
            name: eachVB.name,
            startDateForm: cloneDeep(this.startDateForm),
            startDateValuationBridge: cloneDeep(this.startDateValuationBridge),
            endDateForm: cloneDeep(this.endDateForm),
            endDateValuationBridge: cloneDeep(this.endDateValuationBridge),
            waterFallGraphShowLabels: false,
            intermediateDateForm: intermediateDateExists
              ? cloneDeep(this.intermediateDateForm)
              : "",
            intermediateDateValuationBridge: intermediateDateExists
              ? cloneDeep(this.intermediateDateValuationBridge)
              : "",
            waterFallNumbers: cloneDeep(this.waterFallNumbers),
            isMultiValueBridge: eachVB.isMultiValueBridge,
            valueBridgeModel: cloneDeep(this.valueBridgeModel),
            waterFallChartData: cloneDeep(waterFallChartData),
            comments: eachVB.comments,
          };

          this.multipleValueBridgeData.push(valueBridgeObj);
          console.log("multipleValueBridgeData", this.multipleValueBridgeData)
        } catch (error) {
          console.log("Failed to Generate Value Bridge Chart")
          this.prepareWaterFallChartNew(true);
        }
      }

      this.saveDataToWidget();
    } else {
      this.prepareWaterFallChartNew(true);
    }
  }


  closeDropdown(event) {
    // console.log("working here: ", event);
    // this.showDropdown = false
  }

  searchFilter(expression) {
    if (expression && expression.length >= 1) {
      this.algorithmFiltersForNAVBridge.forEach((adj) => {
        const filteredValue = expression.toLowerCase();
        if (adj["name"].toLowerCase().includes(filteredValue)) {
          adj.filtered = true;
          adj.children.forEach((child) => {
            child.filtered = true;
            child?.children?.forEach((c) => (c.filtered = true));
          });
        } else {
          adj.filtered = false;
          if (adj.children && adj.children.length > 0) {
            adj.children.forEach((child) => {
              if (child["name"].toLowerCase().includes(filteredValue)) {
                adj.filtered = true;
                child.filtered = true;
                child?.children?.forEach((c) => (c.filtered = true));
              } else {
                child.filtered = false;
                if (child.children && child.children.length > 0) {
                  child.children?.forEach((c) => {
                    if (c["name"].toLowerCase().includes(filteredValue)) {
                      adj.filtered = true;
                      child.filtered = true;
                      c.filtered = true;
                    } else {
                      c.filtered = false;
                    }
                  });
                }
              }
            });
          }
        }
      });
    } else {
      this.algorithmFiltersForNAVBridge.forEach((algo) => {
        algo.filtered = true;
        algo.children?.forEach((section) => {
          section.filtered = true;
          section.children?.forEach((adj) => {
            adj.filtered = true;
          });
        });
      });
    }
  }


  allChildTabsSelected(tab): boolean {
    // if(subTab == 'algorithms'){
      if (!tab || tab?.children?.length == 0) {
        return false;
      }
      const selectedChildAdjustments = tab?.children?.filter(
        (t) => t.selected
      );
      // tab.selected = selectedChildAdjustments?.length == tab?.children?.length;
      return (
        selectedChildAdjustments?.length > 0 &&
        selectedChildAdjustments?.length < tab?.children?.length
      );
  }


  onChangeParent($event, tab) {
    const adjustmentId = tab.key;
    const isChecked = $event.checked;
    this.algorithmFiltersForNAVBridge = this.algorithmFiltersForNAVBridge.map((d) => {
      if (d.key == adjustmentId) {
        d.selected = isChecked;
        d.children.forEach((child) => {
          child.selected = isChecked;
          child?.children?.forEach((c) => {
            c.selected = isChecked;
            c?.children?.forEach((row) => {
              row.selected = isChecked;
            });
          });
        });
        return d;
      }
      return d;
    });
  }


  // prepareAdjustmentSelection(adjustments) {
  //   let filterSelectedAjustments = cloneDeep(adjustments);

  //   for (let algo of filterSelectedAjustments) {
  //     if (algo.selected) {
  //     } else {
  //       algo.children = algo.children.filter(
  //         (section) => section.selected == true
  //       );
  //     }
  //   }

  //   filterSelectedAjustments = filterSelectedAjustments.filter(
  //     (algo) => algo.children.length > 0
  //   );

  //   // filterSelectedAjustments.forEach((algo) => {
  //   //   this.getTotalValueBeforeAdjustment(algo.adjustments, algo.name);
  //   // });

  //   this.selectedAdjustments = filterSelectedAjustments;

  //   this.searchInput = '';
  // }

  onChangeChildrentab(event, tab) {
    const adjustmentId = tab.key;
    const isChecked = event.checked;
    this.algorithmFiltersForNAVBridge = this.algorithmFiltersForNAVBridge.map((d) => {
      d.children.forEach((child) => {
        if (child.key == adjustmentId) {
          child.selected = isChecked;
          child?.children?.forEach((c) => {
            c.selected = isChecked;
            if(c?.children){
              c?.children?.forEach((row) => {
                row.selected = isChecked;
                if(row?.children){
                  row?.children?.forEach((item) => {
                    item.selected = isChecked;
                  })
                }
              })
            }
          });
        }
        return d;
      });
      return d;
    });
  }


  onChangeChildrenSubtab(event, tab, algo) {
    const adjustmentId = tab.key;
    const isChecked = event.checked;
    this.algorithmFiltersForNAVBridge = this.algorithmFiltersForNAVBridge.map((d) => {
      let subChildTab = d.children.find(row =>  row.key == algo.key)
      subChildTab.children.forEach((child) => {
        if (child.key == adjustmentId) {
          child.selected = isChecked;
          child?.children?.forEach((c) => {
            c.selected = isChecked;
            c.children?.forEach((subChild) => {
              subChild.selected = isChecked
            })
          });
        }
        return d;
      });
      return d;
    });
  }

  addValueBridge() {
    this.valueBridgeName = "Default Value Bridge"; //Do not translate the label as this data gets saved.

    const valueBridgeObj: any = {
      name: this.valueBridgeName,
      startDateForm: cloneDeep(this.startDateForm),
      startDateValuationBridge: cloneDeep(this.startDateValuationBridge),
      endDateForm: cloneDeep(this.endDateForm),
      endDateValuationBridge: cloneDeep(this.endDateValuationBridge),
      waterFallGraphShowLabels: false,
      intermediateDateForm: cloneDeep(this.intermediateDateForm),
      intermediateDateValuationBridge: cloneDeep(
        this.intermediateDateValuationBridge
      ),
      waterFallNumbers: cloneDeep(
        this.multipleValueBridgeData[0].waterFallNumbers
      ),
      isMultiValueBridge: cloneDeep(
        this.multipleValueBridgeData[0].isMultiValueBridge
      ),
      valueBridgeModel: cloneDeep(
        this.multipleValueBridgeData[0].valueBridgeModel
      ),
      waterFallChartData: {},
      comments: [
        { title: "", comment: "" },
        { title: "", comment: "" },
        { title: "", comment: "" },
        { title: "", comment: "" },
      ],
      valueBridgeLabel : this.valueBridgeLabel
    };

    // While adding a User Saved VB, adding Unique id to the Saved Data.
    const valueBridgeId = "VB-" + this.utilService.getUID();

    valueBridgeObj.valueBridgeId = valueBridgeId;

    const newEntry = this.getNewUserEntryToValueBridge(
      valueBridgeObj,
      valueBridgeId
    );

    //Pushing new Entry to User saved Data On addition of New VB
    this.savedData.valuationBridgeDates.push(newEntry);

    const waterFallChartData : Chart = this.initwaterFallChart();

    setTimeout(() => {
      const finalChartData = cloneDeep(valueBridgeObj.waterFallNumbers).filter(
        (row, index) =>
          row.y !== 0 || index === 0 || row.isSum || row.isIntermediateSum
      );

      const finalChartDataWithTranslatedLabels =
        this.getTranslatedLabelsForVBItems(finalChartData);

      waterFallChartData.addSeries(
        {
          upColor: "#5FAFF2",
          color: "#5FAFF2",
          data: cloneDeep(finalChartDataWithTranslatedLabels),
          pointPadding: 0,
        } as any,
        true,
        false
      );

      valueBridgeObj.waterFallChartData = waterFallChartData;

      setTimeout(() => {
        this.multipleValueBridgeData.push(valueBridgeObj);
        this.createValueBridgeData(valueBridgeObj);
      });
    });
  }

  createValueBridgeData(valueBridgeObj) {
    //POST call to save Valuation bridge data
    let valuationBridgeData = {
      startDate: this.datePipe.transform(
        Date.parse(valueBridgeObj.valueBridgeModel[0].name),
        "dd-MMM-yyyy"
      ),
      endDate: this.datePipe.transform(
        Date.parse(
          valueBridgeObj.valueBridgeModel[
            valueBridgeObj.valueBridgeModel.length - 1
          ].name
        ),
        "dd-MMM-yyyy"
      ),
      companyId: this.companyId,
      typeOfValueBridge: valueBridgeObj.isMultiValueBridge ? 2 : 1,
      centerDate: null,
      data: JSON.stringify(valueBridgeObj.valueBridgeModel),
    };

    const typeOfValueBridgeIsTwo = valueBridgeObj.valueBridgeModel.find(
      (v) => v.isIntermediateSum == true
    );

    if (typeOfValueBridgeIsTwo) {
      valuationBridgeData["centerDate"] = this.datePipe.transform(
        Date.parse(typeOfValueBridgeIsTwo.name),
        "dd-MMM-yyyy"
      );
    }

    this.ds.createValuationBridge(valuationBridgeData).subscribe(
      (res) => {
        this.saveDataToWidget();
      },
      (error) => {
        console.log("Error while saving valuation Bridge", error);
      }
    );
  }

  assignValueBridgeComments(event, index, key, valueBridgeId) {
    const savedDataIndexExists = this.savedData.valuationBridgeDates.findIndex(
      (vb) => vb.valueBridgeId == valueBridgeId
    );

    if (savedDataIndexExists >= 0) {
      this.savedData.valuationBridgeDates[savedDataIndexExists].comments[index][
        key
      ] = event;
    }
  }

  refreshWaterfallGraph(index) {
    this.preparedValueBridge = false;

    if (this.multipleValueBridgeData[index] && this.multipleValueBridgeData[index].waterFallChartData) {
      this.multipleValueBridgeData[index].waterFallChartData.destroy();
    }

    this.valueBridgeModel = cloneDeep(
      this.multipleValueBridgeData[index].valueBridgeModel
    );

    if(this.valueBridgeLabel == "ADVANCED_NAV_BRIDGE"){
      this.valueBridgeModel.forEach(vb => {
        if(vb.type == "ADVANCED_IMPACT"){
          vb.hide = this.getHideStatusOfAlgorithmImpacts(vb.algoName, vb.typeOfImpact)
        }
      })
    }
    
    const waterFallChartData = this.drawValuationBridge(
      this.multipleValueBridgeData[index].waterFallGraphShowLabels
    );

    if (this.multipleValueBridgeData[index]) {
      this.multipleValueBridgeData[index].waterFallChartData =
        cloneDeep(waterFallChartData);
      // this.multipleValueBridgeData[index].waterFallNumbers = cloneDeep(this.waterFallNumbers);
      // this.multipleValueBridgeData[index].valueBridgeModel = cloneDeep(this.valueBridgeModel)
    }

    // setTimeout(() => {
    //   // add data to chart.
    //   waterFallChartData.addSeries({
    //     upColor: "#5FAFF2",
    //     color: "#5FAFF2",
    //     data: cloneDeep(this.multipleValueBridgeData[index].waterFallNumbers),
    //     pointPadding: 0
    //   } as any, true, false);

    //   setTimeout(() => {
    //     this.multipleValueBridgeData[index].waterFallChartData = cloneDeep(waterFallChartData)
    //     this.preparedValueBridge = true;
    //   });
    // });
  }

  prepareWaterFallChartNew(init?) {
    let reqBody = {};

    this.initValueBridgeDatesDefault();

    if (this.preparedValueBridge) return;

    //Value Bridge For Multiple Val Dates/ BU Value Bridge
    if (this.filteredForms.length >= 2) {
      const valuationDates = [];

      //selecting inv form, latest form, and prev to latest form for forms greater than 3;
      this.filteredForms.forEach((form, index) => {
        if (
          index == 0 ||
          index == this.filteredForms.length - 2 ||
          index == this.filteredForms.length - 1
        ) {
          valuationDates.push(form);
        }
      });

      reqBody = this.prepareValuaBridgeAPIReqFromInputs(valuationDates);
    } else {
      // this.preparedValueBridge = true;
      return;
    }

    this.initValueBridgeDates();

    this.getValuationBridgeData(init, reqBody);
    // }
  }

  async getValuationBridgeData(init, reqBody) {
    // This is specific workflow when there are no User Saved Value Bridges in the this.saveDataToWidget,
    // so creating default Master VB
    this.ds
      .getValuationBridgeDataForAllForms(reqBody, this.fundId, this.companyId)
      .subscribe(
        async (res) => {
          const apiResponse = res.body["response"];

          await this.fetchAdvanceNavBridgeFilterData(reqBody.forms); 

          this.initValueBridgeChartAndDraw(apiResponse);
          let waterFallChartData;
          if (init) {
            // this.waterFallNumbers = cloneDeep(this.valueBridgeModel);
            waterFallChartData = this.drawValuationBridge();
          }

          //Saving And Creating Value Bridge Logic
          this.createMultipleValueBridge(
            waterFallChartData,
            this.waterFallNumbers,
            this.valueBridgeModel
          );

          // index is 0 by default
          // this.saveValuationBridgeData(0)
        },
        (error) => {
          this.preparedValueBridge = true;

          this.valueBridgeModel[0].name = this.datePipe.transform(
            this.startDateForm["valuationDate"],
            "mediumDate"
          );
          this.valueBridgeModel[this.valueBridgeModel.length - 1].name =
            this.datePipe.transform(
              this.endDateForm["valuationDate"],
              "mediumDate"
            );

          this.savedData;
          console.log("Error while Calculating Value Bridge", error);

          this.valueBridgeModel.forEach((obj, index) => {
            if (index == 0) {
              const date2 = new Date(obj.name);
              const temp = this.selectedValuationDates.filter((comp) => {
                const date1 = new Date(comp.valuationDate);
                return (
                  date2.getFullYear() === date1.getFullYear() &&
                  date2.getDate() === date1.getDate() &&
                  date2.getMonth() === date1.getMonth()
                );
              });

              obj.y = temp[0]["investment"]
                ? temp[0]["investment"].equityValue.finalAdjustedEquityVal
                : 0;
              obj["formattedValue"] = obj.y;
              obj["isIntermediateSum"] = false;
            } else if (index == this.valueBridgeModel.length - 1) {
              const date2 = new Date(obj.name);
              const temp = this.selectedValuationDates.filter((comp) => {
                const date1 = new Date(comp.valuationDate);
                return (
                  date2.getFullYear() === date1.getFullYear() &&
                  date2.getDate() === date1.getDate() &&
                  date2.getMonth() === date1.getMonth()
                );
              });
              obj.y = temp[0]["investment"]
                ? temp[0]["investment"].equityValue.finalAdjustedEquityVal
                : 0;
              obj["formattedValue"] = obj.y;
              obj["isIntermediateSum"] = false;
            } else {
              obj.y = 0;
              obj["formattedValue"] = 0;
              obj["isIntermediateSum"] = false;
            }
          });

          // this.waterFallNumbers = cloneDeep(this.valueBridgeModel);
          const waterFallChartData = this.drawValuationBridge();

          //Saving And Creating Value Bridge Logic
          this.createMultipleValueBridge(
            waterFallChartData,
            this.waterFallNumbers,
            this.valueBridgeModel
          );

          // index is 0 by default
          // this.saveValuationBridgeData(0)
        }
      );
  }

  saveValuationBridgeData(selectedValueBridgeIndex) {
    if (
      !this.valueBridgeModel[0].name ||
      !this.valueBridgeModel[this.valueBridgeModel.length - 1].name
    )
      return;

    this.modalService.dismissAll();

    const waterFallChartData = this.drawValuationBridge(
      this.multipleValueBridgeData[selectedValueBridgeIndex]
        .waterFallGraphShowLabels
    );
    // this.createMultipleValueBridge(waterFallChartData, this.waterFallNumbers);

    if (this.multipleValueBridgeData[selectedValueBridgeIndex]) {
      this.multipleValueBridgeData[
        selectedValueBridgeIndex
      ].waterFallChartData = cloneDeep(waterFallChartData);
      this.multipleValueBridgeData[selectedValueBridgeIndex].waterFallNumbers =
        cloneDeep(this.waterFallNumbers);
      this.multipleValueBridgeData[selectedValueBridgeIndex].valueBridgeModel =
        cloneDeep(this.valueBridgeModel);
      this.multipleValueBridgeData[selectedValueBridgeIndex].name =
        this.valueBridgeName;
      this.multipleValueBridgeData[
        selectedValueBridgeIndex
      ].isMultiValueBridge = this.isMultiValueBridge;
    }

    this.valueBridgeModelClone = cloneDeep(this.valueBridgeModel);
    this.startDateValuationBridgeClone = this.startDateValuationBridge;
    this.endDateValuationBridgeClone = this.endDateValuationBridge;

    if (this.intermediateDateValuationBridgeClone) {
      this.intermediateDateValuationBridgeClone =
        this.intermediateDateValuationBridge;
    }
    this.initValueBridgeDates();

    //POST call to save Valuation bridge data
    let valuationBridgeData = {
      startDate: this.datePipe.transform(
        Date.parse(this.valueBridgeModel[0].name),
        "dd-MMM-yyyy"
      ),
      endDate: this.datePipe.transform(
        Date.parse(
          this.valueBridgeModel[this.valueBridgeModel.length - 1].name
        ),
        "dd-MMM-yyyy"
      ),
      companyId: this.companyId,
      typeOfValueBridge: this.multipleValueBridgeData[selectedValueBridgeIndex]
        .isMultiValueBridge
        ? 2
        : 1,
      centerDate: null,
      data: JSON.stringify(this.valueBridgeModel),
    };

    const typeOfValueBridgeIsTwo = this.valueBridgeModel.find(
      (v) => v.isIntermediateSum == true
    );

    if (typeOfValueBridgeIsTwo) {
      valuationBridgeData["centerDate"] = this.datePipe.transform(
        Date.parse(typeOfValueBridgeIsTwo.name),
        "dd-MMM-yyyy"
      );
    }

    const vbToBeEdited = this.multipleValueBridgeData[selectedValueBridgeIndex];

    this.ds.createValuationBridge(valuationBridgeData).subscribe(
      (res) => {
        if (
          !this.savedData.valuationBridgeDates ||
          !isArray(this.savedData.valuationBridgeDates)
        ) {
          this.savedData.valuationBridgeDates = [];
        }

        const startDate = new Date(valuationBridgeData.startDate);
        const endDate = new Date(valuationBridgeData.endDate);
        const centerDate = valuationBridgeData.centerDate
          ? new Date(valuationBridgeData.centerDate)
          : null;

        const clonedListOfSavedVBs = cloneDeep(
          this.savedData.valuationBridgeDates
        );

        const dateCombinationIndex = this.checkForDateCombinationinSavedData(
          startDate,
          endDate,
          centerDate,
          this.multipleValueBridgeData[selectedValueBridgeIndex]
            .isMultiValueBridge,
          this.multipleValueBridgeData[selectedValueBridgeIndex].valueBridgeId
        );

        // this.multipleValueBridgeData.forEach((data, index) => {
        //   const typeOfValueBridgeIsTwo = data.valueBridgeModel.find( v => v.isIntermediateSum == true)
        //   let centerDate = null;

        //   if(typeOfValueBridgeIsTwo){
        //     centerDate = new Date(this.datePipe.transform(Date.parse(typeOfValueBridgeIsTwo.name), "dd-MMM-yyyy"));
        //   }

        //   //Checking the Combination by Value Bridge ID
        //   const dateCombinationIndex = this.checkForDateCombinationinSavedData(startDate, endDate, centerDate, data.isMultiValueBridge, data.valueBridgeId)

        //   if(dateCombinationIndex == -1){
        //     const newEntry = {
        //       startDate: this.datePipe.transform(Date.parse(data.valueBridgeModel[0].name), "dd-MMM-yyyy"),
        //       endDate: this.datePipe.transform(Date.parse(data.valueBridgeModel[(data.valueBridgeModel.length - 1)].name), "dd-MMM-yyyy"),
        //       centerDate: typeOfValueBridgeIsTwo ? this.datePipe.transform(Date.parse(typeOfValueBridgeIsTwo.name), "dd-MMM-yyyy") : null,
        //       name: data.name,
        //       isMultiValueBridge: data.isMultiValueBridge,
        //       valueBridgeId: "VB-" + this.utilService.getUID(),
        //       comments: [{title: "", comment: ""}, {title: "", comment: ""}, {title: "", comment: ""}, {title: "", comment: ""}]
        //     }

        //     data.valueBridgeId = newEntry.valueBridgeId;
        //     this.savedData.valuationBridgeDates.push(newEntry);
        //   }
        //   else if(dateCombinationIndex >= 0){

        //   }
        // })

        this.savedData.valuationBridgeDates[dateCombinationIndex].startDate =
          this.datePipe.transform(
            Date.parse(vbToBeEdited.valueBridgeModel[0].name),
            "dd-MMM-yyyy"
          );
        this.savedData.valuationBridgeDates[dateCombinationIndex].endDate =
          this.datePipe.transform(
            Date.parse(
              vbToBeEdited.valueBridgeModel[
                vbToBeEdited.valueBridgeModel.length - 1
              ].name
            ),
            "dd-MMM-yyyy"
          );
        this.savedData.valuationBridgeDates[dateCombinationIndex].centerDate =
          typeOfValueBridgeIsTwo
            ? this.datePipe.transform(
                Date.parse(typeOfValueBridgeIsTwo.name),
                "dd-MMM-yyyy"
              )
            : null;
        this.savedData.valuationBridgeDates[dateCombinationIndex].name =
          vbToBeEdited.name;
        this.savedData.valuationBridgeDates[
          dateCombinationIndex
        ].isMultiValueBridge = vbToBeEdited.isMultiValueBridge;

        this.saveDataToWidget();
      },
      (error) => {
        console.log("Error while saving valuation Bridge", error);
      }
    );
  }

  saveDataToWidget(showMessage?) {
    // Commenting out below code as it is auto closing the replicate/revalue Pop up
    // this.modalService.dismissAll();

    // P.S : Shouldn't be using dismissAll Which might close all the existing Modals
    // which we are not intending to close, so use the below code snippet
    // 1. Store the Modal ref in some instance Variable like below
    //   this.modalReference = this.modalService.open(modalComponent);

    // 2. this.modalReference.close();
    //   This will close only the modal that you have opened

    this.ds
      .saveWidgetDataToDB("INVESTMENT_SUMMARY", this.savedData, this.companyId)
      .subscribe(
        (res) => {
          if (showMessage) {
            this.utilService.showMessage(
              this.translateService.getLabel("suc_summary_save"),
              this.translateService.getLabel("ok")
            );
          }
        },
        (error) => {
          if (showMessage) {
            this.utilService.showMessage(
              this.translateService.getLabel("err_failed_save_summary"),
              this.translateService.getLabel("ok")
            );
          }
        }
      );
  }

  deleteMultiplValueBridgeData(rowIndex) {
    if (rowIndex == 0) {
      return;
    }

    const deletedValueBridge = this.multipleValueBridgeData.splice(rowIndex, 1);

    const typeOfValueBridgeIsTwo = deletedValueBridge[0].valueBridgeModel.find(
      (v) => v.isIntermediateSum == true
    );
    let centerDate: any;
    if (typeOfValueBridgeIsTwo) {
      centerDate = this.datePipe.transform(
        Date.parse(typeOfValueBridgeIsTwo.name),
        "dd-MMM-yyyy"
      );
    }

    let startDate: any = this.datePipe.transform(
      Date.parse(deletedValueBridge[0].valueBridgeModel[0].name),
      "dd-MMM-yyyy"
    );
    let endDate: any = this.datePipe.transform(
      Date.parse(
        deletedValueBridge[0].valueBridgeModel[
          deletedValueBridge[0].valueBridgeModel.length - 1
        ].name
      ),
      "dd-MMM-yyyy"
    );

    startDate = new Date(startDate);
    endDate = new Date(endDate);
    centerDate = centerDate ? new Date(centerDate) : null;

    const dateCombinationIndex = this.checkForDateCombinationinSavedData(
      startDate,
      endDate,
      centerDate,
      deletedValueBridge[0].isMultiValueBridge,
      deletedValueBridge[0].valueBridgeId
    );

    this.savedData.valuationBridgeDates.splice(dateCombinationIndex, 1);

    this.saveDataToWidget();
  }

  checkForDateCombinationinSavedData(
    startDate,
    endDate,
    centerDate,
    isMultiValueBridge,
    valueBridgeId
  ) {
    let dateCombinationIndex;
    if (isMultiValueBridge) {
      dateCombinationIndex = this.savedData.valuationBridgeDates.findIndex(
        (data) => {
          const startDate1 = new Date(data.startDate);
          const endDate1 = new Date(data.endDate);
          const centerDate1 = data.centerDate
            ? new Date(data.centerDate)
            : null;
          return data.valueBridgeId == valueBridgeId;
          // && centerDate1 && centerDate
          // && startDate.getFullYear() === startDate1.getFullYear()
          // && startDate.getDate() === startDate1.getDate()
          // && startDate.getMonth() === startDate1.getMonth()

          // && endDate.getFullYear() === endDate1.getFullYear()
          // && endDate.getDate() === endDate1.getDate()
          // && endDate.getMonth() === endDate1.getMonth()

          // && centerDate.getFullYear() === centerDate1.getFullYear()
          // && centerDate.getDate() === centerDate1.getDate()
          // && centerDate.getMonth() === centerDate1.getMonth();
        }
      );
    } else {
      dateCombinationIndex = this.savedData.valuationBridgeDates.findIndex(
        (data) => {
          const startDate1 = new Date(data.startDate);
          const endDate1 = new Date(data.endDate);

          return data.valueBridgeId == valueBridgeId;
          // && startDate.getFullYear() === startDate1.getFullYear()
          // && startDate.getDate() === startDate1.getDate()
          // && startDate.getMonth() === startDate1.getMonth()

          // && endDate.getFullYear() === endDate1.getFullYear()
          // && endDate.getDate() === endDate1.getDate()
          // && endDate.getMonth() === endDate1.getMonth()
          // && !data.isMultiValueBridge
        }
      );
    }

    return dateCombinationIndex;
  }

  createMultipleValueBridge(
    waterFallChartData,
    waterFallNumbers,
    valueBridgeModel
  ) {
    this.valueBridgeName = this.translateService.getLabel(
      "default_value_bridge"
    ); //+ "_" + (this.multipleValueBridgeData.length + 1)
    const valueBridgeObj: any = {
      name: this.valueBridgeName,
      startDateForm: cloneDeep(this.startDateForm),
      startDateValuationBridge: cloneDeep(this.startDateValuationBridge),
      endDateForm: cloneDeep(this.endDateForm),
      endDateValuationBridge: cloneDeep(this.endDateValuationBridge),
      waterFallGraphShowLabels: false,
      intermediateDateForm: cloneDeep(this.intermediateDateForm),
      intermediateDateValuationBridge: cloneDeep(
        this.intermediateDateValuationBridge
      ),
      waterFallNumbers: cloneDeep(waterFallNumbers),
      isMultiValueBridge: this.isMultiValueBridge,
      valueBridgeModel: cloneDeep(valueBridgeModel),
      waterFallChartData: cloneDeep(waterFallChartData),
      comments: this.savedData?.comments
        ? cloneDeep(this.savedData.comments)
        : [
            { title: "", comment: "" },
            { title: "", comment: "" },
            { title: "", comment: "" },
            { title: "", comment: "" },
          ],
    };

    // Creating Master VB Everytime Onload to keep it Live, Below is the master ID
    const valueBridgeId = "VB-MASTER-" + this.utilService.getUID();

    valueBridgeObj.valueBridgeId = valueBridgeId;
    //Incase 2 master Id exists, it can happen due to introduction of new Valuation date when form is submitted
    //So Removing Existing Master Id if exists
    this.savedData.valuationBridgeDates =
      this.savedData.valuationBridgeDates.filter(
        (vb) => vb.valueBridgeId.indexOf("MASTER") < 0
      );

    const newEntry = this.getNewUserEntryToValueBridge(
      valueBridgeObj,
      valueBridgeId
    );
    this.savedData.valuationBridgeDates.unshift(newEntry);

    this.multipleValueBridgeData.push(valueBridgeObj);

    this.createValueBridgeData(valueBridgeObj);
  }

  getNewUserEntryToValueBridge(valueBridgeObj, valueBridgeId) {
    const valueBridgeModel = valueBridgeObj.valueBridgeModel;
    const typeOfValueBridgeIsTwo = valueBridgeModel.find(
      (v) => v.isIntermediateSum == true
    );

    const newEntryForMasterVB = {
      startDate: this.datePipe.transform(
        Date.parse(valueBridgeModel[0].name),
        "dd-MMM-yyyy"
      ),
      endDate: this.datePipe.transform(
        Date.parse(valueBridgeModel[valueBridgeModel.length - 1].name),
        "dd-MMM-yyyy"
      ),
      centerDate: typeOfValueBridgeIsTwo
        ? this.datePipe.transform(
            Date.parse(typeOfValueBridgeIsTwo.name),
            "dd-MMM-yyyy"
          )
        : null,
      name: valueBridgeObj.name,
      isMultiValueBridge: this.isMultiValueBridge,
      valueBridgeId: valueBridgeId,
      comments: [
        { title: "", comment: "" },
        { title: "", comment: "" },
        { title: "", comment: "" },
        { title: "", comment: "" },
      ],
    };

    return newEntryForMasterVB;
  }

  updateValueBridgeModelAndAddUpOthersForHiddenImpact(filteredVBImpact, valueBridgeModel){
    const isIntermediateSumIndexExistsVBModel = valueBridgeModel.findIndex(vb => vb.isIntermediateSum)
    const isIntermediateSumIndexExistsfilteredVBModel = filteredVBImpact.findIndex(vb => vb.isIntermediateSum)
    
    let firstIndex = 0;
    let lastIndex = valueBridgeModel.length - 1

    let totalImpactBeforeIntermediateSum = 0;
    let totalImpactBeforeIsSum = 0;

    if(isIntermediateSumIndexExistsVBModel >= 0){
      lastIndex = isIntermediateSumIndexExistsVBModel;

      // this.updateStatusOfTheImpacts('Comparable Company Method', ["METRIC_IMPACT", "MULTIPLE_IMPACT"], valueBridgeModel, firstIndex, lastIndex)
      // this.updateStatusOfTheImpacts('Comparable Transaction Method', ["METRIC_IMPACT", "MULTIPLE_IMPACT"], valueBridgeModel, firstIndex, lastIndex)
      // this.updateStatusOfTheImpacts('Income Approach', ["PROJECTIONS_IMPACT", "TERMINAL_VALUE_IMPACT", "DISCOUNT_RATE_IMPACT"], valueBridgeModel, firstIndex, lastIndex)

      let impacts = valueBridgeModel.filter((vb, i) => vb.hide && i >= firstIndex && i <= lastIndex && vb.status != "IGNORE")
      impacts = impacts.map(vb => vb.y)

      if(impacts && impacts.length > 0){
        const totalImpacts = impacts.reduce((total, y) => total + y)        
        totalImpactBeforeIntermediateSum = totalImpacts
      }  

      firstIndex = isIntermediateSumIndexExistsVBModel + 1
      lastIndex = valueBridgeModel.length - 1
    }

    // this.updateStatusOfTheImpacts('Comparable Company Method', ["METRIC_IMPACT", "MULTIPLE_IMPACT"], valueBridgeModel, firstIndex, lastIndex)
    // this.updateStatusOfTheImpacts('Comparable Transaction Method', ["METRIC_IMPACT", "MULTIPLE_IMPACT"], valueBridgeModel, firstIndex, lastIndex)
    // this.updateStatusOfTheImpacts('Income Approach', ["PROJECTIONS_IMPACT", "TERMINAL_VALUE_IMPACT", "DISCOUNT_RATE_IMPACT"], valueBridgeModel, firstIndex, lastIndex)
  
    let impacts = valueBridgeModel.filter((vb, i) => vb.hide && i >= firstIndex && i <= lastIndex && vb.status != "IGNORE")
    impacts = impacts.map(vb => vb.y)

    if(impacts && impacts.length > 0){
      const totalImpacts = impacts.reduce((total, y) => total + y)
      totalImpactBeforeIsSum = totalImpacts;
    }  

    firstIndex = 0;
    lastIndex = filteredVBImpact.length - 1;

    if(isIntermediateSumIndexExistsfilteredVBModel >= 0 ){
      lastIndex = isIntermediateSumIndexExistsfilteredVBModel;
      const othersImpactIndexExists = filteredVBImpact.findIndex((vb, i) => i >= firstIndex && i <= lastIndex && vb.name == "Others");
      if(othersImpactIndexExists >= 0) {
        filteredVBImpact[othersImpactIndexExists].y += totalImpactBeforeIntermediateSum;
        filteredVBImpact[othersImpactIndexExists].formattedValue = this.utilService.getFormattedNumber(totalImpactBeforeIntermediateSum)
      }

      firstIndex = isIntermediateSumIndexExistsfilteredVBModel + 1
      lastIndex = filteredVBImpact.length - 1
    }

    const othersImpactIndexExists = filteredVBImpact.findIndex((vb, i) => i >= firstIndex && i <= lastIndex && vb.name == "Others");
    if(othersImpactIndexExists >= 0) {
      filteredVBImpact[othersImpactIndexExists].y += totalImpactBeforeIsSum;
      filteredVBImpact[othersImpactIndexExists].formattedValue = this.utilService.getFormattedNumber(totalImpactBeforeIsSum)
    }
  }

  drawValuationBridge(waterFallGraphShowLabels?) {
    let waterFallNumbers = [];

    let valueBridgeModel = cloneDeep(this.valueBridgeModel);
    valueBridgeModel = valueBridgeModel.filter(vb => vb.typeOfImpact != "INVESTMENT_IMPACT");
    let filteredVBImpact = this.updateStatusOfAdvancedImpactsinValueBridge(valueBridgeModel);

    let firstIndex = 1;
    filteredVBImpact.forEach((entry, index) => {
      if (entry.isIntermediateSum) {
        firstIndex++;
        waterFallNumbers.push({
          name: this.datePipe.transform(entry.name, "mediumDate"),
          isIntermediateSum: true,
          type : entry.type,
          color: colors.primaryColor,
        });
      }
      else if (entry.isSum) { 
        waterFallNumbers.push({
          name: this.datePipe.transform(entry.name, "mediumDate"),
          isSum: true,
          type : entry.type,
          color: colors.primaryColor,
        });
      } 
      else if (index == 0) {
        waterFallNumbers.push({
          name: this.datePipe.transform(entry.name, "mediumDate"),
          y: Number(entry.y),
          type : entry.type,
          color: colors.primaryColor,
        });
      } 
      else if (index < this.valueBridgeModel.length - 1) {
        waterFallNumbers.push({
          name:
            this.filteredForms.length >= 3
              ? firstIndex + ". " + entry.name
              : entry.name,
          type : entry.type,
          y: Number(entry.y),
        });
      }
    });

    let investmentImpacts = this.valueBridgeModel.filter(vb => vb.typeOfImpact == "INVESTMENT_IMPACT").reverse();

    if(investmentImpacts && investmentImpacts.length > 0){
      waterFallNumbers[0].isIntermediateSum = true

      investmentImpacts = investmentImpacts.map(ip => {
        return {
          name: ip.name,
          type: ip.type,
          y: ip.y
        }  
      })

      waterFallNumbers = investmentImpacts.concat(waterFallNumbers);    

      waterFallNumbers[0].color = colors.primaryColor;
      delete waterFallNumbers[4].y

      const indexExists = waterFallNumbers.map(vb => vb.isIntermediateSum ? true : false).lastIndexOf(true);
      if(indexExists >= 0) {
        delete waterFallNumbers[indexExists].isIntermediateSum;
        waterFallNumbers[indexExists].isSum = true;
      }
    }

    this.waterFallNumbers = cloneDeep(waterFallNumbers);

    // Create the chart object.
    const waterFallChartData = this.initwaterFallChart(
      waterFallGraphShowLabels
    );

    console.log(
      "value bridge chart data bf ------------",
      this.waterFallNumbers
    );

    const finalChartData = cloneDeep(this.waterFallNumbers).filter(
      (row, index) =>
        row.y !== 0 || index === 0 || row.isSum || row.isIntermediateSum
    );

    const finalChartDataWithTranslatedLabels =
      this.getTranslatedLabelsForVBItems(finalChartData);
    console.log(
      "value bridge chart data af ------------",
      finalChartDataWithTranslatedLabels
    );

    // add data to chart.
    waterFallChartData.addSeries(
      {
        upColor: "#5FAFF2",
        color: "#5FAFF2",
        data: cloneDeep(finalChartDataWithTranslatedLabels),
        pointPadding: 0,
      } as any,
      true,
      false
    );

    this.preparedValueBridge = true;

    return waterFallChartData;
  }

  updateStatusOfAdvancedImpactsinValueBridge(valueBridgeModel) {
    let fIndex = 0;
    let lIndex = this.valueBridgeModel.length - 1
    const isIntermediateSumIndexExistsVBModel = valueBridgeModel.findIndex(vb => vb.isIntermediateSum)
    if(isIntermediateSumIndexExistsVBModel >= 0){
      lIndex = isIntermediateSumIndexExistsVBModel;

      this.updateStatusOfTheImpacts('Comparable Company Method', ["METRIC_IMPACT", "MULTIPLE_IMPACT"], valueBridgeModel, fIndex, lIndex)
      this.updateStatusOfTheImpacts('Comparable Transaction Method', ["METRIC_IMPACT", "MULTIPLE_IMPACT"], valueBridgeModel, fIndex, lIndex)
      this.updateStatusOfTheImpacts('Income Approach', ["PROJECTIONS_IMPACT", "TERMINAL_VALUE_IMPACT", "DISCOUNT_RATE_IMPACT"], valueBridgeModel, fIndex, lIndex)

      fIndex = isIntermediateSumIndexExistsVBModel + 1
      lIndex = valueBridgeModel.length - 1
    }

    this.updateStatusOfTheImpacts('Comparable Company Method', ["METRIC_IMPACT", "MULTIPLE_IMPACT"], valueBridgeModel, fIndex, lIndex)
    this.updateStatusOfTheImpacts('Comparable Transaction Method', ["METRIC_IMPACT", "MULTIPLE_IMPACT"], valueBridgeModel, fIndex, lIndex)
    this.updateStatusOfTheImpacts('Income Approach', ["PROJECTIONS_IMPACT", "TERMINAL_VALUE_IMPACT", "DISCOUNT_RATE_IMPACT"], valueBridgeModel, fIndex, lIndex)

    valueBridgeModel = valueBridgeModel.filter(vb => vb.status != "IGNORE");
    let filteredVBImpact = valueBridgeModel.filter(vb => vb && !vb.hide);

    this.updateValueBridgeModelAndAddUpOthersForHiddenImpact(filteredVBImpact, valueBridgeModel)

    return filteredVBImpact;
  }

  updateStatusOfTheImpacts(algoName, impactsToBeChecked, valueBridgeModel, firstIndex, lastIndex) {
    const subImpactExistsForAlgo = valueBridgeModel.findIndex((vb, i) => i >= firstIndex && i <= lastIndex && vb.algoName == algoName 
    && impactsToBeChecked.indexOf(vb.typeOfImpact) >= 0 && !vb.hide)

    if(subImpactExistsForAlgo >= 0) {
      const valueImpactExists = valueBridgeModel.findIndex((vb, i) => i >= firstIndex && i <= lastIndex && vb.algoName == algoName 
      && vb.typeOfImpact == "VALUE_IMPACT")

      if(valueImpactExists >= 0){
        valueBridgeModel[valueImpactExists].status = "IGNORE"
      }
    }
    else {
      valueBridgeModel.forEach((vb, i) => {
        if(i >= firstIndex && i <= lastIndex && vb.algoName == algoName && impactsToBeChecked.indexOf(vb.typeOfImpact) >= 0) {
          vb.status = "IGNORE"
        }
      })
    }
  }

  getTranslatedLabelsForVBItems(finalChartData) {
    const finalChartDataWithTranslatedLabels = [];

    finalChartData.forEach((data) => {
      const updatedData = cloneDeep(data);

      updatedData.name = this.getTranslatedLabelForEachVBItem(data.name, data.type);
      finalChartDataWithTranslatedLabels.push(updatedData);
    });

    return finalChartDataWithTranslatedLabels;
  }

  getTranslationForAlgorithmsInAdvancedValueBridgeImpact(name : string) {
    try {
      let parts = name.split("-");
      let algoName = parts[0].split(".")[1].trim();

      let key = this.utilService.getTranslationAlgoName(algoName);

      let label = this.translateService.getLabel(key);

      label = name.replace(algoName, label);

      return label
    } catch (error) {
      console.log("Failed to translate the label for Advanced Impact : " + name, error);
    }

    return name;
  }

  getTranslatedLabelForEachVBItem(name, type) {
    if(type == "ADVANCED_IMPACT") {
      return this.getTranslationForAlgorithmsInAdvancedValueBridgeImpact(name);
    }
    
    const parts = name.split(". ");

    switch (parts[1]) {
      case "Net Debt":
        return parts[0] + " " + this.translateService.getLabel("net_debt");

      case "Debt & Others":
        return parts[0] + " " + this.translateService.getLabel("debt_others");

      case "FX":
        return parts[0] + " " + this.translateService.getLabel("fx");

      case "Realised Proceeds":
        return (
          parts[0] + " " + this.translateService.getLabel("realised_proceeds")
        );

      case "Stake":
        return parts[0] + " " + this.translateService.getLabel("stake");

      case "Others":
        return parts[0] + " " + this.translateService.getLabel("others");

      case "EBITDA":
        return parts[0] + " " + this.translateService.getLabel("ebitda");

      case "Revenue":
        return parts[0] + " " + this.translateService.getLabel("revenue");

      case "Gross Profit":
        return parts[0] + " " + this.translateService.getLabel("gross_profit");

      case "Multiple":
        return parts[0] + " " + this.translateService.getLabel("multiple");

      case "Net Debt and Other Balance Sheet Adjustments":
        return parts[0] + " " + this.translateService.getLabel("netDebt_and_other_balanceSheet_adjustments");

      case "Adjustment to Equity Value":
        return parts[0] + " " + this.translateService.getLabel("adjustment_to_equity_value");

      case "Others":
        return parts[0] + " " + this.translateService.getLabel("others");
        
      case "Waterfall":
        return parts[0] + " " + this.translateService.getLabel("waterfall");

      case "Adjustment to Stake Equity Value":
        return parts[0] + " " + this.translateService.getLabel("adjustment_to_stake_equity_value");

      case "Shareholder's Equity":
        return (
          parts[0] +
          " " +
          this.translateService.getLabel("shareholder's_equity")
        );

      case "Earnings (Net Income)":
        return parts[0] + " " + this.translateService.getLabel("net_income");

      case "EBIT":
        return parts[0] + " " + this.translateService.getLabel("ebit");

      case "EBITDA-Capex":
        return parts[0] + " " + this.translateService.getLabel("ebitda-capex");

      default:
        return name;
    }
  }

  getTranslatedLabelsByActualLabel(name) {
    switch (name) {
      // case "Net Debt" :
      //   return this.translateService.getLabel("net_debt");

      // case "Debt & Others" :
      //   return this.translateService.getLabel("debt_others");

      case "FX":
        return this.translateService.getLabel("fx");

      case "Realised Proceeds":
        return this.translateService.getLabel("realised_proceeds");

      case "Stake":
        return this.translateService.getLabel("stake");

      case "Others":
        return this.translateService.getLabel("others");

      case "EBITDA":
        return this.translateService.getLabel("ebitda");

      case "Revenue":
        return this.translateService.getLabel("revenue");

      case "Gross Profit":
        return this.translateService.getLabel("gross_profit");

      case "Shareholder's Equity":
        return this.translateService.getLabel("shareholder's_equity");

      case "Earnings (Net Income)":
        return this.translateService.getLabel("net_income");

      case "EBIT":
        return this.translateService.getLabel("ebit");

      case "EBITDA-Capex":
        return this.translateService.getLabel("ebitda-capex");

      default:
        return name;
    }
  }

  initwaterFallChart(waterFallGraphShowLabels?) {
    const waterFallChartData = new Chart({
      chart: {
        type: "waterfall",
        // backgroundColor: "#e6e6e6",
        // events: {
        //   load: function() {
        //     this.renderer.text('Under Construction', 300, 100)
        //       .css({
        //           color: '#5FAFF2',
        //           fontSize: '20px'
        //       })
        //       .add()
        //   }
        // }
      },
      title: { text: "" },
      credits: { enabled: false },
      exporting: { enabled: false },
      legend: { enabled: false },

      xAxis: {
        type: "category",
        gridLineWidth: 1,
        gridLineColor: "#BEBEBE",
      },

      yAxis: {
        title: { text: "" },
        gridLineWidth: 1,
        gridLineColor: "#BEBEBE",
      },

      plotOptions: {
        series: {
          stacking: "normal",
        },
        waterfall: {
          negativeColor: colors.secondaryColor,

          dataLabels: {
            enabled:
              waterFallGraphShowLabels && waterFallGraphShowLabels == true
                ? true
                : false,
            formatter: function () {
              return Highcharts.numberFormat(this.y, 0, ",") + " Mn";
            },
            verticalAlign: "top",
            x: 3,
            y: -30,
            color: "black",

            style: { textOutline: "none" },
          },
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b> : " + this.y.toLocaleString();
        },
      },
      series: [
        {
          upColor: "#5FAFF2",
          color: "#5FAFF2",
          data: [],
          pointPadding: 0,
        } as any,
      ],
    });

    // console.log("waterfall", this.waterFallNumbers, this.waterFallChartData)

    return waterFallChartData;
  }

  initValueBridgeDatesDefault() {
    if (this.filteredForms.length >= 3) {
      this.startDateForm = this.filteredForms[0];
      this.startDateValuationBridge = this.filteredForms[0].valuationDate;

      this.intermediateDateForm =
        this.filteredForms[this.filteredForms.length - 2];
      this.intermediateDateValuationBridge =
        this.filteredForms[this.filteredForms.length - 2].valuationDate;

      this.endDateForm = this.filteredForms[this.filteredForms.length - 1];
      this.endDateValuationBridge =
        this.filteredForms[this.filteredForms.length - 1].valuationDate;

      this.isMultiValueBridge = true;
    } else if (this.filteredForms.length == 2) {
      this.startDateForm = this.filteredForms[0];
      this.startDateValuationBridge = this.filteredForms[0].valuationDate;

      this.endDateForm = this.filteredForms[this.filteredForms.length - 1];
      this.endDateValuationBridge =
        this.filteredForms[this.filteredForms.length - 1].valuationDate;

      this.isMultiValueBridge = false;
    } else {
      // this.preparedValueBridge = true;
      return;
    }
  }

  prepareAdvancedNAVBridgeModel(response, valueBridgeModel){
    valueBridgeModel.splice(1, 2);
    const advanceImpacts = response.advancedImpacts;

    const investmentImpact = advanceImpacts.investmentImpact;

    let othersImpactValue = 0;

    const othersImpactIndex = valueBridgeModel.findIndex(imp => imp && imp.name == "Others");

    if(othersImpactIndex >= 0){
      othersImpactValue = this.utilService.getValidNumber(othersImpactIndex.y)
    }

    this.uniqueListOfAlgorithmsForValueBridge.forEach(algo => {
      const algoWiseImpacts = advanceImpacts.algorithmImpacts[algo.name]
      
      const weightImpact = {
        "name": algo.name  + " - Weight Impact",
        "isIntermediate": false,
        "y": algoWiseImpacts && algoWiseImpacts["weightImpact"]? this.utilService.getValidNumber(algoWiseImpacts["weightImpact"]["impactValue"]) : 0,
        "formattedValue": algoWiseImpacts && algoWiseImpacts["weightImpact"]? this.utilService.getFormattedNumber(algoWiseImpacts["weightImpact"]["impactValue"]) : 0,
        "type": "ADVANCED_IMPACT",
        "algoName": algo.name,
        "typeOfImpact": "WEIGHT_IMPACT",
        "hide": this.getHideStatusOfAlgorithmImpacts(algo.name, "WEIGHT_IMPACT"),
        "status": "CONSIDER"
      }

      valueBridgeModel.splice(1, 0, weightImpact);

      const valueImpact = {
        "name": algo.name  + " - Value Impact",
        "isIntermediate": false,
        "y": algoWiseImpacts && algoWiseImpacts["valueImpact"]? this.utilService.getValidNumber(algoWiseImpacts["valueImpact"]["impactValue"]) : 0,
        "formattedValue": algoWiseImpacts && algoWiseImpacts["valueImpact"]? this.utilService.getFormattedNumber(algoWiseImpacts["valueImpact"]["impactValue"]) : 0,
        "type": "ADVANCED_IMPACT",
        "algoName": algo.name,
        "typeOfImpact": "VALUE_IMPACT",
        "hide": this.getHideStatusOfAlgorithmImpacts(algo.name, "VALUE_IMPACT"),
        "status": "CONSIDER"
      }

      valueBridgeModel.splice(1, 0, valueImpact);

      if(algoWiseImpacts) {
        if(algoWiseImpacts["metricImpact"] != undefined){
          const metricImpact = {
            "name": algo.name + " - Metric Impact",
            "isIntermediate": false,
            "y": algoWiseImpacts && algoWiseImpacts["metricImpact"]? this.utilService.getValidNumber(algoWiseImpacts["metricImpact"]["impactValue"]) : 0,
            "formattedValue": algoWiseImpacts && algoWiseImpacts["metricImpact"]? this.utilService.getFormattedNumber(algoWiseImpacts["metricImpact"]["impactValue"]) : 0,
            "type": "ADVANCED_IMPACT",
            "algoName": algo.name,
            "typeOfImpact": "METRIC_IMPACT",
            "hide": this.getHideStatusOfAlgorithmImpacts(algo.name, "METRIC_IMPACT"),
            "status": "CONSIDER"
          }
  
          valueBridgeModel.splice(1, 0, metricImpact);
        }
        
        if(algoWiseImpacts["multipleImpact"] != undefined){
          const multipleImpact = {
            "name": algo.name  + " - Multiple Impact",
            "isIntermediate": false,
            "y": algoWiseImpacts && algoWiseImpacts["multipleImpact"]? this.utilService.getValidNumber(algoWiseImpacts["multipleImpact"]["impactValue"]) : 0,
            "formattedValue": algoWiseImpacts && algoWiseImpacts["multipleImpact"]? this.utilService.getFormattedNumber(algoWiseImpacts["multipleImpact"]["impactValue"]) : 0,
            "type": "ADVANCED_IMPACT",
            "algoName": algo.name,
            "typeOfImpact": "MULTIPLE_IMPACT",
            "hide": this.getHideStatusOfAlgorithmImpacts(algo.name, "MULTIPLE_IMPACT"),
            "status": "CONSIDER"
          }
  
          valueBridgeModel.splice(1, 0, multipleImpact);
        }

        if(algoWiseImpacts["projectionsImpact"] != undefined){
          const projectionsImpact = {
            "name": algo.name  + " - Projections Impact",
            "isIntermediate": false,
            "y": algoWiseImpacts && algoWiseImpacts["projectionsImpact"]? this.utilService.getValidNumber(algoWiseImpacts["projectionsImpact"]["impactValue"]) : 0,
            "formattedValue": algoWiseImpacts && algoWiseImpacts["projectionsImpact"]? this.utilService.getFormattedNumber(algoWiseImpacts["projectionsImpact"]["impactValue"]) : 0,
            "type": "ADVANCED_IMPACT",
            "algoName": algo.name,
            "typeOfImpact": "PROJECTIONS_IMPACT",
            "hide": this.getHideStatusOfAlgorithmImpacts(algo.name, "PROJECTIONS_IMPACT"),
            "status": "CONSIDER"
          }
  
          valueBridgeModel.splice(1, 0, projectionsImpact);
        }
        
        if(algoWiseImpacts["terminalValueImpact"] != undefined){
          const terminalValueImpact = {
            "name": algo.name  + " - Terminal Impact",
            "isIntermediate": false,
            "y": algoWiseImpacts && algoWiseImpacts["terminalValueImpact"]? this.utilService.getValidNumber(algoWiseImpacts["terminalValueImpact"]["impactValue"]) : 0,
            "formattedValue": algoWiseImpacts && algoWiseImpacts["terminalValueImpact"]? this.utilService.getFormattedNumber(algoWiseImpacts["terminalValueImpact"]["impactValue"]) : 0,
            "type": "ADVANCED_IMPACT",
            "algoName": algo.name,
            "typeOfImpact": "TERMINAL_VALUE_IMPACT",
            "hide": this.getHideStatusOfAlgorithmImpacts(algo.name, "TERMINAL_VALUE_IMPACT"),
            "status": "CONSIDER"
          }
  
          valueBridgeModel.splice(1, 0, terminalValueImpact);
        }

        if(algoWiseImpacts["discountRateImpact"] != undefined){
          const discountRateImpact = {
            "name": algo.name  + " - Discount Impact",
            "isIntermediate": false,
            "y": algoWiseImpacts && algoWiseImpacts["discountRateImpact"]? this.utilService.getValidNumber(algoWiseImpacts["discountRateImpact"]["impactValue"]) : 0,
            "formattedValue": algoWiseImpacts && algoWiseImpacts["discountRateImpact"]? this.utilService.getFormattedNumber(algoWiseImpacts["discountRateImpact"]["impactValue"]) : 0,
            "type": "ADVANCED_IMPACT",
            "algoName": algo.name,
            "typeOfImpact": "DISCOUNT_RATE_IMPACT",
            "hide": this.getHideStatusOfAlgorithmImpacts(algo.name, "DISCOUNT_RATE_IMPACT"),
            "status": "CONSIDER"
          }
  
          valueBridgeModel.splice(1, 0, discountRateImpact);
        }
      }
    })

    if(investmentImpact){
      const initialInvestmentImpact = {
        "name": this.datePipe.transform(investmentImpact.initialInvestmentDate, "mediumDate"),
        "isIntermediate": false,
        "y": investmentImpact && investmentImpact["totalAmountOnInitialInvestmentDate"]? this.utilService.getValidNumber(investmentImpact["totalAmountOnInitialInvestmentDate"]) : 0,
        "formattedValue": investmentImpact && investmentImpact["totalAmountOnInitialInvestmentDate"]? this.utilService.getFormattedNumber(investmentImpact["totalAmountOnInitialInvestmentDate"]) : 0,
        "type": "ADVANCED_IMPACT",
        "algoName": "Investment Impact",
        "typeOfImpact": "INVESTMENT_IMPACT",
        "hide": false,
        "status": "CONSIDER"
      }

      valueBridgeModel.splice(1, 0, initialInvestmentImpact);

      const additionalInvestmentImpact = {
        "name": "Additional Investment",
        "isIntermediate": false,
        "y": investmentImpact && investmentImpact["totalAmountOfAdditionalInvestment"]? this.utilService.getValidNumber(investmentImpact["totalAmountOfAdditionalInvestment"]) : 0,
        "formattedValue": investmentImpact && investmentImpact["totalAmountOfAdditionalInvestment"]? this.utilService.getFormattedNumber(investmentImpact["totalAmountOfAdditionalInvestment"]) : 0,
        "type": "ADVANCED_IMPACT",
        "algoName": "Investment Impact",
        "typeOfImpact": "INVESTMENT_IMPACT",
        "hide": false,
        "status": "CONSIDER"
      }

      valueBridgeModel.splice(1, 0, additionalInvestmentImpact);

      const realisedProceedsImpact = {
        "name": "Realised Proceeds Investment",
        "isIntermediate": false,
        "y": investmentImpact && investmentImpact["totalAmountOnRealisedProceeds"]? this.utilService.getValidNumber(investmentImpact["totalAmountOnRealisedProceeds"]) * -1 : 0,
        "formattedValue": investmentImpact && investmentImpact["totalAmountOnRealisedProceeds"]? this.utilService.getFormattedNumber(investmentImpact["totalAmountOnRealisedProceeds"]) * -1 : 0,
        "type": "ADVANCED_IMPACT",
        "algoName": "Investment Impact",
        "typeOfImpact": "INVESTMENT_IMPACT",
        "hide": false,
        "status": "CONSIDER"
      }

      valueBridgeModel.splice(1, 0, realisedProceedsImpact);

      const fairValueOfExistingInvestment = {
        "name": "Fair Value of Existing Investment",
        "isIntermediate": false,
        "y": investmentImpact && investmentImpact["fairValueOfExistingInvestment"]? this.utilService.getValidNumber(investmentImpact["fairValueOfExistingInvestment"]) : 0,
        "formattedValue": investmentImpact && investmentImpact["fairValueOfExistingInvestment"]? this.utilService.getFormattedNumber(investmentImpact["fairValueOfExistingInvestment"]) : 0,
        "type": "ADVANCED_IMPACT",
        "algoName": "Investment Impact",
        "typeOfImpact": "INVESTMENT_IMPACT",
        "hide": false,
        "status": "CONSIDER"
      }

      valueBridgeModel.splice(1, 0, fairValueOfExistingInvestment);


    }

    return valueBridgeModel;
  }

  // To return Hide Status of the Impact
  getHideStatusOfAlgorithmImpacts(algoName, impactKey){
    let showStatus = true;

    const valuationAlgorithmExists = this.algorithmFiltersForNAVBridge[0].children.find(val => val.key == algoName);
    if(valuationAlgorithmExists) {
      if(valuationAlgorithmExists.selected && valuationAlgorithmExists.children) {
        if(impactKey == "WEIGHT_IMPACT" || impactKey == "VALUE_IMPACT"){
          const impactExists = valuationAlgorithmExists.children.find(val => val.key == impactKey);
          if(impactExists){
            showStatus = impactExists.selected ? true : false;
          }
        }
        else if(valuationAlgorithmExists.children && valuationAlgorithmExists.children[1] && valuationAlgorithmExists.children[1].children) {
          const impactExists = valuationAlgorithmExists.children[1].children.find(val => val.key == impactKey);
          if(impactExists){
            showStatus = impactExists.selected ? true : false;
          }
        }
      }
      else {
        showStatus = false;
      }
    }

    return !showStatus;
  }

  // hideShowImpactsOnAdvancedValueBridge(algoName, impactKey, othersImpactValue, valueBridgeModel) {
  //   const valuationAlgorithmSelected = this.algorithmFiltersForNAVBridge[0].children.find(val => val.key == algoName && val.selected);
  //   if(valuationAlgorithmSelected && valuationAlgorithmSelected.children){
      
  //     // Impact One, if not Selected, adding it to Others
  //     const impactOneSelected = valuationAlgorithmSelected.children.find(val => val.key == impactOneKey && val.selected);
  //     if(impactOneSelected){
  //       valueBridgeModel.splice(1, 0, impactOne);
  //     }
  //     else {
  //       othersImpactValue += impactOne.y;
  //     }
  //   }
  //   else {
  //     othersImpactValue += impactOne.y;
  //   }
      
  //   return othersImpactValue;
  // }

  prepareUpdatedNAVBridgeOnApplyingFilters(index){
    this.refreshWaterfallGraph(index)
  }

  initValueBridgeChartAndDraw(apiResponse) {
    if (!apiResponse || !(apiResponse.length > 0)) {
      this.prepareDefaultValueBridge();
      return;
    }

    this.valueBridgeModel = this.getDefaultValueBridge();

    let valueBridgeModelForAllForms = [];

    let typeOfValueBridge;
    if (apiResponse.length == 1) {
      typeOfValueBridge = 1;
    } else if (apiResponse.length > 1) {
      typeOfValueBridge = 2;
    }

    apiResponse.forEach((data, rowIndex) => {
      const res =
        this.localCurrency == true
          ? data.valueBridgeDataInLocalCurrency
          : data.valueBridgeData;
      const tempModel = cloneDeep(this.valueBridgeModel);
      tempModel.forEach((obj: any, index) => {
        if (index == 0) {
          obj.y = res.startingValue ? res.startingValue : 0;
          if (rowIndex == 0) {
            obj.name = this.datePipe.transform(
              this.startDateForm["valuationDate"],
              "mediumDate"
            );
          } else {
            delete tempModel[0];
          }
        } else if (index == 1) {
          obj.name = this.getValueBridgeMetricLabelNonTranslated(res.key);
          obj.y = res.multipleValueImpact
            ? this.utilService.zeroInForOneDecimalFormat(
                res.multipleValueImpact
              )
            : 0;
        } else if (index == 2) {
          obj.y = res.multipleImpact
            ? this.utilService.zeroInForOneDecimalFormat(res.multipleImpact)
            : 0;
        } else if (index == 3) {
          obj.y = res.realisedProceedsImpact
            ? this.utilService.zeroInForOneDecimalFormat(
                res.realisedProceedsImpact
              )
            : 0;
        } else if (index == 4) {
          obj.y = res.netDebtImpact
            ? this.utilService.zeroInForOneDecimalFormat(res.netDebtImpact)
            : 0;
        } else if (index == 5) {
          obj.y = res.adjustmentsToEquityValueImpact
            ? this.utilService.zeroInForOneDecimalFormat(
                res.adjustmentsToEquityValueImpact
              )
            : 0;
        } else if (index == 6) {
          obj.y = res.othersDebtImpact
            ? this.utilService.zeroInForOneDecimalFormat(res.othersDebtImpact)
            : 0;
        } else if (index == 7) {
          obj.y = res.stakeImpact
            ? this.utilService.zeroInForOneDecimalFormat(res.stakeImpact)
            : 0;
        } else if (index == 8) {
          obj.y = res.fxImpact
            ? this.utilService.zeroInForOneDecimalFormat(res.fxImpact)
            : 0;
        } else if (index == 9) {
          obj.y = res.waterfallImpact
            ? this.utilService.zeroInForOneDecimalFormat(res.waterfallImpact)
            : 0;
        } else if (index == 10) {
          obj.y = res.othersImpact
            ? this.utilService.zeroInForOneDecimalFormat(res.othersImpact)
            : 0;
        } else if (index == 11) {
          obj.y = res.endingValue ? res.endingValue : 0;
          if (typeOfValueBridge == 1) {
            obj.name = this.datePipe.transform(
              this.endDateForm["valuationDate"],
              "mediumDate"
            );
          } else {
            if (rowIndex == 0) {
              obj.name = this.datePipe.transform(
                this.intermediateDateForm["valuationDate"],
                "mediumDate"
              );
            } else {
              obj.name = this.datePipe.transform(
                this.endDateForm["valuationDate"],
                "mediumDate"
              );
            }
          }
          if (rowIndex < apiResponse.length - 1) {
            obj.isIntermediateSum = true;
          } else {
            obj.isIntermediateSum = false;
            obj.isSum = true;
          }
        }

        obj["formattedValue"] = this.utilService.getFormattedNumber(obj.y);
      });

      if (res.externalGrowth) {
        const externalGrowth = {
          formattedValue: this.utilService.getFormattedNumber(
            res.externalGrowth
          ),
          isIntermediateSum: false,
          name: "External Growth",
          y: res.externalGrowth,
        };

        tempModel.splice(1, 0, externalGrowth);
      }

      if(this.valueBridgeLabel == "ADVANCED_NAV_BRIDGE"){
        this.prepareAdvancedNAVBridgeModel(res, tempModel)
      }

      valueBridgeModelForAllForms = valueBridgeModelForAllForms.concat(
        cloneDeep(tempModel)
      );
    });

    this.valueBridgeModel = valueBridgeModelForAllForms.filter((v) => v);
    // this.waterFallNumbers = this.valueBridgeModel;
    this.preparedValueBridge = true;

    // console.log("Value Bridge Model", this.valueBridgeModel);
  }

  getMultipleLabel(key) {
    if (key === "pbv" || key === "priceBookValue") {
      let data = this.translateService
        .getLabel("price_of_book_value")
        .concat(" " + this.translateService.getLabel("multiple"));
      return data;
    } else if (key != "pbv" || key != "priceBookValue") {
      return this.getValueBridgeMetricLabel(key).concat(
        " " + this.translateService.getLabel("multiple")
      );
    } else {
      return key;
    }
  }

  getValueBridgeMetricLabel(key) {
    if (key === "bevEbitda" || key === "ebitda") {
      return this.translateService.getLabel("ebitda");
    } else if (key === "bevRevenue" || key === "revenue") {
      return this.translateService.getLabel("revenue");
    } else if (key === "evGrossProfit" || key === "grossProfit") {
      return this.translateService.getLabel("gross_profit");
    } else if (key === "pbv" || key === "priceBookValue") {
      return this.translateService.getLabel("book_value");
    } else if (key === "psales" || key === "ps") {
      return this.translateService.getLabel("revenue");
    } else if (key === "pe" || key === "priceEarnings") {
      return this.translateService.getLabel("net_income");
    } else if (key === "bevEbit" || key === "ebit") {
      return this.translateService.getLabel("ebit");
    } else if (key === "bevEbitda_Capex" || key === "ebitda_capex") {
      return this.translateService.getLabel("ebitda-capex");
    } else {
      return key;
    }
  }

  // Do not Translate the labels here as the data getting saved
  getValueBridgeMetricLabelNonTranslated(key) {
    if (key === "bevEbitda" || key === "ebitda") {
      return "EBITDA";
    } else if (key === "bevRevenue" || key === "revenue") {
      return "Revenue";
    } else if (key === "evGrossProfit" || key === "grossProfit") {
      return "Gross Profit";
    } else if (key === "pbv" || key === "priceBookValue") {
      return "Shareholder's Equity";
    } else if (key === "psales" || key === "ps") {
      return "Revenue";
    } else if (key === "pe" || key === "priceEarnings") {
      return "Earnings (Net Income)";
    } else if (key === "bevEbit" || key === "ebit") {
      return "EBIT";
    } else if (key === "bevEbitda_Capex" || key === "ebitda_capex") {
      return "EBITDA-Capex";
    } else {
      return key;
    }
  }

  getDefaultValueBridge() {
    let bridgeModel = [
      {
        name: this.datePipe.transform(
          this.startDateForm["valuationDate"],
          "mediumDate"
        ),
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: "Metric",
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: "Multiple",
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: "Realised Proceeds",
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: "Net Debt and Other Balance Sheet Adjustments",
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: "Adjustment to Equity Value",
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: "Adjustment to Stake Equity Value",
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: "Stake",
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: "FX",
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: "Waterfall",
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: "Others",
        y: 0,
        isIntermediateSum: false,
      },
      {
        name: this.datePipe.transform(
          this.endDateForm["valuationDate"],
          "mediumDate"
        ),
        y: 0,
        isIntermediateSum: false,
      },
    ];

    return bridgeModel
  }

  prepareDefaultValueBridge() {
    let endValue = this.endDateForm["investment"]
      ? this.utilService.getValidNumber(
          this.endDateForm["investment"].equityValue.finalAdjustedEquityVal
        )
      : 0;

    let intermediateSum = 0;

    this.valueBridgeModel.forEach((obj, index) => {
      if (index == 0) {
        obj.y = this.startDateForm["investment"]
          ? this.startDateForm["investment"].equityValue.finalAdjustedEquityVal
          : 0;
        obj["formattedValue"] = obj.y;
        obj["isIntermediateSum"] = false;

        intermediateSum += obj.y;
      } else if (index == this.valueBridgeModel.length - 1) {
        obj.y = endValue;
        obj["formattedValue"] = obj.y;
        obj["isIntermediateSum"] = false;
      } else if (index == this.valueBridgeModel.length - 2) {
        obj.y = endValue - intermediateSum;
        obj["formattedValue"] = obj.y;
        obj["isIntermediateSum"] = false;
      } else {
        obj.y = 0;
        obj["formattedValue"] = 0;
        obj["isIntermediateSum"] = false;

        intermediateSum += obj.y;
      }
    });

    // this.waterFallNumbers = cloneDeep(this.valueBridgeModel);
  }

  initValueBridgeDates() {
    try {
      const date1 = new Date(this.startDateValuationBridge);
      this.startDateForm = this.selectedValuationDates.find((comp) => {
        const valDate = new Date(comp.valuationDate);
        return (
          valDate.getFullYear() === date1.getFullYear() &&
          valDate.getDate() === date1.getDate() &&
          valDate.getMonth() === date1.getMonth()
        );
      });

      const date2 = new Date(this.endDateValuationBridge);
      this.endDateForm = this.selectedValuationDates.find((comp) => {
        const valDate = new Date(comp.valuationDate);
        return (
          valDate.getFullYear() === date2.getFullYear() &&
          valDate.getDate() === date2.getDate() &&
          valDate.getMonth() === date2.getMonth()
        );
      });

      if (this.intermediateDateValuationBridge) {
        const date3 = new Date(this.intermediateDateValuationBridge);
        this.intermediateDateForm = this.selectedValuationDates.find((comp) => {
          const valDate = new Date(comp.valuationDate);
          return (
            valDate.getFullYear() === date3.getFullYear() &&
            valDate.getDate() === date3.getDate() &&
            valDate.getMonth() === date3.getMonth()
          );
        });
      }
    } catch (e) {
      this.startDateForm = this.filteredForms[0];
      this.endDateForm = this.filteredForms[this.filteredForms.length - 1];
    }
  }

  getValueBridgeAPIReqBody() {
    const valuationDates = [this.startDateForm];

    if (this.intermediateDateForm && this.intermediateDateForm["id"]) {
      valuationDates.push(this.intermediateDateForm);
    }

    valuationDates.push(this.endDateForm);

    return this.prepareValuaBridgeAPIReqFromInputs(valuationDates);
  }

  prepareValuaBridgeAPIReqFromInputs(valuationDates) {
    const dates = [];

    valuationDates.forEach((vd) => {
      dates.push({
        formId: vd["id"],
        version: vd["formVersion"],
        type: vd["formType"],
      });
    });

    let fy = this.getFiscalYearKeyByPeriod(this.settings.period);

    const reqBody = {
      forms: dates,
      customRequirements: {
        destinationCurrency: this.valueBridgeCurrency,
        metricImpactToBeConsidered: this.settings.metric,
        period: fy,
        valueBridgeType: this.settings.valueBridge,
      },
    };
    return reqBody;
  }

  showValuationBridgeDataModal(content, valueBridge, rowIndex) {
    if (this.selectedValuationDates.length === 1) {
      return;
    }

    this.selectedValueBridgeIndex = rowIndex;

    this.startDateValuationBridge = valueBridge.startDateValuationBridge
      ? valueBridge.startDateValuationBridge
      : "";
    this.endDateValuationBridge = valueBridge.endDateValuationBridge
      ? valueBridge.endDateValuationBridge
      : "";
    this.intermediateDateValuationBridge =
      valueBridge.intermediateDateValuationBridge
        ? valueBridge.intermediateDateValuationBridge
        : "";

    this.valueBridgeName =
      this.multipleValueBridgeData[this.selectedValueBridgeIndex].name;
    this.isMultiValueBridge =
      this.multipleValueBridgeData[
        this.selectedValueBridgeIndex
      ].isMultiValueBridge;

    this.initValueBridgeDates();

    valueBridge.startDateForm = this.startDateForm ? this.startDateForm : null;
    valueBridge.endDateForm = this.endDateForm ? this.endDateForm : null;
    valueBridge.intermediateDateForm = this.intermediateDateForm
      ? this.intermediateDateForm
      : null;

    this.valueBridgeModel = cloneDeep(valueBridge.valueBridgeModel);

    this.valueBridgeModel.forEach((row) => {
      if (row.y) {
        row["formattedValue"] = this.utilService.getFormattedNumber(row.y);
      }
    });

    this.modalService.open(content, {
      centered: true,
      size: "lg",
      backdrop: "static",
    });
  }

  fetchMarketEvolutionChartOnBUChange() {
    this.areaChartLoader = true;
    const reqBodyForMarketEvolutionChart =
      this.getReqBodyForMarketEvolutionChart(this.buMarketMultiple);
    this.marketMultiple = this.settings.metric
      ? this.settings.metric
      : this.marketMultiple;

    //By Default, calling the API to update
    if (
      false &&
      this.multipleEvolutionFormWiseData[
        reqBodyForMarketEvolutionChart.forms[0].id
      ]
    ) {
      this.getFormattedDataForChart(
        this.multipleEvolutionFormWiseData[
          reqBodyForMarketEvolutionChart.forms[0].id
        ]
      );
      this.createMarketEvolutionChart(this.marketMultiple);
      this.areaChartLoader = false;
    } else {
      this.ds
        .getMultipleEvolutionChartData(reqBodyForMarketEvolutionChart)
        .subscribe(
          (res) => {
            this.multipleEvolutionFormWiseData[
              reqBodyForMarketEvolutionChart.forms[0].id
            ] = res.body["response"].marketEvolutionChartData;
            this.getFormattedDataForChart(
              this.multipleEvolutionFormWiseData[
                reqBodyForMarketEvolutionChart.forms[0].id
              ]
            );
            this.createMarketEvolutionChart(this.marketMultiple);
            this.areaChartLoader = false;
          },
          (error) => {
            this.areaChartLoader = false;
            console.log("ERROR", error);
          }
        );
    }
  }

  updateMarketEvolutionChartVariations(chartData) {
    const impliedMultiples = chartData.subjectComp;

    const vsLastestValuation =
      impliedMultiples[impliedMultiples.length - 1] /
        impliedMultiples[impliedMultiples.length - 2] -
      1;
    const vsInvestmentValuation =
      impliedMultiples[impliedMultiples.length - 1] / impliedMultiples[0] - 1;

    let vsQ4QuarterPrevToLastestValuation = 0;

    const valDates = chartData.xAxis;
    const latestValDateYear: number = new Date(
      valDates[valDates.length - 1]
    ).getFullYear();
    const Q4QuarterPrevToLatestValDateYear: number = latestValDateYear - 1;

    // Finding the last quarter for the previous year to latest valuation date year
    const q4IndexFound = valDates.findIndex((date) => {
      const d = new Date(date);
      return (
        d.getFullYear() == Q4QuarterPrevToLatestValDateYear &&
        d.getMonth() == 11
      );
    });

    if (q4IndexFound >= 0) {
      vsQ4QuarterPrevToLastestValuation =
        impliedMultiples[impliedMultiples.length - 1] /
          impliedMultiples[q4IndexFound] -
        1;
    }

    return {
      vsLastestValuation: vsLastestValuation,
      vsQ4QuarterPrevToLastestValuation: vsQ4QuarterPrevToLastestValuation,
      vsInvestmentValuation: vsInvestmentValuation,
    };
  }

  // On Multiple Change
  createMarketEvolutionChart(userSelected) {
    // console.log("Plotting area chart", userSelected);
    let latestValDate = this.selectedValuationDates[0].valuationDate; //Latest Val Date
    latestValDate = new Date(latestValDate);
    const currentDate = new Date();

    // Disabling Show Button wehn Latest Val Date is beyond the current date
    //since market data cannot be fetched (Tristan's Requirement)
    this.isLatestValDateGreaterThanCurrentDate =
      this.utilService.compareDates(latestValDate, currentDate) >= 0
        ? false
        : true;

    let subjectCompanyName =
      this.selectedValuationDates[this.selectedValuationDates.length - 1]
        .companyNameInForm;

    if (
      this.selectedValuationDates[this.selectedValuationDates.length - 1]
        .businessUnitsNumber > 0
    ) {
      subjectCompanyName = this.selectedValuationDates[
        this.selectedValuationDates.length - 1
      ].businessUnits.find(
        (bu) => bu.businessUnitName === this.buMarketMultiple
      ).companyNameInForm;
    }
    const chartData = this.prepareDataForEvolutionChart();
    const xAxis = chartData.xAxis;
    const median = chartData.median;
    const average = chartData.average;
    const subjectComp = chartData.subjectComp;

    const thirdQuartile = chartData.thirdQuartile;
    const firstQuartile = chartData.firstQuartile;

    const separatorPosition = this.marketDataForAdditionalDate?.aggregations?.[
      userSelected
    ]
      ? xAxis.length - 2
      : undefined;

    //Reading charData for Drill Down on KPIs
    this.impliedMultipleForDrillDown = chartData;

    this.marketChartDataVariations = this.updateMarketEvolutionChartVariations(chartData)

    let grapHPlacement = "on";

    if (xAxis.length <= 1) {
      grapHPlacement = "between";
    }

    // Below code makes sure that legend icon color is picked from chart options
    // This will ensure "1st qurtile" legend has same color as "3rd quartile" legend
    (function (H) {
      H.wrap(
        H.Legend.prototype,
        "colorizeItem",
        function (proceed, item, visible) {
          item.legendColor = item.options.legendColor;
          proceed.apply(this, Array.prototype.slice.call(arguments, 1));
        }
      );
    })(Highcharts);

    const __this = this;

    this.areaChart = new Chart({
      chart: { type: "area", height: 340 },

      title: { text: "" },
      legend: { layout: "horizontal" },

      xAxis: {
        categories: xAxis,
        gridLineWidth: 1,
        gridLineColor: "#BEBEBE",

        labels: { rotation: 270 },

        plotLines: [
          {
            color: "black",
            width: 1,
            value: separatorPosition,
            zIndex: 10,
            dashStyle: "dash",
          } as any,
        ],
      },

      yAxis: {
        title: { text: "" },
        labels: { format: "{value}x" },
        gridLineWidth: 1,
        gridLineColor: "#BEBEBE",
      },
      tooltip: {
        enabled: true,

        formatter: function () {
          const yValue = __this.utilService.getValidNumber(this.y);

          if (this.series.name === "(Upper Bound) - 3rd Quartile") {
            const pointIndex = this.point["index"];
            return (
              this.series.name +
              ": " +
              (yValue + firstQuartile[pointIndex]).toFixed(1).toLocaleString() +
              "x"
            );
          } else {
            return (
              this.series.name + ": " + yValue.toFixed(1).toLocaleString() + "x"
            );
          }
        },
      },
      credits: { enabled: false },
      plotOptions: {
        area: {
          stacking: "normal",
          pointStart: 0,
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
            states: { hover: { enabled: true } },
          },
        },
        series: {
          pointPlacement: grapHPlacement,

          dataLabels: {
            enabled: this.multipleChartShowLabels,
            style: { textOutline: "none" },
            color: "#000000",

            formatter: function () {
              if (this.y > 0) {
                const yValue = +this.y;

                if (this.series.name === "(Upper Bound) - 3rd Quartile") {
                  const pointIndex = this.point["index"];
                  return (
                    (yValue + firstQuartile[pointIndex])
                      .toFixed(1)
                      .toLocaleString() + "x"
                  );
                } else {
                  return yValue.toFixed(1).toLocaleString() + "x";
                }
              }
            },
          },
        },
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: ["downloadPNG"],
          },
        },
      },
      series: [
        {
          name: this.translateService.getLabel("upper_bound_3rd_quartile"),
          data: thirdQuartile.map((num, i) => num - firstQuartile[i]),
          // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.q3[1],this.valuationDateCCM.multipleType[userSelected].aggregations.q3[1]],
          type: "area",
          color: "#EEFFC6",
        },
        {
          name: this.translateService.getLabel("lower_bound_1st_quartile"),
          data: firstQuartile,
          // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.q1[1],this.valuationDateCCM.multipleType[userSelected].aggregations.q1[1]],
          type: "area",
          legendColor: "#EEFFC6",
          color: "transparent",
        } as any,
        {
          name: this.translateService.getLabel("median"),
          type: "spline",
          data: median,
          // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.median[1],this.valuationDateCCM.multipleType[userSelected].aggregations.median[1]],
          color: "#5FAFF2",
        },
        {
          name: this.translateService.getLabel("average"),
          type: "spline",
          data: average,
          // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.average[1],this.valuationDateCCM.multipleType[userSelected].aggregations.average[1]],
          color: "#FFD32B",
        },
        {
          name: subjectCompanyName,
          type: "spline",
          data: subjectComp,
          // data: [this.investmentDateCCM.subjectCompany[userSelected][1], this.valuationDateCCM.subjectCompany[userSelected][1]],
          color: this.themeService.primaryColor,
        },
      ],
    });
  }

  prepareDataForEvolutionChart() {
    let userSelected = this.marketMultiple;
    const xAxis = cloneDeep(this.marketEvolutionChartData[userSelected].xAxis);
    const median = cloneDeep(
      this.marketEvolutionChartData[userSelected].median
    );
    const average = cloneDeep(
      this.marketEvolutionChartData[userSelected].average
    );
    const subjectComp = cloneDeep(
      this.marketEvolutionChartData[userSelected].subjectComp
    );

    const thirdQuartile = cloneDeep(
      this.marketEvolutionChartData[userSelected].thirdQuartile
    );
    const firstQuartile = cloneDeep(
      this.marketEvolutionChartData[userSelected].firstQuartile
    );

    const adjustmentFactor = cloneDeep(
      this.marketEvolutionChartData[userSelected].adjustmentFactor
    );

    if (this.marketDataForAdditionalDate?.aggregations?.[userSelected]) {
      const additionalData = this.prepareMarketDataForAdditionalDate();

      xAxis.push(
        this.datePipe.transform(this.evolutionChartAdditionalDate, "mediumDate")
      );
      median.push(additionalData.median);
      average.push(additionalData.average);
      subjectComp.push(additionalData.impliedMultiple);
      thirdQuartile.push(additionalData.q3);
      firstQuartile.push(additionalData.q1);

      adjustmentFactor.push(additionalData.adjustmentFactor);
    }

    return {
      xAxis,
      median,
      average,
      subjectComp,
      thirdQuartile,
      firstQuartile,
      adjustmentFactor,
    };
  }

  addDateToEvolutionChart() {
    const date = moment(this.evolutionChartAdditionalDate).format("YYYY-MM-DD");

    this.marketDataForAdditionalDate = { loading: true };

    this.ds
      .getMarketDataForGivenDate(
        this.latestValuationDate.id,
        date,
        this.latestValDateTrackRecord.currency,
        this.companyId
      )
      .subscribe(
        (res) => {
          this.marketDataForAdditionalDate = res.body["response"];
          this.createMarketEvolutionChart(this.marketMultiple);
        },
        (error) => {
          this.marketDataForAdditionalDate = {};
        }
      );
  }

  prepareMarketDataForAdditionalDate() {
    const selectedMultipleData =
      this.marketDataForAdditionalDate?.aggregations?.[this.marketMultiple];
    const availableYears = this.marketDataForAdditionalDate?.years;

    let year = availableYears[0];

    year = this.getActualYearFromTheListOfYearByPeriod(
      this.settings.period,
      availableYears
    );

    console.log("Additional date market date", year, selectedMultipleData);
    return selectedMultipleData && selectedMultipleData[year]
      ? selectedMultipleData[year]
      : 0;
  }

  getActualYearFromTheListOfYearByPeriod(period, availableYears) {
    let year = availableYears[0];

    switch (period) {
      case "fy-1":
        year = availableYears[0];
        break;
      case "fy":
        year = availableYears[1];
        break; // LTM
      case "fy+1":
        year = availableYears[2];
        break;
      case "fy+2":
        year = availableYears[3];
        break;
      case "ntm":
        year = "NTM";
        break;
      case "ntm+1":
        year = "NTM+1";
        break;
    }

    return year;
  }

  getReqBodyForMarketEvolutionChart(buMarketMultiple) {
    let valuationDatesWithoutBU = [];
    valuationDatesWithoutBU = this.selectedValuationDates
      .filter((comp) => comp.businessUnitsNumber == 0)
      .filter((comp) => comp.status != "Initiated")
      .map((comp) => {
        return {
          id: comp.id,
          formType: comp.formType,
          formVersion: comp.formVersion,
          valuationDate: comp.valuationDate,
        };
      });

    const invForm = this.selectedValuationDates.find(
      (comp) => comp.groupFormId == null
    );

    if (
      !buMarketMultiple &&
      invForm &&
      invForm.businessUnits &&
      invForm.businessUnits[0]
    ) {
      buMarketMultiple = invForm.businessUnits[0].businessUnitName;
      this.buMarketMultiple = buMarketMultiple;
    }

    let valuationDatesWithBU = [];

    this.selectedValuationDates.forEach((comp) => {
      if (comp.businessUnits) {
        valuationDatesWithBU = valuationDatesWithBU.concat(
          comp.businessUnits
            .filter((comp) => comp.status != "Initiated")
            .map((bu) => {
              if (
                buMarketMultiple &&
                bu.businessUnitName &&
                bu.businessUnitName.toUpperCase() ==
                  buMarketMultiple.toUpperCase()
              ) {
                return {
                  id: bu.id,
                  formType: bu.formType,
                  formVersion: bu.formVersion,
                  valuationDate: bu.valuationDate,
                };
              } else {
                return null;
              }
            })
            .filter((bu) => bu != null)
        );
      }
    });

    const listOfAllValuationDates =
      valuationDatesWithoutBU.concat(valuationDatesWithBU);

    const reqBody = {
      forms: listOfAllValuationDates,
      marketMultiples: [
        "bevRevenue",
        "bevEbitda",
        "bevEbit",
        "bevEbitda_Capex",
        "evGrossProfit",
        "psales",
        "pe",
        "pbv",
      ],
      period: this.settings.period,
    };

    return reqBody;
  }

  getFiscalYearKeyByPeriod(period) {
    let fy = period;

    if (fy == "ntm") {
      fy = "ntm";
    } else if (fy == "ntm+1") {
      fy = "ntm+1";
    }

    return fy;
  }

  getFormattedDataForChart(marketEvolutionChartData) {
    const marketMultiples = Object.keys(marketEvolutionChartData);

    marketMultiples.forEach((m) => {
      const formIds = Object.keys(marketEvolutionChartData[m]);
      let agregationData = [];
      formIds.forEach((id) => {
        agregationData.push(marketEvolutionChartData[m][id]);
      });

      agregationData = agregationData.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date ? 0 : f1Date < f2Date ? -1 : 1;
      });

      this.marketEvolutionChartData[m] = {
        xAxis: agregationData.map((ag) =>
          this.datePipe.transform(ag.valuationDate, "mediumDate")
        ),
        median: agregationData.map((ag) => ag.median),
        average: agregationData.map((ag) => ag.average),
        subjectComp: agregationData.map((ag) => ag.subjectCompanyMultiple),

        thirdQuartile: agregationData.map((ag) => ag.q3),
        firstQuartile: agregationData.map((ag) => ag.q1),

        adjustmentFactor: agregationData.map((ag) => ag.adjustmentFactor),
      };
    });
  }

  async getImageURL(event) {
    try {
      const imageUrlRes = await this.ds.getImageUrl(event.target.files[0], this.companyId + event.target.files[0].name);
      
      let imagePath = imageUrlRes.body["response"];

      if(imagePath.indexOf("http") < 0) {
        const urlParts = imagePath.split("/");
        imagePath = urlParts[urlParts.length - 1];
      }

      const requestBody = {
          url: imagePath,
          companyId: this.companyId
      }
      await this.ds.saveLogoUrl(requestBody);

      this.invSummaryService.investmentDateValuation["logo"] = imagePath;
    }
    catch(e) {
      console.log("Error while saving company logo", e);
      this.utilService.showMessage(
        this.translateService.getLabel("err_save_logo") +
          this.formData.GENERAL.GD_General_Details.GD_CN_COMPANY_NAME +
          ". " +
          this.translateService.getLabel("info_try_again_later"),
        this.translateService.getLabel("ok"),
        true
      );
    }
  }

  reloadLogoImage(investmentDateValuation) {
    console.log("Reloading Logo . . . .", investmentDateValuation.logo);

    if(investmentDateValuation.logo && investmentDateValuation.logo.length < 100) {
      this.ds.getFileFromDirectStorage(investmentDateValuation.logo).subscribe(res => {
        // const url = URL.createObjectURL(res.body);
        // this.investmentDateValuation["logo"] = url;

        const reader = new FileReader();
        reader.readAsDataURL(res.body); //FileStream response from .NET core backend
        reader.onload = _event => {
            let url = reader.result; //url declared earlier
            this.invSummaryService.investmentDateValuation["logo"] = url;
        };

      }, er => {
        investmentDateValuation.logo = null;
      })
    }
  }

  saveData(showMessage?) {
    // try {
    //   this.initCalculationMultiples();
    // }  catch(e) {
    //   console.log("Error while calculating IRR", e)
    // }

    this.savedData["details"] = this.latestValuationDate["details"]
      ? JSON.parse(this.latestValuationDate["details"])
      : {};

    //Save market multiple - Market Multiple evolution chart type
    this.savedData.marketMultiple = this.marketMultiple;

    this.saveDataToWidget(showMessage);

    this.ds
      .saveWidgetDataToDB(
        "PARTNER_MASTER_LIST",
        this.masterPartnersList,
        this.orgId
      )
      .subscribe(
        (res) => {
          // console.log("Master Partner list saved successfully");
        },
        (error) => {
          console.log("Error while saving Master Partner list", error);
        }
      );

    this.ds
      .saveWidgetDataToDB("ORG_KEYWORDS", this.orgKeyWords, this.companyId)
      .subscribe(
        (res) => {
          // console.log("Keywords data saved successfully", this.portfolioService.orgKeyWords);
        },
        (error) => {
          console.log("error while saving data", error);
        }
      );

    // Updating Attributes
    this.invSummaryService.updateSummaryAndValuationDateAttributesByCompany(
      this.companyId,
      this.fundId,
      this.invSummaryService.INVESTMENT_SUMMARY_SAVE
    );
  }

  deletePartnerName(index) {
    const removedPartner = this.masterPartnersList.splice(index, 1);
    const deletedPartnerIndex = this.savedData.partners.findIndex(
      (s) => s.name.toLowerCase() === removedPartner[0].name.toLowerCase()
    );
    if (deletedPartnerIndex >= 0) {
      this.savedData.partners.splice(deletedPartnerIndex, 1);
    }
  }

  createActualPerformanceGraph() {
    let graphType = this.actualPerformanceDataModel["graphType"];
    if (!graphType) {
      graphType = "quarter";
      this.actualPerformanceDataModel["graphType"] = graphType;
    }

    let valueIndex = 0;
    let seriesLabel = "Second Quarter";

    if (graphType == "year") {
      valueIndex = 3;
      seriesLabel = "Year to date";
    }

    const xAxis = ["Revenue", "EBITDA"];

    const series = [
      {
        name: seriesLabel + " - Actual",
        color: colors.primaryColor,
        data: [
          this.actualPerformanceDataModel.rows[0].values[valueIndex],
          this.actualPerformanceDataModel.rows[3].values[valueIndex],
        ],
      },
      {
        name: seriesLabel + " - Budget",
        color: colors.secondaryColor,
        data: [
          this.actualPerformanceDataModel.rows[0].values[valueIndex + 1],
          this.actualPerformanceDataModel.rows[3].values[valueIndex + 1],
        ],
      },
    ];

    this.actualPerformanceGraph = new Chart({
      // chart: { polar: true, type: 'line'},
      chart: { type: "bar" },
      credits: { enabled: false },
      accessibility: { description: "" },
      title: { text: "" },
      xAxis: {
        categories: xAxis,
        title: { text: "" },
      },

      yAxis: { title: { text: "" } },

      tooltip: {
        shared: true,
        pointFormat:
          '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>',
      },

      legend: { enabled: true },

      series: series as any,

      responsive: {
        rules: [
          {
            condition: { maxWidth: 500 },
          },
        ],
      },
    });
  }

  savePerformanceData() {
    // console.log("Save performance data");
    // console.log(this.actualPerformanceDataModel);

    if (!this.actualPerformanceDataModel["graphType"]) {
      this.actualPerformanceDataModel["graphType"] = "quarter";
    }

    this.createActualPerformanceGraph();
    this.savedData["actualPerformance"] = this.actualPerformanceDataModel;
    this.saveDataToWidget();
    this.actualVsPlannedGrid.refresh();
    //Save excelDataModel
  }

  getSelectedCompanyOrgDetails(domain) {
    // this.ds.getSelectedCompanyOrgDetails(domain).subscribe((fundCompanyDetails) => {
    //   this.fundDetails = fundCompanyDetails.body["response"];
    //   if(!this.fundDetails.organization) {
    //     this.fundDetails.organization = {}
    //   }
    //   this.fundDetails.organization.logo_url = null;
    // }, error=>{
    //   console.log("Error: While fetching fund company details.", error);
    //   this.fundDetails = {
    //     organization: {
    //       logo_url: "",
    //       name: "",
    //       city: "",
    //       state: "",
    //       country: "",
    //       industry: "",
    //       estimated_num_employees: 0,
    //       website_url: ""
    //     },
    //     people: []
    //   }
    // })
  }

  getKeywords() {
    this.orgKeyWords = [];

    this.ds.getWidgetDataFromDB("ORG_KEYWORDS", this.companyId).subscribe(
      (res) => {
        this.orgKeyWords = res.body["response"][0].widgetData || [];
      },
      (error) => {
        const keyWordsAPI_Input = {
          uuid: "Test",
          isNewApi: true,
          text: this.companyDescription,
        };

        this.ds.getTagsForText(keyWordsAPI_Input).subscribe(
          (res) => {
            if(res){
              this.orgKeyWords = res.body["response"].map((n) =>
                n.tag.replaceAll("_", " ")
              );
            }
            // console.log("keywords fetched successfully", this.portfolioService.orgKeyWords);
          },
          (error) => {
            console.log(
              "Error: While fetching keywords based on company description",
              error.error
            );
          }
        );
      }
    );
  }

  openValdateLimitPopUp(content) {
    this.valDateUpperLimitUserInput = this.valDateUpperLimit;
    this.modalService.open(content, {
      backdrop: "static",
      size: "sm",
      centered: true,
    });
  }

  openPopupModal(content) {
    this.editCompanyDetails = {
      organization: {
        logo_url: "",
        name: "",
        city: "",
        state: "",
        country: "",
        industry: "",
        estimated_num_employees: undefined,
        website_url: "",
        location: "",
        newKeyPeople: "",
      },
      people: [],
      newKeyPeopleList: [],
    };
    this.prepareEditableCompanyData();

    this.modalService.open(content, {
      centered: true,
      windowClass: "center-popup-modal",
    });
  }

  prepareEditableCompanyData() {
    this.editCompanyDetails.organization.city =
      this.fundDetails.organization.city;
    this.editCompanyDetails.organization.state =
      this.fundDetails.organization.state;
    this.editCompanyDetails.organization.country =
      this.fundDetails.organization.country;

    if (
      !this.fundDetails.organization.name ||
      this.fundDetails.organization.name.toLowerCase().indexOf("orolia") < 0
    ) {
      this.editCompanyDetails.organization.industry =
        this.fundDetails.organization.industry;
    } else {
      this.editCompanyDetails.organization.industry = "Defence & Aerospace";
    }
    this.editCompanyDetails.organization.estimated_num_employees =
      this.fundDetails.organization.estimated_num_employees;
    this.editCompanyDetails.organization.logo_url =
      this.fundDetails.organization.logo_url;
    this.editCompanyDetails.organization.website_url =
      this.fundDetails.organization.website_url;
    this.editCompanyDetails.people = this.fundDetails.people;
    if (
      this.fundDetails.organization.city &&
      this.fundDetails.organization.state &&
      this.fundDetails.organization.country
    ) {
      this.editCompanyDetails.organization.location =
        this.fundDetails.organization.city +
        "," +
        this.fundDetails.organization.state +
        "," +
        this.fundDetails.organization.country;
    } else {
      this.editCompanyDetails.organization.location =
        this.fundDetails.organization.city;
    }
  }

  openPopUpModel(content) {
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  addSecurityType() {
    this.savedData.securityType = this.securityTypeAv;
    this.securityTypeAv = "";
  }

  startDateChange(valuation) {
    this.startDateValuationBridge = valuation.valuationDate;
    this.startDateForm = valuation;
  }

  endDateChange(valuation) {
    this.endDateValuationBridge = valuation.valuationDate;
    this.endDateForm = valuation;
  }

  intermediateDateChange(valuation) {
    this.intermediateDateValuationBridge = valuation.valuationDate;
    this.intermediateDateForm = cloneDeep(valuation);
  }

  fetchValuationDataForWaterfallChart(rowIndex) {
    let reqBody = {} as any;

    if (
      this.filteredForms &&
      this.filteredForms.length >= 3 &&
      this.isMultiValueBridge &&
      (this.startDateForm["id"] == this.endDateForm["id"] ||
        (this.endDateForm["id"] == this.intermediateDateForm["id"] &&
          this.isMultiValueBridge) ||
        this.intermediateDateForm["id"] == this.startDateForm["id"])
    ) {
      this.utilService.showMessage(
        this.translateService.getLabel("info_select_unique_date"),
        this.translateService.getLabel("ok")
      );
      return;
    } else if (
      this.filteredForms &&
      this.filteredForms.length == 2 &&
      this.startDateForm["id"] == this.endDateForm["id"]
    ) {
      this.utilService.showMessage(
        this.translateService.getLabel("info_select_unique_date"),
        this.translateService.getLabel("ok")
      );
      return;
    }

    if (this.filteredForms && this.filteredForms.length >= 3) {
      const vds = [];

      const startDate = this.filteredForms.find(
        (comp) => comp.id === this.startDateForm["id"]
      );
      vds.push(startDate);

      if (this.intermediateDateValuationBridge && this.isMultiValueBridge) {
        const interDate = this.filteredForms.find(
          (comp) => comp.id === this.intermediateDateForm["id"]
        );
        vds.push(interDate);
      }

      const endDate = this.filteredForms.find(
        (comp) => comp.id === this.endDateForm["id"]
      );
      vds.push(endDate);

      reqBody = this.prepareValuaBridgeAPIReqFromInputs(vds);

      this.multipleValueBridgeData[rowIndex].startDateValuationBridge = this
        .startDateValuationBridge
        ? this.startDateValuationBridge
        : "";
      this.multipleValueBridgeData[rowIndex].endDateValuationBridge = this
        .endDateValuationBridge
        ? this.endDateValuationBridge
        : "";
      this.multipleValueBridgeData[rowIndex].intermediateDateValuationBridge =
        this.intermediateDateValuationBridge
          ? this.intermediateDateValuationBridge
          : "";

      // this.initValueBridgeDates();

      this.multipleValueBridgeData[rowIndex].startDateForm = this.startDateForm
        ? this.startDateForm
        : null;
      this.multipleValueBridgeData[rowIndex].endDateForm = this.endDateForm
        ? this.endDateForm
        : null;
      this.multipleValueBridgeData[rowIndex].intermediateDateForm = this
        .intermediateDateForm
        ? this.intermediateDateForm
        : null;
    } else if (this.filteredForms && this.filteredForms.length == 2) {
      const vds = [];

      const startDate = this.filteredForms.find(
        (comp) => comp.id === this.startDateForm["id"]
      );
      vds.push(startDate);

      const endDate = this.filteredForms.find(
        (comp) => comp.id === this.endDateForm["id"]
      );
      vds.push(endDate);

      reqBody = this.prepareValuaBridgeAPIReqFromInputs(vds);

      this.multipleValueBridgeData[rowIndex].startDateValuationBridge = this
        .startDateValuationBridge
        ? this.startDateValuationBridge
        : "";
      this.multipleValueBridgeData[rowIndex].endDateValuationBridge = "";
      this.multipleValueBridgeData[rowIndex].intermediateDateValuationBridge =
        this.intermediateDateValuationBridge
          ? this.intermediateDateValuationBridge
          : "";

      // this.initValueBridgeDates();

      this.multipleValueBridgeData[rowIndex].startDateForm = this.startDateForm
        ? this.startDateForm
        : null;
      this.multipleValueBridgeData[rowIndex].endDateForm = null;
      this.multipleValueBridgeData[rowIndex].intermediateDateForm = this
        .intermediateDateForm
        ? this.intermediateDateForm
        : null;
    }

    this.showValueBridgePopUpLoader = true;
    this.ds
      .getValuationBridgeDataForAllForms(reqBody, this.fundId, this.companyId)
      .subscribe(
        async (res) => {
          const apiResponse = res.body["response"];

          if (apiResponse) {
            await this.fetchAdvanceNavBridgeFilterData(reqBody.forms);

            this.initValueBridgeChartAndDraw(apiResponse);

            // const waterFallChartData = this.drawValuationBridge()
            // this.createMultipleValueBridge(waterFallChartData, this.waterFallNumbers);

            // this.multipleValueBridgeData[rowIndex].waterFallNumbers = cloneDeep(this.waterFallNumbers)
            // this.multipleValueBridgeData[rowIndex].waterFallChartData = cloneDeep(waterFallChartData)

            this.showValueBridgePopUpLoader = false;
          } else {
            this.prepareDefaultValueBridge();

            this.showValueBridgePopUpLoader = false;
          }
        },
        (error) => {
          console.log("Error while Calculating Value Bridge", error);
          this.prepareDefaultValueBridge();

          this.showValueBridgePopUpLoader = true;
        }
      );
  }

  reInstateTheValues() {
    //reinstating the values back
    this.startDateValuationBridge = this.startDateValuationBridgeClone;
    this.endDateValuationBridge = this.endDateValuationBridgeClone;

    if (this.intermediateDateValuationBridgeClone) {
      this.intermediateDateValuationBridge =
        this.intermediateDateValuationBridgeClone;
    }
    this.initValueBridgeDates();
    this.valueBridgeModel = cloneDeep(this.valueBridgeModelClone);
  }

  addPartnerToCompany(event, list) {
    if (event) {
      if (!this.savedData.partners) {
        this.savedData.partners = [];
      }
      this.savedData.partners.push(list);
    } else {
      this.savedData.partners = this.masterPartnersList.filter((p) => {
        return p.isSelected;
      });
    }
  }

  addKeyPeople() {
    let newKeyPeople = {
      name: this.editCompanyDetails.organization.newKeyPeople,
    };
    this.editCompanyDetails.people.push(newKeyPeople);
    // console.log(this.editCompanyDetails.people);
  }

  deleteKeyPeople(index) {
    this.editCompanyDetails.people.splice(index, 1);
  }

  saveCompanyDetails(editCompanyContent) {
    let editCompanyRequestBody = {
      organization: {
        logo_url: this.editCompanyDetails.organization.logo_url,
        name: this.fundDetails.organization.name,
        city: this.editCompanyDetails.organization.location,
        state: "",
        country: "",
        industry: this.editCompanyDetails.organization.industry,
        estimated_num_employees:
          this.editCompanyDetails.organization.estimated_num_employees,
        website_url: this.editCompanyDetails.organization.website_url,
      },
      people: this.editCompanyDetails.people,
    };
    this.ds
      .saveWidgetDataToDB(
        "USER_ORG_DETAILS",
        JSON.stringify(editCompanyRequestBody),
        this.companyId
      )
      .subscribe(
        (res) => {
          this.fundDetails = editCompanyRequestBody;
          console.log("Company Details saved to db", res.body);
          editCompanyContent.dismiss("Cross click");
        },
        (error) => {
          console.log("Failed to save Company Details data to db");
        }
      );
  }

  // Value Bridge Download
  downloadValueBridgeExcel() {
    if (this.isVBExcelDownloading) return;

    let fileData = [];

    this.valueBridgeFullData.forEach((ele, index) => {
      fileData.push(this.getExcelDownloadValueBridgeBody(ele, index));
    });

    this.isVBExcelDownloading = true;

    this.ds.downloadExcelFileV2(fileData).subscribe(
      (fileData) => {
        this.manageFileDownload(fileData, "xlsx");
      },
      (err) => {
        console.log("Failed to download file", err);
        this.utilService.showMessage(
          this.translateService.getLabel("err_download_file"),
          this.translateService.getLabel("ok")
        );
        this.isVBExcelDownloading = false;
      }
    );
  }

  getCellObject(type, value, decimal?) {
    if (decimal) {
      const obj = {
        type: type,
        value: value,
        decimal: decimal,
      };

      return obj;
    } else {
      const obj = {
        type: type,
        value: value,
      };

      return obj;
    }
  }

  getExcelDownloadValueBridgeBody(responseData, index) {
    let data = responseData.valueBridgeData;
    let fX = responseData.oldData.fX;

    if (this.localCurrency) {
      fX = 1;
      data = responseData.valueBridgeDataInLocalCurrency;
    }

    let headers = ["", "", data.startDate, "", data.endDate];
    const values = [];
    let responseDataIndex = index + 1;

    if (this.settings.valueBridge == "ENTERPRISE") {
      values.push([
        this.getCellObject("text", "Enterprise Value"),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.oldData.enterpriseValue),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.newData.enterpriseValue),
      ]);
    } else if (this.settings.valueBridge == "EQUITY") {
      values.push([
        this.getCellObject("text", "Enterprise Value"),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.oldData.enterpriseValue),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.newData.enterpriseValue),
      ]);

      values.push([
        this.getCellObject(
          "text",
          "Net Debt and Other Balance Sheet Adjustments"
        ),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.oldData.netDebt),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.newData.netDebt),
      ]);

      values.push([
        this.getCellObject("text", "Equity Value (100%)"),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.oldData.nonAdjustedEquityValue
        ),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.newData.nonAdjustedEquityValue
        ),
      ]);
      values.push([
        this.getCellObject("text", "Adjustment to Equity Value"),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.oldData.adjustmentsToEquityValue
        ),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.newData.adjustmentsToEquityValue
        ),
      ]);
      values.push([
        this.getCellObject("text", "Adjusted Equity Value (100%)"),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.oldData.equityValueFromValSummary
        ),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.newData.equityValueFromValSummary
        ),
      ]);
    } else {
      values.push([
        this.getCellObject("text", "Enterprise Value"),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.oldData.enterpriseValue),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.newData.enterpriseValue),
      ]);

      values.push([
        this.getCellObject(
          "text",
          "Net Debt and Other Balance Sheet Adjustments"
        ),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.oldData.netDebt),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.newData.netDebt),
      ]);

      values.push([
        this.getCellObject("text", "Equity Value (100%)"),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.oldData.nonAdjustedEquityValue
        ),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.newData.nonAdjustedEquityValue
        ),
      ]);
      values.push([
        this.getCellObject("text", "Adjustment to Equity Value"),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.oldData.adjustmentsToEquityValue
        ),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.newData.adjustmentsToEquityValue
        ),
      ]);

      values.push([
        this.getCellObject("text", "Adjusted Equity Value (100%)"),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.oldData.equityValueFromValSummary
        ),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.newData.equityValueFromValSummary
        ),
      ]);
      values.push([
        this.getCellObject("text", "Equity Value from Waterfall"),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.oldData.equityValueFromWaterfall
        ),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.newData.equityValueFromWaterfall
        ),
      ]);

      values.push([
        this.getCellObject("text", "Stake"),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.oldData.stake),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.newData.stake),
      ]);
      values.push([
        this.getCellObject("text", "Stake Equity Value"),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.oldData.stakeValueBeforeAdjustment
        ),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.newData.stakeValueBeforeAdjustment
        ),
      ]);

      values.push([
        this.getCellObject("text", "Adjustment to Stake Equity Value"),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.oldData.debtAndOthers),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.newData.debtAndOthers),
      ]);
      values.push([
        this.getCellObject("text", "NAV"),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.oldData.stakeValue),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.newData.stakeValue),
      ]);
      values.push([
        this.getCellObject("text", "Realised Proceeds"),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.oldData.realisedProceeds),
        this.getCellObject("text", ""),
        this.getCellObject("number", responseData.newData.realisedProceeds),
      ]);
    }

    let multipleKey = responseData.newData.multipleKey;

    values.push([
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
    ]);

    values.push([
      this.getCellObject("text", this.getValueBridgeMetricLabel(multipleKey)),
      this.getCellObject("text", ""),
      this.getCellObject("number", responseData.oldData[multipleKey]),
      this.getCellObject("text", ""),
      this.getCellObject("number", responseData.newData[multipleKey]),
    ]);
    values.push([
      this.getCellObject(
        "text",
        this.getValueBridgeMetricLabel(multipleKey) + " Multiple"
      ),
      this.getCellObject("text", ""),
      this.getCellObject("number", responseData.oldData.multiple),
      this.getCellObject("text", ""),
      this.getCellObject("number", responseData.newData.multiple),
    ]);

    values.push([
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
    ]);
    values.push([
      this.getCellObject("text", "FX"),
      this.getCellObject("text", ""),
      this.getCellObject("number", fX),
      this.getCellObject("text", ""),
      this.getCellObject("number", responseData.newData.fX),
      this.getCellObject(
        "text",
        this.latestValDateTrackRecord.currency +
          " / " +
          this.valueBridgeCurrency
      ),
    ]);
    values.push([
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
    ]);

    const buildUp = this.utilService.getValidNumber(
      responseData.oldData.consolidatedStakeValue
    );

    if (buildUp > 0) {
      values.push([
        this.getCellObject("text", "Build-up"),
        this.getCellObject("text", ""),
        this.getCellObject(
          "number",
          responseData.oldData.consolidatedStakeValue
        ),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
      ]);
      values.push([
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
      ]);
    }

    if (this.settings.valueBridge == "NAV") {
      values.push([
        this.getCellObject("text", "NAV (Old)"),
        this.getCellObject("text", ""),
        this.getCellObject("number", data.startingValue),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject("text", "Formula"),
      ]);
    } else if (this.settings.valueBridge == "EQUITY") {
      values.push([
        this.getCellObject("text", "EQUITY (Old)"),
        this.getCellObject("text", ""),
        this.getCellObject("number", data.startingValue),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject("text", "Formula"),
      ]);
    } else {
      values.push([
        this.getCellObject("text", "ENTERPRISE (Old)"),
        this.getCellObject("text", ""),
        this.getCellObject("number", data.startingValue),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject("text", "Formula"),
      ]);
    }

    values.push([
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
    ]);

    // if(buildUp > 0) {
    //   values.push(["External Growth", "", this.utilService.zeroInForOneDecimalFormat(data.externalGrowth), "", "", "("+ responseData.oldData.consolidatedStakeValue +"-"+ responseData.oldData.stakeValue +")" + "*" + fX]);
    // }

    let oldmultipleValueImpactkey = responseData.oldData.multipleKey;
    let newmultipleValueImpactkey = responseData.newData.multipleKey;

    if (this.settings.valueBridge == "NAV") {
      // If a row is added or deleted the existing rows consisting formula needs to be updated accordingly.

      if (buildUp > 0) {
        values.push([
          this.getCellObject("text", "External Growth"),
          this.getCellObject("text", ""),
          this.getCellObject(
            "number",
            this.utilService.zeroInForOneDecimalFormat(data.externalGrowth)
          ),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.oldData.consolidatedStakeValue +
              "-" +
              responseData.oldData.stakeValue +
              ")" +
              "*" +
              fX
          ),
        ]);
      }

      values.push([
        this.getCellObject(
          "text",
          this.getValueBridgeMetricLabel(multipleKey) + " Impact"
        ),
        this.getCellObject("text", ""),
        this.getCellObject(
          "formula",
          "(##4!!14-(##2!!14))*##2!!15*##4!!8*##2!!17"
        ),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject(
          "text",
          "(" +
            responseData.newData[newmultipleValueImpactkey] +
            "-" +
            "(" +
            responseData.oldData[oldmultipleValueImpactkey] +
            ")" +
            ")" +
            "*" +
            responseData.oldData.multiple +
            "*" +
            responseData.newData.stake +
            "*" +
            fX
        ),
      ]);

      values.push([
        this.getCellObject(
          "text",
          this.getValueBridgeMetricLabel(multipleKey) + " Multiple Impact"
        ),
        this.getCellObject("text", ""),
        this.getCellObject(
          "formula",
          "(##4!!15-(##2!!15))*##4!!14*##4!!8*##2!!17"
        ),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject(
          "text",
          "(" +
            responseData.newData.multiple +
            "-" +
            "(" +
            responseData.oldData.multiple +
            ")" +
            ")" +
            "*" +
            responseData.newData[multipleKey] +
            "*" +
            responseData.newData.stake +
            "*" +
            fX
        ),
      ]);

      values.push([
        this.getCellObject("text", "Realised Proceeds Impact"),
        this.getCellObject("text", ""),
        this.getCellObject("formula", "(##4!!12-(##2!!12))*-1*##2!!17"),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject(
          "text",
          "(" +
            responseData.newData.realisedProceeds +
            "-" +
            responseData.oldData.realisedProceeds +
            ")" +
            "*" +
            "-1" +
            "*" +
            fX
        ),
      ]);

      values.push([
        this.getCellObject(
          "text",
          "Net Debt and Other Balance Sheet Adjustments Impact"
        ),
        this.getCellObject("text", ""),
        this.getCellObject("formula", "(##4!!3-(##2!!3))*##4!!8*##2!!17"),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject(
          "text",
          "(" +
            responseData.newData.netDebt +
            "-" +
            "(" +
            responseData.oldData.netDebt +
            ")" +
            ")" +
            "*" +
            responseData.newData.stake +
            "*" +
            fX
        ),
      ]);

      values.push([
        this.getCellObject("text", "Adjustment to Equity Value Impact"),
        this.getCellObject("text", ""),
        this.getCellObject("formula", "(##4!!5-(##2!!5))*##4!!8*##2!!17"),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject(
          "text",
          "(" +
            responseData.newData.adjustmentsToEquityValue +
            "-" +
            responseData.oldData.adjustmentsToEquityValue +
            ")*" +
            responseData.newData.stake +
            "*" +
            fX
        ),
      ]);

      values.push([
        this.getCellObject("text", "Adjustment to Stake Equity Value Impact"),
        this.getCellObject("text", ""),
        this.getCellObject("formula", "(##4!!10-(##2!!10))*##2!!17"),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject(
          "text",
          responseData.newData.debtAndOthers +
            "-" +
            "(" +
            responseData.oldData.debtAndOthers +
            ")" +
            "*" +
            fX
        ),
      ]);

      if (responseData.oldData.equityValueFromWaterfall == 0) {
        values.push([
          this.getCellObject("text", "Stake Impact"),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(##4!!8-(##2!!8))*##2!!6*##2!!17"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.newData.stake +
              "-" +
              "(" +
              responseData.oldData.stake +
              ")" +
              ")" +
              "*" +
              responseData.oldData.equityValueFromValSummary +
              "*" +
              fX
          ),
        ]);
      } else {
        values.push([
          this.getCellObject("text", "Stake Impact"),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(##4!!8-(##2!!8))*##2!!7*##2!!17"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.newData.stake +
              "-" +
              "(" +
              responseData.oldData.stake +
              ")" +
              ")" +
              "*" +
              responseData.oldData.equityValueFromWaterfall +
              "*" +
              fX
          ),
        ]);
      }

      values.push([
        this.getCellObject("text", "FX Impact"),
        this.getCellObject("text", ""),
        this.getCellObject("formula", "(##4!!17-(##2!!17))*##4!!11"),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject(
          "text",
          "(" +
            responseData.newData.fX +
            "-(" +
            responseData.oldData.fX +
            "))*" +
            responseData.newData.stakeValue +
            "*" +
            1
        ),
      ]);

      if (
        responseData.newData.equityValueFromWaterfall == 0 &&
        responseData.oldData.equityValueFromWaterfall != 0
      ) {
        values.push([
          this.getCellObject("text", "Waterfall Impact"),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(0-(##2!!7-(##2!!6)))*##4!!8*##2!!17"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              0 +
              "-(" +
              responseData.oldData.equityValueFromWaterfall +
              "-" +
              responseData.oldData.equityValueFromValSummary +
              "))*" +
              responseData.newData.stake +
              "*" +
              fX
          ),
        ]);
      } else if (
        responseData.oldData.equityValueFromWaterfall == 0 &&
        responseData.newData.equityValueFromWaterfall != 0
      ) {
        values.push([
          this.getCellObject("text", "Waterfall Impact"),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "((##4!!7-(##4!!6))-0)*##4!!8*##2!!17"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "((" +
              responseData.newData.equityValueFromWaterfall +
              "-" +
              responseData.newData.equityValueFromValSummary +
              ")-" +
              0 +
              ")*" +
              responseData.newData.stake +
              "*" +
              fX
          ),
        ]);
      } else if (
        responseData.oldData.equityValueFromWaterfall == 0 &&
        responseData.newData.equityValueFromWaterfall == 0
      ) {
        values.push([
          this.getCellObject("text", "Waterfall Impact"),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(0-0)*##4!!8*##2!!17"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" + 0 + "-" + 0 + ")*" + responseData.newData.stake + "*" + fX
          ),
        ]);
      } else {
        values.push([
          this.getCellObject("text", "Waterfall Impact"),
          this.getCellObject("text", ""),
          this.getCellObject(
            "formula",
            "((##4!!7-(##4!!6))-(##2!!7-(##2!!6)))*##4!!8*##2!!17"
          ),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "((" +
              responseData.newData.equityValueFromWaterfall +
              "-" +
              responseData.newData.equityValueFromValSummary +
              ")-(" +
              responseData.oldData.equityValueFromWaterfall +
              "-" +
              responseData.oldData.equityValueFromValSummary +
              "))*" +
              responseData.newData.stake +
              "*" +
              fX
          ),
        ]);
      }

      if (buildUp > 0) {
        values.push([
          this.getCellObject("text", "Others Impact"),
          this.getCellObject("text", ""),
          this.getCellObject(
            "formula",
            "##2!!35-(##2!!21+(##2!!23)+(##2!!24)+(##2!!25)+(##2!!26)+(##2!!27)+(##2!!28)+(##2!!29)+(##2!!30)+(##2!!31)+(##2!!32))"
          ),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            data.endingValue +
              "- (" +
              data.startingValue +
              "+(" +
              data.multipleValueImpact +
              ")+(" +
              data.multipleImpact +
              ")+(" +
              data.realisedProceedsImpact +
              ")+(" +
              data.netDebtImpact +
              ")+(" +
              data.adjustmentsToEquityValueImpact +
              ")+(" +
              data.othersDebtImpact +
              ")+(" +
              data.stakeImpact +
              ")+(" +
              data.fxImpact +
              ")+(" +
              data.waterfallImpact +
              "))"
          ),
        ]);
      } else {
        values.push([
          this.getCellObject("text", "Others Impact"),
          this.getCellObject("text", ""),
          this.getCellObject(
            "formula",
            "##2!!32-(##2!!19+(##2!!21)+(##2!!22)+(##2!!23)+(##2!!24)+(##2!!25)+(##2!!26)+(##2!!27)+(##2!!28)+(##2!!29))"
          ),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            data.endingValue +
              "- (" +
              data.startingValue +
              "+(" +
              data.multipleValueImpact +
              ")+(" +
              data.multipleImpact +
              ")+(" +
              data.realisedProceedsImpact +
              ")+(" +
              data.netDebtImpact +
              ")+(" +
              data.adjustmentsToEquityValueImpact +
              ")+(" +
              data.othersDebtImpact +
              ")+(" +
              data.stakeImpact +
              ")+(" +
              data.fxImpact +
              ")+(" +
              data.waterfallImpact +
              "))"
          ),
        ]);
      }
    } else {
      // Weather the valueBridge is Equity or Enterprise
      // If a row is added or deleted the existing rows consisting formula needs to be updated accordingly.

      if (this.settings.valueBridge == "EQUITY") {
        if (buildUp > 0) {
          values.push([
            this.getCellObject("text", "External Growth"),
            this.getCellObject("text", ""),
            this.getCellObject(
              "number",
              this.utilService.zeroInForOneDecimalFormat(data.externalGrowth)
            ),
            this.getCellObject("text", ""),
            this.getCellObject("text", ""),
            this.getCellObject(
              "text",
              "(" +
                responseData.oldData.consolidatedEquityValue +
                "-" +
                responseData.oldData.equityValue +
                ")" +
                "*" +
                fX
            ),
          ]);
        }

        // Metric impact For Equity valueBridge: (newMetric-oldMetric) * oldMultiple
        values.push([
          this.getCellObject(
            "text",
            this.getValueBridgeMetricLabel(multipleKey) + " Impact"
          ),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(##4!!8-(##2!!8))*##2!!9*##2!!11"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.newData[newmultipleValueImpactkey] +
              "-" +
              "(" +
              responseData.oldData[oldmultipleValueImpactkey] +
              ")" +
              ")" +
              "*" +
              responseData.oldData.multiple +
              "*" +
              fX
          ),
        ]);

        //MultipleImpact For Equity valueBridge: (newMultiple - oldMultiple) * newMetric
        values.push([
          this.getCellObject(
            "text",
            this.getValueBridgeMetricLabel(multipleKey) + " Multiple Impact"
          ),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(##4!!9-(##2!!9))*##4!!8*##2!!11"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.newData.multiple +
              "-" +
              "(" +
              responseData.oldData.multiple +
              ")" +
              ")" +
              "*" +
              responseData.newData[multipleKey] +
              "*" +
              fX
          ),
        ]);

        //Net Debt Impact: (newDebt - oldDebt) * fx
        values.push([
          this.getCellObject(
            "text",
            "Net Debt and Other Balance Sheet Adjustments Impact"
          ),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(##4!!3-(##2!!3))*##2!!11"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.newData.netDebt +
              "-" +
              "(" +
              responseData.oldData.netDebt +
              ")" +
              ")" +
              "*" +
              fX
          ),
        ]);

        //Adjustemts to equity value impact
        values.push([
          this.getCellObject("text", "Adjustment to Equity Value Impact"),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(##4!!5-(##2!!5))*##2!!11"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.newData.adjustmentsToEquityValue +
              "-" +
              responseData.oldData.adjustmentsToEquityValue +
              ")*" +
              fX
          ),
        ]);

        //If updated EQUITY or ENTERPRISE please update fx and others impact formula accordingly.
        values.push([
          this.getCellObject("text", "FX Impact"),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(##4!!11-(##2!!11))*##4!!6"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.newData.fX +
              "-(" +
              responseData.oldData.fX +
              "))*" +
              responseData.newData.equityValueFromValSummary +
              "*" +
              1
          ),
        ]);

        if (buildUp > 0) {
          values.push([
            this.getCellObject("text", "Others Impact"),
            this.getCellObject("text", ""),
            this.getCellObject(
              "formula",
              "##2!!25-(##2!!15+(##2!!17)+(##2!!18)+(##2!!19)+(##2!!20)+(##2!!21)+(##2!!22))"
            ),
            this.getCellObject("text", ""),
            this.getCellObject("text", ""),
            this.getCellObject(
              "text",
              data.endingValue +
                "- (" +
                data.startingValue +
                "+(" +
                data.multipleValueImpact +
                ")+(" +
                data.multipleImpact +
                ")+(" +
                data.netDebtImpact +
                ")+(" +
                data.adjustmentsToEquityValueImpact +
                ")+(" +
                data.fxImpact +
                "))"
            ),
          ]);
        } else {
          values.push([
            this.getCellObject("text", "Others Impact"),
            this.getCellObject("text", ""),
            this.getCellObject(
              "formula",
              "##2!!22-(##2!!13+(##2!!15)+(##2!!16)+(##2!!17)+(##2!!18)+(##2!!19))"
            ),
            this.getCellObject("text", ""),
            this.getCellObject("text", ""),
            this.getCellObject(
              "text",
              data.endingValue +
                "- (" +
                data.startingValue +
                "+(" +
                data.multipleValueImpact +
                ")+(" +
                data.multipleImpact +
                ")+(" +
                data.netDebtImpact +
                ")+(" +
                data.adjustmentsToEquityValueImpact +
                ")+(" +
                data.fxImpact +
                "))"
            ),
          ]);
        }
      } else {
        if (buildUp > 0) {
          values.push([
            this.getCellObject("text", "External Growth"),
            this.getCellObject("text", ""),
            this.getCellObject(
              "number",
              this.utilService.zeroInForOneDecimalFormat(data.externalGrowth)
            ),
            this.getCellObject("text", ""),
            this.getCellObject("text", ""),
            this.getCellObject(
              "text",
              "(" +
                responseData.oldData.consolidatedEnterpriseValue +
                "-" +
                responseData.oldData.enterpriseValue +
                ")" +
                "*" +
                fX
            ),
          ]);
        }

        // Metric impact For Enterprise valueBridge: (newMetric-oldMetric) * oldMultiple
        values.push([
          this.getCellObject(
            "text",
            this.getValueBridgeMetricLabel(multipleKey) + " Impact"
          ),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(##4!!4-(##2!!4))*##2!!5*##2!!7"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.newData[newmultipleValueImpactkey] +
              "-" +
              "(" +
              responseData.oldData[oldmultipleValueImpactkey] +
              ")" +
              ")" +
              "*" +
              responseData.oldData.multiple +
              "*" +
              fX
          ),
        ]);

        //MultipleImpact For Enterprise valueBridge: (newMultiple - oldMultiple) * newMetric
        values.push([
          this.getCellObject(
            "text",
            this.getValueBridgeMetricLabel(multipleKey) + " Multiple Impact"
          ),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(##4!!5-(##2!!5))*##4!!4*##2!!7"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.newData.multiple +
              "-" +
              "(" +
              responseData.oldData.multiple +
              ")" +
              ")" +
              "*" +
              responseData.newData[multipleKey] +
              "*" +
              fX
          ),
        ]);

        //If updated EQUITY or ENTERPRISE please update fx and others formula accordingly.
        values.push([
          this.getCellObject("text", "FX Impact"),
          this.getCellObject("text", ""),
          this.getCellObject("formula", "(##4!!7-(##2!!7))*##4!!2"),
          this.getCellObject("text", ""),
          this.getCellObject("text", ""),
          this.getCellObject(
            "text",
            "(" +
              responseData.newData.fX +
              "-(" +
              responseData.oldData.fX +
              "))*" +
              responseData.newData.enterpriseValue +
              "*" +
              1
          ),
        ]);

        if (buildUp > 0) {
          values.push([
            this.getCellObject("text", "Others Impact"),
            this.getCellObject("text", ""),
            this.getCellObject(
              "formula",
              "##2!!19-(##2!!11+(##2!!13)+(##2!!14)+(##2!!15)+(##2!!16))"
            ),
            this.getCellObject("text", ""),
            this.getCellObject("text", ""),
            this.getCellObject(
              "text",
              data.endingValue +
                "- (" +
                data.startingValue +
                "+(" +
                data.multipleValueImpact +
                ")+(" +
                data.multipleImpact +
                ")+(" +
                data.fxImpact +
                "))"
            ),
          ]);
        } else {
          values.push([
            this.getCellObject("text", "Others Impact"),
            this.getCellObject("text", ""),
            this.getCellObject(
              "formula",
              "##2!!16-(##2!!9+(##2!!11)+(##2!!12)+(##2!!13))"
            ),
            this.getCellObject("text", ""),
            this.getCellObject("text", ""),
            this.getCellObject(
              "text",
              data.endingValue +
                "- (" +
                data.startingValue +
                "+(" +
                data.multipleValueImpact +
                ")+(" +
                data.multipleImpact +
                ")+(" +
                data.fxImpact +
                "))"
            ),
          ]);
        }
      }
    }

    values.push([
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
      this.getCellObject("text", ""),
    ]);

    if (this.settings.valueBridge == "NAV") {
      values.push([
        this.getCellObject("text", "NAV (New)"),
        this.getCellObject("text", ""),
        this.getCellObject("number", data.endingValue),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
      ]);
    } else if (this.settings.valueBridge == "EQUITY") {
      values.push([
        this.getCellObject("text", "EQUITY (New)"),
        this.getCellObject("text", ""),
        this.getCellObject("number", data.endingValue),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
      ]);
    } else {
      values.push([
        this.getCellObject("text", "ENTERPRISE (New)"),
        this.getCellObject("text", ""),
        this.getCellObject("number", data.endingValue),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
        this.getCellObject("text", ""),
      ]);
    }

    return {
      tabName: "Bridge " + responseDataIndex,
      data: { headers: headers, values: values },
    };
  }

  //  Value Track record Download
  downloadTrackRecordExcel() {
    if (this.isTrackRecordExcelDownloading) return;

    let fileData = this.getExcelTrackRecordBody();
    this.isTrackRecordExcelDownloading = true;

    this.ds.downloadExcelFileV2(fileData).subscribe(
      (fileData) => {
        this.manageFileDownload(fileData, "xlsx");
      },
      (err) => {
        console.log("Failed to download file", err);
        this.utilService.showMessage(
          this.translateService.getLabel("err_download_file"),
          this.translateService.getLabel("ok")
        );
        this.isTrackRecordExcelDownloading = false;
      }
    );
  }

  getExcelTrackRecordBody() {
    let data = this.trackRecord;
    console.log("trackRecord Excel", data);
    const values = [];
    const hiddenRows = [];

    // (Its mandatory) If any new row is added or if a row is deleted,
    // please make sure the below rows with "type": "formula" is updated accordingly.
    // So that on excel download the rows doesnt break.

    //  valuation date header for excel
    let headers = [
      "In" + " " + this.latestValDateTrackRecord.currency + " " + "Mn",
    ];
    this.trackRecord.forEach((row) => {
      headers.push(moment(row.valuationDate).format("ll"));
    });

    // let primaryMetric = [this.getValueBridgeMetricLabel(this.settings.metric),];
    // this.trackRecord.forEach((row)=>{primaryMetric.push(this.getSelectedTRMultiple(row, this.settings.metric, this.settings.period, 'adjustedMetric'))})

    // let secondaryMetric = [this.getSelectedTRMultipleLabel(this.savedData.trackRecordPreference.selectedMetrics[1])];
    // this.trackRecord.forEach((row)=>{secondaryMetric.push(this.getSelectedTRMultiple(row, this.savedData.trackRecordPreference.selectedMetrics[1], this.settings.period, 'adjustedMetric'))})

    // let primaryMetricMultiple = [this.getValueBridgeMetricLabel(this.settings.metric) + " " + "Multiple",];
    // this.trackRecord.forEach((row)=>{primaryMetricMultiple.push(this.getSelectedTRMultiple(row, this.settings.metric, this.settings.period, 'impliedMultiple'))})

    // let secondaryMetricMultiple = [this.getSelectedTRMultipleLabel(this.savedData.trackRecordPreference.selectedMetrics[1]) + " " + "Multiple",];
    // this.trackRecord.forEach((row)=>{secondaryMetricMultiple.push(this.getSelectedTRMultiple(row, this.savedData.trackRecordPreference.selectedMetrics[1], this.settings.period, 'impliedMultiple'))})

    let primaryMetric = [
      {
        type: "text",
        value:
          this.getValueBridgeMetricLabel(
            this.getTrackRecordMetric()?.firstMetric
          ) +
          " " +
          "(" +
          this.getSelectedPeriodLabel(
            this.getTrackRecordMetric()?.firstPeriod
          ) +
          ")",
      },
    ];
    this.trackRecord.forEach((row) => {
      primaryMetric.push(
        this.getCellObject(
          "number",
          this.getSelectedTRMultiple(
            row,
            this.getTrackRecordMetric()?.firstMetric,
            this.getTrackRecordMetric()?.firstPeriod,
            "adjustedMetric"
          ),
          "1"
        )
      );
    });

    let secondaryMetric = [];
    if (
      this.settings.secondaryMetric == this.getTrackRecordMetric()?.secondMetric
    ) {
      secondaryMetric = [
        {
          type: "text",
          value:
            this.getValueBridgeMetricLabel(
              this.getTrackRecordMetric()?.secondMetric
            ) +
            " " +
            "(" +
            this.getSelectedPeriodLabel(
              this.getTrackRecordMetric()?.secondaryPeriod
            ) +
            ")",
        },
      ];
      this.trackRecord.forEach((row) => {
        secondaryMetric.push({
          type: "number",
          value: this.getSelectedTRMultiple(
            row,
            this.getTrackRecordMetric()?.secondMetric,
            this.getTrackRecordMetric()?.secondaryPeriod,
            "adjustedMetric"
          ),
          decimal: "1",
        });
      });
    } else if (
      this.settings.secondaryMetric != this.getTrackRecordMetric()?.secondMetric
    ) {
      secondaryMetric = [
        {
          type: "text",
          value:
            this.getValueBridgeMetricLabel(
              this.getTrackRecordMetric()?.secondMetric
            ) +
            " " +
            "(" +
            this.getSelectedPeriodLabel(
              this.getTrackRecordMetric()?.secondaryPeriod
            ) +
            ")",
        },
      ];

      this.trackRecord.forEach((row) => {
        secondaryMetric.push({
          type: "number",
          value: this.getSelectedTRMultiple(
            row,
            this.getTrackRecordMetric()?.secondMetric,
            this.getTrackRecordMetric()?.secondaryPeriod,
            "adjustedMetric"
          ),
          decimal: "1",
        });
      });
    }

    let tertiaryMetric = [];
    if (
      this.settings.secondaryMetric == this.getTrackRecordMetric()?.thirdMetric
    ) {
      tertiaryMetric = [
        {
          type: "text",
          value:
            this.getValueBridgeMetricLabel(
              this.getTrackRecordMetric()?.thirdMetric
            ) +
            " " +
            "(" +
            this.getSelectedPeriodLabel(
              this.getTrackRecordMetric()?.tertiaryPeriod
            ) +
            ")",
        },
      ];
    } else if (
      this.settings.secondaryMetric != this.getTrackRecordMetric()?.thirdMetric
    ) {
      tertiaryMetric = [
        {
          type: "text",
          value:
            this.getValueBridgeMetricLabel(
              this.getTrackRecordMetric()?.thirdMetric
            ) +
            " " +
            "(" +
            this.getSelectedPeriodLabel(
              this.getTrackRecordMetric()?.tertiaryPeriod
            ) +
            ")",
        },
      ];
    }

    this.trackRecord.forEach((row) => {
      tertiaryMetric.push({
        type: "number",
        decimal: "1",
        value: this.getSelectedTRMultiple(
          row,
          this.getTrackRecordMetric()?.thirdMetric,
          this.getTrackRecordMetric()?.tertiaryPeriod,
          "adjustedMetric"
        ),
      });
    });

    let primaryMetricMultiple = [
      {
        type: "text",
        value:
          this.getValueBridgeMetricLabel(
            this.getTrackRecordMetric()?.firstMetric
          ) +
          " " +
          "Multiple" +
          " " +
          "(" +
          this.getSelectedPeriodLabel(
            this.getTrackRecordMetric()?.firstPeriod
          ) +
          ")",
      },
    ];
    this.trackRecord.forEach((row) => {
      primaryMetricMultiple.push(
        this.getCellObject(
          "number",
          this.getSelectedTRMultiple(
            row,
            this.getTrackRecordMetric()?.firstMetric,
            this.getTrackRecordMetric()?.firstPeriod,
            "impliedMultiple"
          ),
          "1"
        )
      );
    });

    let secondaryMetricMultiple = [];
    if (
      this.settings.secondaryMetric == this.getTrackRecordMetric()?.secondMetric
    ) {
      secondaryMetricMultiple = [
        {
          type: "text",
          value:
            this.getValueBridgeMetricLabel(
              this.getTrackRecordMetric()?.secondMetric
            ) +
            " " +
            "Multiple" +
            " " +
            "(" +
            this.getSelectedPeriodLabel(
              this.getTrackRecordMetric()?.secondaryPeriod
            ) +
            ")",
        },
      ];
      this.trackRecord.forEach((row) => {
        secondaryMetricMultiple.push({
          type: "number",
          decimal: "1",
          value: this.getSelectedTRMultiple(
            row,
            this.getTrackRecordMetric()?.secondMetric,
            this.getTrackRecordMetric()?.secondaryPeriod,
            "impliedMultiple"
          ),
        });
      });
    } else if (
      this.settings.secondaryMetric != this.getTrackRecordMetric()?.secondMetric
    ) {
      secondaryMetricMultiple = [
        {
          type: "text",
          value:
            this.getValueBridgeMetricLabel(
              this.getTrackRecordMetric()?.secondMetric
            ) +
            " " +
            "Multiple" +
            " " +
            "(" +
            this.getSelectedPeriodLabel(
              this.getTrackRecordMetric()?.secondaryPeriod
            ) +
            ")",
        },
      ];
      this.trackRecord.forEach((row) => {
        secondaryMetricMultiple.push({
          type: "number",
          value: this.getSelectedTRMultiple(
            row,
            this.getTrackRecordMetric()?.secondMetric,
            this.getTrackRecordMetric()?.secondaryPeriod,
            "impliedMultiple"
          ),
          decimal: "1",
        });
      });
    }

    let tertiaryMetricMultiple = [];
    if (
      this.settings.secondaryMetric == this.getTrackRecordMetric()?.thirdMetric
    ) {
      tertiaryMetricMultiple = [
        {
          type: "text",
          value:
            this.getValueBridgeMetricLabel(
              this.getTrackRecordMetric()?.thirdMetric
            ) +
            " " +
            "Multiple" +
            " " +
            "(" +
            this.getSelectedPeriodLabel(
              this.getTrackRecordMetric()?.tertiaryPeriod
            ) +
            ")",
          decimal: "1",
        },
      ];
    } else if (
      this.settings.secondaryMetric != this.getTrackRecordMetric()?.thirdMetric
    ) {
      tertiaryMetricMultiple = [
        {
          type: "text",
          value:
            this.getValueBridgeMetricLabel(
              this.getTrackRecordMetric()?.thirdMetric
            ) +
            " " +
            "Multiple" +
            " " +
            "(" +
            this.getSelectedPeriodLabel(
              this.getTrackRecordMetric()?.tertiaryPeriod
            ) +
            ")",
          decimal: "1",
        },
      ];
    }
    this.trackRecord.forEach((row) => {
      tertiaryMetricMultiple.push({
        type: "number",
        value: this.getSelectedTRMultiple(
          row,
          this.getTrackRecordMetric()?.thirdMetric,
          this.getTrackRecordMetric()?.tertiaryPeriod,
          "impliedMultiple"
        ),
        decimal: "1",
      });
    });

    let enterpriseValue: any[] = [{ type: "text", value: "Enterprise Value" }];
    this.trackRecord.forEach((row) => {
      enterpriseValue.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(
          row.enterpriseValue
        ),
        decimal: "1",
      });
    });

    let netDebt: any[] = [
      { type: "text", value: "Net Debt and Other Balance Sheet Adjustments" },
    ];
    this.trackRecord.forEach((row) => {
      netDebt.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(row.netDebt),
        decimal: "1",
      });
    });

    // (Its mandatory) If any new row is added or if a row is deleted,
    // please make sure the below rows with "type": "formula" is updated accordingly.
    // So that on excel download the rows doesnt break.

    let equityValue: any[] = [{ type: "text", value: "Equity Value (100%)" }];
    if (this.getTrackRecordMetric()?.thirdMetric) {
      this.trackRecord.forEach((row, index) => {
        let i = index + 1;
        equityValue.push({
          type: "formula",
          value: "##" + i + "!!9+##" + i + "!!10",
          decimal: "1",
        });
      });
    } else {
      this.trackRecord.forEach((row, index) => {
        let i = index + 1;
        equityValue.push({
          type: "formula",
          value: "##" + i + "!!7+##" + i + "!!8",
          decimal: "1",
        });
      });
    }

    let adjustmentsToEquityValue: any[] = [
      { type: "text", value: "Adjustments to Equity Value" },
    ];
    this.trackRecord.forEach((row) => {
      adjustmentsToEquityValue.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(
          row.adjustmentsToEquityValue
        ),
      });
    });

    let adjustedConcludedEquityValueInValuationSummary: any[] = [
      { type: "text", value: "Adjusted Equity Value (100%)" },
    ];
    this.trackRecord.forEach((row) => {
      adjustedConcludedEquityValueInValuationSummary.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(
          row.adjustedConcludedEquityValueInValuationSummary
        ),
        decimal: "1",
      });
    });

    let adjustedConcludedEquityValueInWaterfall: any[] = [
      { type: "text", value: "Adjusted Equity Value (Waterfall)" },
    ];
    this.trackRecord.forEach((row) => {
      adjustedConcludedEquityValueInWaterfall.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(
          row.adjustedConcludedEquityValueInWaterfall
        ),
      });
    });

    let stake: any[] = [{ type: "text", value: "Stake (%)" }];
    this.trackRecord.forEach((row) => {
      stake.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(row.stake),
      });
    });

    // (Its mandatory) If any new row is added or if a row is deleted,
    // please make sure the below rows with "type": "formula" is updated accordingly.
    // So that on excel download the rows doesnt break.

    let stakeValue: any[] = [{ type: "text", value: "Stake Equity Value" }];
    if (this.getTrackRecordMetric()?.thirdMetric) {
      this.trackRecord.forEach((row, index) => {
        let i = index + 1;
        if (row.adjustedConcludedEquityValueInWaterfall != 0) {
          stakeValue.push({
            type: "formula",
            value: "(##" + i + "!!14*##" + i + "!!15)/100",
          });
        } else {
          stakeValue.push({
            type: "formula",
            value: "(##" + i + "!!13*##" + i + "!!15)/100",
          });
        }
      });
    } else {
      this.trackRecord.forEach((row, index) => {
        let i = index + 1;
        if (row.adjustedConcludedEquityValueInWaterfall != 0) {
          stakeValue.push({
            type: "formula",
            value: "(##" + i + "!!12*##" + i + "!!13)/100",
            decimal: "1",
          });
        } else {
          stakeValue.push({
            type: "formula",
            value: "(##" + i + "!!11*##" + i + "!!13)/100",
            decimal: "1",
          });
        }
      });
    }

    let stakeEquityAdjustments: any[] = [
      { type: "text", value: "Adjustment to Stake Equity Value" },
    ];
    this.trackRecord.forEach((row) => {
      stakeEquityAdjustments.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(
          row.stakeEquityAdjustments
        ),
        decimal: "1",
      });
    });

    let stakeAdjustedEquityValue: any[] = [{ type: "text", value: "NAV" }];

    if (this.getTrackRecordMetric()?.thirdMetric) {
      this.trackRecord.forEach((row, index) => {
        let i = index + 1;
        stakeAdjustedEquityValue.push({
          type: "formula",
          value: "##" + i + "!!16+##" + i + "!!18",
          decimal: "1",
        });
      });
    } else {
      this.trackRecord.forEach((row, index) => {
        let i = index + 1;
        stakeAdjustedEquityValue.push({
          type: "formula",
          value: "##" + i + "!!14+##" + i + "!!16",
          decimal: "1",
        });
      });
    }

    let investmentAmount: any[] = [{ type: "text", value: "Invested Amount" }];
    this.trackRecord.forEach((row) => {
      investmentAmount.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(
          row.investmentAmount
        ),
        decimal: "1",
      });
    });

    let realisedProceeds: any[] = [
      { type: "text", value: "Realised Proceeds" },
    ];
    this.trackRecord.forEach((row) => {
      realisedProceeds.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(
          row.realisedProceeds
        ),
        decimal: "1",
      });
    });

    // (Its mandatory) If any new row is added or if a row is deleted,
    // please make sure the below rows with "type": "formula" is updated accordingly.
    // So that on excel download the rows doesnt break.

    let moic: any[] = [{ type: "text", value: "MOIC" }];
    if (this.getTrackRecordMetric()?.thirdMetric) {
      this.trackRecord.forEach((row, index) => {
        let i = index + 1;
        moic.push(
          row.investmentAmount > 0
            ? {
                type: "formula",
                value: "(##" + i + "!!19+##" + i + "!!21)/##" + i + "!!20",
              }
            : { type: "number", value: 0 }
        );
      });
    } else {
      this.trackRecord.forEach((row, index) => {
        let i = index + 1;
        moic.push(
          row.investmentAmount > 0
            ? {
                type: "formula",
                value: "(##" + i + "!!17+##" + i + "!!19)/##" + i + "!!18",
              }
            : { type: "number", value: 0 }
        );
      });
    }

    let dpi: any[] = [{ type: "text", value: "DPI" }];
    if (this.getTrackRecordMetric()?.thirdMetric) {
      this.trackRecord.forEach((row, index) => {
        let i = index + 1;
        dpi.push(
          row.investmentAmount > 0
            ? { type: "formula", value: "##" + i + "!!21/##" + i + "!!20" }
            : { type: "number", value: 0 }
        );
      });
    } else {
      this.trackRecord.forEach((row, index) => {
        let i = index + 1;
        dpi.push(
          row.investmentAmount > 0
            ? { type: "formula", value: "##" + i + "!!19/##" + i + "!!18" }
            : { type: "number", value: 0 }
        );
      });
    }

    let grossIRR_YTD: any[] = [{ type: "text", value: "YTD IRR (%)" }];
    this.trackRecord.forEach((row) => {
      grossIRR_YTD.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(row.grossIRR_YTD),
        decimal: "1",
      });
    });


    let grossIRR: any[] = [{ type: "text", value: "IRR" }];
    this.trackRecord.forEach((row) => {
      grossIRR.push({
        type: "number",
        value: this.utilService.getValueForExcelWithNegatives(row.grossIRR),
        decimal: "1",
      });
    });

    // if(this.trService.preferences[this.settings.metric]){values.push(primaryMetric);}

    // if(this.trService.preferences[this.savedData.trackRecordPreference.selectedMetrics[1]]){values.push(secondaryMetric);}

    // if(this.trService.preferences[this.settings.metric + 'Multiple']){values.push(primaryMetricMultiple);}

    // if(this.trService.preferences[this.savedData.trackRecordPreference.selectedMetrics[1] + 'Multiple']){values.push(secondaryMetricMultiple);}

    values.push(primaryMetric);
    if (
      !this.trService.preferences[
        this.getTrackRecordMetric()?.firstMetric.concat("_primary")
      ]
    ) {
      hiddenRows.push(1);
    }

    values.push(secondaryMetric);
    if (
      !this.trService.preferences[
        this.getTrackRecordMetric()?.secondMetric.concat("_secondary")
      ]
    ) {
      hiddenRows.push(2);
    }

    if (this.getTrackRecordMetric()?.thirdMetric) {
      values.push(tertiaryMetric);
      if (
        !this.trService.preferences[
          this.getTrackRecordMetric()?.thirdMetric.concat("_tertiary")
        ]
      ) {
        hiddenRows.push(3);
      }
    }

    values.push(primaryMetricMultiple);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (
        !this.trService.preferences[
          this.getTrackRecordMetric()?.firstMetric + "Multiple" + "_primary"
        ]
      ) {
        hiddenRows.push(4);
      }
    } else {
      if (
        !this.trService.preferences[
          this.getTrackRecordMetric()?.firstMetric + "Multiple" + "_primary"
        ]
      ) {
        hiddenRows.push(3);
      }
    }

    values.push(secondaryMetricMultiple);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (
        !this.trService.preferences[
          this.getTrackRecordMetric()?.secondMetric + "Multiple" + "_secondary"
        ]
      ) {
        hiddenRows.push(5);
      }
    } else {
      if (
        !this.trService.preferences[
          this.getTrackRecordMetric()?.secondMetric + "Multiple" + "_secondary"
        ]
      ) {
        hiddenRows.push(4);
      }
    }

    if (this.getTrackRecordMetric()?.thirdMetric) {
      values.push(tertiaryMetricMultiple);
      if (
        !this.trService.preferences[
          this.getTrackRecordMetric()?.thirdMetric + "Multiple" + "_tertiary"
        ]
      ) {
        hiddenRows.push(6);
      }
    }

    values.push([
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
    ]);

    values.push(enterpriseValue);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["enterpriseValue"]) {
        hiddenRows.push(8);
      }
    } else {
      if (!this.trService.preferences["enterpriseValue"]) {
        hiddenRows.push(6);
      }
    }

    values.push(netDebt);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["netDebt"]) {
        hiddenRows.push(9);
      }
    } else {
      if (!this.trService.preferences["netDebt"]) {
        hiddenRows.push(7);
      }
    }

    values.push(equityValue);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["equityValue"]) {
        hiddenRows.push(10);
      }
    } else {
      if (!this.trService.preferences["equityValue"]) {
        hiddenRows.push(8);
      }
    }

    values.push(adjustmentsToEquityValue);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["adjustmentsToEquityValue"]) {
        hiddenRows.push(11);
      }
    } else {
      if (!this.trService.preferences["adjustmentsToEquityValue"]) {
        hiddenRows.push(9);
      }
    }

    values.push(adjustedConcludedEquityValueInValuationSummary);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["adjustedEquityValue"]) {
        hiddenRows.push(12);
      }
    } else {
      if (!this.trService.preferences["adjustedEquityValue"]) {
        hiddenRows.push(10);
      }
    }

    values.push(adjustedConcludedEquityValueInWaterfall);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["adjustedEquityValueWaterfall"]) {
        hiddenRows.push(13);
      }
    } else {
      if (!this.trService.preferences["adjustedEquityValueWaterfall"]) {
        hiddenRows.push(11);
      }
    }

    values.push(stake);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["stake"]) {
        hiddenRows.push(14);
      }
    } else {
      if (!this.trService.preferences["stake"]) {
        hiddenRows.push(12);
      }
    }

    values.push(stakeValue);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["stakeValue"]) {
        hiddenRows.push(15);
      }
    } else {
      if (!this.trService.preferences["stakeValue"]) {
        hiddenRows.push(13);
      }
    }

    values.push([
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
    ]);

    values.push(stakeEquityAdjustments);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["debtAndOthers"]) {
        hiddenRows.push(17);
      }
    } else {
      if (!this.trService.preferences["debtAndOthers"]) {
        hiddenRows.push(15);
      }
    }

    values.push(stakeAdjustedEquityValue);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["value"]) {
        hiddenRows.push(18);
      }
    } else {
      if (!this.trService.preferences["value"]) {
        hiddenRows.push(16);
      }
    }

    values.push(investmentAmount);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["investedAmount"]) {
        hiddenRows.push(19);
      }
    } else {
      if (!this.trService.preferences["investedAmount"]) {
        hiddenRows.push(17);
      }
    }

    values.push(realisedProceeds);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["realisedProceeds"]) {
        hiddenRows.push(20);
      }
    } else {
      if (!this.trService.preferences["realisedProceeds"]) {
        hiddenRows.push(18);
      }
    }

    values.push(moic);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["investmentMultiple"]) {
        hiddenRows.push(21);
      }
    } else {
      if (!this.trService.preferences["investmentMultiple"]) {
        hiddenRows.push(19);
      }
    }

    values.push(dpi);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["dpi"]) {
        hiddenRows.push(22);
      }
    } else {
      if (!this.trService.preferences["dpi"]) {
        hiddenRows.push(20);
      }
    }

    values.push(grossIRR_YTD);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["irr_YTD"]) {
        hiddenRows.push(23);
      }
    } else {
      if (!this.trService.preferences["irr_YTD"]) {
        hiddenRows.push(21);
      }
    }

    values.push(grossIRR);
    if (this.getTrackRecordMetric()?.thirdMetric) {
      if (!this.trService.preferences["irr"]) {
        hiddenRows.push(24);
      }
    } else {
      if (!this.trService.preferences["irr"]) {
        hiddenRows.push(22);
      }
    }

    values.push([
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
    ]);
    if (this.getCustomAttributesStatus()) {
      values.push([
        { type: "text", value: "Custom Attributes" },
        this.getBlankCell(),
        this.getBlankCell(),
        this.getBlankCell(),
        this.getBlankCell(),
        this.getBlankCell(),
        this.getBlankCell(),
      ]);
    }
    values.push([
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
      this.getBlankCell(),
    ]);

    // Custom Attributes Entries

    this.customAttributesEntries = [];

    this.uniqueAttributesList.forEach((row) => {
      let customAttributesValues = [];
      let customAttributesNames = [];
      if (this.trService.preferences[row.attributeKey]) {
        customAttributesNames.push(row.attributeName + " ");
        this.trackRecord.forEach((valuation) => {
          if (
            this.attributesData[valuation.formId] &&
            this.attributesData[valuation.formId][row.attributeKey]
          ) {
            if (this.attributesData[valuation.formId][row.attributeKey].value) {
              customAttributesValues.push(
                this.attributesData[valuation.formId][row.attributeKey].value
              );
            } else {
              customAttributesValues.push("-");
            }
          } else {
            customAttributesValues.push("-");
          }
        });
        this.customAttributesEntries.push(
          customAttributesNames.concat(customAttributesValues)
        );
      }
    });

    if (this.customAttributesEntries) {
      this.customAttributesEntries.forEach((element) => {
        let customValues = [];
        element.forEach((item) => {
          customValues.push({ type: "text", value: item });
        });

        values.push(customValues);
      });
    }

    return [
      {
        tabName: "Excel Download",
        data: { headers: headers, values: values, hiddenRows: hiddenRows },
      },
    ];
  }

  getBlankCell() {
    const obj = {
      type: "text",
      value: "",
    };

    return obj;
  }

  downloadMultipleEvolutionChart() {
    this.isMultipleEvolutionChartExcelDownloading = true;

    const reqBody = this.getReqBodyForMarketEvolutionChart(
      this.buMarketMultiple
    );

    this.ds.downloadEvolutionChartData(reqBody).subscribe(
      (res) => {
        let fileData = this.getExcelMultipleEvolutionChartBody(
          res.body["response"],
          reqBody.forms
        );

        this.ds.downloadExcelFile(fileData).subscribe(
          (fileData) => {
            this.manageFileDownload(fileData, "xlsx");
          },
          (err) => {
            console.log("Failed to download file", err);
            this.utilService.showMessage(
              "Sorry! Not able to download the file. Please contact us.",
              "Ok"
            );
            this.isMultipleEvolutionChartExcelDownloading = false;
          }
        );
      },
      (error) => {
        console.log("Failed to download the Excel");
        this.isMultipleEvolutionChartExcelDownloading = false;
        this.utilService.showMessage(
          "Failed to download the Excel",
          "Ok",
          true
        );
      }
    );
  }

  getLTMTypeLabel(latestValDateDetails) {
    return latestValDateDetails.LTMType.periodType == "SPOT"
      ? "SPOT"
      : latestValDateDetails.LTMType.periodValue +
          " - " +
          latestValDateDetails.LTMType.periodType +
          " - " +
          latestValDateDetails.LTMType.frequency;
  }

  getAggregationLabel(key) {
    switch (key) {
      case "average":
        return "Average";
      case "median":
        return "Median";
      case "q1":
        return "1st Quartile";
      case "q3":
        return "3rd Quartile";
      case "min":
        return "Low";
      case "max":
        return "High";
    }
  }

  getExcelMultipleEvolutionChartBody(response, forms) {
    const values = [];
    const headers = [];
    const chartData = this.prepareDataForEvolutionChart();

    forms = forms.sort((f1, f2) => {
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date ? 0 : f1Date > f2Date ? 1 : -1;
    });

    values.push([
      "LTM Type",
      this.getLTMTypeLabel(response.latestValDateDetails),
      "",
    ]);

    values.push([
      "Multiple",
      this.invSummaryService.getMarketMultipleLabelForEvolutionChartByKey(
        this.marketMultiple
      ),
      "",
      "",
    ]);

    values.push([
      "Conlcuded On",
      this.getAggregationLabel(
        response.latestValDateDetails.selectedAggregation
      ),
      "",
    ]);

    values.push([
      "Period",
      this.getSelectedPeriodLabel(this.settings.period),
      "",
    ]);

    chartData.xAxis.unshift("");

    values.push(["", ""]);

    chartData.median = chartData.median.map((row) => {
      return this.utilService.getValueForExcelWithNegatives(row);
    });
    chartData.median.unshift("Median (x)");

    chartData.average = chartData.average.map((row) => {
      return this.utilService.getValueForExcelWithNegatives(row);
    });
    chartData.average.unshift("Average (x)");

    chartData.firstQuartile = chartData.firstQuartile.map((row) => {
      return this.utilService.getValueForExcelWithNegatives(row);
    });
    chartData.firstQuartile.unshift("First Quartile (x)");

    chartData.thirdQuartile = chartData.thirdQuartile.map((row) => {
      return this.utilService.getValueForExcelWithNegatives(row);
    });
    chartData.thirdQuartile.unshift("Third Quartile (x)");

    chartData.adjustmentFactor = chartData.adjustmentFactor.map((row) => {
      return this.utilService.getValueForExcelWithNegatives(row * 100);
    });
    chartData.adjustmentFactor.unshift("Discount/Premium (%)");

    chartData.subjectComp = chartData.subjectComp.map((row) => {
      return this.utilService.getValueForExcelWithNegatives(row);
    });
    chartData.subjectComp.unshift("Implied Multiple (x)");

    values.push(chartData.xAxis);

    const similarComps =
      response.formWiseSimilarCompsAndYears[forms[0].id].similarCompanies;

    similarComps.forEach((comp) => {
      const rowWiseValues = [];

      rowWiseValues.push(comp.companyName);
      forms.forEach((form) => {
        const formWiseSimilarCompsAndYears =
          response.formWiseSimilarCompsAndYears[form.id].similarCompanies;
        const compExists = formWiseSimilarCompsAndYears.find(
          (c) => c.companyId == comp.companyId
        );
        rowWiseValues.push(
          compExists && compExists[this.marketMultiple]
            ? this.utilService.getValueForExcelWithNegatives(
                compExists[this.marketMultiple]
              )
            : "0.0"
        );
      });

      if (this.marketDataForAdditionalDate.similarCompanies) {
        const compExists =
          this.marketDataForAdditionalDate.similarCompanies.find(
            (c) => c.companyId == comp.companyId
          );

        const year = this.getActualYearFromTheListOfYearByPeriod(
          this.settings.period,
          this.marketDataForAdditionalDate.years
        );

        rowWiseValues.push(
          compExists && compExists[this.marketMultiple][year]
            ? this.utilService.getValueForExcelWithNegatives(
                compExists[this.marketMultiple][year]
              )
            : "0.0"
        );
      }

      values.push(rowWiseValues);
    });
    values.push(["", ""]);
    values.push(chartData.median);
    values.push(chartData.average);
    values.push(chartData.firstQuartile);
    values.push(chartData.thirdQuartile);
    values.push(chartData.adjustmentFactor);
    values.push(chartData.subjectComp);

    return [
      { tabName: "Excel Download", data: { headers: headers, values: values } },
    ];
  }

  manageFileDownload(fileData, type) {
    const currentDate = this.datePipe.transform(new Date(), "dd-MMM-yyyy");
    const valueBridgeType = this.settings.valueBridge;

    try {
      const url = (window as any).URL.createObjectURL(fileData.body);
      this.downloadLink.nativeElement.href = url;
      this.downloadLink.nativeElement.target = "_blank";

      if (this.isTrackRecordExcelDownloading) {
        this.downloadLink.nativeElement.download =
          this.fundListService.getFundName(this.fundId) +
          "_" +
          this.formData.GENERAL.GD_General_Details.GD_CN_COMPANY_NAME +
          "_" +
          this.latestValuationDate.valuationDate +
          "_" +
          "TrackRecord." +
          type;

        this.isTrackRecordExcelDownloading = false;
      } else if (this.isMultipleEvolutionChartExcelDownloading) {
        this.downloadLink.nativeElement.download =
          this.fundListService.getFundName(this.fundId) +
          "_" +
          this.formData.GENERAL.GD_General_Details.GD_CN_COMPANY_NAME +
          "_" +
          this.latestValuationDate.valuationDate +
          "_" +
          "MultipleEvolution." +
          type;

        this.isMultipleEvolutionChartExcelDownloading = false;
      } else {
        this.downloadLink.nativeElement.download =
          this.formData.GENERAL.GD_General_Details.GD_CN_COMPANY_NAME +
          "_" +
          currentDate +
          "_" +
          valueBridgeType +
          "_Bridge." +
          type;
      }

      this.downloadLink.nativeElement.click();
    } catch (e) {
      console.log("Failed to download file", e);
      this.utilService.showMessage(
        this.translateService.getLabel("err_download_file"),
        this.translateService.getLabel("ok")
      );
    }

    this.isVBExcelDownloading = false;
  }

  // -------------------------------------------------------------
  // Upper Limit for Valuation date
  // Investment Summary is plotted as on this date (upper limit)
  // -------------------------------------------------------------

  saveValDateUpperLimit(popUp) {
    if(popUp){
      popUp.dismiss("Close Popup");
    }

    this.utilService.showLoadingPopup();

    const oldValDateUpperLimit = this.valDateUpperLimit;

    this.valDateUpperLimit = this.valDateUpperLimitUserInput;

    this.upperLimitChanged.emit(this.valDateUpperLimit);
    // this.ds.saveWidgetDataToDB("VAL_DATES_UPPERLIMIT", { limit: this.valDateUpperLimitUserInput }, this.companyId).subscribe(res => {
    //   window.location.reload();

    // },error =>{
    //   console.log("error while saving VAL_DATES_UPPERLIMIT", error);

    //   this.utilService.showMessage("Failed to refresh the page. Please try later.", "Ok", true);
    //   this.valDateUpperLimit = oldValDateUpperLimit;
    // })
  }

  addPartnerList() {
    if (this.partnerList.name) {
      this.masterPartnersList.push(this.partnerList);
      if (this.partnerList.isSelected) {
        if (!this.savedData.partners) {
          this.savedData.partners = [];
        }
        this.savedData.partners.push(this.partnerList);
      }
    }

    this.partnerList = {
      name: "",
      isSelected: false,
    };

    this.saveData();
  }

  getCustomAttributesStatus() {
    let isCustomAttributesSelected = false;
    this.uniqueAttributesList.forEach((row) => {
      if (this.trService.preferences[row.attributeKey]) {
        isCustomAttributesSelected = true;
        return;
      }
    });
    return isCustomAttributesSelected;
  }

  translateCustomAttributes(attribute) {
    if (
      attribute.attributeKey === "no_of_employees" ||
      attribute.attributeKey === "key_people" ||
      attribute.attributeKey === "security_type" ||
      attribute.attributeKey === "partners"
    ) {
      return this.translateService.getLabel(attribute.attributeKey);
    } else {
      return attribute.attributeName;
    }
  }

  getYearTranslation(year) {
    switch (year) {
      case "LTM":
        return this.translateService.getLabel("ltm");
      case "NTM":
        return this.translateService.getLabel("ntm");
      case "NTM+1":
        return this.translateService.getLabel("ntm") + " + 1";
      default:
        return year;
    }
  }

  createGeneralAttributesData(){
    this.popupState = undefined;

    this.showHideStatusOfGeneralAttributesClone = cloneDeep(this.showHideStatusOfGeneralAttributes);
    
    const generalAttributeData = {
      location: this.countryName,
      sector: this.companySector,
      website: this.formData.GENERAL.GD_General_Details.GD_WEBSITE,
      investmentDate : this.invSummaryService.investmentDateValuation.valuationDate,
      intialInvestment : this.trackRecord[0].initialInvestment,
      investmentToDate : this.latestValDateTrackRecord.investmentAmount,
      irr : this.latestValDateTrackRecord.grossIRR,
      moic : this.latestValDateTrackRecord.moic,
      realisedproceeds : this.latestValDateTrackRecord.realisedProceeds,

      impliedebitdamultiple : this.summaryNumbers.subjectCompBevEbitda,
      impliedrevenuemultiple :this.summaryNumbers.subjectCompBevRevenue,
      impliedebitmultiple :this.summaryNumbers.subjectCompBevEbit,
      impliedgrossprofitmultiple : this.summaryNumbers.subjectCompEVGrossProfit,
      
      revenue:this.summaryNumbers.revennue,
      ebitda :this.summaryNumbers.ebitda,
      ebitdamargin :this.summaryNumbers.ebitdaMargin,
      grossprofit : this.summaryNumbers.grossProfit,
      ebitmargin : this.summaryNumbers.ebitMargin,
      ebit : this.summaryNumbers.ebit,

      impliedpriceearnings : this.summaryNumbers.subjectCompPe,
      impliedpricebookvalue : this.summaryNumbers.subjectCompPbv,
      impliedpricesales: this.summaryNumbers.subjectCompPsales,

      priceearnings : this.summaryNumbers.pe,
      pricebookvalue : this.summaryNumbers.pbv,
      pricesales: this.summaryNumbers.psales,
      
      impliedownership : this.summaryNumbers.fullyDilutedOwnership
    }

    setTimeout(() => {
      this.generalAttributeData = generalAttributeData;
      this.popupState = "OPEN";
    }, 800);
  }

  initDefaultShowHideStatusOfGeneralAttrs(){
    const showHideStatusOfGeneralAttributes = {}
    this.utilService.GENERAL_ATTRIBUTES_KEYS.forEach(attr => {
      switch(attr){
        case "impliedebitdamultiple": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.subjectCompBevEbitda && this.summaryNumbers.subjectCompBevEbitda > 0)
          }
          break;

        case "impliedrevenuemultiple": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.subjectCompBevRevenue && this.summaryNumbers.subjectCompBevRevenue > 0)
          }
          break;
          
        case "impliedebitmultiple": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.subjectCompBevEbit && this.summaryNumbers.subjectCompBevEbit > 0)
          }
          break;


        case "impliedgrossprofitmultiple": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.subjectCompEVGrossProfit && this.summaryNumbers.subjectCompEVGrossProfit > 0)
          }
          break;

        case "revenue": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.revennue && this.summaryNumbers.revennue > 0)
          }
          break;

        case "ebitda": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.ebitda && this.summaryNumbers.ebitda > 0)
          }
          break;

        case "ebitdamargin": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.ebitdaMargin && this.summaryNumbers.ebitdaMargin > 0)
          }
          break;

        case "ebitmargin": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.ebitMargin && this.summaryNumbers.ebitMargin > 0)
          }
          break;
          
        case "ebit": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.ebit && this.summaryNumbers.ebit > 0)
          }
          break;
          
        case "grossprofit": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.grossProfit && this.summaryNumbers.grossProfit > 0)
          }
          break;

        case "priceearnings": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.pe && this.summaryNumbers.pe > 0 && this.latestValDateTrackRecord.ccm_selected_multiples 
              && this.latestValDateTrackRecord.ccm_selected_multiples.indexOf('pe'))
          }
          break;
          
        case "pricebookvalue": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.pbv && this.summaryNumbers.pbv > 0 && this.latestValDateTrackRecord.ccm_selected_multiples 
              && this.latestValDateTrackRecord.ccm_selected_multiples.indexOf('pbv'))
          }
          break;

        case "pricesales": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.psales && this.summaryNumbers.psales > 0 && this.latestValDateTrackRecord.ccm_selected_multiples 
              && this.latestValDateTrackRecord.ccm_selected_multiples.indexOf('psales'))
          }
          break;

        case "impliedpricesales": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.subjectCompPsales && this.summaryNumbers.subjectCompPsales > 0 && this.latestValDateTrackRecord.ccm_selected_multiples 
              && this.latestValDateTrackRecord.ccm_selected_multiples.indexOf('psales'))
          }
          break;
          
        case "impliedpriceearnings": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.subjectCompPe && this.summaryNumbers.subjectCompPe > 0 && this.latestValDateTrackRecord.ccm_selected_multiples 
              && this.latestValDateTrackRecord.ccm_selected_multiples.indexOf('pe'))
          }
          break;

        case "impliedpricebookvalue": 
          showHideStatusOfGeneralAttributes[attr] = {
            show: (Boolean)(this.summaryNumbers.subjectCompPbv && this.summaryNumbers.subjectCompPbv > 0 && this.latestValDateTrackRecord.ccm_selected_multiples 
              && this.latestValDateTrackRecord.ccm_selected_multiples.indexOf('pbv'))
          }
          break; 
        
        default :
          showHideStatusOfGeneralAttributes[attr] = {show: true}
          break;
      }
    });

    this.showHideStatusOfGeneralAttributes = cloneDeep(showHideStatusOfGeneralAttributes);
    this.showHideStatusOfGeneralAttributesClone = cloneDeep(showHideStatusOfGeneralAttributes);
  }

  updateGeneralAttrsStatus(event){
    this.showHideStatusOfGeneralAttributes = cloneDeep(event);
    this.showHideStatusOfGeneralAttributesClone =  cloneDeep(this.showHideStatusOfGeneralAttributes);

    this.saveShoHideAttrsData();
  }
  
  async fetchAdvanceNavBridgeFilterData(forms){
    const formIds = forms.map(f => f.formId);

    let reqbody = {
      "formIds" : formIds,
      "attrKeys" : ['selectedAlgorithms']
    }
    try{
      const res = await this.ds.getAdvanceNavBridgeFilterData(reqbody).toPromise();
      this.valDateWiseAlgorithmList = res.body['response']
      this.uniqueListOfAlgorithmsForValueBridge = this.valDateWiseAlgorithmList.uniqueAlgorithmsList    
    }
    catch(err) {
      console.log("Error While fetching Date wise Algorithms List with Uniqueness", err)
    }

    this.algorithmFiltersForNAVBridge = [
      {
        "key": "VALUATION_METHOD",
        "name": "Valuation Method",
        "selected": true,
        "expanded": false,
        "filtered": true,
        "type": "METHOD",
        "children": []     
      }
    ]

    if(this.uniqueListOfAlgorithmsForValueBridge && this.uniqueListOfAlgorithmsForValueBridge.length > 0){
      this.uniqueListOfAlgorithmsForValueBridge.forEach(eachAlgo =>{ 
        if(eachAlgo.name == 'Comparable Company Method' || eachAlgo.name == "Comparable Transaction Method"){
         this.algorithmFiltersForNAVBridge = this.utilService.formatFilterAlgorithmnArrayForCTM_CCM(this.algorithmFiltersForNAVBridge, 
          eachAlgo.name)
        }
        else if(eachAlgo.name == 'Income Approach'){
          this.algorithmFiltersForNAVBridge = this.utilService.formatFilterAlgorithmnArrayforIncomeApproach(this.algorithmFiltersForNAVBridge, 
            eachAlgo.name)
        }
        else{
          this.algorithmFiltersForNAVBridge = this.utilService.formatFilterAlgorithmnArrayforOtherAlgorithmns(this.algorithmFiltersForNAVBridge, 
            eachAlgo.name)
        }
      });

      for (let algo of this.algorithmFiltersForNAVBridge) {
        const isSectionUnderAlgoSelected = algo.children.filter((section) => section.selected);
        if (isSectionUnderAlgoSelected && isSectionUnderAlgoSelected.length == algo.children.length) {
          algo.selected = true;
        }
      }
    }
  }
}
