import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserManagementService } from '../services/user-management.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-recon-dashboard',
  templateUrl: './data-recon-dashboard.component.html',
  styleUrls: ['./data-recon-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataReconDashboardComponent implements OnInit{

    constructor(private ums: UserManagementService,private router: Router) { 
    }

  ngOnInit(): void {

    if(!this.ums.is73sAdminAndMasterUser()) {      
      this.router.navigateByUrl('/fundList');
    }
  }

}
