import {Component} from '@angular/core';
import { DataService } from '../services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { UserManagementService } from '../services/user-management.service';
import { Router } from '@angular/router';

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrls: ['/admin.component.scss']
})

export class AdminComponent{

    companyName: String = '';
    companyMappingName: String = '';

    companyType: String = 'PME';
    reprocessFileType:String = "PME";
    uploadFileType: String = 'PME';
    companyOrganizationName: String = 'Eurazeo';
    updateCompanyName: String = '';
    updateCompanyMappingName: String = '';
    updateCompanyType: String = '';
    fileToUpload:File = null;
    updateCompanyOrganizationName: String = 'Eurazeo';
    selectedCompany = null;
    reprocessFileButton: Boolean = false;

    selectedCompanyId = null;
    allAvailableFilesList:Array<String> = [];

    allCompanyDetails = [];
    sftpFilesButtonDisabled = false;

    constructor(private dataService:DataService,private utilService: UtilService, private ums: UserManagementService, private router: Router){


    }

    ngOnInit(){

        console.log("Admin component");

        if(!this.ums.is73sAdminAndMasterUser()) {
            this.router.navigateByUrl('/fundList');
        }

        this.fetchAllCompanies();
        this.getAllAvailableFiles();

    }

    getAllAvailableFiles(){
        this.dataService.getAllAvailableFilesInInput().subscribe(data =>{
            this.allAvailableFilesList = data.body['response'];
            console.log("all available files ",this.allAvailableFilesList);
        },error =>{
            console.log("Error in fetching all available files ::" ,error);
        });
    }

    reprocessSftpFile(){
        console.log("Reprocess file type ::" + this.reprocessFileType);
        this.reprocessFileButton = true;
        this.dataService.reProcessSftpFiles(this.reprocessFileType).subscribe(data =>{
            this.utilService.showMessage("The reprocessing of the sftp files is completed, back up of data would take around 2 hours.","OK");
            this.reprocessFileButton = false;
            console.log("Re processing of the sftp files from the server completed with data", data);
            
        },error =>{
            console.log("Error in fetching all available files ::" ,error);
            this.reprocessFileButton = false;
        });
    }

    refreshAddData(){

        this.companyName = '';

        this.companyMappingName = '';

        this.companyType = 'PME';

        this.companyOrganizationName = 'Eurazeo';

    }

    refreshUpdateDeleteData(){

        this.selectedCompany = null;

        this.updateCompanyMappingName = '';

        this.updateCompanyType = 'PME';

        this.updateCompanyOrganizationName = 'Eurazeo';

        this.updateCompanyName = '';
        this.selectedCompanyId = null;

    }

    fetchAllCompanies(){

        this.dataService.getAllSftpCompanies().subscribe(data =>{

            // console.log("All sftp company details ::" ,data);

            this.allCompanyDetails = data.body['response'];

            console.log("all company details size",this.allCompanyDetails.length);

            console.log("all company details actual data:",this.allCompanyDetails);

        },error =>{

            console.log("Error in fetching the company details ::" ,error);
        });

    }

    processSftpFiles(){

        console.log("Processing of the sftp files started");
        this.sftpFilesButtonDisabled = true;
        this.dataService.processSftpFiles().subscribe( data =>{
            this.utilService.showMessage("The processing of the sftp files is completed, back up of data would take around 2 hours.","OK");
            this.sftpFilesButtonDisabled = false;
            console.log("processing of the sftp files from the server completed with data", data);
            this.getAllAvailableFiles();

          }, (error)=>{
            this.sftpFilesButtonDisabled = false;
            this.utilService.showMessage("Processing of the sftp files is completed with error.","OK");
            console.log("Error in processing the files", error);
            this.getAllAvailableFiles();

          })

    }


    addCompany(){

        console.log("Inside the submit form method");

        let companyDetails = {companyName:this.companyName,organizationId:this.companyOrganizationName,

            companyType:this.companyType,companyMappingName:this.companyMappingName};


        this.dataService.addSftpCompanyDetails(companyDetails)

        .subscribe(data =>{

            console.log("the data is saved successfully" + data);

            this.utilService.showMessage("Company " + this.companyName + " added successfully","OK");

            this.refreshAddData();

            this.fetchAllCompanies();

        },error =>{

            console.log("issue in saving data in the database",error);

            this.utilService.showMessage("failed to add Company " + this.companyName,"OK");

        });

        

    }

    companySelectionDone(){

        this.updateCompanyMappingName = this.selectedCompany.companyMappingName;

        this.updateCompanyType = this.selectedCompany.companyType;

        this.updateCompanyOrganizationName = this.selectedCompany.organizationId;

        this.updateCompanyName = this.selectedCompany.companyName;

        this.selectedCompanyId = this.selectedCompany ? this.selectedCompany.id : null;

        console.log("selected company id ",this.selectedCompanyId)

    }

    updateCompanyDetails(data){
        if(data == "update"){
            let updatedData = {

                id:this.selectedCompanyId,organizationId:this.updateCompanyOrganizationName,

                companyName:this.updateCompanyName,companyMappingName:this.updateCompanyMappingName,

                companyType:this.updateCompanyType

            };

            console.log("updated details :: ",updatedData);

            this.dataService.updateSftpCompanyDetails(updatedData).subscribe(data =>{

                console.log("selected company :" + this.updateCompanyName + " is updated with response ", data.body['response']);

                this.utilService.showMessage("selected company :" + this.updateCompanyName + " is updated in the system","Ok");

                this.refreshUpdateDeleteData();

                this.fetchAllCompanies();

            },error =>{

                console.log("Issue in updating the selected company :" + this.updateCompanyName + " with error ", error.body['message']);

                this.utilService.showMessage("Error in updating the company :" + this.updateCompanyName,"Ok");

            });

        } else {

            this.dataService.deleteSftpCompanyDetails(this.selectedCompanyId).subscribe(data =>{

                console.log("selected company :" + this.updateCompanyName + " is deleted with response ", data.body['response']);

                this.utilService.showMessage("selected company :" + this.updateCompanyName + " is deleted from the system","Ok");

                this.refreshUpdateDeleteData();

                this.fetchAllCompanies();

            },error =>{

                console.log("Issue in deleting the selected company :" + this.updateCompanyName + " with error ", error.body['message']);

                this.utilService.showMessage("Error in deleting the company :" + this.updateCompanyName,"Ok");

            });

        }
    }
    uploadDataFile(){
        if(this.fileToUpload == null || this.uploadFileType == null){
            console.log("Kindly select a file to upload");
            this.utilService.showMessage("Kindly select the file type and file to upload","error");
            return false;
        }
        let isPME = this.uploadFileType == "PME" ? true : false;
        this.dataService.uploadSftpFile(this.fileToUpload,isPME).subscribe(data => {
            console.log(this.fileToUpload.name + " uploaded successfully");
            console.log("output data",data);
            this.getAllAvailableFiles();
            this.utilService.showMessage("FIle uploaded successfully","Ok");
        },error => {
            console.log("Error in uploading the file : " + this.fileToUpload.name);
            console.log("output data",error);
            this.utilService.showMessage("Error in uploading file","error");
        })
    }
    selectFileToUpload(event){
        console.log("Event data after file selection",event);
        if(event.target.files.length == 0){
            this.fileToUpload = null;
            return;
        }
        this.fileToUpload = event.target.files[0];
        let fileFormat = this.fileToUpload.name.split(".")[this.fileToUpload.name.split(".").length-1];
        fileFormat = fileFormat.toLowerCase();
        if(fileFormat != "csv" && fileFormat != "xlsx"){
            this.fileToUpload = null;
            event.target.files.lenght = 0;
            this.utilService.showMessage("Please upload only csv or xlsx file","Ok");
            return;
        }
        console.log("File to be uploaded",this.fileToUpload);
    }
}

