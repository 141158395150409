
<div class="card page">
    <div class="page-inner">
        <div class="row">
            <div class="col-9">
                <h5 class="header" style="border-bottom: 3px solid #ffd32b;width: max-content;">{{translateService.getLabel("list_of_transactions (investments_realised_proceeds)") }}</h5>
            </div>
            <div class="col dateClass" *ngIf="lastModifiedByDate">
                {{translateService.getLabel("last_updated_at")}}: {{lastModifiedByDate}}
            </div>
        </div>
        <div class="table-toolbar" style="text-align: right; margin: 5px 10px;">

            <div style="flex-basis: 60%; text-align: left;">
                <button  mat-stroked-button color="primary" (click)="navigateToFundLevelCashflows()"  style="height:28px;margin: 10px 10px;">
                    {{translateService.getLabel("fund_level_cash_flows")}}
                </button>

                 <button mat-raised-button class="primary-bg p-button" style="margin: 10px 10px;">
                    {{translateService.getLabel("company_level_cash_flows")}}
                 </button>

                 <button mat-stroked-button color="primary" (click)="navigateToNetReturnCalculations()" style="margin: 10px 10px;">
                  {{translateService.getLabel("gross_to_net_return_calculations")}}
                 </button>
            </div>
            <div>
                <input type="file" style="display: none;" accept=".csv,.xls,.xlsx" #fileInput class="custom-file-input" (change)="fileUpload($event)"/>
            </div>
            <ng-template [ngIf]="fileTobeUploaded && fileTobeUploaded.name">
                <span>
                    {{fileTobeUploaded.name}}
                </span>
            </ng-template>
            <div style="flex-basis: 40%;">

            <button mat-raised-button class="primary-bg p-button" (click)="downloadExcel()" [class.disabled]="isDownloading" style="margin: 10px 10px;">
               {{translateService.getLabel("download")}}
            </button>
            <button mat-raised-button class="primary-bg p-button" (click)="clickOnFileInput(fileInput)" style="margin: 10px 10px;">
                {{translateService.getLabel("select_file_to_upload")}}
            </button>
            <button mat-raised-button class="primary-bg p-button" (click)="openTransactionModal(transactionsModel)" style="margin: 10px 10px;">
                {{translateService.getLabel("add")}}
            </button>
            <button mat-raised-button class="primary-bg p-button" style="margin: 10px 10px;" (click) = "saveTransactions()">
                {{translateService.getLabel("save")}}
            </button>
            <button mat-raised-button class="primary-light-bg p-button" style="margin: 10px 10px;" (click) = "closeThePage()">
                {{translateService.getLabel("close")}}
            </button>
            </div>

        </div>

        <div class="util-lis-grid">
            <ejs-grid #transactionsListGrid *ngIf="!editInProgress"
              [dataSource]="transactionData" 
              [allowResizing]="true" 
              [allowTextWrap]="true"
              [allowSorting]="true" 
              [allowReordering]="true"  
              [allowPaging]="true" 
              [showColumnChooser]="false"
              [pageSettings]="gridPageSettings" 
              [allowFiltering]="true"
              [filterSettings]="gridFilterSettings"
              [allowGrouping]="false"        
              >
              <e-columns>
                <e-column field='Date' [sortComparer]='utilService.sortComparer' headerText='Date' width='100px'>
                    <ng-template #template let-trans let-index>
                        {{ trans.Date | date: "mediumDate" }}
                    </ng-template>
                </e-column>

                <e-column field='companyName' headerText='{{translateService.getLabel("TMEX_COMPANY")}}' width='120px'></e-column>

                <e-column field='Type' headerText='{{translateService.getLabel("type")}}' width='120px'>
                    <ng-template #template let-data let-index>
                        <ng-template [ngIf]="data.Type == 'Investment'">
                            {{ translateService.getLabel('investment') }}
                        </ng-template>
                        <ng-template [ngIf]="data.Type == 'Realised Proceeds'">
                            {{ translateService.getLabel('realised_proceeds') }}
                        </ng-template>
                        <ng-template [ngIf]="data.Type != 'Realised Proceeds' && data.Type != 'Investment'">
                            {{ translateService.getLabel(data.Type) }}
                        </ng-template>
                    </ng-template>
                </e-column>

                <e-column field='Buyer' headerText='{{translateService.getLabel("buyer")}}' width='110px'>
                    <ng-template #template let-trans let-index>
                        {{ trans.Buyer }}
                    </ng-template>
                </e-column>

                <e-column field='Seller' headerText='{{translateService.getLabel("seller")}}' width='110px'>
                    <ng-template #template let-trans let-index>
                        {{ trans.Seller }}
                    </ng-template>
                </e-column>

                <e-column field='vehicleName' headerText='{{translateService.getLabel("vehicle_name")}}' width='110px'>
                    <ng-template #template let-trans let-index>
                        {{ trans.vehicleName }}
                    </ng-template>
                </e-column>
                
                <e-column field='Amount' headerText='{{translateService.getLabel("transaction_currency_amount")}}' width='150px' textAlign='Right'>
                    <ng-template #template let-trans let-index>
                        <span [RawDataDirective]="trans.Amount">
                            {{trans.Currency}} 
                            {{ utilService.getDisplayFormattedNumber(trans.Amount, 1, "", "", false) }}
                        </span>
                    </ng-template>
                </e-column>

                <e-column field='Traded_Stake' headerText='{{translateService.getLabel("traded_stake")}}' width='110px' textAlign='Right'>
                    <ng-template #template let-trans let-index>
                        {{ utilService.getDisplayFormattedNumber(trans.Traded_Stake, 1, "%", "", false) }}
                    </ng-template>
                </e-column>

                <e-column field='rpSource' headerText='{{translateService.getLabel("source")}}' width='110px'>
                    <ng-template #template let-data let-index>
                        <ng-template [ngIf]="data.Type == 'Investment'"> </ng-template>
                        <ng-template [ngIf]="data.rpSource && data.Type == 'Realised Proceeds'">
                            <ng-template [ngIf]="data.rpSource == 'Stake Sale'">
                                {{translateService.getLabel("stake_sale")}}
                            </ng-template>
                            <ng-template [ngIf]="data.rpSource == 'Dividend Recap'">
                                {{ translateService.getLabel('dividend_recap') }}
                            </ng-template>
                            <ng-template [ngIf]="data.rpSource != 'Stake Sale' && data.rpSource != 'Dividend Recap'">
                                {{ translateService.getLabel(data.rpSource) }}
                            </ng-template>
                        </ng-template>
                        <ng-template [ngIf]="!data.rpSource && data.Type == 'Realised Proceeds'">
                            {{translateService.getLabel("stake_sale")}}
                        </ng-template>
                    </ng-template>
                </e-column>

                <e-column field='Comment' headerText='{{translateService.getLabel("comment")}}' width='110px' textAlign='Right'>
                    <ng-template #template let-trans let-index>
                        <span> {{trans.Comment}} </span>
                    </ng-template>
                </e-column>

                <e-column field="Amount_Valuation_Currency" headerText='{{translateService.getLabel("local_currency_amount")}}' width='120px' textAlign='Right'>
                    <ng-template #template let-trans let-index>
                        <span *ngIf="trans['Amount_' + trans.valuationCurrency]">{{trans.valuationCurrency}} &nbsp;</span>
                        <span [RawDataDirective]="trans['Amount_' + trans.valuationCurrency]">{{ utilService.getDisplayFormattedNumber(trans['Amount_' + trans.valuationCurrency], 1, "", "", false) }}</span>
                    </ng-template>
                </e-column>
                
                <e-column field="Amount_Reporting_Currency" headerText='{{translateService.getLabel("reporting_currency_amount")}}' width='120px' textAlign='Right'>
                    <ng-template #template let-trans let-index>
                        <span *ngIf="trans['Amount_' + reportingCurrency]">{{reportingCurrency}} &nbsp;</span>
                        <span [RawDataDirective]="trans['Amount_' + reportingCurrency]">{{ utilService.getDisplayFormattedNumber(trans['Amount_' + reportingCurrency], 1, "", "", false) }}</span>
                    </ng-template>
                </e-column>
                
                <e-column headerText='{{translateService.getLabel("edit_transaction")}}' width='90px' textAlign='Right'>
                  <ng-template #template let-data let-index>
                    <a class="view" >
                      <span>
                        <i class="fas fa-pen" (click)="openEditModal(editModel, data)"></i>
                      </span>
                    </a>
                  </ng-template>
                </e-column>
                <e-column headerText='{{translateService.getLabel("delete_transaction")}}' width='90px' textAlign='Right'>
                    <ng-template #template let-data let-index>
                      <a class="view" >
                        <span>
                          <i class="fa fa-trash action-icon primary-color-medium" (click)="deleteTransactionFromNormalTable(data)"></i>
                        </span>
                      </a>
                    </ng-template>
                </e-column>
              </e-columns>
            </ejs-grid>
        </div>
    </div>
</div>


<!-- Popup model to Add Transaction -->
<ng-template #transactionsModel let-transactionsModel>
    <div class="transaction-modal">
        <div class="modal">
            <div class="modal-header">
                <div class="modal-title">
                    <h5>{{translateService.getLabel("add_company_cash_flow")}}</h5>

                </div>
                <button type="button" class="close" aria-label="Close"
                    (click)="transactionsModel.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #f="ngForm" (ngSubmit)="addTransactionData(f.value, transactionsModel)">
                    <!-- Validation Message -->
                    <div class="row" *ngIf = "validationMessage">
                        <div class="col-4"></div>
                        <div class="col" style="color: red; padding-bottom: 2.5%; text-align: right; font-size: small;">
                            {{ validationMessage }}
                        </div>
                    </div>

                    <!-- Date -->
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("date")}}</div>
                        <div class="col">
                            <mat-form-field (click)="vDatePicker.open()" style="margin: 0 0 5px 0; width: 100%;">
                                <input matInput [matDatepicker]="vDatePicker" [max]="maxDate" readonly class="date-field" name="Date" [(ngModel)]="transData.Date"
                                placeholder="Select">
                                <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Company Name -->
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("company")}}</div>
                        <div class="col">
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" placeholder="Enter" (input)="filterCompanies()">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                  <mat-option *ngFor="let option of filteredCompanies | async" [value]="option" (click)="selectCompany(option)">
                                    {{option.name}}
                                  </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Type of transaction -->
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("type_transactions")}}</div>
                        <div class="col">
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <mat-select name="Type" (ngModelChange)="initTypeOfTransaction($event)" [(ngModel)]="transData.Type" placeholder="Select">
                                    <mat-option value="Realised Proceeds">{{translateService.getLabel("realised_proceeds")}}</mat-option>
                                    <mat-option value="Investment">{{translateService.getLabel("investment")}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Buyer -->
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("buyer")}}</div>
                        <div class="col">
                            <ng-template [ngIf] = "rpType == 'Investment'" [ngIfElse] = "notInvestment">
                                <span style="margin: 2.5%; display: block;">
                                    {{ fundName }}
                                </span>
                            </ng-template>
                            <ng-template #notInvestment>
                                <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                    <input type="text" matInput [formControl]="buyersControl" [matAutocomplete]="autoBuyer" placeholder="Enter">
                                    <mat-autocomplete #autoBuyer="matAutocomplete" [displayWith]="displayFn">
                                      <mat-option *ngFor="let option of filteredBuyerNames | async" [value]="option" (click)="initBuyerName(option)">
                                        {{getBuyerOrSellerName(option)}}
                                      </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-template>
                        </div>
                    </div>

                    <!-- Seller -->
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("seller")}}</div>
                        <div class="col">
                            <ng-template [ngIf] = "rpType == 'Realised Proceeds'" [ngIfElse] = "notRealisedProceeds">
                                <span style="margin: 2.5%; display: block;">
                                    {{ fundName }}
                                </span>
                            </ng-template>
                            <ng-template #notRealisedProceeds>
                                <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                    <input type="text" matInput [formControl]="sellersControl" 
                                    [matAutocomplete]="autoSeller" placeholder="Enter">
                                    <mat-autocomplete #autoSeller="matAutocomplete" [displayWith]="displayFn">
                                      <mat-option *ngFor="let option of filteredSellerNames | async" [value]="option" (click)="initSellerName(option)">
                                        {{getBuyerOrSellerName(option)}}
                                      </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-template>
                        </div>
                    </div>

                    <!-- Vehicle Name (Optional) -->
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("vehicle_name")}}</div>
                        <div class="col">
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <input type="text" name="vehicleName" matInput [formControl]="vehicleControl" [(ngModel)]="transData.vehicleName" [ngModelOptions]="{standalone: true}" [matAutocomplete]="autoVehicle" placeholder="Enter">
                                <mat-autocomplete #autoVehicle="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of filteredVehicleList | async" [value]="option">
                                    {{getBuyerOrSellerName(option)}}
                                </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Transaction Currency -->
                    <div class="row">
                        <div class="col-4">Transaction Currency</div>
                        <div class="col">
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <mat-select name="Currency" [(ngModel)]="transData.Currency" placeholder="Select">
                                    <mat-option [value]="cur.id" *ngFor="let cur of utilService.currencies">
                                        {{ cur.country }} - {{ cur.id }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Transaction Amount -->
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("transaction_currency_amount")}}</div>
                        <div class="col">
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <input type="number" matInput name="Amount" (input)="changeInTransactionAmount($event, f.form)" [(ngModel)]="transData.Amount" placeholder="Enter">
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Traded Stake (Optional) -->
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("traded_stake")}}</div>
                        <div class="col">
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <input type="number" name="tradedstake" matInput [(ngModel)]="transData.Traded_Stake" placeholder="Enter">
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <!-- "Source" Type Should be taken if it of Realised Proceeds -->
                    <ng-container *ngIf="rpType == 'Realised Proceeds'">
                        <div class="row">
                            <div class="col-4">{{translateService.getLabel("source")}}</div>
                            <div class="col">
                                <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                    <mat-select name="rpSource" [(ngModel)]="transData.rpSource">
                                        <mat-option value="Stake Sale">{{translateService.getLabel("stake_sale")}}</mat-option>
                                        <mat-option value="Dividend Recap">{{translateService.getLabel("dividend_recap")}}</mat-option>
                                        <mat-option value="noi">{{translateService.getLabel("noi")}}</mat-option>
                                        <mat-option value="real_gain_loss">{{translateService.getLabel("real_gain_loss")}}</mat-option>
                                        <mat-option value="return_of_capital">{{translateService.getLabel("return_of_capital")}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Comment (Optional) -->
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("comment")}}</div>
                        <div class="col">
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <input type="text" name="Comment" matInput [(ngModel)]="transData.Comment" placeholder="Enter">
                            </mat-form-field>
                        </div>
                    </div>

                     <!-- Valuation Currency -->
                     <div class="row">
                        <div class="col-4">
                            {{translateService.getLabel("local_currency_amount")}}                            <span *ngIf="selectedCompany && selectedCompany.currency">({{selectedCompany.currency}})</span>
                        </div>
                        <div class="col">
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <input type="number" matInput name="Valuation_Currency_Amount" [disabled]="transData.Currency == selectedCompany.currency"
                                    [(ngModel)]="transData['Amount_' + selectedCompany.currency]"
                                    placeholder="Enter">
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Reporting Currency -->
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("reporting_currency_amount")}} ({{reportingCurrency}})</div>
                        <div class="col">
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <input type="number" matInput name="Reporting_Currency_Amount" [disabled]="reportingCurrency == transData.Currency"
                                    [(ngModel)]="transData['Amount_' + reportingCurrency]" [ngModelOptions]="{standalone: true}"
                                    placeholder="Enter">
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Adding the Trasaction -->
                    <div class="row">
                        <div class="col"></div>
                        <div class="col-3">
                            <button mat-raised-button class="primary-bg" type="submit" 
                            style="margin: 5px 0; float: right;">
                                {{translateService.getLabel("add")}}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>

<!-- Popup model to Edit Transaction -->
<ng-template #editModel let-editModel>
    <div class="edit-modal">
        <div class="modal">
            <div class="modal-header">
                <div class="modal-title">
                    <h5>{{translateService.getLabel("add_company_cash_flow")}}</h5>

                </div>
                <button type="button" class="close" aria-label="Close" (click)="editModel.dismiss('Cross click'); validationMessage = ''">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="max-height: 60vh; overflow-y: scroll;">

                <div class="row" *ngIf = "validationMessage">
                    <div class="col-4"></div>
                    <div class="col" style="color: red; padding-bottom: 2.5%; text-align: right; font-size: small;">
                        {{ validationMessage }}
                    </div>
                </div>

                <!-- Date -->
                <div class="row">
                    <div class="col-4">{{translateService.getLabel("date")}}</div>
                    <div class="col">
                        <mat-form-field (click)="vDatePicker.open()" style="margin: 0 0 5px 0; width: 100%;">
                            <input matInput [matDatepicker]="vDatePicker" [max]="maxDate" class="date-field" 
                                name="Date" [(ngModel)] = "transData.Date" >
                            <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                
                <!-- Company Name -->
                <div class="row">
                    <div class="col-4">{{translateService.getLabel("company")}}</div>
                    <div class="col">
                        <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                            <input type="text" matInput [formControl]="myControl" (input)="getCompanyNameValidation()" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                              <mat-option *ngFor="let option of filteredCompanies | async" [value]="option" (click)="selectCompany(option); emptySelectedCompanyName()">
                                {{option.name}}
                              </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Type of transaction -->
                <div class="row">
                    <div class="col-4">{{translateService.getLabel("type_transactions")}}</div>
                    <div class="col">
                        <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                            <mat-select name="Type" [placeholder]="transData.Type" 
                            (ngModelChange)="initTypeOfTransaction($event)" 
                            [(ngModel)] = "transData.Type" >
                                <mat-option value="Realised Proceeds">{{translateService.getLabel("realised_proceeds")}}</mat-option>
                                <mat-option value="Investment">{{translateService.getLabel("investment")}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Buyer -->
                <div class="row">
                    <div class="col-4">{{translateService.getLabel("buyer")}}</div>
                    <div class="col">
                        <ng-template [ngIf] = "rpType == 'Investment'" [ngIfElse] = "notInvestment">
                            <span style="margin: 2.5%; display: block;">
                                {{ fundName }}
                            </span>
                        </ng-template>
                        <ng-template #notInvestment>
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <input type="text" matInput [formControl]="buyersControl" [matAutocomplete]="autoBuyer">
                                <mat-autocomplete #autoBuyer="matAutocomplete" [displayWith]="displayFn">
                                  <mat-option *ngFor="let option of filteredBuyerNames | async" [value]="option" (click)="initBuyerName(option)">
                                    {{getBuyerOrSellerName(option)}}
                                  </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-template>
                    </div>
                </div>

                <!-- Seller -->
                <div class="row">
                    <div class="col-4">{{translateService.getLabel("seller")}}</div>
                    <div class="col">
                        <ng-template [ngIf] = "rpType == 'Realised Proceeds'" [ngIfElse] = "notRealisedProceeds">
                            <span style="margin: 2.5%; display: block;">
                                {{ fundName }}
                            </span>
                        </ng-template>
                        <ng-template #notRealisedProceeds>
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <input type="text" matInput [formControl]="sellersControl" 
                                [matAutocomplete]="autoSeller">
                                <mat-autocomplete #autoSeller="matAutocomplete" [displayWith]="displayFn">
                                  <mat-option *ngFor="let option of filteredSellerNames | async" [value]="option" (click)="initSellerName(option)">
                                    {{getBuyerOrSellerName(option)}}
                                  </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-template>
                    </div>
                </div>

                <!-- Vehicle Name (Optional) -->
                <div class="row">
                    <div class="col-4">{{translateService.getLabel("vehicle_name")}}</div>
                    <div class="col">
                        <!-- <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                            <input type="text" matInput [matAutocomplete]="autoVehicles"  name="vehicleName" matInput [(ngModel)]="transData.vehicleName" (ngModelChange)="filterVehicleNames($event)">
                            <mat-autocomplete #autoVehicles="matAutocomplete">
                                <mat-option *ngFor="let option of filteredVehicles" [value]="option" (click)="initVehicleName(option, transData)">
                                    {{option.mandateName}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field> -->
                        
                        <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                            <input name="vehicleName" matInput [(ngModel)]="transData.vehicleName">
                        </mat-form-field>
                    </div>
                </div>

                <!-- Transaction Currency -->
                <div class="row">
                    <div class="col-4">Transaction Currency</div>
                    <div class="col">
                        <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                            <mat-select name="Currency"
                                [placeholder]="transData.Currency" [(ngModel)] = "transData.Currency" >
                                <mat-option [value]="cur.id" *ngFor="let cur of utilService.currencies">
                                    {{ cur.country }} - {{ cur.id }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Transaction Amount -->
                <div class="row">
                    <div class="col-4">{{translateService.getLabel("transaction_currency_amount")}}</div>
                    <div class="col">
                        <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                            <input type="number" matInput name="Amount"
                                [(ngModel)]="transData.Amount" (input)="changeInEditTransactionAmount($event)"
                                >
                        </mat-form-field>
                    </div>
                </div>
                
                <!-- Traded Stake (Optional) -->
                <div class="row">
                    <div class="col-4">{{translateService.getLabel("traded_stake")}}</div>
                    <div class="col">
                        <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                          <input type="number" matInput [(ngModel)]="transData.Traded_Stake">
                          <span matSuffix>%</span>
                      </mat-form-field>
                    </div>
                </div>
                
                <!-- "Source" Type Should be taken if it of Realised Proceeds -->
                <ng-container *ngIf="rpType == 'Realised Proceeds'">
                    <div class="row">
                        <div class="col-4">{{translateService.getLabel("source")}}</div>
                        <div class="col">
                            <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                                <mat-select name="rpSource" [placeholder]="transData.rpSource" [(ngModel)]="transData.rpSource">
                                    <mat-option value="Stake Sale">{{translateService.getLabel("stake_sale")}}</mat-option>
                                    <mat-option value="Dividend Recap">{{translateService.getLabel("dividend_recap")}}</mat-option>
                                    <mat-option value="noi">{{translateService.getLabel("noi")}}</mat-option>
                                    <mat-option value="real_gain_loss">{{translateService.getLabel("real_gain_loss")}}</mat-option>
                                    <mat-option value="return_of_capital">{{translateService.getLabel("return_of_capital")}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
                
                <!-- Comment (Optional) -->
                <div class="row">
                    <div class="col-4">{{translateService.getLabel("comment")}}</div>
                    <div class="col">
                        <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                            <input type="text" name="Comment" matInput [(ngModel)]="transData.Comment">
                        </mat-form-field>
                    </div>
                </div>

                 <!-- Valuation Currency Amount -->
                 <div class="row">
                    <div class="col-4">
                        {{translateService.getLabel("local_currency_amount")}}                        <span *ngIf="selectedCompany && selectedCompany.currency">({{selectedCompany.currency}})</span>
                    </div>
                    <div class="col">
                        <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                            <input type="number" matInput [disabled]="transData.Currency == selectedCompany.currency"
                                [(ngModel)]="transData['Amount_' + selectedCompany.currency]"
                                placeholder="Enter">
                        </mat-form-field>
                    </div>
                </div>

                <!-- Reporting Currency Amount -->
                <div class="row">
                    <div class="col-4">Reporting Currency Amount ({{reportingCurrency}})</div>
                    <div class="col">
                        <mat-form-field style="margin: 0 0 5px 0; width: 100%;">
                            <input type="number" matInput name="Amount" [disabled]="reportingCurrency == transData.Currency"
                                [(ngModel)]="transData['Amount_' + reportingCurrency]"
                                placeholder="Enter">
                        </mat-form-field>
                    </div>
                </div>

                <!-- Updating the transaction -->
                <div class="row">
                    <div class="col"></div>
                    <div class="col-3">
                        <button mat-raised-button class="primary-bg" type="submit" [disabled]="validationMessage != ''"
                        style="margin: 5px 0; float: right;" 
                        (click) = "editTransactionData(editModel); editModel.dismiss('Cross click')">
                            {{translateService.getLabel("update")}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<a style="display: none;" #downloadLink></a>