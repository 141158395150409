import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';

@Component({
  selector: 'app-master-company-info',
  templateUrl: './master-company-info.component.html',
  styleUrls: ['./master-company-info.component.scss']
})
export class MasterCompanyInfoComponent implements OnInit {

  companyListDetails;
  editCompanyData;
  fundId;
  userId;
  addExternalCompanyIdData;
  orgId;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  gridPageSettings = { pageSizes: true, pageSize: 20 };
  @ViewChild('usersListGrid') public usersListGrid: GridComponent;
  gridFilterSettings = { type: 'Excel' };


  constructor(private modalService: NgbModal,
    public translateService: TranslateService,
    public ums: UserManagementService,
    public activatedRoute: ActivatedRoute,
    public dataService: DataService,
    public portfolioService: PortFolioSummaryServiceV2,
    public fundService: FundListService,
    public utilService: UtilService,
    private router: Router
    ) { }
  

  ngOnInit(): void {

    if(!this.ums.is73sAdminAndMasterUser()) {      
      this.router.navigateByUrl('/fundList');
    }

    this.activatedRoute.paramMap.subscribe( async params => {
      this.fundId = params.get("fundId");
      this.userId = this.ums.getSelectedUserDetails().id;
      this.orgId = this.ums.getSelectedUserDetails().organization.id;
      this.getCompanyDetailsList(this.fundId, this.userId);
    });

  }

  getCompanyDetailsList(fundId, userId){
    let reqBody = {
      fundIds : [fundId],
      userId: userId
    }
    this.dataService.getCompanyDetailsForCompanyMaster(reqBody).subscribe(data => {
    this.companyListDetails =  data.body["response"][this.fundId]
    })
  }

  saveCompanyMasterChanges(comp){
    let reqBody =[
      {
        companyId: this.editCompanyData.companyId,
        companyName: this.editCompanyData.companyName
      }]

    this.dataService.updateCompanyDetailsForCompanyMaster(reqBody).subscribe(data => {
      this.getCompanyDetailsList(this.fundId, this.userId);

    })
  }

  saveExternalIDForCompanyMasterChanges(id) {
    let reqBody ={
      "equityMaster": [{
        orgId: this.orgId,
        fundId: this.fundId,
        companyInternalId: this.addExternalCompanyIdData.companyId,
        companyExternalId: this.addExternalCompanyIdData.externalId
      }]};
      this.dataService.updateexternalIdForCompanyMaster(reqBody).subscribe(data => {
        this.getCompanyDetailsList(this.fundId, this.userId);
      })
  }

  openPopUpModel(content, data) {    
    this.editCompanyData = data;
    this.modalService.open(content, { centered: true, size: 'md' })
  }

  openAddExternalCompanyIDPopUpModel(content, data) {  
    this.addExternalCompanyIdData = data;
    this.dataService.getexternalIdForCompanyMaster(data.companyId).subscribe(data => {
      this.addExternalCompanyIdData['externalId'] = data.body['response']? data.body['response']['uniqueExternalId']: '';
      this.modalService.open(content, { centered: true, size: 'md' });
    });
  }

  deleteCompany(data) {
    const dialog = this.utilService.showConfirmMessage(this.translateService.getLabel("info_confirm_delete") + " " + data.companyName, this.translateService.getLabel("ok"), this.translateService.getLabel("cancel"));

    dialog.afterClosed().subscribe(confirmation => {
      if (confirmation === "Yes") {
        
        const orgId = this.ums.getSelectedUserDetails().organization.id;

        this.dataService.deleteCompanies([data.companyId], orgId).subscribe(response => {
          const success = this.utilService.showMessage(this.translateService.getLabel("suc_delete") + ": " + data.companyName, this.translateService.getLabel("ok"));
          this.getCompanyDetailsList(this.fundId, this.userId);
        });
        this.utilService.closeAllPopups();
      } else {
        this.utilService.closeAllPopups();
      } (error) => {
        console.log("Error failed to delete company", error);
      }
    });

  }


}
