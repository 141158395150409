import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/utils/util.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { cloneDeep, sortBy } from 'lodash';
import { HttpResponse } from '@angular/common/http';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';
import { MessageService } from 'src/app/services/message.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';
import { TranslateService } from 'src/app/services/translation.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  form: FormGroup;
  fileTobeUploaded;

  transactions;

  companies = [];

  @ViewChild("transactionsListGrid") public transactionsListGrid: GridComponent;
  @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;
  gridPageSettings = { pageSizes: true, pageSize: 20 };
  gridFilterSettings = { type: 'Excel' };
  gridToolbar = ['ColumnChooser'];

  transactionData;
  selectedMandateId;

  allVehicles = [];
  filteredVehicles = [];

  editInProgress = false;

  existingCompanyName = "";

  source  : "TRANSACTIONS"

  lastModifiedByDate;
  
  // transaction_data;
  transactionIndex;
  constructor(private modalService: NgbModal, public utilService: UtilService, private datePipe: DatePipe,private popUpService: UtilService, 
    private formBuilder: FormBuilder, private dataService : DataService, 
    private ums : UserManagementService, private currExchangeService : CurrencyExchangeService, public fundService : FundListService, 

    private ms: MessageService, private router: Router, public portfolioService : PortFolioSummaryServiceV2, public translateService: TranslateService,
    private activatedRoute: ActivatedRoute) 
  {

      this.form = this.formBuilder.group({
        Date: '',
        companyName: '',
        vehicleName: '',
        companyId: '',
        Type: '',
        Buyer: '',
        Seller: '',
        Currency: '',
        Amount: 0,
        Valuation_Currency_Amount: null,
        Reporting_Currency_Amount: null,
        Traded_Stake: 0,
        rpSource: "",
        Comment: '',
        exchangeRate: 1,
        convertedValue: 0,
      });
    }

  transactMode = false;

  myControl = new FormControl();
  buyersControl = new FormControl()
  sellersControl = new FormControl()
  vehicleControl = new FormControl()

  buyerSellerNames = [{name: ""}];

  suggestions = ""

  filteredCompanies: Observable<any>;
  filteredBuyerNames: Observable<any>;
  filteredSellerNames: Observable<any>;
  filteredVehicleList: Observable<any>;

  selectedCompany = {} as any;
  fundId;
  capTableData;
  loadingCaptable = false;
  capTableExists = true;
  selectedBuyer = "";
  selectedSeller = "";
  transData = {} as any
  buyers = [];
  sellers = [];
  vehicles = [];
  isDownloading = false;

  capTableDataForAllIds = {};

  maxDate;

  rpType = "";

  capTableDataForAllIdsTransaction = {} as any;

  fundName = "";

  validationMessage = "";
  reportingCurrency = "USD";
  
  ngOnInit() {
    this.activatedRoute.paramMap.subscribe( async params => {
      this.fundId = params.get("fundId");
      this.portfolioService.selectedFundId = this.fundId;

      this.dataService.getWidgetDataFromDB("PORTFOLIO_SUMMARY_DATA", this.fundId).subscribe(res => {
        let widgetData = res["body"]["response"][0].widgetData;
        this.reportingCurrency = widgetData.selectedCurrency;
      })

      this.init();
    });
  }

  init() {
    if(!this.fundId) {
      this.router.navigateByUrl('/users');
      return;
    }

    this.dataService.getVehiclesByFundId(this.fundId).subscribe(res => {
      this.allVehicles = res.body["response"] || [];
      this.filteredVehicles = cloneDeep(this.allVehicles);
    }, er => {
      this.allVehicles = [];
      this.filteredVehicles = [];
    })

    this.fundService.fundsLoaded$.subscribe(() => {
      this.fundName = this.fundService.getFundName(this.portfolioService.selectedFundId);
    })

    this.initTransactions();

    this.initCompanyAndValuationDates();

    this.filteredCompanies = this.myControl.valueChanges.pipe(
      startWith(),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name, "company") : this.companies.slice()));

    this.filteredBuyerNames = this.buyersControl.valueChanges.pipe(
      startWith(),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name, "buyer") : this.buyers.slice()));

    this.filteredSellerNames = this.sellersControl.valueChanges.pipe(
      startWith(),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name, "seller") : this.sellers.slice()));
    
    this.filteredVehicleList = this.vehicleControl.valueChanges.pipe(
      startWith(),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name, "vehicle") : this.vehicles.slice()));

    this.maxDate = new Date().toISOString().split("T")[0];

    if(this.transactionsListGrid){
      this.transactionsListGrid.refresh();
    }
  }

  initTransactions() {
    this.dataService.getWidgetDataFromDB("ALL_TRANSACTIONS", this.portfolioService.selectedFundId).subscribe(transactionsApiData => {
    
      this.transactions = transactionsApiData.body["response"][0].widgetData;

      this.fetchLastUpdatedTimestamp(transactionsApiData.body["response"][0])

      if(this.transactions) {
        this.transactionData = cloneDeep(this.transactions)
        this.transactionData.sort(function(a,b){
          if (a.companyName < b.companyName) return -1;
          else if (a.companyName > b.companyName) return 1;
          else {
            if (a.Date < b.Date) return 1;
            else if (a.Date > b.Date) return -1;
            return 0;
          }          
        })

        this.transactionData.forEach(tr => {
          tr.id = tr.id ? tr.id : this.utilService.getUUID();

          if(!tr.rpSource && tr.Type == 'Realised Proceeds') {
            tr.rpSource = "Stake Sale"
          }
        });
      }
    })
  }

  fetchLastUpdatedTimestamp(transactionsApiData){
    let timestamp = transactionsApiData.lastModifiedDate ? transactionsApiData.lastModifiedDate : transactionsApiData?.createdDate
    this.lastModifiedByDate = this.datePipe.transform(timestamp, 'hh:mm a; d MMMM, yyyy');
  }

  initCompanyAndValuationDates() {
    const userId = this.ums.getSelectedUserDetails().id;

    this.dataService.getCompanyAndLatestValuationDates(userId, this.fundId).subscribe(res => {
      const allValDates = res.body["response"];

      this.companies = allValDates
          .filter(c => !c.groupFormId)
          .map( comp => {
        
            if(comp.details) {
              const details = JSON.parse(comp.details);
              comp.currency = details.currency;
            }
            return {name: comp.companyName, id: comp.id, currency: comp.currency, valuationDate: comp.valuationDate}
          })

      this.companies.forEach( comp => {
        const valDatesInCompany = allValDates.filter(c => {
          const isValDate = c.id == comp.id || c.groupFormId == comp.id;

          if(isValDate && c.details) {
            const details = JSON.parse(c.details);
            c.currency = details.currency;

            return details.status == "Submitted";
          }

          return false;
        });

        const valDatesInCompanySorted = sortBy(valDatesInCompany, "valuationDate").reverse();
        this.companies.sort((a, b) => a.name.localeCompare(b.name));

        if(valDatesInCompanySorted[0]) {
          comp.latestValuationDateId = valDatesInCompanySorted[0].id;
          comp.latestValuationDate = valDatesInCompanySorted[0].valuationDate;
        } else {
          comp.latestValuationDateId = comp.id;
          comp.latestValuationDate = comp.valuationDate;
        }
      })
  
      //Sending latest val date ids to fetch capTable;
      const latestValuationDateIds = this.companies.map(c => c["latestValuationDateId"]).filter(vd => vd); // Remove null values
  
      this.dataService.getConcludedWaterfallVersionForAllIds(latestValuationDateIds).subscribe( res => {

        const apiData = res.body['response'];
        const allFormIds = Object.keys(apiData);

        allFormIds.forEach(id => {
          this.capTableDataForAllIds[id] = apiData[id];
        })
      }, error => {
        console.log("Failed to fetch Cap Table Data", error);
      })
    })
  }

  displayFn(comp): string {
    return comp && comp.name ? comp.name : '';
  }

  private _filter(name: string, type: string) {
    const filterValue = name.toLowerCase();
    if(type == "company"){
      return this.companies.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
    }
    else if(type == "buyer"){
      return this.buyers.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0)
    }
    else if(type == "seller"){
      return this.sellers.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0)
    }
    else
    {
      return this.vehicles.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0)
    }
  }

  initBuyerName(buyer){
    this.selectedBuyer = buyer;
    if(typeof(buyer) == 'object'){
      this.buyersControl.setValue({name: buyer.name});
    }
    else{
      this.buyersControl.setValue({name: buyer});
    }
  }

  initSellerName(seller){
    this.selectedSeller = seller;
    if(typeof(seller) == 'object'){
      this.sellersControl.setValue({name: seller.name});
    }
    else{
      this.sellersControl.setValue({name: seller})
    }
  }

  selectCompany(comp){
    this.selectedCompany = { name: comp.name, id: comp.id, currency: comp.currency };

    this.existingCompanyName = this.myControl.value;
    

    // Initializing List of Buyers and Sellers from Captable of Selected Company Latest Valuation Date Available
    if(comp.latestValuationDateId && this.capTableDataForAllIds[comp.latestValuationDateId]){
      const capTable = cloneDeep(this.capTableDataForAllIds[comp.latestValuationDateId]);
      this.capTableData = capTable.table;
      this.buyers = this.capTableData.map( (investorNames, index) => {
        if(index != 0 && index != this.capTableData.length - 1 && investorNames[0]){
          return { name: investorNames[0] };
        }
      }).filter( v => v); // Remove null values
      this.buyersControl.patchValue("")

      // Adding the current Fund Name to list of buyers and sellers
      this.buyers.unshift({name:this.fundName});

      this.sellers = cloneDeep(this.buyers);
      this.sellersControl.patchValue("")
      this.vehicles = cloneDeep(this.buyers)
      this.vehicleControl.patchValue("")
      if(!this.capTableDataForAllIdsTransaction) {
        this.capTableDataForAllIdsTransaction = {} as any;
      }

      if(!this.capTableDataForAllIdsTransaction[comp.id]) {
        this.capTableDataForAllIdsTransaction[comp.id] = {
          sellers: null,
          buyers: null
        }
      }
  
      this.loadingCaptable = false;
      this.capTableExists = true;
    }
    //If captable doesnot exist, adding Currenct Selected fund name by default
    else{
      console.log("Captable does not exists");
      this.loadingCaptable = false;
      this.capTableExists = false;

      this.buyers = [this.fundName];
      this.sellers = [this.fundName];
    }
  }

  getCaptableInvestors(companyId){ //invFormId
    let investors = [];

    const comp = this.companies.find(comp => comp.id == companyId);

    const latestValuationDateId = comp ? comp.latestValuationDateId : null;

    if(latestValuationDateId 
      && this.capTableDataForAllIds[latestValuationDateId]
      && this.capTableDataForAllIds[latestValuationDateId]["widgetData"]
      && this.capTableDataForAllIds[latestValuationDateId]["widgetData"].table){
      const tableData =  cloneDeep(this.capTableDataForAllIds[latestValuationDateId]["widgetData"].table);

      if(tableData){
        investors = tableData.map( (investorNames, index) => {
          if(index != 0 && index != tableData.length - 1 && investorNames[0]){
            return { name: investorNames[0] };
          }
          else{
            return null;
          }
        }).filter( investor => investor != null);
      }
  
    }
    
    return investors;
  }

  initTypeOfTransaction(selectedType){
    this.rpType = selectedType;
    if(this.rpType == "Realised Proceeds"){
      this.sellersControl.setValue({name: this.fundName});
      this.buyersControl.setValue({name: ""});
    }
    else if(this.rpType == "Investment"){
      this.buyersControl.setValue({name: this.fundName});
      this.sellersControl.setValue({name: ""});
    }
  }

  getBuyerOrSellerName(option){
    if(typeof(option) === 'object'){
      return option.name;
    }
    else {
      return option;
    }
  }

  async addTransactionData(f, transactionsModel){

    this.selectedBuyer = (this.buyersControl.value.name || this.buyersControl.value.name == "") ? this.buyersControl.value.name : this.buyersControl.value;

    this.selectedSeller = (this.sellersControl.value.name || this.sellersControl.value.name == "") ? this.sellersControl.value.name : this.sellersControl.value;

    this.validationMessage = this.getValidationMessage(this.transData);

    if(this.validationMessage){
      return;
    }

    let trans = {
      id: this.utilService.getUUID(),
      Date : this.datePipe.transform(f.Date, "MMMM dd, yyyy"),
      Buyer : this.selectedBuyer,
      Seller : this.selectedSeller,
      companyName : this.selectedCompany.name,
      companyId : this.selectedCompany.id,
      Traded_Stake : this.transData.Traded_Stake ? this.transData.Traded_Stake : 0,
      Amount : f.Amount ? f.Amount : 0,
      Currency : f.Currency ? f.Currency : "",
      Type : f.Type ? f.Type : "",
      rpSource: f.rpSource ? f.rpSource : "",
      Comment: f.Comment ? f.Comment : "",
      exchangeRate: 1,
      convertedValue: f.Amount ? f.Amount : 0,
    }

    const compExists = this.companies.find( comp => comp.id === this.selectedCompany.id);

    if(compExists && compExists.currency && compExists.currency != trans.Currency){
      const reqBody = this.getCurrencyRequestBody(compExists, trans);

      await this.currExchangeService.initExchangeRates(reqBody);

      const currencyKey = trans.Currency + compExists.currency + (moment(trans.Date)).format("YYYY-MM-DD")
        if(this.currExchangeService.exchangeRates[currencyKey]){        
          trans['exchangeRate'] = this.currExchangeService.exchangeRates[currencyKey] 
            ? this.currExchangeService.exchangeRates[currencyKey] 
            : 1;
          trans["convertedValue"] = trans.Amount * trans["exchangeRate"];
        }

    }

    if(!this.transactionData){
      this.transactionData = [];
    }

    if(compExists) {
      trans["valuationCurrency"] = compExists.currency;
      if(this.transData["Amount_" + compExists.currency]) {
        trans["Amount_" + compExists.currency] = this.utilService.getValidNumber(this.transData["Amount_" + compExists.currency]);
      }
    }
    
    if(this.transData["Amount_" + this.reportingCurrency]) {
      trans["Amount_" + this.reportingCurrency] = this.utilService.getValidNumber(this.transData["Amount_" + this.reportingCurrency]);
    }

    let vehicleName = this.transData.vehicleName;
    if(this.transData.vehicleName && this.transData.vehicleName.name && this.transData.vehicleName.name.length > 0) {
      vehicleName = this.transData.vehicleName.name;
    }

    if(vehicleName && vehicleName.length > 0) {
      try {
        const vehicleCompanyMappingReqBody = this.getVehiclAndMappingReqBody(trans, (moment(trans.Date)).format("YYYY-MM-DD"), compExists.currency, vehicleName);

        const vehicleCompanyMapping = await this.dataService.createVehicle(vehicleCompanyMappingReqBody).toPromise();
        const mapping = vehicleCompanyMapping["body"]["response"][0];
        trans["vehicleName"] = vehicleName;
        trans["mandateId"] = mapping.mandateId;
        trans["mandateCompanyMappingId"] = mapping.id;
      } catch(e) {
        console.log("Failed to save vehicle mapping", e)
      }
    }

    this.transactionData.push(trans);

    /* inserting one more record with investment to realised proceeds or vice versa 
      if buyer && seller are from captable - Sambeet */

    if(this.isBuyerAndSellerFromSameCaptable(trans)){
      const transRecord = cloneDeep(trans);
      transRecord.Type = trans.Type.toLowerCase() == "investment" ? "Realised Proceeds" : "Investment";
      transRecord.id = this.utilService.getUUID();
      this.transactionData.push(transRecord);
    }

    if(this.transactionsListGrid){
      this.transactionsListGrid.refresh();
    }
    
    this.selectedCompany = {};
    this.selectedBuyer = "";
    this.selectedSeller = ""
    this.buyersControl.setValue("");
    this.sellersControl.setValue("");
    this.capTableExists = true;
    this.rpType = "";

    this.validationMessage = "";
    transactionsModel.dismiss();
  }

  getValidationMessage(f){
    if(!f.Date){
      return "Please enter the trasaction date."
    }
    else if(!this.selectedCompany.name){
      return "Please select the company."
    }
    else if(!f.Type){
      return "Please select the transaction type."
    }
    else if(!(this.selectedSeller || this.selectedBuyer)){
      return "Please select the buyer or seller."
    }
    else if(!f.Currency){
      return "Please select the transaction currency."
    }
    else if(!f.Amount){
      return "Please enter a valid transaction amount."
    }
    else if(f.Type == "Realised Proceeds" && !f.rpSource){
      return "Please select the source for Realised Proceeds."
    }

    return "";
  }

  getCompanyNameValidation(){

    if(this.existingCompanyName != this.myControl.value){
      this.validationMessage = "Please Select a valid company name.";
    }
    else {
      this.validationMessage = "";
    }
  }

  filterCompanies() {
    const comps = this.companies.filter(c => !this.myControl.value || c.name.indexOf(this.myControl.value) >= 0);
    this.filteredCompanies = of(comps);
  }

  emptySelectedCompanyName(){
    this.validationMessage = "";
  }

  async editTransactionData(editModel){
    this.editInProgress = true;

    if(!this.selectedBuyer){
      this.selectedBuyer = (this.buyersControl.value.name || this.buyersControl.value.name == "") ? this.buyersControl.value.name : this.buyersControl.value;

      if(this.selectedBuyer && this.selectedBuyer.length > 0) {
        this.transactionData[this.transactionIndex].Buyer = this.selectedBuyer;
      }
    }

    if(!this.selectedSeller){
      this.selectedSeller = (this.sellersControl.value.name || this.sellersControl.value.name == "") ? this.sellersControl.value.name : this.sellersControl.value;
      
      if(this.selectedSeller && this.selectedSeller.length > 0) {
        this.transactionData[this.transactionIndex].Seller = this.selectedSeller
      }
    }

    if(!this.selectedCompany.name){
      this.selectedCompany.name = this.transData.companyName
      this.selectedCompany.id = this.transData.companyId
    }
    
    if(this.transData.Date){
      this.transactionData[this.transactionIndex].Date = this.datePipe.transform(this.transData.Date, "MMMM dd, yyyy");
    }

    this.transactionData[this.transactionIndex].companyName = this.selectedCompany.name ;

    this.transactionData[this.transactionIndex].companyId = this.selectedCompany.id ;

    if(this.transData.Traded_Stake != ''){
      this.transactionData[this.transactionIndex].Traded_Stake = this.transData.Traded_Stake ;
    }
    if(this.transData.Amount != ''){
      this.transactionData[this.transactionIndex].Amount = this.transData.Amount ;
    }
    if(this.transData.Currency != ''){
      this.transactionData[this.transactionIndex].Currency = this.transData.Currency ;
    }
    if(this.transData.Type != ''){
      this.transactionData[this.transactionIndex].Type = this.transData.Type ;
    }
    if(this.transData.Comment != ''){
      this.transactionData[this.transactionIndex].Comment = this.transData.Comment ;
    }
    if(this.transData.rpSource != ''){
      if(this.transData.Type == "Investment"){
        this.transactionData[this.transactionIndex].rpSource = "";
      }
      else{
        this.transactionData[this.transactionIndex].rpSource = this.transData.rpSource ;
      }
    }

    this.transactionData[this.transactionIndex]["exchangeRate"] = 1;
    this.transactionData[this.transactionIndex]["convertedValue"] = this.transactionData[this.transactionIndex].Amount;

    const compExists = this.companies.find( comp => comp.id === this.transactionData[this.transactionIndex].companyId);

    if(compExists && compExists.currency && compExists.currency != this.transactionData[this.transactionIndex].Currency){

      const reqBody = this.getCurrencyRequestBody(compExists, this.transactionData[this.transactionIndex]);

      await this.currExchangeService.initExchangeRates(reqBody);

      const currencyKey = this.transactionData[this.transactionIndex].Currency + compExists.currency + (moment(this.transactionData[this.transactionIndex].Date)).format("YYYY-MM-DD")
        if(this.currExchangeService.exchangeRates[currencyKey]){        
          this.transactionData[this.transactionIndex]['exchangeRate'] = this.currExchangeService.exchangeRates[currencyKey] 
            ? this.currExchangeService.exchangeRates[currencyKey] 
            : 1;
          this.transactionData[this.transactionIndex]["convertedValue"] = this.transactionData[this.transactionIndex].Amount * this.transactionData[this.transactionIndex]["exchangeRate"];
        }

    }

    if(compExists) {
      this.transactionData[this.transactionIndex]["valuationCurrency"] = compExists.currency;
      this.transactionData[this.transactionIndex]["Amount_" + compExists.currency] = this.transData["Amount_" + compExists.currency];
    }

    this.transactionData[this.transactionIndex]["Amount_" + this.reportingCurrency] = this.transData["Amount_" + this.reportingCurrency];

    if(this.transData.vehicleName && this.transData.vehicleName != this.transactionData[this.transactionIndex].vehicleName) {
      try {
        const vehicleCompanyMappingReqBody = this.getVehiclAndMappingReqBody(this.transactionData[this.transactionIndex], (moment(this.transactionData[this.transactionIndex].Date)).format("YYYY-MM-DD"), compExists.currency, this.transData.vehicleName);

        const vehicleCompanyMapping = await this.dataService.createVehicle(vehicleCompanyMappingReqBody).toPromise();
        const mapping = vehicleCompanyMapping["body"]["response"][0]
        this.transactionData[this.transactionIndex]["mandateId"] = mapping.mandateId;
        this.transactionData[this.transactionIndex]["mandateCompanyMappingId"] = mapping.id;
        this.transactionData[this.transactionIndex]["vehicleName"] = this.transData.vehicleName;
      } catch(e) {
        console.log("Failed to save vehicle mapping", e)
      }
    }

    //inserting one more record with investment to realised proceeds or vice versa if buyer && seller are from captable
    if(this.isBuyerAndSellerFromSameCaptable(this.transactionData[this.transactionIndex])){ 
      const transRecord = cloneDeep(this.transactionData[this.transactionIndex]);
      transRecord.Type = this.transactionData[this.transactionIndex].Type.toLowerCase() == "investment" ? "Realised Proceeds" : "Investment";
      transRecord.id = this.utilService.getUUID();
      this.transactionData.push(transRecord);
    }

    this.editInProgress = false;
    if(this.transactionsListGrid){
      this.transactionsListGrid.refresh();
    }
    
    this.selectedBuyer = null;
    this.selectedSeller = null;

    this.validationMessage = "";

    this.rpType = "";

    this.transData = {};  

    this.selectedCompany = {};
  }

  getCurrencyRequestBody(company, transaction) {
    const reqBody = {
      "rateCapturedForId": this.portfolioService.selectedFundId,
      "rateCapturedFor": "FUND",
      "module": "EQUITY",
    }

    const input = [{
      date: (moment(transaction.Date)).format("YYYY-MM-DD"),
      source_currency: transaction.Currency,
      target_currency: company.currency,
      type: "TRANSACTIONS"
    }]
    
    reqBody["input"] = input;

    return reqBody;
  }

  filterUniquePairs(data) {
    const uniquePairs = new Set();
  
    return data.filter((arr) => {
      const key = arr.date + '-' + arr.source_currency+ '-' + arr.target_currency+ '-' + arr.type;
  
      if (!uniquePairs.has(key)) {
        uniquePairs.add(key);
        return true;
      }
      return false;
    });
  }

  async saveTransactions(){
    this.utilService.showLoadingPopup();

    this.transactionData = this.transactionData.map(trans => {
      trans.Date = (moment(trans.Date)).format("YYYY-MM-DD");

      trans.Buyer = trans.Buyer && trans.Buyer.trim ? trans.Buyer.trim(): trans.Buyer;
      trans.Seller = trans.Seller && trans.Seller.trim ? trans.Seller.trim(): trans.Seller;
      return trans;
    });

    this.transactions = cloneDeep(this.transactionData);

    this.portfolioService.summary[this.portfolioService.selectedFundId] = null;
    this.portfolioService.loading = true;

    this.ms.publish("loadingMessage", this.translateService.getLabel("saving_transactions"));

    this.dataService.saveWidgetDataToDB("ALL_TRANSACTIONS", this.transactions, this.fundId).subscribe(res => {
      this.updateSummaryAndValuationDateAttributes(this.source);

      this.fetchLastUpdatedTimestamp(res.body["response"])

    }, error => {
      console.log("error", error);
      this.utilService.showMessage(this.translateService.getLabel("err_failed_transaction"), this.translateService.getLabel("ok"), true);
    })
  }

  updateSummaryAndValuationDateAttributes(source){
    const userId = this.ums.getSelectedUserDetails().id
    const reqBody = {
      userId : userId,
      fundId  : this.fundId,
      source  : source
    }

    this.dataService.updateSummaryAttributesOnTransactionsChange(reqBody).subscribe(res => {
      this.utilService.closeAllPopups();
      console.log("Sucessfully updated the Summary", res);
      this.utilService.showMessage(this.translateService.getLabel("suc_transaction_updated_irr_and_moic"), this.translateService.getLabel("ok"));
    }, error => {
      this.utilService.closeAllPopups();
      this.utilService.showMessage(this.translateService.getLabel("err_update_irr_and_moic"), this.translateService.getLabel("ok"), true);
      console.log("Failed to updated the Summary", error);
    })
  }

  deleteTransactionFromNormalTable(data){
    const confirm = this.utilService.showConfirmMessage(this.translateService.getLabel("info_delete_transaction"), this.translateService.getLabel("yes"), this.translateService.getLabel("no"));
    confirm.afterClosed().subscribe(confirmation => {

      const transIndex = this.transactionData.findIndex(ele => {
        return ele.Date === data.Date 
        && ele.Buyer === data.Buyer 
        && ele.Seller === data.Seller 
        && ele.Type === data.Type
        && ele.companyId == data.companyId
        && ele.convertedValue == data.convertedValue
      })

      if (confirmation === "Yes") {
        this.transactionData.splice(transIndex, 1);

        if(this.transactionsListGrid){
          this.transactionsListGrid.refresh();
        }
      }
    });
  }

  deleteTransaction(txnToBeDeleted, index){
    const confirm = this.utilService.showConfirmMessage(this.translateService.getLabel("info_delete_transaction"), this.translateService.getLabel("yes"), this.translateService.getLabel("no"));
    confirm.afterClosed().subscribe(confirmation => {

      if (confirmation === "Yes") {
        const trxnIndex = this.transactionData
            .findIndex(t => t.Date == txnToBeDeleted.Date 
              && t.Buyer == txnToBeDeleted.Buyer 
              && t.Seller == txnToBeDeleted.Seller 
              && t.companyId == txnToBeDeleted.companyId);

        if(trxnIndex >= 0) {
          this.transactionData.splice(+trxnIndex, 1)

        } else {
          this.utilService.showMessage(this.translateService.getLabel("err_failed_delete_transaction"), this.translateService.getLabel("ok"), true);
        }
      }
    });
  }

  setMode(){
    this.transactMode = true;
  }
  
  openTransactionModal(content){
    
    this.capTableExists = true;

    this.buyersControl.setValue("");
    this.sellersControl.setValue("");
    this.myControl.setValue("");
    this.filteredCompanies = of(this.companies.slice())

    this.transData = {};
    this.rpType = "";
    this.validationMessage = "";

    this.selectedCompany = {};
    this.transData = {};

    this.modalService.open(content, { centered: true, windowClass: 'center-popup-modal'});
  }

  openEditModal(content, selectedTransaction){
    this.capTableExists = true;
    this.validationMessage = "";

    const transIndex = this.transactionData.findIndex(ele => {
      // return ele.Date === comp.Date && ele.Buyer === comp.Buyer && ele.Seller === comp.Seller && ele.Type === comp.Type;
      return ele.id == selectedTransaction.id;
    })

    this.transactionIndex = +transIndex;

    this.transData = {} as any;

    this.transData["Date"] = new Date(selectedTransaction.Date);
    this.transData["Buyer"] = selectedTransaction.Buyer;
    this.transData["Seller"] = selectedTransaction.Seller;
    this.transData["vehicleName"] = selectedTransaction.vehicleName;
    this.transData["Traded_Stake"] = selectedTransaction.Traded_Stake;
    this.transData["Amount"] = selectedTransaction.Amount;
    this.transData["Currency"] = selectedTransaction.Currency;
    this.transData["Type"] = selectedTransaction.Type;
    this.transData["rpSource"] = selectedTransaction.rpSource;
    this.transData["companyName"] = selectedTransaction.companyName;
    this.transData["companyId"] = selectedTransaction.companyId;
    this.transData["Comment"] = selectedTransaction.Comment;
    
    const companyExists = this.companies.find(c => c.id == selectedTransaction.companyId);
    if(companyExists && companyExists.currency) {
      this.selectedCompany = { name: companyExists.name, id: companyExists.id, currency: companyExists.currency };
      this.transData["Amount_" + companyExists.currency] = selectedTransaction["Amount_" + companyExists.currency];
    }

    this.transData["Amount_" + this.reportingCurrency] = selectedTransaction["Amount_" + this.reportingCurrency];

    this.rpType = selectedTransaction.Type;

    this.buyersControl.setValue({name: selectedTransaction.Buyer});
    this.sellersControl.setValue({name: selectedTransaction.Seller});
    
    this.myControl.setValue({name: selectedTransaction.companyName});

    this.existingCompanyName = selectedTransaction.companyName;

    this.modalService.open(content, { centered: true, windowClass: 'center-popup-modal'});
  }  
  
  clickOnFileInput(fileInput) {
    fileInput.click();
  }

  fileUpload(event) {
    var list: FileList = event.target.files;

    if (!list || list.length === 0) return;

    this.fileTobeUploaded = list[0];

    const target: DataTransfer = <DataTransfer>(event.target);
    
		if (target.files.length === 0 || target.files.length !== 1) {

      //This allows uploading same file again.
      event.target.value = "";

      this.utilService.showMessage(this.translateService.getLabel("info_select_filled_template"), this.translateService.getLabel("ok"));
      return;
    }

    if(!target.files[0].name.endsWith("xlsx")) {

      //This allows uploading same file again.
      event.target.value = "";
      
      this.utilService.showMessage(this.translateService.getLabel("info_select_xlsx_format"), this.translateService.getLabel("ok"));
      return;
    }

    this.utilService.showLoadingPopup();

    var list:FileList = event.target.files
    var file: File = list.item(0);

    //This allows uploading same file again.
    event.target.value = "";

    this.dataService.parseExcel(file).subscribe(async event=>{

      if (event instanceof HttpResponse) {
        let apiResponseData = JSON.parse(event.body.toString())["response"];

        //Replacing all the existing Transactions and Updating new Transactions from Excel
        this.transactionData = [];

        if(apiResponseData.length > 0){
          if(!apiResponseData[apiResponseData.length - 1][0] && !apiResponseData[apiResponseData.length - 1][1]){
            apiResponseData.splice(apiResponseData.length - 1, 1)
          }
        }
        
        for (let index = 0; index < apiResponseData.length; index++) {
          const row = apiResponseData[index];
          
          const compExists = this.companies.find(comp => comp.name && row[1] && comp.name.toLowerCase().trim() === row[1].toLowerCase().trim());
          if(compExists 
            && row[0] && row[0]!="Date" 
            && row[2] 
            && (row[2].toLowerCase() == "investment" || row[2].toLowerCase() =="realised proceeds")){
            let trans = {
              id: this.utilService.getUUID(),
              Date : row[0],
              companyName: row[1],
              companyId: compExists["id"],
              Buyer : row[3] ? row[3] : "",
              Seller : row[4] ? row[4] : "",
              Traded_Stake : row[7] ? row[7] : 0,
              Amount : row[6] ? row[6] : 0,
              Currency : row[5] ? row[5].toUpperCase() : row[5],
              Type : row[2],
              rpSource : this.getReliasedProceedsSource(row[2], row[8]),
              Comment: row[9],
              exchangeRate: 1,
              convertedValue: row[6] ? row[6] : 0,
              vehicleName: (row[14] && row[14].length > 0) ? row[14] : null
            }

            trans["valuationCurrency"] = compExists.currency;

            if(row[11]) {
              const valueInValuationCurrency = this.utilService.getValidNumber(row[11]);

              if(valueInValuationCurrency != 0) {
                trans.convertedValue = valueInValuationCurrency;
                trans["Amount_" + compExists.currency] = valueInValuationCurrency;
              }
            }

            if(row[13]) {
              const valueInReportingCurrency = this.utilService.getValidNumber(row[13]);
              trans["Amount_" + this.reportingCurrency] = valueInReportingCurrency > 0 ? valueInReportingCurrency : null;
            }

            this.transactionData.push(trans);

            if(this.isBuyerAndSellerFromSameCaptable(trans)){ //inserting one more record with investment to realised proceeds or vice versa
              const transRecord = cloneDeep(trans);
              transRecord.Type = trans.Type.toLowerCase() == "investment" ? "Realised Proceeds" : "Investment";
              transRecord["id"] = this.utilService.getUUID();
              this.transactionData.push(transRecord);
            }

            if(this.transactionData) {
              this.transactionData = cloneDeep(this.transactionData)
              this.transactionData.sort(function(a,b){
                if (a.companyName < b.companyName) return -1;
                else if (a.companyName > b.companyName) return 1;
                else return new Date(a.Date).valueOf() - new Date(b.Date).valueOf();       
              })

              this.transactionData.forEach(tr => {
                if(!tr.rpSource && tr.Type == 'Realised Proceeds') {
                  tr.rpSource = "Stake Sale"
                }
              });
            }
          }
        }

        if(this.transactionsListGrid){
          this.transactionsListGrid.refresh();
        }

        await this.updateExcelUploadedTransactions(this.transactionData)

        console.log("TRANSACTION_DATA", this.transactionData)

        this.utilService.closeAllPopups();
      }
    }, error=>{
      console.log("Financial History File Upload failed.", error)
      this.utilService.closeAllPopups();
      this.utilService.showMessage(this.translateService.getLabel("err_fileupload_failed"), this.translateService.getLabel("ok"))
    })
  }

  getReliasedProceedsSource(type, source) {

    if(type != 'Realised Proceeds') {
      return "";

    } else if(source == "NOI") {
      return "noi";

    } else if(source == "Return of Capital") {
      return "return_of_capital";

    } else if(source == "Real. Gain/(Loss)") {
      return "real_gain_loss";

    } else if(source && source.length > 0){
      return source;

    } else {
      return "Stake Sale";
    }
  }

  getRP_SourceLabel(type, sourceKey) {
    if(type != 'Realised Proceeds') {
      return "";

    } else if(sourceKey == "noi") {
      return "NOI";

    } else if(sourceKey == "return_of_capital") {
      return "Return of Capital";

    } else if(sourceKey == "real_gain_loss") {
      return "Real. Gain/(Loss)";

    } else if(sourceKey && sourceKey.length > 0){
      return sourceKey;

    } else {
      return "Stake Sale";
    }
  }

  isBuyerAndSellerFromSameCaptable(trans){
    const investors = this.getCaptableInvestors(trans.companyId);

    let isFromSameCaptable = investors.length > 0 
    && investors.find(inv => inv.name && trans.Buyer && inv.name.toLowerCase() === trans.Buyer.toLowerCase())
    && investors.find(inv => inv.name && trans.Seller && inv.name.toLowerCase() === trans.Seller.toLowerCase()) ? true : false;
            
    return isFromSameCaptable;
  }

  closeThePage() {
    this.router.navigateByUrl('/portfolio/' + this.portfolioService.selectedFundId);
  }

  getExcelDownLoadRequestBody() {

    let headers = ["Date", "Company", "Type", "Buyer", "Seller", "Transaction Currency", "Transaction Currency Amount", "Trader stake", "Source", "Comment", "Local Currency", "Local Currency Amount", "Reporting Currency", "Reporting Currency Amount", "Vehicle"];
    const values = [];
    this.transactions.forEach(trans =>{

      let row = [];

      row.push(this.getCellObject("date", trans['Date']));
      row.push(this.getCellObject("text", trans['companyName']));
      row.push(this.getCellObject("text", trans['Type']));
      row.push(this.getCellObject("text", trans['Buyer']));
      row.push(this.getCellObject("text", trans['Seller']));
      row.push(this.getCellObject("text", trans['Currency']));
      row.push(this.getCellObject("number", parseFloat(trans['Amount']), "1"));
      row.push(this.getCellObject("number", this.utilService.getValueForExcel(trans['Traded_Stake']), "1"));

      row.push(this.getCellObject("text", this.getRP_SourceLabel(trans['Type'], trans['rpSource'])));
      row.push(this.getCellObject("text", trans['Comment']))
     
      let valuationCurrency = "";
      const compInTransaction = this.companies.find(c => c.id == trans.companyId);
      if(compInTransaction) {
        valuationCurrency = compInTransaction.currency;
      }
      
      row.push(this.getCellObject("text", valuationCurrency));
      row.push(this.getCellObject("number", trans['Amount_' + valuationCurrency] ? trans['Amount_' + valuationCurrency] : ""));

      row.push(this.getCellObject("text", this.reportingCurrency));
      row.push(this.getCellObject("number", trans['Amount_' + this.reportingCurrency] ? trans['Amount_' + this.reportingCurrency] : ""));
      row.push(this.getCellObject("text", trans['vehicleName']));

      values.push(row);
    })

    values.push([this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(),
    this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell()]);

    return [{ "tabName": "Excel Download", "data": {"headers": headers, "values": values} }]
  }

  getBlankCell(){
    const obj = {
      "type": "text",
      "value": ""
    }

    return obj;
  }

  getCellObject(type, value, decimal?){
    if(decimal){
      const obj = {
        "type": type,
        "value": value,
        "decimal": decimal
      }
  
      return obj
    }
    else{
      const obj = {
        "type": type,
        "value": value
      }
  
      return obj
    }
  }


    manageFileDownload(fileData, type) {
      let fundName= this.fundService.getFundName(this.portfolioService.selectedFundId);
      let date = moment().format("MMM DD YYYY").replace(/ /gi,"-"); 
      try {
        const url = (window as any).URL.createObjectURL(fileData.body);
        this.downloadLink.nativeElement.href = url;
        this.downloadLink.nativeElement.target = "_blank";
        this.downloadLink.nativeElement.download = fundName + "_Transaction_" + date + "." + type;
        this.downloadLink.nativeElement.click();
      } catch(e) {
        console.log("Failed to download file", e)
        this.popUpService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"))
      }
  
      this.isDownloading = false;
    }
  
    downloadExcel() {
      if(this.isDownloading) return;
      let fileData = this.getExcelDownLoadRequestBody(); 
  
      this.isDownloading = true;
  
      this.dataService.downloadExcelFileV2(fileData).subscribe((fileData)=>{
        this.manageFileDownload(fileData, "xlsx");
      }, err => {
        console.log("Failed to download file", err)
        this.popUpService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"))
  
        this.isDownloading = false;
      });
    }

    async updateExcelUploadedTransactions(transactions){
      let input = [];

      transactions.forEach(data => {
        const compExists = this.companies.find( comp => comp.id === data.companyId);
        if(compExists && compExists.currency && compExists.currency != data.Currency){
          input.push({
            date: (moment(data.Date)).format("YYYY-MM-DD"),
            source_currency: data.Currency,
            target_currency: compExists.currency,
            type: "TRANSACTIONS"
          })
        }

      })
      const filteredArray = this.filterUniquePairs(input);
      input = filteredArray


      const reqBody = {
        "rateCapturedForId": this.portfolioService.selectedFundId,
        "rateCapturedFor": "FUND",
        "module": "EQUITY",
        "input": input
      }

      await this.currExchangeService.initExchangeRates(reqBody);

      transactions.forEach(data => {
        const compExists = this.companies.find( comp => comp.id === data.companyId);

        if(compExists && compExists.currency && compExists.currency != data.Currency){
          const currencyKey = data.Currency + compExists.currency + (moment(data.Date)).format("YYYY-MM-DD")

          if(this.currExchangeService.exchangeRates[currencyKey]){        
            data['exchangeRate'] = this.currExchangeService.exchangeRates[currencyKey] 
              ? this.currExchangeService.exchangeRates[currencyKey] 
              : 1;
            data["convertedValue"] = data.Amount * data["exchangeRate"];
          }
        }
        
        if(compExists) {
          data["valuationCurrency"] = compExists.currency;
        }
      })

      const uniqueListOfMandates = {};

      // Update Vehicle names into transactions

      for(let transData of transactions) {
        const compExists = this.companies.find( comp => comp.id === transData.companyId);

        if(compExists && compExists.currency && transData["vehicleName"] && transData["vehicleName"].length > 0) {
          try {
            const matchingVehicle = this.allVehicles.find(
              (v) => v.mandateName.toLowerCase() == transData["vehicleName"].toLowerCase());

            this.selectedMandateId = matchingVehicle ? matchingVehicle.id : uniqueListOfMandates[transData["vehicleName"].toLowerCase()];

            const vehicleCompanyMappingReqBody = this.getVehiclAndMappingReqBody(transData, (moment(transData.Date)).format("YYYY-MM-DD"), compExists.currency, transData["vehicleName"]);
    
            const vehicleCompanyMapping = await this.dataService.createVehicle(vehicleCompanyMappingReqBody).toPromise();
            const mapping = vehicleCompanyMapping["body"]["response"][0];
            
            transData["mandateId"] = mapping.mandateId;
            transData["mandateCompanyMappingId"] = mapping.id;

            uniqueListOfMandates[transData["vehicleName"].toLowerCase()] = mapping.mandateId;

          } catch(e) {
            transData["vehicleName"] = null;
            console.log("Failed to save vehicle mapping", e)
          }
        }
      }
    }

    changeInTransactionAmount(event, form) { 
      if(this.transData.Currency) {
        
        if(this.selectedCompany && this.selectedCompany.currency && this.selectedCompany.currency == this.transData.Currency) {
                    
          this.transData['Amount_' + this.selectedCompany.currency] 
                = this.utilService.getValidNumber(event.srcElement.value)
                ? this.utilService.getValidNumber(event.srcElement.value)
                : null;
        }

        if(this.reportingCurrency == this.transData.Currency) {
                    
          this.transData['Amount_' + this.reportingCurrency] 
                = this.utilService.getValidNumber(event.srcElement.value)
                ? this.utilService.getValidNumber(event.srcElement.value)
                : null;
        }
      }
    }

    changeInEditTransactionAmount(event) { 
      this.changeInTransactionAmount(event, { })
    }

    navigateToFundLevelCashflows() {
        const url = `${environment.portalUrl}/gnt/#/fund-cash-flow?fundId=${this.fundId}`;
        console.log(url);
        window.open(url, '_self');
    }

    navigateToNetReturnCalculations() {
      const url = `${environment.portalUrl}/gnt/#/gross-to-net?fundId=${this.fundId}`;
      console.log(url);
      window.open(url, '_self');
    }

    getVehiclAndMappingReqBody(transaction, date, companyCurrency, vehicleName) {
      const user = this.ums.getUserDetails();
      
      const reqBody = {
        "mandates": [{
          "lastModifiedBy": user.userName,
          "createdBy": user.userName,
          "mandateName": vehicleName,
          "mandateId": this.selectedMandateId,
          "parentId": this.fundId,
          
          "mandateCurrency": companyCurrency,
          "investmentDate": date,
          "committedCapital": 0,
          "stake": transaction.Traded_Stake,
          "investmentAmount": transaction.convertedValue,
          "companyId": transaction.companyId,
          "fundId": this.fundId,
        }],

        "companyId": transaction.companyId,
        "fundId": this.fundId,
        "createdBy": user.userName,
        "lastModifiedBy": user.userName
      }

      return reqBody;
    }

    filterVehicleNames(input) {
      if(!input) {
        this.filteredVehicles = this.allVehicles; 
      }
      else {
        const lowerCaseInput = input.toLowerCase();
        this.filteredVehicles = this.allVehicles.filter(
          (v) => v.mandateName.toLowerCase().includes(lowerCaseInput) );
      }
    }

    initVehicleName(option, transData) {
      this.selectedMandateId = option.id;
      transData.vehicleName = option.mandateName;
    }
}
