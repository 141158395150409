import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserManagementService } from '../../../services/user-management.service';
import { FormGroup, FormBuilder,Validators, FormArray,FormControl } from '@angular/forms';
import { ToastService } from 'src/app/utils/toast.service';

@Component({
  selector: 'app-leverage-ratio',
  templateUrl: './leverage-ratio.component.html',
  styleUrls: ['./leverage-ratio.component.scss']
})
export class LeverageRatioComponent implements OnInit {

  gridArray = [];
  performanceArray = [];
  orgId: any;
  userId: any;
  selectedMethod: any;
  isLoading: boolean = false;
  isLeverageData: boolean = false;
  isClassificationData: boolean = false;
  isSaving: boolean = false;
  isClassificationSaving: boolean = false;
  classificationComments: any;
  selectedfairValue: any;
  selectedValueBridge: any;
  selectedPerecentPar: boolean;
  selectdNumberOfDecimals = 1;
  isNumberOfDecimalsSaved: boolean;
  fairValueSaved: boolean = false;
  percentParValueSaved: boolean = false;
  selectedPriceRounding: any;
  priceRoundingValueSaved: boolean = false;
  selectedPriceRoundingValue:number;
  selectedDefaultCreditQuality: any;
  isCreditQualitySaving: boolean = false;
  isValueBridgeSaving: boolean = false;
  selectedCallPremium: any;
  selectedCallPremiumValue: any;
  isCallPremiumCapSaving: boolean = false;
  selectedForwardRate: any;
  isForwardRateSaving: boolean = false;
  selectedDiscountFactor: any;
  isDiscountFactorSaving: boolean = false;
  selectedDifferentDiscounting: any;
  isDifferentDiscountingSaving: boolean = false;
  selectedDiscountRateMethodology: any;
  isDiscountRateMethodologySaving: boolean = false;
  selectedOIDImpact: any;
  selectedCSAImpact: any;
  isOIDImpactSaving: boolean = false;
  selectedMiddleMarketAdj: any;
  isMiddleMarketAdjSaving: boolean = false;
  selectedEurLliAdj: any;
  isEurLliAdj: boolean = false;
  selectedSpreadMatrixAdjustments: string = '';
  isSpreadMatrixAdjustmentsSaving: boolean = false;
  isCSAImpactSaving: boolean = false;
  selectedTags: any;
  isTagsSaving: boolean = false;
  fairValueSelected : any = {
    'dirtyPrice' : 'Dirty Price',
    'cleanPrice' : 'Clean Price',
  }
  tagsForm : FormGroup;
  singleTag : FormArray;
  option = new FormControl('',[Validators.required]);
  selectedAdditionalInput : any;
  isInputSaving: boolean = false;
  additionalInputForm : FormGroup;
  additionalInput : FormArray;
  selectedShadowRating: boolean = false;
  isShadowRating: boolean = false;
  selectedSpreadBuildUp : any;
  isSpreadBuildUp: boolean = false;
  isQualitativeAnalysis: boolean = false;
  qualitativeAnalysisNewTitle = new FormControl('',[Validators.required]);
  qualitativeAnalysisTitleList:any[] = [];
  isFinancialNWC:boolean = false;
  selectedFinancialNWC:boolean = false;
  //[(UAT,EU PROD -> 73Strings Testing 1), (EU PROD -> 73S Client Demo), (EU PROD, US PROD 2 -> Blackstone Credit Pilot), (EU PROD, US PROD 2 -> Blackstone Pilot), (9a7b2bd7-ac96-443d-8a0c-960cdf1407eb(us prod 2) -> arshiya orgId)]
  bxOrganisationId = ['b2ccc9fd-fe19-41e3-b49e-a65b4113d8b3', '556bde28-29ac-4f8b-b35c-e957b4eedea5', 'aaa66e18-bdb6-4bcf-844d-dba779f16413', 'b54f0b78-8d5f-42a5-9854-aa36eb8680cc','6194477e-4df5-499f-87e8-b13fe7c81337', '214fa428-0f39-4c06-9343-d0c51c9b4e34', '9a7b2bd7-ac96-443d-8a0c-960cdf1407eb', '9a7b2bd7-ac96-443d-8a0c-960cdf1407eb', '4a4b052c-edfb-48e0-af87-d8086c6346a6', '322597b1-8165-4c72-a59c-0fe0e552b83e'];
  isClientSpecificSettings: boolean = false;
  selectedNetLatestLeverage:boolean = false;
  isNetLatestLeverage:boolean = false;
  selectedDiscountRateType: boolean = false;
  isDiscountRateType : boolean = false;
  selectedAmortization: string = '';
  isAmortization:boolean = false;
  additionalInputErrorState = {
    duplicateAdditionalInputFound: false
  };

  tagsErrorState = {
    duplicateTagsFound: false
  };
  selectedImpliedYieldCalibration : any;
  isImpliedYieldCalibration : boolean = false;
  selectedSecurityLinkage : boolean = false;
  isSecurityLinkage: boolean = false;

  isCompoundingInterestPayments: boolean = false;
  isCompoundingInterestPaymentsSaving: boolean = false;
  isValuationSignOff: boolean = false;
  isValuationSignOffSaving: boolean = false;
  isSingleRegimeAllocation: boolean = false;
  isSingleRegimeAllocationSaving: boolean = false;
  lowValid: boolean = false;
  highValid: boolean = false;
  errorMessage: string = '';
  isInputValid: boolean = true;
  isSRTCardsSelected: boolean = false;
  isSRTCardSaving: boolean = false;

  constructor(private dataService: DataService, private um: UserManagementService,
    private modalService: NgbModal, private fb: FormBuilder, private toastService: ToastService) { }

  ngOnInit(): void {
    const userDetails = this.um.getSelectedUserDetails();
    this.orgId = userDetails?.organization?.id
    this.userId = userDetails?.userId; 
    if (this.bxOrganisationId.includes(this.orgId)) {
      this.isClientSpecificSettings = true;
    }

    this.dataService.getGlobalClassification(this.orgId)
      .subscribe((res: any) => {
        this.performanceArray = res?.body?.response?.data;
        this.classificationComments = res?.body?.response?.comments;
        this.isClassificationData = this.performanceArray?.length ? true : false;
      })

    this.dataService.getLeverageRatioData(this.orgId)
      .subscribe((res: any) => {
        this.isLoading = true;
        this.gridArray = res.body.response;
        this.isLeverageData = this.gridArray?.length ? true : false
      })

    this.dataService.getGlobalFairValueConclusion(this.orgId)
      .subscribe((res: any) => {
        this.selectedfairValue = res?.body?.response?.data?.fairValue;
      })

    this.dataService.getGlobalPercentParComputation(this.orgId)
      .subscribe((res: any) => {
        this.selectedPerecentPar = res?.body?.response?.percentParComputation;
      })

      this.dataService.getPriceRounding(this.orgId)       
       .subscribe((res: any) => {
        let obj = res?.body?.response?.configValue;
           this.selectedPriceRounding = JSON.parse(obj).setting
           this.selectedPriceRoundingValue = JSON.parse(obj).value     
       })
      
    this.dataService.getGlobalDefaultCreditQuality(this.orgId)
      .subscribe((res: any) => {
        this.selectedDefaultCreditQuality = res?.body?.response?.isResetToDefault;
      })

    this.dataService.getGlobalCallPremiumCap(this.orgId)
      .subscribe((res: any) => {
        this.selectedCallPremium = res?.body?.response?.capCallPremium;
        this.selectedCallPremiumValue = res?.body?.response?.capCleanOrDirty;
      })

    this.dataService.getForwardLag(this.orgId)
      .subscribe((res: any) => {
        this.selectedForwardRate = res?.body?.response?.configValue;
      })

    this.dataService.getDiscountFactor(this.orgId)
      .subscribe((res: any) => {
        this.selectedDiscountFactor = res?.body?.response?.configValue;
      })
    
    this.dataService.getDifferentDiscounting(this.orgId)
      .subscribe((res: any) => {
        this.selectedDifferentDiscounting = res?.body?.response?.configValue;
      });

    this.dataService.getWarrantValuationDiscount(this.orgId)
      .subscribe((res: any) => {
        this.selectedDiscountRateMethodology = res?.body?.response?.configValue;
      });

      this.dataService.getNumberOfDecimals(this.orgId)
      .subscribe((res: any) => {
        this.selectdNumberOfDecimals = res?.body?.response?.configValue;
      });

    this.dataService.getOIDImpact(this.orgId)
      .subscribe((res: any) => {
        this.selectedOIDImpact = res?.body?.response?.configValue;
      });

    this.dataService.getCSAImpact(this.orgId)
      .subscribe((res: any) => {
        this.selectedCSAImpact = res?.body?.response?.configValue;        
      });

    this.dataService.getMiddleMarketAdj(this.orgId)
      .subscribe((res: any) => {
        this.selectedMiddleMarketAdj = res?.body?.response?.configValue;
      })
    
    this.dataService.getSpreadMatrixAdjustments(this.orgId)
      .subscribe(res => {
        this.selectedSpreadMatrixAdjustments = res?.body?.response?.configValue;
      });

    this.dataService.getTags(this.orgId)
      .subscribe((res: any) => {
        this.selectedTags= JSON.parse(res?.body?.response?.configValue).isTagsAdded;
        let tagValue = JSON.parse(res?.body?.response?.configValue).tagsValues;
        for (let tag of tagValue){
          this.addTag(tag);
        }       
      });

    this.dataService.getAdditionalInput(this.orgId)
      .subscribe((res: any) => {
      this.selectedAdditionalInput = JSON.parse(res?.body?.response?.configValue).isAdditionalInputsAdded;
      let inputValue = JSON.parse(res?.body?.response?.configValue).additionalInputs;
      if(inputValue)
      for (let additionalValue of inputValue) {
        this.addInputs(additionalValue);
      }
    });

    this.dataService.getCompoundingInterestPayments(this.orgId)
      .subscribe((res: any) => {
        this.isCompoundingInterestPayments = res?.body?.response?.configValue === '1';
      });
      
    this.dataService.getValuationSignOff(this.orgId)
      .subscribe((res: any) => {
        this.isValuationSignOff = res?.body?.response?.configValue === '1';
     });

    this.dataService.getSpreadBuildUp(this.orgId)
      .subscribe((res: any) => {
        this.selectedSpreadBuildUp = res?.body?.response?.configValue;
      });

      this.dataService.getQualitativeAnalysisTitle(this.orgId)
      .subscribe((res: any) => {
        const allQualitativeAnalysis = JSON.parse(res?.body?.response?.configValue)?.keys;
        for(let qualitativeAnalysis of allQualitativeAnalysis ){
          this.qualitativeAnalysisTitleList.push({
            editStatus:false,
            value:qualitativeAnalysis
          })
        }
      });

      //Get Global Config Status
      this.dataService.getGlobalConfig(this.orgId)
      .subscribe((res: any) => {
        let allOrgSettings = res?.body?.response?.orgSettings;
        this.selectedFinancialNWC = allOrgSettings?.find(e=>e?.orgSettingsKey=='NWC')?.isActive || false;
        this.selectedShadowRating = allOrgSettings?.find(e=>e?.orgSettingsKey=='BX_SHADOW_RATING')?.isActive || false;
        this.selectedNetLatestLeverage = allOrgSettings?.find(e=>e?.orgSettingsKey=='NET_LATEST_LEVERAGE')?.isActive || false;
        this.selectedDiscountRateType = allOrgSettings?.find(e => e?.orgSettingsKey=='DISCOUNT_RATE_TYPE')?.isActive || false;
        this.selectedEurLliAdj = allOrgSettings?.find( e => e?.orgSettingsKey == 'EUR_LLI_ADJUSTMENT')?.isActive || false;
        this.selectedAmortization = allOrgSettings?.find(e => e?.orgSettingsKey=='AMORTIZATION_SCHEDULE')?.isActive || false;
        this.selectedImpliedYieldCalibration = allOrgSettings?.find(e => e?.orgSettingsKey == 'RECALIBRATION')?.isActive || false;
        this.selectedSecurityLinkage = allOrgSettings?.find( e => e?.orgSettingsKey == 'SECURITY_LINKAGE')?.isActive || false;
        this.isSingleRegimeAllocation = allOrgSettings?.find( e => e?.orgSettingsKey == 'SINGLE_REGIME_CASH_OR_PIK_ALLOCATION')?.isActive || false;
        this.isSRTCardsSelected = allOrgSettings?.find( e => e?.orgSettingsKey == 'SRT_ASSETS_CARD')?.isActive || false;
        this.selectedValueBridge = allOrgSettings?.find( e => e?.orgSettingsKey == 'VALUE_BRIDGE_ENABLED')?.isActive || false;
      });
      
    this.createTagForm();
    this.createAdditionalInputForm();
  }

  // for tags 
  createTagForm(){
    this.tagsForm = new FormGroup({
      allTagFormData : new FormArray([])
    });
    this.tagsForm.valueChanges.subscribe(tagsForm => {
      const tags = tagsForm.allTagFormData as { name: string; level: 'company' | 'security'; options: string[] }[];
      if (!tags) return;
      const uniqueTagNames: Set<string> = tags.reduce(
        (acc, tag) => acc.add(tag.name), new Set<string>()
      );
      this.tagsErrorState = {
        ...this.tagsErrorState,
        duplicateTagsFound: uniqueTagNames.size != tags.length
      };
    });
  }

  createTagSingleForm(formValue){
    return this.fb.group({
      name: formValue?.name || '',
      level: formValue?.level || '',
      options: formValue?.options?[formValue?.options]:this.fb.array([])
    })
  }

  addTag(formValue:any){
    this.singleTag = this.tagsForm.get('allTagFormData') as FormArray;
    this.singleTag.push(this.createTagSingleForm(formValue));
  }

  deleteTag(index){
    this.singleTag.removeAt(index);
  }

  addOption(i: number){
    let tags= this.option.value;
    if(this.option.valid){
      this.tagsForm.value.allTagFormData[i].options.push(tags);
      this.option.reset();
    } 
  }

  removeOption(i: number, option: string) {
    const index = this.tagsForm.value.allTagFormData[i].options.indexOf(option);
    if (index >= 0) {
      this.tagsForm.value.allTagFormData[i].options.splice(index, 1);
    }
  }

  // bx additional inputs
  createAdditionalInputForm(){
    this.additionalInputForm = new FormGroup({
      allAdditionalInput : new FormArray([])
    });
    this.additionalInputForm.valueChanges.subscribe(additionalInputsWrapper => {
      const additionalInputs = additionalInputsWrapper.allAdditionalInput as { name: string, level: string }[];
      if (!additionalInputs) return;
      const uniqueAdditionalInputNames: Set<string> = additionalInputs.reduce(
        (acc, additionalInput) => acc.add(additionalInput.name), new Set<string>()
      );
      this.additionalInputErrorState = {
        ...this.additionalInputErrorState,
        duplicateAdditionalInputFound: uniqueAdditionalInputNames.size != additionalInputs.length
      };
    });
  }

  createInputForm(formValue){
    return this.fb.group({
      name: formValue?.name ||'',
      level: formValue?.level ||'',
    })
  }

  addInputs(data:any) {
    this.additionalInput = this.additionalInputForm.get('allAdditionalInput') as FormArray;
    this.additionalInput.push(this.createInputForm(data));
  }

  deleteInput(index){
    this.additionalInput.removeAt(index);
  }

  addNewMetric() {
    this.isLeverageData = true;
    let obj =
    {
      low: "",
      high: "",
      concludedAdjustmentFactor: "",
      isEdit: true,
      isValid: false
    }

    this.gridArray.push(obj);
    this.checkOverallValidity()
  }

  validateInputs(row: any, index: number) {
    let isInvalid = false;
    // Ensure both low and high are not null
    if (row.low === null || row.low === "" || row.high === null || row.high === "") {
      this.errorMessage = "";
      row.isValid = false;
      this.checkOverallValidity();
      return;
    }
    // convert low and high to float for comparison
    const lowValue = parseFloat(row.low);
    const highValue = parseFloat(row.high);

    // check if low is greater than or equal to high
    if (lowValue >= highValue) {
      isInvalid = true;
    }

    // check for overlapping ranges in the grid array
    for (let i = 0; i < this.gridArray.length; i++) {
      if (i !== index) { // Skip the current row
        const grid = this.gridArray[i];
        const gridLow = parseFloat(grid.low);
        const gridHigh = parseFloat(grid.high);

        if ((lowValue > gridLow && lowValue < gridHigh) ||
          (highValue > gridLow && highValue < gridHigh) ||
          (lowValue <= gridLow && highValue >= gridHigh)) {
          isInvalid = true;
          break;
        }
      }
    }
    row.isValid = !isInvalid;
    this.errorMessage = isInvalid ? "This range conflicts with an existing range added historically." : "";
    this.checkOverallValidity();
  }

  checkOverallValidity() {
    this.isInputValid = this.gridArray.every(row => row.isValid !== false);
  }

  addNewClassification() {
    this.isClassificationData = true;
    let obj =
    {
      upperBound: "",
      classification: "",
      isEdit: true
    }

    this.performanceArray.push(obj);
  }

  deleteMetric(index) {
    this.gridArray.splice(index, 1);
    //this.findValueInRange();
    this.errorMessage = '';
    this.checkOverallValidity(); 
    if(!this.gridArray.length) {
      this.isLeverageData = false
    }
  }

  deleteClassification(index) {
    this.performanceArray.splice(index, 1);
    if(!this.performanceArray.length) {
      this.isClassificationData = false;
      this.classificationComments = '';
    }
  }

  updateMetric() {
    if (this.isSaving) {
      return; // Prevent multiple API calls if already saving
    }
    this.isSaving = true;
    this.gridArray.map((x: any) => {
      delete x.isEdit;
      return x;
    })
    const payLoadObj = {
      gridData: this.gridArray
    }
    this.dataService.saveLeverageRatioData(this.orgId, payLoadObj)
      .subscribe((res: any) => {
        this.isSaving = false;
        this.isInputValid = true;
      })
  }

  updateClassification() {
    this.isClassificationSaving = true;
    this.performanceArray.map((x: any) => {
      delete x.isEdit;
      return x;
    })
    const payLoadObj = {
      data: this.performanceArray,
      comments: this.classificationComments
    }
    this.dataService.saveGlobalClassification(this.orgId, payLoadObj)
      .subscribe((res: any) => {
        this.isClassificationSaving = false;
      })
  }

  fairValueChanged() {
    this.fairValueSaved = true;
    const payLoadObj = {
      data: { fairValue : this.selectedfairValue}
    }
    this.modalService.dismissAll();
    
    this.dataService.saveGlobalFairValueConclusion(this.orgId, payLoadObj)
    .subscribe((res: any) => {
      this.fairValueSaved = false;
    })
  }

  percentParChanged() {
    this.percentParValueSaved = true;
    const payLoadObj = { "percentParComputation" : this.selectedPerecentPar };
    this.modalService.dismissAll();
    
    this.dataService.saveGlobalPercentParComputation(this.orgId, payLoadObj)
    .subscribe((res: any) => {
      this.percentParValueSaved = false;
    })
  }

  savePriceRounding() {
    this.priceRoundingValueSaved = true;
    const payLoadObj ={
             "configKey": "PRICE_ROUNDING",
             "configValue": JSON.stringify({
              "setting": this.selectedPriceRounding,
              "value": this.selectedPriceRoundingValue,
             }),
             "orgId": this.orgId
            };
    this.modalService.dismissAll();
    this.dataService.savePriceRound(payLoadObj)
    .subscribe((res: any) => {
      this.priceRoundingValueSaved = false;
    })
  }

  openPopupModal(content){
    this.modalService.open(content, { centered: true , windowClass: 'center-popup-modal', size: 'lg'});
  }

  saveCreditQualityChanged() {
    this.isCreditQualitySaving = true;
    const payLoadObj = { "isResetToDefault" : this.selectedDefaultCreditQuality };
    this.modalService.dismissAll();
    
    this.dataService.saveGlobalDefaultCreditQuality(this.orgId, payLoadObj)
    .subscribe((res: any) => {
      this.isCreditQualitySaving = false;
    })
  }

  saveCallPremiumCap() {
    this.isCallPremiumCapSaving = true;
    const payLoadObj = { 
      "capCallPremium" : this.selectedCallPremium,
      "capCleanOrDirty" : this.selectedCallPremiumValue,
     };
    this.modalService.dismissAll();
    
    this.dataService.saveGlobalCallPremiumCap(this.orgId, payLoadObj)
    .subscribe((res: any) => {
      this.isCallPremiumCapSaving = false;
    })
  }

  saveForwardRate() {
    this.isForwardRateSaving = true;
    const payLoadObj = { 
      "configKey" : 'LAG_IN_FORWARD_BASE',
      "configValue": this.selectedForwardRate,
      "orgId": this.orgId
    };

    this.modalService.dismissAll();
    this.dataService.saveForwardLag(payLoadObj)
    .subscribe((res: any) => {
      this.isForwardRateSaving = false;
    })
  }

  discountFactorChanged() {
    this.isDiscountFactorSaving = true;
    const payLoadObj = { 
      "configKey" : 'ALTERNATIVE_DISCOUNT_FACTOR',
      "configValue": this.selectedDiscountFactor,
      "orgId": this.orgId
    };

    this.modalService.dismissAll();
    this.dataService.saveDiscountFactor(payLoadObj)
    .subscribe((res: any) => {
      this.isDiscountFactorSaving = false;
    })
  }

  differentDiscountingChanged() {
    this.isDifferentDiscountingSaving = true;
    const payLoadObj = {
      'configKey': 'DIFFERENT_DISCOUNTING',
      'configValue': this.selectedDifferentDiscounting,
      'orgId': this.orgId
    };
    this.modalService.dismissAll();
    this.dataService.saveDifferentDiscounting(payLoadObj)
      .subscribe(() => {
        this.isDifferentDiscountingSaving = false;
      });
  }

  numberofDecimalsChanged() {
    this.isNumberOfDecimalsSaved = true;
    const payLoadObj = {
      'configKey': 'NUMBER_OF_DECIMALS',
      'configValue': this.selectdNumberOfDecimals,
      'orgId': this.orgId
    };
    this.modalService.dismissAll();
    this.dataService.saveNumberOfDecimals(payLoadObj)
      .subscribe(() => {
        this.isNumberOfDecimalsSaved = false;
        window.location.reload();
      });
  }

  warrantValuationDiscountChanged() {
    this.isDiscountRateMethodologySaving = true;
    const payLoadObj = {
      'configKey': 'WARRANT_VALUATION_DEBT_DISCOUNT_RATE',
      'configValue': this.selectedDiscountRateMethodology,
      'orgId': this.orgId
    };
    this.modalService.dismissAll();
    this.dataService.savewarrantValuationDiscount(payLoadObj)
      .subscribe(() => {
        this.isDiscountRateMethodologySaving = false;
      });
  }

  oidImpactChanged() {
    this.isOIDImpactSaving = true;
    const payLoadObj = {
      'configKey': 'OID_IMPACT_ON_VALUATION',
      'configValue': this.selectedOIDImpact,
      'orgId': this.orgId
    };
    this.modalService.dismissAll();
    this.dataService.savewarrantValuationDiscount(payLoadObj)
      .subscribe(() => {
        this.isOIDImpactSaving = false;
      });
  }

  csaImpactChanged(){
    this.isCSAImpactSaving = true;    
    const payLoadObj = {
      'configKey': 'CREDIT_SPREAD_ADJUSTMENT',
      'configValue':this.selectedCSAImpact,
      'orgId': this.orgId
    };
    this.modalService.dismissAll();
    this.dataService.saveCSAImpact(payLoadObj)
      .subscribe(() => {
        this.isCSAImpactSaving = false;
      });
  }

  middleMarketChanged() {
    this.isMiddleMarketAdjSaving = true;
    const payLoadObj = {
      'configKey': 'MIDDLE_MARKET_ADJUSTMENT',
      'configValue': this.selectedMiddleMarketAdj,
      'orgId': this.orgId
    };
    
    this.modalService.dismissAll();
    this.dataService.saveMiddleMarketAdj(payLoadObj)
      .subscribe(() => {
        this.isMiddleMarketAdjSaving = false;
      })
    }

  spreadMatrixAdjustmentChanged() {
    this.isSpreadMatrixAdjustmentsSaving = true;
    const payload = {
      configKey: 'SPREAD_MATRIX_ADJUSTMENTS',
      configValue: this.selectedSpreadMatrixAdjustments,
      orgId: this.orgId
    };
    this.modalService.dismissAll();
    this.dataService.saveSpreadMatrixAdjustment(payload)
      .subscribe(() => {
        this.isSpreadMatrixAdjustmentsSaving = false;
      });
  }

  tagsChanged() {
    this.isTagsSaving = true;
    const payLoadObj = {
      'configKey': 'BX_TAGS',
      'configValue': JSON.stringify({
        'isTagsAdded': this.selectedTags,
        'tagsValues': (this.selectedTags == '1') ? this.tagsForm.value.allTagFormData : []
      }),
      'orgId': this.orgId
    };

    this.modalService.dismissAll();
    this.dataService.saveTags(payLoadObj)
      .subscribe(() => {
        this.isTagsSaving = false;
      })
  }

  additionalInputChanged(){
    this.isInputSaving = true;
    const payLoadObj = {
      'configKey': 'ADDITIONAL_INPUTS',
      'configValue': JSON.stringify({
        'isAdditionalInputsAdded': this.selectedAdditionalInput,
        'additionalInputs': (this.selectedAdditionalInput == '1') ? this.additionalInputForm.value.allAdditionalInput : []
      }),
      'orgId': this.orgId
    };
    this.modalService.dismissAll();
    this.dataService.saveAdditionalInputs(payLoadObj)
      .subscribe(() => {
        this.isInputSaving = false;
      })
  }


  spreadBuildUpChanged() {
    this.isSpreadBuildUp = true;
    const payLoadObj = {
      'configKey': 'TRANCHE_ANALYSIS',
      'configValue': this.selectedSpreadBuildUp,
      'orgId': this.orgId
    };
    this.modalService.dismissAll();
    this.dataService.saveSpreadBuildUp(payLoadObj)
      .subscribe(() => {
        this.isSpreadBuildUp = false;
      })
  }

  compoundingInterestPaymentsChanged(): void {
    this.isCompoundingInterestPaymentsSaving = true;
    const payLoadObj  = {
      configKey: 'COMPOUNDING_INTEREST_PAYMENTS_SUPPORTED',
      configValue: +this.isCompoundingInterestPayments,
      orgId: this.orgId
    };
    this.modalService.dismissAll();
    this.dataService.saveCompoundingInterestPayments(payLoadObj)
      .subscribe(() => this.isCompoundingInterestPaymentsSaving = false);
  }

  valuationSignOffChanged(): void {
    this.isValuationSignOffSaving = true;
    const payLoadObj  = {
      configKey: 'FUND_LEVEL_VALUATION_SIGN_OFF',
      configValue: +this.isValuationSignOff,
      orgId: this.orgId
    };
    this.modalService.dismissAll();
    this.dataService.saveGenericConfig(payLoadObj)
      .subscribe(() => this.isValuationSignOffSaving = false);
  }

  singleRegimeAllocationChanged(): void {
    this.isSingleRegimeAllocationSaving = true;
    const payLoadObj  = {
      orgSettingsKey: 'SINGLE_REGIME_CASH_OR_PIK_ALLOCATION',
      isActive: !!this.isSingleRegimeAllocation,
      orgId: this.orgId,
      userId: this.userId,
    };
    this.modalService.dismissAll();
    this.dataService.updateGenericConfigSettings(payLoadObj)
      .subscribe(() => this.isSingleRegimeAllocationSaving = false);
  }

   //Add new title
   addNewTitle(){
    const newValue = this.qualitativeAnalysisNewTitle.value;
    this.qualitativeAnalysisTitleList.push({editStatus:false,value:newValue});
  }

  //Delete title
  deleteTitle(index:number){
    this.qualitativeAnalysisTitleList.splice(index,1)
  }

  //Update title
  updateTitle(index:number,updateTitle:string){
    this.qualitativeAnalysisTitleList[index]={editStatus:false,value:updateTitle}
  }

  //Changed in update/delete/add QA
  qualitativeAnalysisChanged(){
    this.qualitativeAnalysisNewTitle.reset();
    let updatedQualitativeList = this.qualitativeAnalysisTitleList.map(m=>m.value);
    this.isQualitativeAnalysis = true;
    const payLoadObj={
      "configKey" : "QUALITATIVE_ANALYSIS",
      "configValue" : JSON.stringify({ keys : updatedQualitativeList}),
      "orgId" : this.orgId
    }
    this.modalService.dismissAll();
    this.dataService.saveQualitativeAnalysis(payLoadObj).subscribe(() => {
      this.isQualitativeAnalysis = false;
    })
  }

  //Save Config Status
  saveConfigStatus(keyName:string,value:boolean){
    const payLoadObj = {
      "orgId": this.orgId,
	    "orgSettingsKey": keyName,
	    "isActive": value,
      "userId": this.userId
    };
    this.modalService.dismissAll();
    this.dataService.saveConfigStatus(payLoadObj)
      .subscribe(() => {
        //Setting Initial Value
        this.isFinancialNWC = false;
        this.isShadowRating = false;
        this.isNetLatestLeverage = false;
        this.isDiscountRateType = false;
        this.isEurLliAdj = false;
        this.isAmortization =false;
        this.isImpliedYieldCalibration = false;
        this.isSecurityLinkage = false;
        this.isSRTCardSaving = false;
        this.isValueBridgeSaving =false;
        if(keyName == 'RECALIBRATION'){
          let name = this.selectedImpliedYieldCalibration?'Dirty Price':'Clean Price';
          this.toastService.openSnackBar('Yield Calibration methodology has been updated to ' + name);
        }
      })
  }

}