import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, NavigationStart, Event, ActivatedRoute } from '@angular/router';
import { DataService } from './services/data.service';
import { UserManagementService } from './services/user-management.service';
import { MessageService } from './services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageLoadService } from './services/image-load.service';
import { environment } from 'src/environments/environment';

import { UtilService } from './utils/util.service';
import { NavigationService } from './services/navigation.service';
import { NotificationService } from './utils/notification/notification.service';
import { FundListService } from './qubit-x/portfolio/fund-list-ui/fund-list.service';
import { TranslateService } from './services/translation.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { syncfusion_data } from './fr-syncfusion.model';
import { DateAdapter } from '@angular/material/core';
import { ThemeService } from './utils/theme.service';
import { HttpParams } from '@angular/common/http';

L10n.load(syncfusion_data);

declare let GC: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{
  userName = "";
  notificationDropdown: boolean = false;
  mobileQuery: MediaQueryList;
  isLoggedIn = false;
  showNineDotMenu = false;

  notifications = [];

  navItems = [];
  portfolioNavItems = [];

  isProduction = true;
  
  searchedMatchingCompanies;
  searchedSavedCompanies = [];

  Organization = { name: "" } 

  productName = "73 Value"; // Proton X

  notification;
  

  orgLogoUrl;

  constructor( private ms: MessageService,
    public router: Router, private ds:DataService, private utilService: UtilService,
    public notificationService: NotificationService,
    private us: UserManagementService, private modalService: NgbModal, private imageLoadService: ImageLoadService, private route: ActivatedRoute, public navigationService: NavigationService,
    private fundListService: FundListService, public translateService: TranslateService, private dateAdapter: DateAdapter<any>,
    private themeService: ThemeService) {

      //Release Build Status
      console.log("Release Date - Jan 13, 2024");
      
      // Code needed for local run of the application.
      // Developer has to copy the session details from hosted url.
      let id_token = this.route.snapshot.paramMap.get('id_token');

      if(environment["dev"] && environment["dev"]["73S-Auth"] && !id_token) {
        for(let key in environment["dev"]) {
          localStorage.setItem(key, environment["dev"][key]);
          environment[key] = environment["dev"][key];

          console.log("Setting local storage for " + key);
        }
      } 

      this.us.setLoginSource(window.location.hash);

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if(event.url === "/" || event.url.toUpperCase() === '/AUTH') {
          this.isLoggedIn = false;
        }
        else{
          this.isLoggedIn = true;
        }
      }
    });

    this.ms.subscribe("user-logged-in", loggedInUser =>{
      setTimeout(async () => {
        // Check for login done for different source
        await this.us.redirectToLoginSource();

        this.navigationService.enableSideMenuItems();
      });
    })
    
    this.ms.subscribe("selected-user-init", loggedInUser =>{

      const initSelectedUser = setInterval(() => {
        const user = this.us.getSelectedUserDetails();

        this.themeService.refreshTheme(user);

        if(user.organization){
          this.orgLogoUrl = localStorage.getItem(user.organization.id + "_THEME");
          this.orgLogoUrl = JSON.parse(this.orgLogoUrl)
        }

        if(!user.id) return;
        else clearInterval(initSelectedUser);

        this.searchedSavedCompanies = [];
  
        this.notificationService.init();
  
        this.fundListService.getFunds(user);
  
        this.navigationService.enableSideMenuItems();
  
        this.ds.getWidgetDataFromDB("LAST_SEARCHED_COMPANIES", user.id).subscribe(res => {
  
          if(res.body["response"][0]["widgetData"]) {
            this.searchedSavedCompanies = res.body["response"][0]["widgetData"];
          }
            
          this.searchedMatchingCompanies = this.searchedSavedCompanies;
        }, error => {
          this.searchedSavedCompanies = [];
          this.searchedMatchingCompanies = [];
        })
      });
    })

    this.notificationService.latestNotification$.subscribe(() => {
      this.notification = this.notificationService.notification;
      console.log("Notification Received", this.notification)
    })
  }

  markAllAsRead() {
    this.notificationService.notificatioNmarkAllAsRead();
  }

  // This is only for local development.
  @HostListener('window:keydown.control.shift.k', ['$event'])
  @HostListener('window:keydown.control.alt.c', ['$event'])
  copyUserDetails(event: KeyboardEvent) {
    event.preventDefault();
    let admin73SAuth = this.us.getHeaderToken()
    let users73S = this.us.getUserDetails()
    let selectedUser73S = this.us.getSelectedUserDetails()
    let selectedUserAuthToken = this.us.getSelectedUserAuthToken()

    if(!selectedUser73S.id) return;

    let obj ={
      "73S-Auth" : admin73SAuth,
      "73S-User" : JSON.stringify(users73S),
      "73s-selected-vc" : JSON.stringify(selectedUser73S),
      "73S-Selected-Auth":selectedUserAuthToken,
      "apiGateway": environment.apiGateway
    }

    navigator.clipboard.writeText(JSON.stringify(obj));
  }

  ngOnInit() {
    this.isProduction = environment.production;
    GC.Spread.Sheets.LicenseKey = environment.grapeCityLicense;
    GC.Spread.Excel.IO.LicenseKey = environment.grapeCityLicense;

    let routerSubscription: Subscription = this.router.events.pipe(first()).subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes('organization')) {
          return;
        } else {
          this.initApp();
        }
        routerSubscription.unsubscribe();
      }
    });
  }

  // openHelpCentrePopup(){
  //   const iframe  = document.getElementById('document360-widget-iframe') as HTMLIFrameElement;            
  //   const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
  //   console.log("iframeDoc", iframeDoc);
  //   if(iframeDoc){
  //       const elementInsideIframe = iframeDoc.getElementById('doc360-button');
  //       elementInsideIframe.click();
  //   }
  // }
    
  initApp() {
    this.userName = this.navigationService.userName;
    this.navigationService.enableSideMenuItems();
    const token = localStorage.getItem("73S-Auth");

    if (!token) {
      this.logout()

    } else {
      this.us.testAuthToken().subscribe((data) => {
        if (!data.body["success"]) {
          this.logout()

        } else {

        }
      }, error => {
        console.log("Auth token expired");
        this.logout()
      });
    }
    
    if(this.translateService.selectedLang == 'FR'){
      setCulture('fr');
      this.dateAdapter.setLocale('fr');
    }

    this.themeService.setDefaultTheme();
  }

  async logout(redirect = false) {
    const currentUserId = this.us.getUserDetails().organization;
    const userDomain = currentUserId?.domainName || '';

    this.us.logout();

    if (window.location.hash.includes('id_token=')) {
      this.router.navigateByUrl(`/auth/${window.location.hash.split('id_token=')[1]}`);
      return;
    }

    if (redirect) {
      const redirectURL = userDomain ? `${environment.portalUrl}/#/organization/${userDomain}` : `${environment.portalUrl}/#/logout`;
      console.log("logging out . . . .");
      const logoutURL = await this.prepareLogoutURL(redirectURL);
      window.open(logoutURL, '_self');
    }
  }

  async prepareLogoutURL(redirectURL){
    const logoutURL = ((await this.us.getSingleSignOnURL().toPromise() as any).body["response"]).replace('/authorize?', '/logout?');

    const url = new URL(logoutURL);   
 
    let params = new HttpParams({ fromString: url.searchParams.toString() });   
    params = params.set('redirect_uri', redirectURL);

    url.search = params.toString();  
    return url.toString();
  }

  ngOnDestroy(): void {

  }

  showNotification() {
    this.notificationDropdown = !this.notificationDropdown;
  }

  openDebtValuation() {
    window.open(environment.portalUrl + environment.debtModel);
  }

  openChangePasswordForm() {
    window.open(environment.portalUrl + "/uam/#/open-profile");
  }
  
  openHelpWindow(){
    window.open("https://73strings.atlassian.net/servicedesk/customer/portal/17");
  }
  
  
}
