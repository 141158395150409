import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import cloneDeep from 'lodash/cloneDeep';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { UtilService } from '../utils/util.service';
import { DataService } from '../services/data.service';
import { InvestmentSummaryService } from '../qubit-x/portfolio/investment-page-summary-v2/investment-summary.service';
import { TranslateService } from '../services/translation.service';
import { PortFolioSummaryServiceV2 } from '../qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.service';
import { UserManagementService } from '../services/user-management.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment/moment';


interface QualitativeData {
  uuid: string;
  titleId?: string;
  title: string;
  comment: string;
}

@Component({
  selector: 'app-qualitative-analysis',
  templateUrl: './qualitative-analysis.component.html',
  styleUrls: ['./qualitative-analysis.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class QualitativeAnalysisComponent implements OnInit, OnChanges {

  @Input() valDateUpperLimit = ""
  @Input() selectedValuationDates = [];

  // @Input() latestValuationDate;

  filteredOptions = [];
  qaTitlesInConfiguration = [];
  
  qualitativeList = [];
  activeQualitativeAnalysis  = [];

  deletedQualitativeAnalysis = [];

  qualitativeData : QualitativeData = {
    uuid: "",
    title: "",
    comment: ""
  }
  qualitativeIndex;
  title = "";
  comment = "";
  fundId = ""
  editTitle = ""

  rightOrder =[];
  leftOrder = [];
  titleExists = false;
  selectedUpperLimitDateFormId = "";
  // rteObj;
  // rteValue: string = this.rteObj.contentModule.getText();

  constructor(private modalService:NgbModal, public utilService: UtilService,
    public portfolioService : PortFolioSummaryServiceV2,
    private ds: DataService, private investmentSummaryService : InvestmentSummaryService,
    public translateService: TranslateService,
    private route: ActivatedRoute,
    public ums: UserManagementService) { }
    

  ngOnInit(): void {
    this.fundId = this.route.snapshot.queryParamMap.get('parentId');
    this.ds.getConfigurationDetails(this.fundId).subscribe(res => {
      const configuration = res.body["response"].find(t => t.configuration=="qualitativeComments")
      if(configuration) {
        this.qaTitlesInConfiguration = JSON.parse(configuration.value);
        this.filteredOptions = this.qaTitlesInConfiguration;
      }
    });
  }

  sortComments(){

    let qualitativeListTemp = [];
    let userAddedComments = [];
    this.activeQualitativeAnalysis.forEach(qa => {
      if(qa.titleId) {
        qualitativeListTemp.push({ ...qa});
      } else {
        userAddedComments.push({...qa});
      }
    });
    this.activeQualitativeAnalysis = qualitativeListTemp.concat(userAddedComments)
  }

  inputChange(value: string): void {
    if(value){
      this.title = value;
      const normalizedValue = value.trim().replace(/\s+/g, '').toLowerCase();
    
      this.filteredOptions = normalizedValue
        ? this.qaTitlesInConfiguration.filter((item) =>
            item.label?.replace(/\s+/g, '').toLowerCase().includes(normalizedValue) )
        : [...this.qaTitlesInConfiguration];
    
      const existingItem = [
        ...this.activeQualitativeAnalysis,
        ...this.qaTitlesInConfiguration
      ].find((item) => item.title?.replace(/\s+/g, '').toLowerCase() === normalizedValue );
      this.titleExists = !!value && !!existingItem;
    }
  }
  
  onOptionSelected(value: string): void {
    if(value){
        this.title = value;
        const normalizedValue = value.trim().replace(/\s+/g, '').toLowerCase();
      
        const existingItem = [
          ...this.activeQualitativeAnalysis,
          ...this.qaTitlesInConfiguration
        ].find((item) => item.title?.replace(/\s+/g, '').toLowerCase() === normalizedValue);
        this.titleExists = !!value && !!existingItem;
      
        if (this.editTitle && this.editTitle.replace(/\s+/g, '').toLowerCase() === normalizedValue) {
          this.titleExists = false;
        }
    }
    this.filteredOptions = [];
  }
  

  restQualitativeAnalysisForm(){
    this.title = ""
    this.editTitle = ""
    this.comment = ""
    this.titleExists = false
    this.qualitativeData.title = ""
    this.qualitativeData.comment = ""
    this.qualitativeData.uuid = ""
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.valDateUpperLimit || this.selectedValuationDates.length == 0) return;

    this.selectedUpperLimitDateFormId = this.getSelectedUpperLimitDateFormIdByValuationDate(this.valDateUpperLimit);
    this.getQualitiativeAnalysisFromWidget();
  }

  getQualitiativeAnalysisFromWidget() {
    this.ds.getWidgetDataFromDBV2("QUALITATIVE_ANALYSIS", this.selectedUpperLimitDateFormId).subscribe( res => {
      this.qualitativeList = res.body['response'].widgetData;
      if(!this.qualitativeList) {
        this.qualitativeList = [];
      }
      this.activeQualitativeAnalysis = [];
      this.deletedQualitativeAnalysis = [];
      this.qualitativeList.forEach((row, index) =>{
        row.uuid = row.uuid ? row.uuid:  this.utilService.getUUID();
        if(row.deleted) {
          this.deletedQualitativeAnalysis.push({...row});
        } else {
          this.activeQualitativeAnalysis.push({...row});
        }
      });

      this.activeQualitativeAnalysis.forEach((row, index) =>{
        if(!row.order){
          if(index % 2 == 0){
            row.order = 'left'
          } 
          if(index % 2 != 0){
            row.order = 'right'
          } 
        }
      })

      this.sortComments();
      console.log("Successfully Fetched Qualitative Analysis Data", res);
    }, error => {
      console.log("Failed to Fetch Qualitative Analysis Data", error);
    })
  }

  public tools: object = {
    items: ['Undo', 'Redo', '|',
        'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
        'SubScript', 'SuperScript', '|',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
        'Indent', 'Outdent', '|', 'CreateLink',
        'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  };
  public iframe: object = { enable: true };
  public height: number = 250;
  public width: number = 460;

  openQualitativeModal(content){
    this.filteredOptions = this.qaTitlesInConfiguration;
    this.modalService.open(content, { centered: true , size: "md", windowClass: 'center-popup-modal'});
  }

  AddAndSaveQualitativeAnalysis(analysis){

    if(!this.activeQualitativeAnalysis) {
      this.activeQualitativeAnalysis = [];
    }
    let obj = cloneDeep(this.qualitativeData);
    obj.uuid = this.utilService.getUUID();
    obj.title = analysis.title;
    obj.comment = analysis.comment;
    obj['userAddition'] = true;
    obj['createdOn'] = moment().toISOString();
    obj['createdBy'] = this.ums.getUserDetails().userName;
    obj['lastUpdatedOn'] = new Date();
    obj['lastModifiedByUser'] = this.ums.getUserDetails().userName;

    const titleInConfiguration = this.qaTitlesInConfiguration.find(qa => qa.label == this.title);
    if(titleInConfiguration) {
      obj["titleId"] = titleInConfiguration.id;
    }

    this.activeQualitativeAnalysis.push(obj);

    this.activeQualitativeAnalysis.forEach((row, index) =>{
      if(!row.order){
        if(index % 2 == 0){
          row.order = 'left'
        } 
        if(index % 2 != 0){
          row.order = 'right'
        } 
      }
    })


    this.restQualitativeAnalysisForm()
    this.saveQualitativeAnalysisToWidget([...this.activeQualitativeAnalysis, ...this.deletedQualitativeAnalysis]);
  }

  getSelectedUpperLimitDateFormIdByValuationDate(valDateUpperLimit){
    let selectedUpperLimit;

    //if upper limit exists, find that form from the selectedCompanydates
    if(valDateUpperLimit){
      const upperLimitDateObj = new Date(valDateUpperLimit);

      selectedUpperLimit = this.selectedValuationDates.find( form => {
        const valDate = new Date(form.valuationDate);
        return this.utilService.areTwoDatesEqual(valDate, upperLimitDateObj);
      })
    }
    //if upper limit doesnt exists, considering the latest form
    else{
      selectedUpperLimit = this.selectedValuationDates[0]; // latest form
    }

    //returning the id
    return selectedUpperLimit.id;
  }

  // getSelectedUpperLimitDateFormId(){
  //   //Checking the upper limit
  //   let valDateUpperLimit = this.valDateUpperLimit;

  //   let selectedUpperLimit = this.latestValuationDate;

  //   //returning the id
  //   return selectedUpperLimit.id;
  // }

  saveQualitativeAnalysisToWidget(dataToBeSaved){
    this.utilService.showLoadingPopup();
    
    this.ds.saveWidgetDataToDBV2("QUALITATIVE_ANALYSIS", this.selectedUpperLimitDateFormId, dataToBeSaved).subscribe( res => {
      console.log("Qualitative Analysis Saved Successfully", res);
      this.utilService.showMessage(this.translateService.getLabel("suc_data_save"), this.translateService.getLabel("ok"));
      setTimeout(() => {
        this.getQualitiativeAnalysisFromWidget();
      }, 250);
    }, error => {
      console.log("Failed to save Qualitative Analysis", error);
      this.utilService.showMessage(this.translateService.getLabel("err_failed_save_data"), this.translateService.getLabel("ok"), true);
    })
  }

  editQualitativeModal(content, data, index){
    this.qualitativeIndex = index;
    this.qualitativeData.uuid = data.uuid;
    this.qualitativeData.title = data.title;
    this.qualitativeData.comment = data.comment;
    this.qualitativeData.titleId = data.titleId;
    this.editTitle = data.title
    this.filteredOptions = this.qaTitlesInConfiguration.filter((item) => item.label.toLocaleLowerCase().includes(data.title.toLocaleLowerCase()));
    this.modalService.open(content, { centered: true , size: "lg", windowClass: 'center-popup-modal'});
  }

  editQualitativeData(){
    const updatedQualitativeList = this.activeQualitativeAnalysis.map((qualitativeAnalysis) => {
      if(qualitativeAnalysis.uuid === this.qualitativeData.uuid) {
        return { ...qualitativeAnalysis, title: this.qualitativeData.title,
          comment: this.qualitativeData.comment,
          lastUpdatedOn: moment().toISOString(),
          lastModifiedByUser: this.ums.getUserDetails().userName
        }
      } else {
        return { ...qualitativeAnalysis}
      }
    });
    this.restQualitativeAnalysisForm()
    this.saveQualitativeAnalysisToWidget([...updatedQualitativeList, ...this.deletedQualitativeAnalysis]);
  }

  deleteQualitative(index, data){
    const confirm = this.utilService.showConfirmMessage(this.translateService.getLabel("confirm_delete_qual_analysis") + "?", this.translateService.getLabel("yes"), this.translateService.getLabel("no"));
    confirm.afterClosed().subscribe(confirmation => {
      if (confirmation === "Yes") {
        this.qualitativeList = this.qualitativeList.map((qualitivativeAnalysis, mapIndex) => {
          if(qualitivativeAnalysis.uuid === data.uuid) {
            return { ...qualitivativeAnalysis, deleted: true, deletedOn : moment().toISOString(), lastModifiedByUser :this.ums.getUserDetails().userName };
          }
          return { ...qualitivativeAnalysis };
        });
        
        // this.qualitativeList.splice(index, 1);
        this.saveQualitativeAnalysisToWidget(this.qualitativeList);
      }
    });
    
  }
}
