
    <span (click)="openPopupModalComment(commentsContent)">
        <em class="fas fa-comments" style="padding-left: 10px;" matTooltipClass="mat-tooltip-sts"  
          style="margin-left: 4px; cursor: pointer;" [ngStyle]="{'color':iconColor}">
        </em>
    </span>  

  <!-- comment section -->
  <ng-template #commentsContent let-commentsModel>
    <div class="modal-header">
      <h5 class="modal-title">{{translateService.getLabel("comments")}}</h5>
      <button #closeBtn type="button" class="close" aria-label="Close" (click)="refreshListofComments(); commentsModel.dismiss('Cross click')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="modal-body-comments">
      <div class="comments-section">
        <comments-section [grpId]="grpId" [keyName]="keyName" [formId]="formId">
        </comments-section>
      </div>
    </div>
  </ng-template>